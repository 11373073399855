import React from 'react';
import { useForm } from 'react-hook-form';
import { Text, View } from 'react-native';

import { PrimaryButton } from '@components/Buttons';
import KeyboardAvoidingSafeScrollView from '@components/KeyboardAvoidingSafeScrollView';
import { TextInput } from '@components/ui/hookFormInputs/TextInput';
import { NavBar } from '@components/ui/NavBar';
import PassRulesBlock from '@components/ui/PassRulesBlock';
import { t } from '@config';
import { useCheckResetPasswordRules } from '@hooks/useCheckPasswordRules';
import { ANONYMOUS_STACK } from '@navigation/Routes';

import { AnonymousStackParamList, ScreenRoute } from '@navigation/types';

import { useResetPassword } from './hooks/reset';
import styles from './styles';
import { ResetPasswordFormData } from './types';

interface ResetPasswordPayload extends ResetPasswordFormData {
  email: string;
}

const ResetPasswordScreen = ({
  route: {
    params: { email },
  },
}: ScreenRoute<
  AnonymousStackParamList,
  ANONYMOUS_STACK.RESET_PASSWORD_SCREEN
>) => {
  let passRulesCompleted = false;
  const { control, handleSubmit, formState, setError } =
    useForm<ResetPasswordPayload>({
      defaultValues: { new_password: '', pwd_reset_code: '', email: email },
    });
  const passwordRulesState = useCheckResetPasswordRules(control);
  const { isSubmitting } = formState;
  const { mutateAsync: resetPassword } = useResetPassword();

  const handleValidate = handleSubmit(async (data: ResetPasswordPayload) => {
    passRulesCompleted = !Object.values(passwordRulesState).includes(false);
    if (!data.pwd_reset_code) {
      setError('pwd_reset_code', {
        message: t('FORM.ERRORS.isEmpty'),
      });
    }
    if (!data.new_password || !passRulesCompleted) {
      setError('new_password', {
        message: t('FORM.ERRORS.REGISTER.PASSWORD'),
      });
    } else {
      try {
        await resetPassword(data, {
          onError(err) {
            const status = err?.response?.status ?? err?.status ?? 0;
            if (status === 429) {
              setError('email', { type: 'TOO_MANY_ATTEMPTS' });
            } else if (status >= 400 && status < 500) {
              setError('pwd_reset_code', { type: 'INCORRECT_CODE' });
            } else if (status === 0) {
              setError('pwd_reset_code', { type: 'NETWORK_ERROR' });
            } else {
              setError('pwd_reset_code', { type: 'UNKNOWN_ERROR' });
            }
          },
        });
      } catch {}
    }
  });

  return (
    <KeyboardAvoidingSafeScrollView style={styles.screenView}>
      <NavBar title={t('AUTH.FORGOT_PASSWORD.RESET.TITLE')} />
      <Text style={styles.infoLabel}>
        {t('AUTH.FORGOT_PASSWORD.EMAIL_REMINDER', { email })}
      </Text>
      <View style={styles.content}>
        <TextInput
          control={control}
          name="new_password"
          onSubmitEditing={isSubmitting ? () => null : handleValidate}
          placeholder={t('FORM.RESET_PASSWORD_PLACEHOLDER')}
          returnKeyType="next"
          secureTextEntry
          textContentType="password"
          label={t('FORM.NEW_PWD')}
          rules={{ required: true }}
          errorMessage={(fieldError) =>
            fieldError
              ? fieldError.type === 'server' && fieldError.message
                ? fieldError.message
                : t('FORM.ERRORS.RESET_PASSWORD.2')
              : ''
          }
        />
        <TextInput
          control={control}
          autoCapitalize="none"
          name={'pwd_reset_code'}
          inputMode="numeric"
          onSubmitEditing={isSubmitting ? () => null : handleValidate}
          placeholder={t('FORM.RESET_CODE_EMAIL_PLACEHOLDER')}
          returnKeyType="send"
          label={t('FORM.CODE_EMAIL')}
          rules={{ required: true }}
          errorMessage={(fieldError) =>
            fieldError
              ? fieldError.type === 'server' && fieldError.message
                ? fieldError.message
                : t('FORM.ERRORS.RESET_PASSWORD.1')
              : ''
          }
        />
        <PassRulesBlock passwordRulesState={passwordRulesState} />
      </View>
      <View style={styles.spacer} />
      <PrimaryButton
        isLoading={isSubmitting}
        onPress={handleValidate}
        label={t('AUTH.FORGOT_PASSWORD.BUTTON')}
      />
    </KeyboardAvoidingSafeScrollView>
  );
};

export default ResetPasswordScreen;
