import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
  },

  inputContainer: {
    flex: 1,
    marginHorizontal: 24,
  },

  passRulesContainer: {
    marginTop: 10,
  },
});
