import { StepChoice } from './SubStepVariants/StepChoice';
import { StepCustom } from './SubStepVariants/StepCustom';
import { StepInput } from './SubStepVariants/StepInput';

export type SubStepCollection = {
  choice: typeof StepChoice;
  input: typeof StepInput;
  custom: typeof StepCustom;
};

export const subStepCollection: SubStepCollection = {
  choice: StepChoice,
  custom: StepCustom,
  input: StepInput,
};
