import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { Trackers } from '@commonTypes/apiResponses/Trackers';
import { t } from '@config';
import { getAgeInMonths } from '@tools/date';

import { FamilySelection } from '@api-requests/api/cms/selected/types';

import SelectedSectionUI from './SelectedSectionUI';

const styles = StyleSheet.create({ first: { marginTop: 4 } });

const getChildAgeText = (name: string, birthday: string) => {
  const ageMonths = Math.floor(getAgeInMonths(birthday));
  if (ageMonths === 0) {
    return t('HOME.SELECTED.CHILD_SUBTITLE_ALT', { name });
  }
  if (ageMonths >= 24) {
    const ageYear = Math.floor(ageMonths / 12);
    return t('HOME.SELECTED.CHILD_SUBTITLE_YEAR', { name, ageYear });
  }
  return t('HOME.SELECTED.CHILD_SUBTITLE', { name, ageMonths });
};

interface SelectedForYouProps {
  style?: StyleProp<ViewStyle>;
  selection: FamilySelection | undefined;
  screen: string;
  trackers?: Trackers | undefined;
  amenorrheaWeeks: number | undefined;
  disabled?: boolean;
}
const SelectedForYouUI = ({
  style,
  selection,
  screen,
  trackers,
  amenorrheaWeeks,
  disabled,
}: SelectedForYouProps) => (
  <View style={style}>
    {!!selection?.pregnancy.length && (
      <SelectedSectionUI
        style={styles.first}
        title={t('HOME.SELECTED.PREGNANCY_SUBTITLE', {
          amenorrheaWeeks,
        })}
        list={selection.pregnancy}
        screen={screen}
        trackers={trackers}
        disabled={disabled}
      />
    )}
    {!!selection?.children.length &&
      selection.children.map(([child, list], index) => (
        <SelectedSectionUI
          key={index}
          style={
            index === 0 && !selection.pregnancy.length
              ? styles.first
              : undefined
          }
          title={getChildAgeText(child.firstName, child.birthDay)}
          list={list}
          screen={screen}
          trackers={trackers}
          disabled={disabled}
        />
      ))}
    {!!selection?.fallback.length && (
      <SelectedSectionUI
        list={selection.fallback}
        screen={screen}
        trackers={trackers}
        disabled={disabled}
      />
    )}
  </View>
);

export default SelectedForYouUI;
