import React from 'react';

import { logOnboardingStepAnswer } from '@analytics/events';
import { ImageTemplate } from '@components/ui/Templates/ImageTemplate';
import { StepTemplate } from '@components/ui/Templates/StepTemplate';
import { ONBOARDING_STACK } from '@navigation/Routes';
import { remoteConfig } from '@tools/firebase';

import {
  IImageTemplate,
  IStepTemplate,
} from '@api-requests/api/cms/scenarii/types';
import { OnboardingStackParamList, ScreenRoute } from '@navigation/types';

import { handleRemoteFormNavigation } from './handleRemoteFormNavigation';

export function RemoteFormImageTemplateScreen({
  route: {
    params: { workflow, currentIndex },
  },
}: ScreenRoute<
  OnboardingStackParamList,
  ONBOARDING_STACK.REMOTE_FORM_IMAGE_TEMPLATE_SCREEN
>) {
  const { __component, type, ...props } = workflow[
    currentIndex
  ] as IImageTemplate;
  return (
    <ImageTemplate
      {...props}
      showBack
      onNext={() =>
        handleRemoteFormNavigation({ workflow, targetIndex: currentIndex + 1 })
      }
    />
  );
}

export function RemoteFormStepTemplateScreen({
  route: {
    params: { workflow, currentIndex },
  },
}: ScreenRoute<
  OnboardingStackParamList,
  ONBOARDING_STACK.REMOTE_FORM_STEP_TEMPLATE_SCREEN
>) {
  const scenario = remoteConfig().getValue('onboardingScenario').asString();

  const { name, steps } = workflow[currentIndex] as IStepTemplate;
  return (
    <StepTemplate
      name={name}
      steps={steps}
      showBack
      onAnswerPress={(answer, stepIndex) => {
        logOnboardingStepAnswer({
          scenario,
          stepTitle: steps[stepIndex].title,
          stepScreenName: `${name} ${stepIndex}`,
          answer: answer.value,
        });
      }}
      onEndReached={() =>
        handleRemoteFormNavigation({ workflow, targetIndex: currentIndex + 1 })
      }
    />
  );
}
