import React from 'react';
import { Text, TextProps } from 'react-native';

interface TextColorProps extends TextProps {
  children: string;
}

/*
 * Same usage as default Text component.
 * Handles color instructions within its string:
 * wrap desired color with *
 * ex: `Parrainez *${color}*vos amis`
 * will change the color of 'vos amis'
 */
const TextColor = ({ children, ...props }: TextColorProps) => {
  let tempColor: string;
  return (
    <Text {...props}>
      {children.split('*').map((str, index) => {
        if (index % 2 === 0) {
          return (
            <Text style={{ color: tempColor }} key={index}>
              {str}
            </Text>
          );
        } else {
          tempColor = str;
          return null;
        }
      })}
    </Text>
  );
};

export default TextColor;
