import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { useSelector } from '@commonTypes/redux';
import useCheckIfPregnant from '@hooks/useCheckIfPregnant';
import { navigate } from '@navigation/Actions';
import { COMMON_SCREEN_STACK } from '@navigation/Routes';
import { useRoute } from '@react-navigation/native';

import { AddMemberOption, ChildOption, PregnancyOption } from './FamilyOptions';

interface FamilyMembersListProps {
  style?: StyleProp<ViewStyle>;
  onAddPress: () => void;
}
const FamilyMembersList = ({ style, onAddPress }: FamilyMembersListProps) => {
  const route = useRoute();
  const isPregnant = useCheckIfPregnant();
  const childList = useSelector((state) => state.user.data.kids);

  if (!childList && !isPregnant) {
    return null;
  }

  return (
    <View style={style}>
      {isPregnant ? (
        <PregnancyOption
          onPress={() =>
            navigate(COMMON_SCREEN_STACK.PREGNANCY_DETAIL_SCREEN, {
              originScreen: route.name,
            })
          }
        />
      ) : null}
      {childList?.map((child) => (
        <ChildOption
          key={child.id}
          avatar={child.image}
          sex={child.sex}
          firstName={child.firstName}
          onPress={() =>
            navigate(COMMON_SCREEN_STACK.KID_DETAIL_SCREEN, { kidId: child.id })
          }
        />
      ))}
      <AddMemberOption onPress={onAddPress} />
    </View>
  );
};

export default FamilyMembersList;
