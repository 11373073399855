import { StyleSheet } from 'react-native';

import DEVICE from '@resources/constants/device';
import { Colors, Fonts } from '@resources/themes';

const IMAGE_SIZE = 0.65 * DEVICE.WINDOW_WIDTH;

export default StyleSheet.create({
  background: { backgroundColor: Colors.white100 },
  scrollContent: { paddingBottom: 16, minHeight: '100%' },
  fill: { height: '100%', width: '100%' },
  container: {
    flex: 1,
    paddingHorizontal: 24,
    gap: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },

  backButton: { position: 'absolute', top: 32, left: 16 },

  imageContainer: { width: IMAGE_SIZE, aspectRatio: 1 },
  image: { width: '100%', height: '100%' },

  textContainer: { gap: 16, alignItems: 'center' },
  title: {
    textAlign: 'center',
    fontFamily: Fonts.type.semiBold,
    color: Colors.duck800,
    fontSize: Fonts.size.h6,
  },
  description: {
    textAlign: 'center',
    fontFamily: Fonts.type.regular,
    color: Colors.duck800,
    fontSize: Fonts.size.title,
  },
});
