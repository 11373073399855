import React, { useState } from 'react';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import 'dayjs/locale/fr';

import { logUploadRetry } from '@analytics/events';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import { S3SignedUrl } from '@commonTypes/S3';
import { PrimaryButton } from '@components/Buttons';
import AppIcon from '@components/ui/Icon';
import { PressableNative } from '@components/ui/Pressables/PressableNative';
import { t } from '@config';
import { Colors } from '@resources/themes';
import { UseMutateFunction } from '@tanstack/react-query';

import styles from '@components/Modal/PictureResultPreview/styles';

import VideoComponent from './VideoComponent';
import Modal from '../';
interface PicturePreviewProps {
  onCancel?: () => void;
  onUploadStarted?: () => void;
  onUploadCompleted: (arg: string) => void;
  uploadFile: UseMutateFunction<
    ReducedAxiosResponse<Pick<S3SignedUrl, 'Key' | 'readUrl'>>,
    any,
    {
      uri: string;
      onUploadProgress: (progress: number) => void;
    }
  >;
  uri: string;
  assetType: 'photo' | 'video';
  iconType?: 'chat' | 'validate';
}

const VisiblePictureResultPreview = ({
  onCancel,
  onUploadStarted,
  onUploadCompleted,
  uploadFile,
  uri,
  assetType,
  iconType = 'validate',
}: PicturePreviewProps) => {
  const [error, setError] = useState('');

  const [uploading, setUploading] = useState(false);
  const [completed, setCompleted] = useState(0);
  const startFileUpload = () => {
    setCompleted(0);
    setUploading(true);
    onUploadStarted?.();
    uploadFile(
      {
        uri,
        onUploadProgress: setCompleted,
      },
      {
        onSuccess: (data) => {
          onUploadCompleted(data.data.readUrl);
        },
        onError: (err: Error) => {
          if (err.message === 'Network Error') {
            setError(t('CHAT.UPLOAD.NETWORK_ERROR'));
          } else {
            setError(
              t('CHAT.UPLOAD.ERROR', {
                message: err.message ?? '',
              }),
            );
          }
        },
        onSettled: () => {
          setUploading(false);
        },
      },
    );
  };

  return (
    <Modal
      isVisible
      onClose={uploading ? () => null : onCancel}
      withBackdrop
      style={styles.container}
      animationType="fade"
    >
      <View style={styles.contentContainer}>
        {assetType !== 'video' && uri && (
          <Image
            // force correct rendering on iOS when photo changes
            key={uri}
            source={{ uri }}
            style={styles.image}
            resizeMode="cover"
          />
        )}
        {assetType === 'video' && uri && <VideoComponent uri={uri} />}
        {!uri && (
          <View style={styles.loaderContainer}>
            <ActivityIndicator color={Colors.white100} size="large" />
          </View>
        )}

        <PressableNative
          onPress={startFileUpload}
          style={[
            styles.sendButton,
            iconType === 'chat' ? styles.greenButton : styles.whiteButton,
            uploading && styles.disabledButton,
          ]}
          disabled={uploading}
        >
          {iconType === 'chat' ? (
            <AppIcon name="Send" color={Colors.white100} />
          ) : (
            <AppIcon name={'CheckIconPink'} size={27} />
          )}
        </PressableNative>
        {Boolean(onCancel) && (
          <PressableNative
            onPress={onCancel}
            style={[
              styles.sendButton,
              styles.cancelButton,
              iconType === 'chat' ? styles.greenButton : styles.whiteButton,
              uploading && styles.disabledButton,
            ]}
            disabled={uploading}
          >
            <AppIcon name={'CrossIcon'} size={27} color={Colors.pink700} />
          </PressableNative>
        )}
        {Boolean(uploading || error) && (
          <View style={styles.loadingContainer}>
            <View style={styles.loadingOpacity} />
            {uploading && <ActivityIndicator />}
            <Text style={error ? styles.errorText : styles.loadingText}>
              {error || t('CHAT.UPLOAD.IN_PROGRESS', { completed: completed })}
            </Text>
            {Boolean(error) && (
              <PrimaryButton
                style={styles.retryButton}
                isLoading={false}
                onPress={() => {
                  setError('');
                  logUploadRetry();
                  startFileUpload();
                }}
                label="Réessayer"
              />
            )}
          </View>
        )}
      </View>
    </Modal>
  );
};

const PictureResultPreview = (
  props: PicturePreviewProps & { isVisible: boolean },
) => {
  const isVisible = props.isVisible;
  return isVisible ? <VisiblePictureResultPreview {...props} /> : null;
};

export default PictureResultPreview;
