import { resetAll } from '@redux/reduxReset';
import { createSlice } from '@reduxjs/toolkit';

const initialState = 0;

const sleepEntriesCountSlice = createSlice({
  name: 'sleepEntriesCount',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    add: (state) => state + 1,
    reset: () => initialState,
  },
});

export const sleepEntriesCountCreators = sleepEntriesCountSlice.actions;
export default sleepEntriesCountSlice.reducer;
