import React from 'react';
import { Text, View } from 'react-native';

import { PressableNative } from '@components/ui/Pressables/PressableNative';
import Check from '@resources/svg/ic_check_white.svg';

import styles from './styles';
import type { Props } from './types';

const CheckboxText = ({
  customStyle = null,
  isError,
  onChange,
  selected,
  title,
}: Props) => {
  return (
    <View style={[styles.cguInputTouchable, customStyle]}>
      <PressableNative
        onPress={onChange}
        style={[styles.cguInput, isError && styles.cguInputError]}
        testID="CGUCGVButton"
      >
        {selected ? <Check height={15} /> : <></>}
      </PressableNative>

      <Text style={[styles.cguText, isError && styles.cguTextError]}>
        {title}
      </Text>
    </View>
  );
};

export default React.memo<Props>(CheckboxText);
