import { StyleSheet } from 'react-native';

import { Colors, Fonts, Metrics } from '@resources/themes';

export default StyleSheet.create({
  cguInput: {
    alignItems: 'center',
    backgroundColor: Colors.yellow500,
    borderRadius: 4,
    height: 25,
    justifyContent: 'center',
    width: 25,
  },

  cguInputError: {
    borderColor: Colors.pink700,
    borderWidth: 1,
  },

  cguInputTouchable: {
    alignItems: 'center',
    flexDirection: 'row',
  },

  cguText: {
    color: Colors.duck800,
    fontFamily: Fonts.type.semiBold,
    fontSize: Fonts.size.mediumUp,
    marginLeft: 15,
    maxWidth: Metrics.screenWidth / 1.2 - 40,
  },

  cguTextError: {
    color: Colors.pink700,
  },
});
