import React from 'react';

import { BorderlessButton, PrimaryButton } from '@components/Buttons';
import AlertModal from '@components/Modal/AlertModal';
import { t } from '@config';

interface ArchivePregnancyModalProps {
  isVisible: boolean;
  onPress: () => void;
  onClose: () => void;
  isSubmitting: boolean;
}
const ArchivePregnancyModal = ({
  isVisible,
  onPress,
  onClose,
  isSubmitting,
}: ArchivePregnancyModalProps) => {
  return (
    <AlertModal
      isVisible={isVisible}
      illustrationSource={'BabyIcon'}
      title={t('FORM.ARCHIVE_PREGNANCY_MODAL.TITLE')}
      description={t('FORM.ARCHIVE_PREGNANCY_MODAL.DESCRIPTION')}
      onClose={onClose}
    >
      <PrimaryButton
        label={t('FORM.ARCHIVE_PREGNANCY_MODAL.CTA1')}
        onPress={onPress}
        isLoading={isSubmitting}
      />
      <BorderlessButton
        label={t('FORM.ARCHIVE_PREGNANCY_MODAL.CTA2')}
        onPress={onClose}
        isLoading={false}
        disabled={isSubmitting}
      />
    </AlertModal>
  );
};

export default ArchivePregnancyModal;
