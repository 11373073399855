export enum SUBSCRIPTION_STATUS {
  // This is an active plan (user is paying)
  ACTIVE = 'active',
  // We chose to give access to this user for free
  BONUS = 'bonus',
  // A company is paying for this user to access
  AFFILIATED = 'affiliated',
  // This user chose a subscription with a free trial period
  TRIALING = 'trialing',
  // This user has a problem with its payment
  PAST_DUE = 'past_due',
}
