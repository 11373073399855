import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  container: {
    position: 'absolute',
    right: 8,
    top: 8,
    paddingHorizontal: 8,
    borderRadius: 12,
    backgroundColor: Colors.yellow500,
  },

  paddingSmall: {
    paddingHorizontal: 3,
  },

  label: {
    color: Colors.white100,
    fontFamily: Fonts.type.bold,
    fontSize: Fonts.size.small,
    lineHeight: 20,
  },

  labelSmall: {
    fontSize: Fonts.size.ten,
    lineHeight: 12,
  },
});
