import {
  logFamilyMasterDeactivatePending,
  logFamilyMasterShareAccount,
  logFamilySlaveAcceptInvitation,
  logFamilySlaveRefusesInvitation,
} from '@analytics/events';
import { usePopup } from '@components/ui/Popup/hook';
import { t } from '@config';
import useAuthenticatedQuery from '@hooks/useAuthenticatedQuery';
import useMutationCall from '@hooks/useMutationCall';
import { useQueryClient } from '@tanstack/react-query';

import familyPendingInvite from './endPoint';
import { DetailedFamilyInvitation } from './types';
import { useRefreshFamily } from '../family/utils/useRefreshFamily';

export const FAMILY_PENDING_INVITE_QUERY_KEYS = {
  create: ['familyPendingInviteCreate'],
  accept: ['familyPendingInviteAccept'],
  decline: ['familyPendingInviteDecline'],
  revoke: ['familyPendingInviteRevoke'],
  getReceived: ['familyPendingInviteGetReceived'],
  getSent: ['familyPendingInviteGetSent'],
  getQRCode: ['familyPendingInviteGetQRCode'],
  acceptQRCode: ['familyPendingInviteAcceptQRCode'],
};

export const useCreateFamilyInvite = () => {
  const queryClient = useQueryClient();
  return useMutationCall({
    mutationFn: familyPendingInvite.create,
    mutationKey: FAMILY_PENDING_INVITE_QUERY_KEYS.create,

    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: FAMILY_PENDING_INVITE_QUERY_KEYS.getSent,
        type: 'all',
      });
      logFamilyMasterShareAccount();
    },
  });
};

export const useGetQRCode = () => {
  return useAuthenticatedQuery({
    queryFn: familyPendingInvite.getQRCode,
    queryKey: FAMILY_PENDING_INVITE_QUERY_KEYS.getQRCode,
    refetchInterval: 10 * 60 * 1000,
  });
};
export const useAcceptQRCode = () => {
  return useMutationCall({
    mutationFn: familyPendingInvite.acceptQRCode,
    mutationKey: FAMILY_PENDING_INVITE_QUERY_KEYS.getQRCode,
  });
};

export const useRevokeFamilyInvite = () => {
  const queryClient = useQueryClient();
  return useMutationCall({
    mutationFn: familyPendingInvite.revoke,
    mutationKey: FAMILY_PENDING_INVITE_QUERY_KEYS.revoke,

    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: FAMILY_PENDING_INVITE_QUERY_KEYS.getSent,
        type: 'all',
      });
      logFamilyMasterDeactivatePending();
    },
  });
};

export const useAcceptFamilyInvite = () => {
  const popup = usePopup();
  const queryClient = useQueryClient();
  const refreshFamily = useRefreshFamily();
  return useMutationCall({
    mutationFn: familyPendingInvite.accept,
    mutationKey: FAMILY_PENDING_INVITE_QUERY_KEYS.accept,

    onSuccess: () => {
      refreshFamily();
      logFamilySlaveAcceptInvitation();
    },

    onError: () => {
      popup(t('FAMILY_ACCOUNT.ERROR.API_GENERIC'));
    },

    onSettled: () => {
      queryClient.resetQueries({
        queryKey: FAMILY_PENDING_INVITE_QUERY_KEYS.getReceived,
        type: 'all',
      });
    },
  });
};

export const useDeclineFamilyInvite = () => {
  const popup = usePopup();
  const queryClient = useQueryClient();
  return useMutationCall({
    mutationFn: familyPendingInvite.decline,
    mutationKey: FAMILY_PENDING_INVITE_QUERY_KEYS.decline,

    onSuccess: () => {
      logFamilySlaveRefusesInvitation();
    },

    onError: () => {
      popup(t('FAMILY_ACCOUNT.ERROR.API_GENERIC'));
    },

    onSettled: () => {
      queryClient.resetQueries({
        queryKey: FAMILY_PENDING_INVITE_QUERY_KEYS.getReceived,
        type: 'all',
      });
    },
  });
};

const getInvite = (invite?: DetailedFamilyInvitation | null) =>
  invite && 'appUser' in invite && invite.appUser
    ? (invite as DetailedFamilyInvitation)
    : undefined;

export const useReceivedFamilyInvite = () =>
  useAuthenticatedQuery({
    queryFn: familyPendingInvite.getReceived,
    queryKey: FAMILY_PENDING_INVITE_QUERY_KEYS.getReceived,
    select: (res) => getInvite(res?.data),
    staleTime: 0,
    gcTime: 0,
  });

export const useSentFamilyInvite = () =>
  useAuthenticatedQuery({
    queryFn: familyPendingInvite.getSent,
    queryKey: FAMILY_PENDING_INVITE_QUERY_KEYS.getSent,
    select: (res) => getInvite(res?.data),
    staleTime: 0,
    gcTime: 0,
  });
