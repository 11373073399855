import React, { useState } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import KeyboardAvoidingSafeView from '@components/KeyboardAvoidingSafeView';
import { goBack } from '@navigation/Actions';

import ProgressTopBar from './ProgressTopBar';
import styles from './styles';
import { subStepCollection } from './subStepCollection';
import { StepTypes, SubStepAnswer } from './types';

interface StepTemplateProps {
  name: string;
  steps: Array<StepTypes>;
  onAnswerPress?: (
    answer: SubStepAnswer,
    stepIndex: number,
    allAnswers: (SubStepAnswer | null)[],
  ) => Promise<boolean> | void;
  onEndReached?: (
    lastAnswer: SubStepAnswer,
    allAnswers: (SubStepAnswer | null)[],
  ) => void;
  showBack?: boolean;
  progressBarStyle?: StyleProp<ViewStyle>;
  progressContainerStyle?: StyleProp<ViewStyle>;
}
export function StepTemplate({
  name,
  steps,
  onAnswerPress,
  onEndReached,
  showBack,
  progressBarStyle,
  progressContainerStyle,
}: StepTemplateProps) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const currentStep = steps[currentStepIndex];

  const handleBackPress = () => {
    if (currentStepIndex === 0) {
      goBack();
    } else if (currentStepIndex > 0) {
      setCurrentStepIndex((prev) => prev - 1);
    }
  };

  const [answers, setAnswers] = useState<(SubStepAnswer | null)[]>(
    Array(steps.length).fill(null),
  );

  const handleAnswerPress = async (answer: SubStepAnswer) => {
    const newAnswers = [...answers];
    newAnswers[currentStepIndex] = answer;

    let goOn = true;
    if (onAnswerPress) {
      setAnswers(newAnswers);
      const ok = await onAnswerPress(answer, currentStepIndex, newAnswers);
      if (typeof ok === 'boolean' && !ok) {
        goOn = false;
      }
    }
    if (currentStepIndex + 1 < steps.length) {
      if (goOn) {
        setCurrentStepIndex((prev) => prev + 1);
      }
    } else if (onEndReached) {
      const allAnswers = [...answers];
      allAnswers[currentStepIndex] = answer;
      onEndReached(answer, allAnswers);
    }
  };

  const SubStepComponent = subStepCollection[currentStep.type];

  return (
    <KeyboardAvoidingSafeView style={styles.container}>
      <ProgressTopBar
        title={name}
        progress={currentStepIndex + 1}
        total={steps.length}
        showBack={showBack}
        onPress={handleBackPress}
        progressBarStyle={progressBarStyle}
        progressContainerStyle={progressContainerStyle}
      />
      <SubStepComponent
        // @ts-ignore
        step={currentStep}
        onAnswerPress={handleAnswerPress}
        previousAnswers={answers}
      />
    </KeyboardAvoidingSafeView>
  );
}
