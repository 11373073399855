import { useSelector } from '@commonTypes/redux';

import { Product } from '@api-requests/api/main/payment/products/types';

/**
 * Trial being reserved only for new customers
 * we want to adapt our subscribe modals wording accordingly
 */
export const useSubscribeModalWordingContext = (
  selectedPlan: Product | null,
) => {
  const newCustomer = useSelector((state) => !state.user?.data?.paidPlan);
  return {
    context: (selectedPlan?.withTrial && newCustomer ? 'TRIAL' : '') as
      | 'TRIAL'
      | '',
    canTrial: newCustomer,
  };
};
