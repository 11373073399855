import React from 'react';
import { InteractionManager, StyleSheet, Text } from 'react-native';

import { t } from '@config';
import { navigate } from '@navigation/Actions';
import { COMMON_SCREEN_STACK } from '@navigation/Routes';
import { Colors, Fonts } from '@resources/themes';

import { StaticContentsSlug } from '@screens/StaticContentsScreen/types';

const styles = StyleSheet.create({
  legal: {
    color: Colors.duck700,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.small,
  },
  accent: { fontFamily: Fonts.type.semiBold },
});

const goToLegal = (slug: StaticContentsSlug) =>
  navigate(COMMON_SCREEN_STACK.STATIC_CONTENTS_SCREEN, { slug });

function LegalText({ onActionPress }: { onActionPress: () => void }) {
  return (
    <Text style={styles.legal}>
      {t('AUTH.SIGN_UP.LEGAL')}
      <Text
        style={styles.accent}
        onPress={() => {
          onActionPress();
          InteractionManager.runAfterInteractions(() =>
            goToLegal(StaticContentsSlug.privacy),
          );
        }}
      >
        {t('GLOBAL.LEGAL.PRIVACY').concat('.')}
      </Text>
    </Text>
  );
}

export default LegalText;
