import MarkdownIt from 'markdown-it';
import React, { useCallback, useMemo } from 'react';
import { Linking, StyleProp, Text, View, ViewStyle } from 'react-native';
import NativeMarkdown, {
  ASTNode,
  hasParents,
  RenderRules,
} from 'react-native-markdown-display';
import { useStore } from 'react-redux';

import { GlobalState } from '@commonTypes/redux';
import { handleDynamicLinks } from '@navigation/handleDeepLink';
import { parseDeepLink } from '@tools/parseDeepLink';

import markdownStyle from './styles';

export enum RULES {
  BLOCKQUOTE = 'blockquote',
  BULLET_LIST = 'bullet_list',
  ORDERED_LIST = 'ordered_list',
  LIST_ITEM = 'list_item',
  LINK = 'link',
  TEXT = 'text',
  TEXTGROUP = 'textgroup',
  HR = 'hr',
  CODE_INLINE = 'code_inline',
}

const md = MarkdownIt({
  typographer: true,
  linkify: true, //turn on link redirection in markdown
});

const useOnLinkPress = () => {
  const store = useStore<GlobalState>();
  return useCallback(
    (url: string) => {
      if (parseDeepLink(url)) {
        handleDynamicLinks(url, store);
        return;
      }
      Linking.canOpenURL(url).then(
        (supported) => supported && Linking.openURL(url),
      );
    },
    [store],
  );
};

const MDLink = ({
  args: { node, children, styles },
}: {
  args: {
    node: ASTNode;
    children: React.ReactNode[];
    styles: any;
  };
}) => {
  const onLinkPress = useOnLinkPress();
  return (
    <Text style={styles.link} onPress={() => onLinkPress(node.attributes.href)}>
      {children}
    </Text>
  );
};

const defaultRules: RenderRules = {
  [RULES.HR]: (node, __, ___, styles) => (
    <View key={node.key} style={styles.hr} />
  ),
  [RULES.BLOCKQUOTE]: (node, _, __, styles) => {
    const title = node.children?.[0]?.children?.[0]?.children?.[0]?.content;
    const quote =
      node.children?.[1]?.children?.[0]?.children?.[0]?.content ??
      node.children?.[0]?.children?.[0]?.children?.[2]?.content;
    return (
      <View key={node.key} style={styles.blockquotes}>
        <View style={styles.bar} />
        <View style={styles.quoteContent}>
          {title && (
            <Text style={quote ? styles.quoteTitle : styles.quote}>
              {title}
            </Text>
          )}
          {quote && <Text style={styles.quote}>{quote}</Text>}
        </View>
      </View>
    );
  },
  [RULES.LIST_ITEM]: (node, children, parentNodes, styles) => {
    if (hasParents(parentNodes, RULES.BULLET_LIST)) {
      return (
        <View key={node.key} style={styles.list}>
          <View style={styles.bulletContainer}>
            <View style={styles.bullet} />
          </View>
          <View style={styles.bulletContent}>{children}</View>
        </View>
      );
    }
    if (hasParents(parentNodes, RULES.ORDERED_LIST)) {
      return (
        <View key={node.key} style={styles.list}>
          <Text style={styles.listNumber}>{node.index + 1}.</Text>
          <Text>{children}</Text>
        </View>
      );
    }
    return null;
  },
  [RULES.CODE_INLINE]: (node, _, __, styles) => (
    <Text key={node.key} style={styles.codeInline}>
      {node.content}
    </Text>
  ),
  [RULES.TEXT]: (node, _, __, styles, inheritedStyles) => (
    <Text key={node.key} style={[styles.text, inheritedStyles]}>
      {node.content}
    </Text>
  ),
  [RULES.LINK]: (node, children, _, styles) => {
    return <MDLink key={node.key} args={{ node, children, styles }} />;
  },
};

interface MarkdownProps {
  style?: StyleProp<ViewStyle>;
  content: React.ReactNode;
  overriddenStyles?: any;
  overriddenRules?: RenderRules;
}
function Markdown({
  style,
  content,
  overriddenStyles,
  overriddenRules,
}: MarkdownProps) {
  const styles = useMemo(
    () => ({ ...markdownStyle, ...overriddenStyles }),
    [overriddenStyles],
  );
  const rules = useMemo(
    () => ({ ...defaultRules, ...overriddenRules }),
    [overriddenRules],
  );

  return (
    <View style={style}>
      <NativeMarkdown markdownit={md} style={styles} rules={rules}>
        {content}
      </NativeMarkdown>
    </View>
  );
}

export default React.memo(Markdown);
