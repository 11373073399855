import React from 'react';
import { Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { PrimaryButton } from '@components/Buttons';
import { t } from '@config';
import { navigate } from '@navigation/Actions';
import { ANONYMOUS_STACK } from '@navigation/Routes';
import Tick from '@resources/svg/tick.svg';

import styles from './styles';

const goToLogin = () => navigate(ANONYMOUS_STACK.LOGIN);

const SuccessResetPasswordScreen = () => {
  return (
    <SafeAreaView style={styles.screen}>
      <Text style={styles.heading}>
        {t('AUTH.FORGOT_PASSWORD.SUCCESS.TITLE')}
      </Text>
      <View style={styles.infoContainer}>
        <Tick height={50} width={50} />
        <Text style={styles.title}>
          {t('AUTH.FORGOT_PASSWORD.SUCCESS.VALIDATION_MSG')}
        </Text>
        <Text style={styles.description}>
          {t('AUTH.FORGOT_PASSWORD.SUCCESS.REDIRECTION_MSG')}
        </Text>
      </View>
      <PrimaryButton
        onPress={goToLogin}
        label={t('AUTH.FORGOT_PASSWORD.SUCCESS.BUTTON')}
        isLoading={false}
      />
    </SafeAreaView>
  );
};

export default SuccessResetPasswordScreen;
