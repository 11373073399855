import React, { useCallback } from 'react';
import { Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { logTutorialBegin } from '@analytics/events';
import { useNotificationPermissionScenario } from '@api-requests/api/cms/queries/useNotificationPermissionScenario';
import { useRemoteOnboardingScenarios } from '@api-requests/api/cms/scenarii/onboarding-scenarios/hooks';
import { useSelector } from '@commonTypes/redux';
import { PrimaryButton } from '@components/Buttons';
import Image from '@components/Image';
import { t } from '@config/bootstrap/i18n';
import { navigate } from '@navigation/Actions';
import { NAVIGATOR_NAMES, ONBOARDING_STACK } from '@navigation/Routes';
import { useFocusEffect } from '@react-navigation/native';
import doctorImg from '@resources/images/illu_docteur-seule-chatain.webp';
import { remoteConfig } from '@tools/firebase';

import styles from './styles';

const scenario = remoteConfig().getValue('onboardingScenario').asString();

const goToInfoScreen = () =>
  navigate(NAVIGATOR_NAMES.ONBOARDING_NAVIGATOR, {
    screen: ONBOARDING_STACK.INTRO_FORM_SCREEN1,
  });

const OnBoardingScreen = () => {
  useRemoteOnboardingScenarios();
  useNotificationPermissionScenario();

  const name = useSelector((state) => state.user.data.firstName ?? '');

  useFocusEffect(
    useCallback(() => {
      logTutorialBegin({ scenario: scenario });
    }, []),
  );

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.imageContainer}>
        <Image style={styles.image} source={doctorImg} resizeMode="contain" />
      </View>
      <Text style={styles.primaryText}>
        {t('AUTH.SUCCESS_REGISTER.TITLE', { name })}
      </Text>
      <Text style={styles.secondaryText}>
        {t('AUTH.SUCCESS_REGISTER.DESCRIPTION')}
      </Text>
      <PrimaryButton
        onPress={goToInfoScreen}
        testID="successRegisterButton"
        label={t('AUTH.SUCCESS_REGISTER.BUTTON')}
        isLoading={false}
      />
    </SafeAreaView>
  );
};

export default OnBoardingScreen;
