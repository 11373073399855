import React from 'react';
import {
  ActivityIndicator,
  GestureResponderEvent,
  PressableProps,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import { Linecap } from 'react-native-svg';

import AppIcon from '@components/ui/Icon';
import { PressableNative } from '@components/ui/Pressables/PressableNative';
import { useForcedDebouncedCallback } from '@hooks/useForcedDebouncedCallback';
import { IconName } from '@resources/svg';
import { Colors, Fonts } from '@resources/themes';

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 40,
    height: 48,
    borderRadius: 24,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  disabled: { opacity: 0.5 },
  hidden: { opacity: 0 },

  loader: StyleSheet.absoluteFillObject,

  inner: {
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  reverse: { flexDirection: 'row-reverse' },
  label: {
    fontFamily: Fonts.type.semiBold,
    fontSize: Fonts.size.input,
    textAlign: 'center',
  },
});

export interface ButtonUIProps
  extends Omit<PressableProps, 'style' | 'onPress'> {
  style?: StyleProp<ViewStyle>;
  onPress: (event: GestureResponderEvent) => void;
  label?: string | undefined;
  iconName?: IconName;
  iconSize?: number;
  iconStrokeWidth?: number;
  iconStrokeLinecap?: Linecap;
  textColor?: string;
  isLoading: boolean;
  inverted?: boolean;
}
function ButtonUI({
  style,
  onPress,
  label,
  textColor = Colors.duck800,
  iconName,
  iconSize = 24,
  iconStrokeWidth,
  iconStrokeLinecap,
  disabled,
  isLoading,
  inverted,
  ...pressableProps
}: ButtonUIProps) {
  const debouncedPress = useForcedDebouncedCallback(onPress, 300, {
    leading: true,
    trailing: false,
  });

  return (
    <PressableNative
      style={[styles.container, disabled && styles.disabled, style]}
      onPress={debouncedPress}
      disabled={disabled || isLoading}
      {...pressableProps}
    >
      {isLoading && (
        <ActivityIndicator
          style={styles.loader}
          color={textColor}
          size="large"
          testID="activityIndicator"
        />
      )}
      <View
        style={[
          styles.inner,
          isLoading && styles.hidden,
          inverted && styles.reverse,
        ]}
      >
        {iconName && (
          <AppIcon
            size={iconSize}
            name={iconName}
            color={textColor}
            strokeWidth={iconStrokeWidth}
            strokeLinecap={iconStrokeLinecap}
          />
        )}
        {label && (
          <Text style={[styles.label, { color: textColor }]}>{label}</Text>
        )}
      </View>
    </PressableNative>
  );
}

export default ButtonUI;
