export enum StaticContentsSlug {
  cgv = 'Conditions-generales-de-vente',
  cgu = "Conditions-generales-d'utilisation",
  privacy = 'Politique-de-confidentialite',
  ethics = 'Charte-ethique',
  faq = 'FAQ',
  fertilityMainInfo = 'Le-suivi-de-cycle-menstruel',
  fertilitySymptomExplanationInfo = 'Pourquoi-suivre-ces-elements',
  symptoThermie = "Qu'est-ce-que-la-symptothermie",
}

export interface StaticContentRaw {
  id: number;
  slug: StaticContentsSlug;
  title: string;
  content: string;
  lastUpdate: string | null;
}

export interface StaticContent extends Omit<StaticContentRaw, 'lastUpdate'> {
  lastUpdate: Date | null;
}
