import { useEffect } from 'react';

import auth from '@api-requests/api/auth';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import {
  DefaultError,
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { TOKENS_QUERY_KEYS, useTokens } from './useTokens';

const useAuthenticatedQuery = <
  TQueryFnData extends ReducedAxiosResponse = ReducedAxiosResponse<any>,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
) => {
  useTokens();
  const queryClient = useQueryClient();

  const result = useQuery<TQueryFnData, TError, TData, TQueryKey>({
    ...options,
    queryFn:
      typeof options.queryFn === 'function'
        ? async () => {
            // retrieve result of useTokens query
            const tok = await queryClient.ensureQueryData({
              queryKey: TOKENS_QUERY_KEYS,
              queryFn: auth.refreshToken,
            });

            // If token is invalid force refresh it. Note that fetchQuery are cumulated so it will only be called once
            if (tok?.data?.jwtExp && tok.data.jwtExp < Date.now()) {
              await queryClient.fetchQuery({
                queryKey: TOKENS_QUERY_KEYS,
                queryFn: auth.refreshToken,
                staleTime: 0.9 * tok.data.jwtExpiresIn * 1000,
              });
            }
            return (options.queryFn as Function)();
          }
        : options.queryFn,
  });

  useEffect(() => {
    // @ts-expect-error
    if (result.isError && (result.error?.status ?? result.status) === 401) {
      queryClient.invalidateQueries({
        queryKey: TOKENS_QUERY_KEYS,
      });
    }
    // @ts-expect-error
  }, [queryClient, result.isError, result.error?.status, result.status]);

  return result;
};

export default useAuthenticatedQuery;
