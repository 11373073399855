import './normalize.css';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { bootstrap } from './bootstrap';
import RootComponent from './RootComponent';

bootstrap();
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <RootComponent />,
);
