import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  inputAndIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 210,
  },
  inputTimeContainer: {
    width: 80,
  },
  inputDateContainer: {
    width: 130,
  },
  button: {
    backgroundColor: Colors.grey400,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 8,
    marginLeft: 4,
    alignItems: 'center',
  },
  buttonText: {
    color: Colors.duck800,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.title,
  },
  bottomSheetButton: { marginVertical: 16 },
});
