export interface Kid {
  id: number;
  firstName: string;
  lastName?: string;
  birthDay: string;
  sex: GENDER;
  active: boolean;
  image?: string;
}

export interface ChildCreate {
  firstName: string;
  birthDay: string;
  sex: GENDER;
  image?: string;
  fromExistingPregnancy: boolean;
}

export interface ChildCreateForm {
  firstName: string;
  birthDay: Date;
  sex: GENDER;
  image?: string;
  fromExistingPregnancy: boolean;
}

export enum GENDER {
  M = 'm',
  F = 'f',
}
