import debounce from 'lodash/debounce';
import memoize from 'lodash/memoize';

import { ApiErrorAlertCreators } from '@components/Modal/ApiErrorAlert/Redux/api-error-alert-redux';
import { store } from '@services/store';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { captureQueryError } from '@tools/captureQueryError';

import { QUERY_OPTIONS } from './defaults';

const MAX_RETRIES = 3;
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404, 429];

const debouncedDispatchNoNetwork = debounce(() => {
  store.dispatch(ApiErrorAlertCreators.noNetworkAlertShow());
}, 1000);

const debouncedDispatchGatewayError = memoize((baseURL: string) =>
  debounce(() => {
    store.dispatch(
      ApiErrorAlertCreators.badGatewayAlertShow({
        baseURL,
      }),
    );
  }, 1000),
);
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: QUERY_OPTIONS.DEFAULT_STALE_TIME,
      gcTime: QUERY_OPTIONS.DEFAULT_CACHE_TIME,
      retry: (failureCount, error: any) => {
        if (failureCount >= MAX_RETRIES) {
          return false;
        }

        if (
          HTTP_STATUS_TO_NOT_RETRY.includes(
            error?.response?.status ?? error.status ?? 0,
          )
        ) {
          return false;
        }

        return true;
      },
    },
  },
  queryCache: new QueryCache({
    onError: captureQueryError,
    onSettled(data: any, error: any) {
      const baseURL =
        data?.config?.baseURL ??
        error?.config?.baseURL ??
        error?.response?.config?.baseURL ??
        '';
      // @ts-ignore
      if (error) {
        const status = error?.response?.status ?? error?.status ?? 0;
        if (typeof status === 'number') {
          if (status === 502 && baseURL) {
            debouncedDispatchGatewayError(baseURL)();
          } else if (status <= 0) {
            debouncedDispatchNoNetwork();
          } else if (status >= 200 && status <= 500) {
            // cancel all alerts if status clearly states that we have a connection with the server
            debouncedDispatchGatewayError(baseURL).cancel();
            debouncedDispatchNoNetwork.cancel();
            store.dispatch(ApiErrorAlertCreators.noNetworkAlertHide());
            store.dispatch(ApiErrorAlertCreators.badGatewayAlertHide(baseURL));
          }
        }
      } else if (data) {
        const status = data?.response?.status ?? data?.status;
        if (typeof status === 'number') {
          // cancel all alerts if status clearly states that we have a connection with the server
          debouncedDispatchGatewayError(baseURL).cancel();
          debouncedDispatchNoNetwork.cancel();
          store.dispatch(ApiErrorAlertCreators.noNetworkAlertHide());
          store.dispatch(ApiErrorAlertCreators.badGatewayAlertHide(baseURL));
        }
      }
    },
  }),
  mutationCache: new MutationCache({
    onError(error) {
      captureQueryError(error);
    },
  }),
});
