import { t } from '@config';

export enum WEB_PROFILE_CHOICES {
  PREGNANT = 'pregnancy',
  PARENT = 'parent',
  BOTH = 'both',
}

const tPath = 'ON_BOARDING.WEB.PROFILE.CHOICES';
export const stepPairs = [
  { key: WEB_PROFILE_CHOICES.PREGNANT, value: t(`${tPath}.PREGNANT`) },
  { key: WEB_PROFILE_CHOICES.PARENT, value: t(`${tPath}.PARENT`) },
  { key: WEB_PROFILE_CHOICES.BOTH, value: t(`${tPath}.BOTH`) },
];
