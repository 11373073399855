import { pick } from 'lodash';
import { Store } from 'redux';

import { logOpenCycleTracking } from '@analytics/events';
import { products } from '@api-requests/api/main/payment/products/endPoint';
import { formatProduct } from '@api-requests/api/main/payment/products/utils/formatProduct';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import { OpenidProviders } from '@commonTypes/Auth';
import { Pregnancy } from '@commonTypes/Pregnancy';
import { GlobalState } from '@commonTypes/redux';
import { subscribeModalCreators } from '@components/Modal/RootSubscribeModal/redux/subscribe-modal';
import { subscribedStatus } from '@hooks/useAuthorizations';
import { DYNAMIC_LINKS } from '@resources/constants/DynamicLinks';
import { queryWrapper } from '@sagas/http';
import { getPregnancyMonthsFromStart } from '@tools/dateManipulation';
import { parseDeepLink } from '@tools/parseDeepLink';

import { ProductRaw } from '@api-requests/api/main/payment/products/types';

import { navigate, push } from './Actions';
import {
  ANONYMOUS_STACK,
  COMMON_SCREEN_STACK,
  HOME_STACK,
  JOURNALING_STACK,
  NAVIGATOR_NAMES,
  SETTINGS_STACK,
  TAB_NAMES,
} from './Routes';

export const handleDynamicLinks = (url: string, store: Store<GlobalState>) => {
  try {
    const linkInfo = parseDeepLink(url);
    if (!linkInfo?.pathname) {
      return;
    }

    const storePregnancy = store.getState()?.user?.data?.pregnancy;
    const pregnancy = storePregnancy?.active
      ? (storePregnancy as Pregnancy)
      : undefined;
    switch (linkInfo.pathname) {
      case DYNAMIC_LINKS.SPONSOR:
        navigate(NAVIGATOR_NAMES.HOME_NAVIGATOR, {
          screen: HOME_STACK.SPONSOR_SCREEN,
        });
        break;
      case DYNAMIC_LINKS.POSTS:
      case DYNAMIC_LINKS.SHARE_POST:
        if (linkInfo.lookup) {
          push(COMMON_SCREEN_STACK.POST_DETAIL_SCREEN, {
            slug: linkInfo.lookup,
            post: undefined,
          });
        }
        break;
      case DYNAMIC_LINKS.TIP:
        if (linkInfo.lookup) {
          navigate(COMMON_SCREEN_STACK.TIP_DETAIL_SCREEN, {
            tipId: linkInfo.lookup,
            tip: undefined,
          });
        }
        break;
      case DYNAMIC_LINKS.WEEKLY_TIP:
        if (linkInfo.lookup && !!Number(linkInfo.lookup)) {
          navigate(NAVIGATOR_NAMES.HOME_NAVIGATOR, {
            screen: HOME_STACK.WEEKLY_ACTIVITIES_SCREEN,
            params: { tipId: Number(linkInfo.lookup), tip: undefined },
          });
        }
        break;
      case DYNAMIC_LINKS.FOLDER:
        linkInfo.lookup &&
          navigate(COMMON_SCREEN_STACK.FOLDER_SCREEN, {
            folderId: linkInfo.lookup,
            folder: undefined,
          });
        break;
      case DYNAMIC_LINKS.PREGNANCY:
        if (pregnancy?.active) {
          navigate(COMMON_SCREEN_STACK.PREGNANCY_DETAIL_SCREEN, {
            originScreen: 'dynamicLinks',
          });
        }
        break;
      case DYNAMIC_LINKS.SUBSCRIBE:
        const targetTab = Number(linkInfo.lookup ?? 0);
        store.dispatch(
          subscribeModalCreators.show({ origin: 'dynamicLinks', targetTab }),
        );
        break;
      case DYNAMIC_LINKS.SUBSCRIPTION:
        navigate(NAVIGATOR_NAMES.SETTINGS_NAVIGATOR, {
          screen: SETTINGS_STACK.SUBSCRIPTION_SCREEN,
        });
        break;
      case DYNAMIC_LINKS.CARD_LIST:
        navigate(NAVIGATOR_NAMES.SETTINGS_NAVIGATOR, {
          screen: SETTINGS_STACK.CARD_LIST_SCREEN,
        });
        break;
      case DYNAMIC_LINKS.SHARE_ACCOUNT:
        navigate(NAVIGATOR_NAMES.SETTINGS_NAVIGATOR, {
          screen: SETTINGS_STACK.FAMILY_ACCOUNT_SCREEN,
        });
        break;
      case DYNAMIC_LINKS.NOTIFICATION_SETTINGS:
        navigate(NAVIGATOR_NAMES.SETTINGS_NAVIGATOR, {
          screen: SETTINGS_STACK.NOTIFICATIONS,
        });
        break;
      case DYNAMIC_LINKS.MASTERCLASS:
        if (linkInfo.lookup) {
          navigate(COMMON_SCREEN_STACK.MASTERCLASS_SCREEN, {
            masterclassId: linkInfo.lookup,
            masterclass: undefined,
          });
        }
        break;
      case DYNAMIC_LINKS.GUIDE:
        if (linkInfo.lookup) {
          navigate(COMMON_SCREEN_STACK.GUIDE_SLIDER, {
            slug: linkInfo.lookup,
            guide: undefined,
          });
        }
        break;
      case DYNAMIC_LINKS.RECIPE:
        if (linkInfo.lookup) {
          navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
            screen: JOURNALING_STACK.RECIPE_DETAIL_SCREEN,
            params: {
              recipeId: Number(linkInfo.lookup),
              recipe: undefined,
            },
          });
        } else {
          navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
            screen: JOURNALING_STACK.RECIPE_SCREEN,
          });
        }
        break;
      case DYNAMIC_LINKS.JOURNALING:
        navigate(NAVIGATOR_NAMES.TAB_BAR_NAVIGATOR, {
          screen: TAB_NAMES.JOURNALING,
        });
        break;
      case DYNAMIC_LINKS.CHILD_SLEEP:
        navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
          screen: JOURNALING_STACK.SLEEP_SCREEN,
          params: { childId: undefined },
        });
        break;
      case DYNAMIC_LINKS.CHILD_FEED:
        navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
          screen: JOURNALING_STACK.CHILD_FEED_SCREEN,
          params: { childId: undefined },
        });
        break;
      case DYNAMIC_LINKS.FAQ_PREGNANCY:
        navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
          screen: JOURNALING_STACK.FAQ_PREGNANCY,
          params: pick(linkInfo.searchParams, ['search', 'questionId']),
        });
        break;
      case DYNAMIC_LINKS.FAQ_PURCHASE:
        navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
          screen: JOURNALING_STACK.FAQ_PURCHASE,
          params: pick(linkInfo.searchParams, ['search', 'categoryId']),
        });
        break;
      case DYNAMIC_LINKS.CALENDAR:
        navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
          screen: JOURNALING_STACK.CALENDAR_SCREEN,
          params: { initialDate: undefined },
        });
        break;
      case DYNAMIC_LINKS.APPOINTMENT:
        navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
          screen: JOURNALING_STACK.APPOINTMENT_SCREEN,
        });
        break;
      case DYNAMIC_LINKS.TODO:
        navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
          screen: JOURNALING_STACK.TODO_SCREEN,
        });
        break;
      case DYNAMIC_LINKS.KID_GROWTH:
        navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
          screen: JOURNALING_STACK.KID_GROWTH_SCREEN,
          params: { childId: undefined },
        });
        break;
      case DYNAMIC_LINKS.BELLY:
        navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
          screen: JOURNALING_STACK.BELLY_SCREEN,
          params: {
            offset: pregnancy?.active
              ? getPregnancyMonthsFromStart(pregnancy)
              : 0,
          },
        });
        break;
      case DYNAMIC_LINKS.MY_WEIGHT:
        navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
          screen: JOURNALING_STACK.MY_WEIGHT_SCREEN,
        });
        break;
      case DYNAMIC_LINKS.PROMOTION_CODE:
        if (!linkInfo.lookup) {
          break;
        }
        const [planId, promoCode] = linkInfo.lookup?.split('-');
        if (!planId) {
          break;
        }

        const storeState = store.getState();
        const subscriptionStatus = storeState.user.data.subscriptionStatus;
        const isSubscribed =
          subscriptionStatus && subscribedStatus.includes(subscriptionStatus);
        if (isSubscribed) {
          break;
        }

        const hasAlreadyPaid = !!store.getState().user?.data?.paidPlan;

        queryWrapper<ReducedAxiosResponse<Array<ProductRaw>>>(
          {
            dispatch: store.dispatch,
            state: store.getState(),
          },
          products.getAll,
        ).then(({ data: productList }) => {
          const rawProduct = productList.find(
            (product) => product.productId === planId,
          );
          if (!rawProduct) {
            return;
          }
          navigate(COMMON_SCREEN_STACK.SUBSCRIPTION_PAYMENT_SCREEN, {
            product: formatProduct(rawProduct, hasAlreadyPaid),
            promoCode,
          });
        });
        break;
      case DYNAMIC_LINKS.OPENID_SIGNIN:
        navigate(ANONYMOUS_STACK.OPENID_SIGNIN, {
          redirectUrl: linkInfo.urlString,
          provider: OpenidProviders.GOOGLE,
          purpose: 'authorize',
        });
        break;
      case DYNAMIC_LINKS.OPENID_SIGNUP:
        navigate(ANONYMOUS_STACK.OPENID_SIGNIN, {
          redirectUrl: linkInfo.urlString,
          provider: OpenidProviders.GOOGLE,
          purpose: 'signup',
        });
        break;
      case DYNAMIC_LINKS.OPENID_ATTACH:
        navigate(NAVIGATOR_NAMES.SETTINGS_NAVIGATOR, {
          screen: SETTINGS_STACK.OPENID_SIGNIN,
          params: {
            redirectUrl: linkInfo.urlString,
            provider: OpenidProviders.GOOGLE,
            purpose: 'attach',
          },
        });
        break;
      case DYNAMIC_LINKS.FERTILITY:
        navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
          screen: JOURNALING_STACK.FERTILITY_SCREEN,
        });
        logOpenCycleTracking('DeepLink');
        break;
      case DYNAMIC_LINKS.FERTILITY_INPUT:
        navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
          screen: JOURNALING_STACK.ADD_CYCLE_SCREEN,
          params: { cycle: undefined },
        });
        break;
      case DYNAMIC_LINKS.INVITATION:
        if (!linkInfo.lookup) {
          break;
        }
        navigate(NAVIGATOR_NAMES.SETTINGS_NAVIGATOR, {
          screen: SETTINGS_STACK.FAMILY_ACCOUNT_ALLOW_SCREEN,
          params: {
            qrCodeInvite: decodeURIComponent(linkInfo.lookup),
            from: TAB_NAMES.HOME,
          },
        });
        break;
      case DYNAMIC_LINKS.AFFILIATION:
        if (linkInfo.searchParams.code) {
          navigate(NAVIGATOR_NAMES.SETTINGS_NAVIGATOR, {
            screen: SETTINGS_STACK.AFFILIATION_SCREEN,
            params: { affiliationCode: linkInfo.searchParams.code },
          });
        } else {
          navigate(NAVIGATOR_NAMES.SETTINGS_NAVIGATOR, {
            screen: SETTINGS_STACK.AFFILIATION_SCREEN,
            params: { affiliationCode: '' },
          });
        }
        break;
      default:
        break;
    }
  } catch {}
};
