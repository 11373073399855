import React from 'react';
import {
  Modal as RNModal,
  ModalProps as RNModalProps,
  Pressable,
  StyleSheet,
} from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import PopupProvider from '@components/ui/Popup';
import { Colors } from '@resources/themes';

import { useIsVisible } from './hooks/useIsVisible';
import { WithinModalProvider } from './hooks/WithinModalProvider';

const styles = StyleSheet.create({
  backdrop: { backgroundColor: `${Colors.black500}${Colors.transparent30}` },
  fill: StyleSheet.absoluteFillObject,
});

export interface ModalProps
  extends Omit<RNModalProps, 'visible' | 'transparent' | 'onRequestClose'> {
  isVisible: boolean;
  /**
   * Setting this to true will render the modal over a transparent backdrop
   *
   * Be aware that mounting simultaneously multiple modals with a backdrop will break despite using the patch provided by useIsVisible when coming back from background
   */
  withBackdrop?: boolean;
  onClose?: () => void;
}
const Modal = ({
  isVisible,
  children,
  style,
  onClose,
  withBackdrop,
  ...modalProps
}: ModalProps) => {
  const canMount = useIsVisible(isVisible);

  return (
    <RNModal
      visible={isVisible && canMount}
      transparent={withBackdrop}
      onRequestClose={onClose}
      {...modalProps}
    >
      <WithinModalProvider value={true}>
        <SafeAreaProvider>
          <PopupProvider>
            <GestureHandlerRootView style={[styles.fill, style]}>
              {withBackdrop && (
                <Pressable
                  style={[styles.fill, styles.backdrop]}
                  onPress={onClose}
                />
              )}
              {children}
            </GestureHandlerRootView>
          </PopupProvider>
        </SafeAreaProvider>
      </WithinModalProvider>
    </RNModal>
  );
};

export default Modal;
