import React from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';

import { t } from '@config';
import { Colors } from '@resources/themes';

const styles = StyleSheet.create({
  container: { width: '100%', flexDirection: 'row', alignItems: 'center' },
  line: { height: 1, backgroundColor: Colors.grey500, flex: 1 },
  text: { marginHorizontal: 16, color: Colors.grey500 },
});

export const Separator = ({ style }: { style?: StyleProp<ViewStyle> }) => (
  <View style={[styles.container, style]}>
    <View style={styles.line} />
    <Text style={styles.text}>{t('AUTH.LOGIN.OR')}</Text>
    <View style={styles.line} />
  </View>
);
