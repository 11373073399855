import { AxiosResponse } from 'axios';
import { useEffect } from 'react';

import consultations from '@api-requests/api/consultations';
import { GENDER } from '@commonTypes/Kid';
import { useSelector } from '@commonTypes/redux';
import useAuthenticatedQuery from '@hooks/useAuthenticatedQuery';
import useMutationCall from '@hooks/useMutationCall';
import { USER_QUERY_KEYS } from '@hooks/useUserDetail';
import { useQueryClient } from '@tanstack/react-query';

import {
  ConsultationAvailableSlot,
  ConsultationDetail,
  UpcomingConsultation,
  UpcomingConsultationRaw,
  ConsultationHistory,
} from '../types';

type Error = { statusCode: number; error: string; message: string };

export const CONSULTATIONS_QUERY_KEYS = {
  getCategories: ['consultationCategories'],
  getAvailableSlots: ['consultationAvailableSlots'],
  create: ['createConsultation'],
  upcoming: ['upcomingConsultations'],
  getOne: (id: number) => ['consultationDetail', id],
  cancel: ['cancelConsultation'],
  getHistory: ['consultationHistory'],
};

export const useConsultationCategories = () =>
  useAuthenticatedQuery({
    queryFn: consultations.getCategories,
    queryKey: CONSULTATIONS_QUERY_KEYS.getCategories,
    select: (data) => data?.data,
  });

export const useConsultationAvailableSlots = () =>
  useAuthenticatedQuery({
    queryFn: consultations.getAvailableSlots,
    queryKey: CONSULTATIONS_QUERY_KEYS.getAvailableSlots,

    select: (data): ConsultationAvailableSlot[] =>
      data?.data?.map((slot) => ({
        startDate: new Date(slot.startDate),
        endDate: new Date(slot.endDate),
      })),
  });

export const useCreateConsultation = (options?: {
  onSuccess: () => void;
  onError: (res: AxiosResponse<Error>) => void;
}) => {
  const queryClient = useQueryClient();
  return useMutationCall({
    mutationFn: consultations.create,
    mutationKey: CONSULTATIONS_QUERY_KEYS.create,

    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: CONSULTATIONS_QUERY_KEYS.getAvailableSlots,
      });
      // Updating upcoming consultations cache
      const { reason, visioCode, ...upcomingConsultationNewData } = data;
      queryClient.setQueryData(
        CONSULTATIONS_QUERY_KEYS.upcoming,
        updateUpcomingConsultationsCache({
          newData: upcomingConsultationNewData,
        }),
      );
      // Updating consultationDetail cache
      queryClient.setQueryData(CONSULTATIONS_QUERY_KEYS.getOne(data.id), {
        data: [data],
      });
      options?.onSuccess();
    },

    onError: options?.onError,
  });
};

const updateUpcomingConsultationsCache =
  ({
    newData,
    deletedDataId,
  }: {
    newData?: UpcomingConsultationRaw;
    deletedDataId?: number;
  }) =>
  (oldData: AxiosResponse<UpcomingConsultationRaw[]> | undefined) => {
    if (!oldData) {
      return;
    }
    let newCache = deletedDataId
      ? oldData.data.filter((old) => old.id !== deletedDataId)
      : oldData.data;
    if (newData) {
      newCache =
        deletedDataId !== newData.id
          ? newCache.filter((old) => old.id !== newData.id)
          : newCache;
      newCache.push(newData);
    }
    return {
      ...oldData,
      data: newCache.sort(({ start: a }, { start: b }) =>
        new Date(a).getTime() < new Date(b).getTime() ? -1 : 1,
      ),
    };
  };

export const useUpcomingConsultations = () => {
  const childList = useSelector((state) => state.user.data.kids ?? []);
  return useAuthenticatedQuery({
    queryFn: consultations.upcoming,
    queryKey: CONSULTATIONS_QUERY_KEYS.upcoming,

    select: (response): UpcomingConsultation[] =>
      response?.data.flatMap(({ childId, ...consultation }) => {
        const currentChild = childList.find((child) => child.id === childId);
        if (!currentChild) {
          return [];
        }
        return {
          ...consultation,
          child: currentChild,
          start: new Date(consultation.start),
          end: new Date(consultation.end),
        };
      }),
  });
};

export const useConsultationDetail = (id: number) => {
  const queryClient = useQueryClient();
  const childList = useSelector((state) => state.user.data.kids ?? []);
  const res = useAuthenticatedQuery({
    queryFn: () => consultations.getOne({ id }),
    queryKey: CONSULTATIONS_QUERY_KEYS.getOne(id),

    select: (response): ConsultationDetail =>
      response?.data.map(({ childId, ...consultation }) => {
        const currentChild = childList.find(
          (child) => child.id === childId,
        ) ?? { firstName: 'unknown', sex: GENDER.F, image: undefined };
        return {
          ...consultation,
          child: currentChild,
          start: new Date(consultation.start),
          end: new Date(consultation.end),
        };
      })[0],
  });
  useEffect(() => {
    // Safeguard: can only happen if there is a discrepancy between back & front cached data
    if (res.data?.child.firstName === 'unknown') {
      queryClient.invalidateQueries({
        queryKey: USER_QUERY_KEYS.detail,
      });
    }
  }, [queryClient, res.data?.child.firstName]);
  return res;
};

export const useCancelConsultation = (id: number) => {
  const queryClient = useQueryClient();
  return useMutationCall({
    mutationFn: () => consultations.cancel({ id }),
    mutationKey: CONSULTATIONS_QUERY_KEYS.cancel,

    onSuccess: () => {
      queryClient.setQueryData(
        CONSULTATIONS_QUERY_KEYS.upcoming,
        updateUpcomingConsultationsCache({ deletedDataId: id }),
      );
    },
  });
};

export const useConsultationHistory = () => {
  const childList = useSelector((state) => state.user.data.kids ?? []);
  return useAuthenticatedQuery({
    queryFn: consultations.history,
    queryKey: CONSULTATIONS_QUERY_KEYS.getHistory,

    select: (response): ConsultationHistory[] =>
      response?.data.flatMap(({ childId, start, end, ...consultation }) => {
        const currentChild = childList.find(({ id }) => id === childId);
        if (!currentChild) {
          return [];
        }
        return {
          ...consultation,
          child: currentChild,
          start: new Date(start),
          end: new Date(end),
        };
      }),
  });
};
