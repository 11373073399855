import React, { useEffect } from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';

import { useSelector } from '@commonTypes/redux';
import { useAuthorizations } from '@hooks/useAuthorizations';
import { usePortalSession } from '@hooks/usePortalSession';
import { navigate } from '@navigation/Actions';
import { Colors } from '@resources/themes';
import { WEB_COMMON_SCREEN_STACK } from '@webNavigation/routes';

const styles = StyleSheet.create({
  container: { height: '100%', backgroundColor: Colors.white100 },
});

const goToSubscribeScreen = () =>
  navigate(WEB_COMMON_SCREEN_STACK.SUBSCRIBE_SCREEN, {
    targetTab: undefined,
  });

/**
 * User has completed onboarding and has a stripe plan -> redirect to stripe portal
 *
 * User has completed onboarding and has no stripe plan -> redirect to subscribe screen
 */
const useHandleRedirection = () => {
  const hasCompletedOnboarding = useSelector(
    (state) =>
      state.auth.hasCompletedOnboarding ||
      state.auth?.scenarios?.includes('onboarding-v1') ||
      state.auth?.scenarios?.includes('onboarding-v2') ||
      false,
  );
  const { hasStripePlan } = useAuthorizations();
  const portal = usePortalSession(hasCompletedOnboarding && hasStripePlan);

  useEffect(() => {
    if (!hasStripePlan) {
      goToSubscribeScreen();
    }
  }, [hasStripePlan]);

  useEffect(() => {
    if (hasStripePlan) {
      if (portal.data?.url) {
        window.location.href = portal.data.url;
      }
    }
  }, [hasCompletedOnboarding, hasStripePlan, portal.data?.url]);
};

const HomeScreen = () => {
  useHandleRedirection();
  return (
    <ActivityIndicator
      size="large"
      color={Colors.duck800}
      style={styles.container}
    />
  );
};

export default HomeScreen;
