import React from 'react';
import { Text, View } from 'react-native';
import Animated from 'react-native-reanimated';

import styles, { BACK_BUTTON_SIZE } from './styles';
import BackButton from '../Pressables/Actions/BackButton';

export interface NavBarProps {
  style?: View['props']['style'];
  title: string;
  onBackPress?: () => void;
  noIcon?: boolean;
  RightComponent?: React.ReactNode | null;
  titleStyle?: Text['props']['style'];
  iconStyle?: View['props']['style'];
}
export function NavBar({
  style,
  title,
  onBackPress,
  noIcon,
  RightComponent = null,
  titleStyle,
  iconStyle,
}: NavBarProps) {
  return (
    <Animated.View style={style}>
      <View
        style={[styles.container, !noIcon && { paddingLeft: BACK_BUTTON_SIZE }]}
      >
        {!noIcon && (
          <Animated.View style={[styles.backButton, iconStyle]}>
            <BackButton style={styles.override} onPress={onBackPress} />
          </Animated.View>
        )}
        <Text style={[styles.title, titleStyle]}>{title}</Text>
        {RightComponent}
      </View>
    </Animated.View>
  );
}
