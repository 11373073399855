import React, { useEffect, useState } from 'react';

import { logSelectPlan, logViewSubscriptionModal } from '@analytics/events';
import { useProductList } from '@api-requests/api/main/payment/products/hooks';
import SubscribeModal6 from '@components/Modal/RootSubscribeModal/variants/SubscribeModal6';
import SubscribeModal7 from '@components/Modal/RootSubscribeModal/variants/SubscribeModal7';
import SubscribeModal8 from '@components/Modal/RootSubscribeModal/variants/SubscribeModal8';
import SubscribeModal9 from '@components/Modal/RootSubscribeModal/variants/SubscribeModal9';
import { navigate } from '@navigation/Actions';
import { remoteConfig } from '@tools/firebase';
import { WebLoggedNavigatorParamList } from '@webNavigation/routeParams';
import { WEB_COMMON_SCREEN_STACK } from '@webNavigation/routes';

import { Product } from '@api-requests/api/main/payment/products/types';
import { SubscribeModalProps } from '@components/Modal/RootSubscribeModal/types';
import { ScreenRoute } from '@navigation/types';

import { useCheckoutSession } from './hooks/useCheckoutSession';

const goToUnsubscribedHomeScreen = () =>
  navigate(WEB_COMMON_SCREEN_STACK.UNSUBSCRIBED_HOME_SCREEN);

const SubscribeScreen = ({
  route: {
    params: { targetTab = 0 },
  },
}: ScreenRoute<
  WebLoggedNavigatorParamList,
  WEB_COMMON_SCREEN_STACK.SUBSCRIBE_SCREEN
>) => {
  const subscribeModalName = remoteConfig()
    .getValue('SubscribeModalName')
    .asNumber();

  useEffect(() => {
    logViewSubscriptionModal({
      screen: 'web',
      modalName: subscribeModalName,
      isAutomaticDisplayed: false,
    });
  }, [subscribeModalName]);

  const checkoutSession = useCheckoutSession();

  const { data: productList } = useProductList();
  const [selectedPlan, setSelectedPlan] = useState<Product | null>(null);

  const onPress = () => {
    if (selectedPlan && productList?.length) {
      logSelectPlan({
        item: selectedPlan.title,
        screen: 'web',
        value: selectedPlan.amount / 100,
      });
      checkoutSession.mutate(
        { productId: selectedPlan.productId },
        {
          onSuccess: ({ data }) => {
            if (data?.url) {
              window.location.href = data.url;
            }
          },
        },
      );
    }
  };

  const props: SubscribeModalProps & { targetTab: number } = {
    productList,
    selectedPlan,
    setSelectedPlan,
    onPress,
    targetTab,
    hideModal: goToUnsubscribedHomeScreen,
  };

  switch (subscribeModalName) {
    case 7:
      return <SubscribeModal7 {...props} />;
    case 8:
      return <SubscribeModal8 {...props} />;
    case 9:
      return <SubscribeModal9 {...props} />;
    default:
      return <SubscribeModal6 {...props} />;
  }
};

export default SubscribeScreen;
