import {
  useSelector as useSelectorRaw,
  useDispatch as useDispatchRaw,
} from 'react-redux';

import appReducer from '@redux';

export type GlobalState = ReturnType<typeof appReducer>;

export function useSelector<TSelected>(
  selector: (state: GlobalState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
) {
  return useSelectorRaw(selector, equalityFn);
}

export const useDispatch = useDispatchRaw;
