import { StyleSheet } from 'react-native';

import DEVICE from '@resources/constants/device';
import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  heading1: {
    color: Colors.duck800,
    fontFamily: Fonts.type.Recoleta.semiBold,
    fontSize: Fonts.size.h6,
    marginTop: 20,
  },
  heading2: {
    marginTop: 16,
    fontFamily: Fonts.type.semiBold,
    fontSize: Fonts.size.h5,
    color: Colors.duck800,
  },
  heading3: {
    marginTop: 8,
    fontFamily: Fonts.type.medium,
    fontSize: Fonts.size.input,
    color: Colors.duck800,
  },

  hr: { marginTop: 24, height: 1, backgroundColor: Colors.duck400 },

  blockquotes: {
    marginVertical: 16,
    marginHorizontal: -24,
    padding: 24,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.duck300,
  },
  bar: {
    marginRight: 16,
    height: '100%',
    width: 3,
    backgroundColor: Colors.duck800,
  },
  quoteContent: {
    // WindowWidth - (horizontalSpacing + barWidth + barMarginRight)
    width: DEVICE.WINDOW_WIDTH - (24 * 2 + 3 + 16),
    gap: 4,
  },
  quoteTitle: {
    fontFamily: Fonts.type.semiBold,
    fontSize: Fonts.size.regular,
    color: Colors.duck800,
  },
  quote: {
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.title,
    color: Colors.duck700,
  },

  list: { alignItems: 'flex-start', flexDirection: 'row', marginVertical: 4 },
  bulletContainer: { height: 21, justifyContent: 'center' },
  bullet: {
    marginRight: 8,
    height: 4,
    aspectRatio: 1,
    borderRadius: 2,
    backgroundColor: Colors.duck800,
  },
  bulletContent: { flex: 1 },
  listNumber: { marginRight: 5, color: Colors.duck700 },

  codeInline: { color: Colors.pink500 },

  text: {
    fontFamily: Fonts.type.regular,
    color: Colors.duck700,
  },
});
