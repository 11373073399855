import React from 'react';

import { PrimaryButton } from '@components/Buttons';
import AlertModal from '@components/Modal/AlertModal';
import { t } from '@config';

export const SignInFailedModal = ({
  isVisible,
  onClose,
}: {
  isVisible: boolean;
  onClose: () => void;
}) => {
  return (
    <AlertModal
      isVisible={isVisible}
      illustrationSource={'StopIcon'}
      title={t('AUTH.SIGN_IN.ERROR.SIGN_IN_FAILED_TITLE')}
      description={t('AUTH.SIGN_IN.ERROR.SIGN_IN_FAILED_MESSAGE')}
      onClose={onClose}
    >
      <PrimaryButton
        label={t('AUTH.SIGN_IN.ERROR.BUTTON')}
        onPress={onClose}
        isLoading={false}
      />
    </AlertModal>
  );
};
