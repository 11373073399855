import React, { useCallback, useState } from 'react';
import { ActivityIndicator, Text, View } from 'react-native';

import { PressableOpacity } from '@components/PressableOpacity';
import { t } from '@config';
import { canGoBack, goBack } from '@navigation/Actions';
import { useFocusEffect } from '@react-navigation/native';
import { hitSlop } from '@resources/constants/hitSlop';
import BackIcon from '@resources/svg/ic_next_pink.svg';

import styles from './styles';
import { RegisterNavBarProps as Props } from './types';

const RegisterNavBar = ({
  customStyle,
  onBackPress = goBack,
  customIconStyle,
  customTitleStyle,
  onPress = goBack,
  nextTitle = t('AUTH.SIGN_UP.NEXT_BUTTON'),
  title,
  isLoading,
  enabled = true,
}: Props) => {
  const [displayBackPress, setDisplayBackPress] = useState(
    onBackPress !== goBack || canGoBack(),
  );
  useFocusEffect(
    useCallback(() => {
      setDisplayBackPress(onBackPress !== goBack || canGoBack());
    }, [onBackPress]),
  );

  return (
    <>
      <View style={[styles.container, customStyle]}>
        {displayBackPress ? (
          <PressableOpacity
            onPress={onBackPress}
            style={[styles.backIconContainer, customIconStyle]}
            hitSlop={hitSlop.large}
          >
            <BackIcon height={16} style={styles.icon} width={16} />
          </PressableOpacity>
        ) : (
          <View />
        )}
        <PressableOpacity
          onPress={onPress}
          disabled={isLoading || !enabled}
          hitSlop={hitSlop.large}
        >
          {isLoading ? (
            <ActivityIndicator />
          ) : (
            <Text
              style={[styles.nextTitle, !enabled && styles.nextTitleDisabled]}
              testID="nextButton"
            >
              {nextTitle}
            </Text>
          )}
        </PressableOpacity>
      </View>
      <View style={styles.titleContainer}>
        <Text style={[styles.title, customTitleStyle]}>{title}</Text>
      </View>
    </>
  );
};

export default RegisterNavBar;
