import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import AppIcon from '@components/ui/Icon';
import { t } from '@config';
import { Colors, Fonts } from '@resources/themes';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 8,
    top: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    color: Colors.white100,
    fontFamily: Fonts.type.bold,
    fontSize: Fonts.size.small,
    height: 15,
  },
  icon: {
    marginLeft: 2,
    padding: 1,
    backgroundColor: Colors.white100,
    borderRadius: 4,
  },
});

const ViewMarker = ({ label }: { label: string }) => (
  <View style={styles.container}>
    <Text style={styles.text}>{label}</Text>
    <View style={styles.icon}>
      <AppIcon name={'CheckmarkSlim'} color={Colors.duck500} size={6} />
    </View>
  </View>
);

export const ListenedMarker = () => <ViewMarker label={t('GLOBAL.LISTENED')} />;
export const ReadMarker = () => <ViewMarker label={t('GLOBAL.SEEN')} />;
export const WatchedMarker = () => <ViewMarker label={t('GLOBAL.WATCHED')} />;
