export interface UserAnswers {
  sex: ANSWERS_SEX;
  maritalStatus: ANSWERS_MARITAL_STATUS;
  source?: ANSWERS_SOURCE;
  paid?: ANSWER_PAID_ORIGIN;
}

export enum ANSWERS_SEX {
  MASCULINE = 'masculine',
  FEMININE = 'feminine',
  UNKNOWN = 'unknown',
}

export enum ANSWERS_MARITAL_STATUS {
  COUPLE = 'couple',
  ALONE = 'alone',
  UNKNOWN = 'unknown',
}

export enum ANSWERS_SOURCE {
  INSTAGRAM = 'follower_instagram',
  PAID = 'paid',
  STORE_DISCOVERY = 'store_discovery',
  FAMILY_AND_FRIENDS = 'family_and_friends',
  DOCTOR = 'healthcare_pro',
  SEO = 'SEO',
  OTHER = 'other',
  UNKNOWN = 'unknown',
}

export enum ANSWER_PAID_ORIGIN {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  GOOGLE = 'google',
  YOUTUBE = 'youtube',
  TIKTOK = 'tiktok',
  PINTEREST = 'pinterest',
  OTHER = 'other',
  UNKNOWN = 'unknown',
}
