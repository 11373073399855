import { AxiosError, AxiosResponse } from 'axios';

import { ApiError } from '@api-requests/api/common';
import { Query } from '@tanstack/react-query';
import * as Sentry from '@tools/sentry';

const interceptResponse = (
  response?: AxiosResponse,
  code?: string,
  query?: Query,
) => {
  // do not capture 4xx errors and canceled requests
  if (
    response?.data?.message?.toLowerCase() === 'canceled' ||
    (response?.status &&
      !isNaN(response.status) &&
      response.status < 500 &&
      response.status >= 400)
  ) {
    return;
  }

  let message = 'no message';
  if (response?.data?.message) {
    message = response.data.message;
  } else if (response?.config.url) {
    message = `${response?.status} while calling ${
      response?.config.url.split('?')[0]
    }`;
  }

  Sentry.captureMessage(message, {
    level: 'error',
    extra: {
      code,
      status: response?.status,
      data: response?.data,
      called: response?.config.url,
      queryKey: query?.queryKey,
    },
  });
};

export const captureQueryError = (error: any, query?: any) => {
  if (!error.isAxiosError) {
    if (error instanceof ApiError) {
      return;
    }
    if (error.request && error.status) {
      const axiosResponse = error as AxiosResponse;
      return interceptResponse(axiosResponse, undefined, query);
    }
    if (error.code === 'ECONNABORTED' || error.code === 'ERR_NETWORK') {
      return;
    }
    Sentry.captureException(error);
    return;
  }
  // no response than it is a network error
  if (!error.response?.status) {
    return;
  }

  const axiosError = error as AxiosError;
  return interceptResponse(axiosError.response, axiosError.code, query);
};
