import { Dimensions, Platform } from 'react-native';

import { WEB_WIDTH } from './web';

const WINDOW_WIDTH = Platform.select({
  web: WEB_WIDTH,
  default: Math.min(
    Dimensions.get('window').width,
    Dimensions.get('window').height,
  ),
});

const DEVICE = {
  PLATFORM: Platform.OS,
  IS_ANDROID: Platform.OS === 'android',
  IS_IOS: Platform.OS === 'ios',
  API_VERSION: Platform.Version,
  SCREEN_HEIGHT: Platform.select({
    web: Dimensions.get('screen').height,
    default: Math.max(
      Dimensions.get('screen').width,
      Dimensions.get('screen').height,
    ),
  }),
  WINDOW_WIDTH,
  SCREEN_WIDTH: Platform.select({
    web: WEB_WIDTH,
    default: Math.min(
      Dimensions.get('screen').width,
      Dimensions.get('screen').height,
    ),
  }),
  WINDOW_HEIGHT: Platform.select({
    web: Dimensions.get('window').height,
    default: Math.max(
      Dimensions.get('window').width,
      Dimensions.get('window').height,
    ),
  }),
  IS_SMALL: WINDOW_WIDTH < 375,

  /**
   * Variable to check android version based on api version to avoid
   * potential parsing problems of variable "Platform.constants.Release"
   * https://source.android.com/docs/setup/about/build-numbers
   */
  IS_ANDROID_10_OR_UPPER: Platform.OS === 'android' && Platform.Version >= 29,
  IS_ANDROID_13_OR_UPPER: Platform.OS === 'android' && Platform.Version >= 33,
  IS_IOS_14_OR_UPPER:
    Platform.OS === 'ios' && parseInt(Platform.Version, 10) >= 14,
};

export default DEVICE;
