import React, { useState } from 'react';
import { Text, View } from 'react-native';

import { PressableOpacity } from '@components/PressableOpacity';
import AppIcon from '@components/ui/Icon';
import { t } from '@config';
import { hitSlop } from '@resources/constants/hitSlop';

import styles from './styles';

const DismissibleInfo = () => {
  const [isVisible, setIsVisible] = useState(true);
  if (!isVisible) {
    return null;
  }
  return (
    <View style={styles.container}>
      <Text style={styles.textIcon}>🎉</Text>
      <View style={styles.text}>
        <View style={styles.firstRow}>
          <Text adjustsFontSizeToFit numberOfLines={1} style={styles.title}>
            {t('ADD_KID.NOTIFICATION.ADD_KID.TITLE')}
          </Text>
          <PressableOpacity
            hitSlop={hitSlop.medium}
            onPress={() => setIsVisible(false)}
          >
            <AppIcon name={'CrossGreenIcon'} />
          </PressableOpacity>
        </View>
        <Text style={styles.message}>
          {t('ADD_KID.NOTIFICATION.ADD_KID.MESSAGE')}
        </Text>
      </View>
    </View>
  );
};

export default DismissibleInfo;
