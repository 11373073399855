import { logPregnancyCreation, logPregnancyDelete } from '@analytics/events';
import pregnancy from '@api-requests/api/pregnancy';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import { Pregnancy } from '@commonTypes/Pregnancy';
import { useDispatch } from '@commonTypes/redux';
import useMutationCall from '@hooks/useMutationCall';
import { USER_QUERY_KEYS } from '@hooks/useUserDetail';
import { userCreators } from '@redux/user';
import { useQueryClient } from '@tanstack/react-query';

import { METHOD, PregnancyCreate, PregnancyUpdate } from '../types';

export const useAddPregnancy = (method: METHOD, screen: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutationCall<ReducedAxiosResponse<Pregnancy>, any, PregnancyCreate>(
    {
      mutationFn: (args: PregnancyCreate) => pregnancy.add(args),
      mutationKey: ['addPregnancy'],

      onSuccess: ({ data }) => {
        logPregnancyCreation({ screen, method });
        dispatch(userCreators.updateUserPregnancy(data));
        queryClient.invalidateQueries({
          queryKey: USER_QUERY_KEYS.detail,
        });
      },
    },
  );
};

export const useUpdatePregnancy = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutationCall<
    ReducedAxiosResponse<Pregnancy>,
    any,
    { data: PregnancyUpdate; childId: number }
  >({
    mutationFn: (args: { data: PregnancyUpdate; childId: number }) =>
      pregnancy.edit(args),
    mutationKey: ['updatePregnancy'],

    onSuccess: ({ data }) => {
      if (data.interrupted) {
        logPregnancyDelete({ cause: 'end' });
        dispatch(userCreators.updateUserPregnancy({}));
      } else if (data.born) {
        dispatch(userCreators.updateUserPregnancy(data));
        logPregnancyDelete({ cause: 'birth' });
      } else {
        dispatch(userCreators.updateUserPregnancy(data));
      }
      queryClient.invalidateQueries({
        queryKey: USER_QUERY_KEYS.detail,
      });
    },
  });
};
