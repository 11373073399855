import { resetAll } from '@redux/reduxReset';
import { createSlice } from '@reduxjs/toolkit';

const initialState = 0;

const feedEntriesCountSlice = createSlice({
  name: 'feedEntriesCount',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    add: (state) => state + 1,
    reset: () => initialState,
  },
});

export const feedEntriesCountCreators = feedEntriesCountSlice.actions;
export default feedEntriesCountSlice.reducer;
