import React from 'react';
import { View } from 'react-native';

import Actions from '@components/ui/Pressables/Actions';
import { goBack } from '@navigation/Actions';

export interface BackButtonProps {
  style?: View['props']['style'];
  /**
   * Defaults to a back action
   */
  onPress?: () => void;
  testID?: string;
}
const BackButton = ({ style, onPress = goBack, testID }: BackButtonProps) => (
  <Actions style={style} onPress={onPress} iconName={'Arrow'} testID={testID} />
);

export default BackButton;
