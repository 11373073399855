import { MinimalFile } from '@commonTypes/File';

export interface ScenarioRaw {
  id: number;
  slug: string;
  name: string;
  description: string;
  published_at: string;
  screens: Array<ScenarioScreenRaw>;
}

export type ScenarioScreenRaw = IImageTemplateRaw | IStepTemplateRaw;

export interface IImageTemplateRaw extends IImageTemplate {
  __component: TEMPLATE_TYPE.IMAGE;
  id: number;
  screenName: string;
}

export interface IStepTemplateRaw extends Omit<IStepTemplate, 'steps'> {
  id: number;
  step: Array<ScenarioSubStepRaw>;
}
export interface ScenarioSubStepRaw
  extends Omit<ScenarioSubStep, 'choices' | 'type'> {
  id: number;
  screenName: string;
  /**
   * @example "A\nB\nC\nD"
   */
  choices: string;
}

export type Scenario = Array<ScenarioScreen>;
export type ScenarioScreen = IImageTemplate | IStepTemplate;

export interface IImageTemplate {
  __component: TEMPLATE_TYPE.IMAGE;
  image: MinimalFile;
  title: string;
  description: string;
  CTALabel: string;
  type: SCENARIO_TARGET;
}

export interface IStepTemplate {
  __component: TEMPLATE_TYPE.STEP;
  name: string;
  steps: Array<ScenarioSubStep>;
  type: SCENARIO_TARGET;
}
export interface ScenarioSubStep {
  type: 'choice';
  title: string;
  description: string | undefined;
  choices: Array<StepChoice>;
}
export type StepChoice = { value: string };

export enum TEMPLATE_TYPE {
  IMAGE = 'onboarding-template.image-template',
  STEP = 'onboarding-template.step-template',
}

export enum SCENARIO_TARGET {
  ALL = 'all',
  PREGNANCY = 'pregnancy',
  CHILDREN = 'children',
}
