import { Kid } from '@commonTypes/Kid';
import { Pregnancy } from '@commonTypes/Pregnancy';
import { getBirthInformation } from '@tools/dateManipulation';

import { CoParent } from '@api-requests/api/main/family/types';
import { PRODUCT_TIER } from '@api-requests/api/main/payment/products/types';

import { IdentificationData } from './types';

export const formatIdentification = ({
  email,
  id,
  subscriptionStatus = 'Nada',
  subscriptionLevel,
  firstName = '',
  kids = [],
  pregnancy,
  createdAt,
  coparent,
}: {
  email: string;
  id: string | number;
  subscriptionStatus?: string | null | undefined;
  subscriptionLevel?: PRODUCT_TIER | null | undefined;
  firstName?: string;
  kids?: Kid[];
  pregnancy?: Pregnancy | Record<string, never>;
  createdAt?: Date | string;
  coparent?: CoParent | null;
}): IdentificationData & { id: string; email: string } => {
  const status = subscriptionStatus || 'Nada';
  const youngestKidAge = kids.length
    ? String(
        Math.min(
          ...kids.map((k) => getBirthInformation(k.birthDay).totalMonths),
        ),
      )
    : '';

  const coparentInfo = coparent
    ? { SharedAccount: coparent.email }
    : coparent === null
    ? { SharedAccount: '' }
    : {};

  return {
    id: String(id),
    email,
    status,
    tier: subscriptionLevel || '',
    name: firstName || '',
    youngestKidAge,
    pregnancyWeeks: String(pregnancy?.amenorrheaWeeks || ''),
    createdAt: createdAt ? new Date(createdAt).toISOString() : '',
    ...coparentInfo,
  };
};
