import { UserAnswers } from './types';
import { axiosPut } from '../request';

const baseUrl = '/apifo/profiles';

const addProfileDetails = (data: UserAnswers) =>
  axiosPut<void>({ url: baseUrl, params: { data } });

export const profiles = {
  add: addProfileDetails,
};
