import React from 'react';
import { Text, View } from 'react-native';

import { t } from '@config';

import styles from './styles';

const SubscribedPill = ({ small }: { small?: boolean }) => (
  <View style={[styles.container, small && styles.paddingSmall]}>
    <Text style={[styles.label, small && styles.labelSmall]}>
      {t('SUBSCRIPTION.SUBSCRIBER')}
    </Text>
  </View>
);

export default SubscribedPill;
