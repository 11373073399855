import React, { useEffect, useState } from 'react';
import { Text } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
  Easing,
  withDelay,
  WithTimingConfig,
  withSequence,
} from 'react-native-reanimated';

import DEVICE from '@resources/constants/device';

import { PopupProvider } from './hook';
import styles from './styles';

const DISPLAYED_POSITION = DEVICE.SCREEN_HEIGHT * 0.85 - 24;
const HIDDEN_POSITION = DEVICE.SCREEN_HEIGHT;
const DELAY_DURATION = 3000;
const withTimingConfig: WithTimingConfig = {
  duration: 600,
  easing: Easing.elastic(1.2),
};

const Popup = ({
  Provider = PopupProvider,
  ...props
}: {
  Provider?: typeof PopupProvider;
} & Omit<React.ComponentProps<typeof PopupProvider>, 'displayText'>) => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState('');
  const handleDisplayText = (text: string) => {
    setMessage(text);
    setIsVisible(true);
  };

  const translateY = useSharedValue(HIDDEN_POSITION);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: translateY.value }],
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      translateY.value = withSequence(
        withTiming(DISPLAYED_POSITION, withTimingConfig),
        withDelay(
          DELAY_DURATION,
          withTiming(HIDDEN_POSITION, withTimingConfig),
        ),
      );
      setIsVisible(false);
    }
  }, [isVisible, translateY]);

  return (
    <>
      {message && (
        <Animated.View style={[styles.popup, animatedStyle]}>
          <Text style={styles.message}>{message}</Text>
        </Animated.View>
      )}
      <Provider displayText={handleDisplayText} {...props} />
    </>
  );
};

export default Popup;
