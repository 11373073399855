import React from 'react';
import { Image, StyleProp, StyleSheet, Text, ViewStyle } from 'react-native';

import { logMasterclassSelect } from '@analytics/events';
import Masterclass from '@commonTypes/apiResponses/Masterclass';
import { Trackers } from '@commonTypes/apiResponses/Trackers';
import { useSelector } from '@commonTypes/redux';
import { FavoritesButton } from '@components/FavoritesButton';
import LinearGradient, {
  LinearGradientConfig,
} from '@components/Gradients/LinearGradient';
import { ListenedMarker } from '@components/Pills/ViewMarker';
import { t } from '@config';
import { navigate } from '@navigation/Actions';
import { COMMON_SCREEN_STACK } from '@navigation/Routes';
import { Colors, Fonts } from '@resources/themes';
import { getBestImageOf } from '@tools/getBestImageOf';
import { getImageAuthorization } from '@tools/getImageAuthorization';

import ContentCardUI from '../ContentCardUI';
import Field from '../Fields';

const styles = StyleSheet.create({
  fill: StyleSheet.absoluteFillObject,
  author: {
    marginTop: 5,
    fontSize: Fonts.size.small,
    fontFamily: Fonts.type.semiBold,
  },
});

interface MasterclassUiProps {
  style?: StyleProp<ViewStyle>;
  title: string;
  highlightedTitle: string | undefined;
  uri?: string;
  author: string;
  totalTime: string | undefined | null;
  onPress: () => void;
  listened: boolean;
  favoriteButton?: React.ReactElement | null;
  disabled?: boolean;
}
const GradientConfig: LinearGradientConfig = [
  { offset: '0%', stopColor: Colors.duck800, stopOpacity: '1' },
  { offset: '100%', stopColor: Colors.duck500, stopOpacity: '1' },
];
export const MasterclassUi = ({
  uri,
  author,
  totalTime,
  listened,
  ...props
}: MasterclassUiProps) => {
  const contentColor = listened ? Colors.grey600 : Colors.duck800;
  const token = useSelector((state) => state.auth.token);
  const Cover = (
    <>
      <LinearGradient style={styles.fill} horizontal config={GradientConfig} />
      <Image
        style={styles.fill}
        resizeMethod="resize"
        resizeMode="cover"
        source={{ uri, headers: getImageAuthorization(uri, token) }}
      />
      {listened && <ListenedMarker />}
    </>
  );
  const Fields = (
    <>
      <Text style={[styles.author, { color: contentColor }]}>
        {t('CMS_CARD.BY_AUTHOR', { author })}
      </Text>
      {totalTime && (
        <Field
          iconName={'Clock'}
          label={totalTime}
          contentColor={contentColor}
        />
      )}
    </>
  );
  return (
    <ContentCardUI
      {...props}
      label={t('COMMON.MASTERCLASS')}
      contentColor={contentColor}
      imageSection={Cover}
      fields={Fields}
    />
  );
};

interface MasterclassCardProps {
  style?: StyleProp<ViewStyle>;
  item: Masterclass;
  trackers?: Trackers;
  screen: string;
  disabled?: boolean;
}
const MasterclassCard = ({
  item,
  trackers,
  screen,
  ...props
}: MasterclassCardProps) => {
  const handlePress = () => {
    logMasterclassSelect({
      masterclassId: item.id,
      ageLevel: item.age_levels?.[0]?.name,
      categories: item.categories?.[0]?.slug,
      expert: item.masterclass_expert?.name,
      screen,
      title: item.title,
    });
    navigate(COMMON_SCREEN_STACK.MASTERCLASS_SCREEN, {
      masterclass: item,
      masterclassId: undefined,
    });
  };
  return (
    <MasterclassUi
      {...props}
      title={item.title}
      highlightedTitle={item.highlightedTitle}
      uri={getBestImageOf(item.masterclass_expert.photo, 'small')}
      author={item.masterclass_expert.name}
      totalTime={item.totalTime}
      onPress={handlePress}
      listened={!!trackers?.masterclasses?.[item.id]?.completed}
      favoriteButton={
        <FavoritesButton type={'masterclasses'} id={`${item.id}`} item={item} />
      }
    />
  );
};

export default MasterclassCard;
