import { resetAll } from '@redux/reduxReset';
import { createSlice } from '@reduxjs/toolkit';

const initialState = { lastSeenTimestamp: 0 };
const tabSponsorReminderSlice = createSlice({
  name: 'tabSponsorReminder',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    dismiss: (state) => {
      state.lastSeenTimestamp = Date.now();
    },
  },
});

export const tabSponsorReminderCreators = tabSponsorReminderSlice.actions;
export default tabSponsorReminderSlice.reducer;
