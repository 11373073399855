import { axiosGet, axiosPost } from '@api-requests/api/main/request';

import {
  ConsultationAvailableSlotRaw,
  ConsultationCategory,
  ConsultationCreate,
  ConsultationDetailRaw,
  S3UrlRequest,
  S3UrlResponse,
  UpcomingConsultationRaw,
  ConsultationHistoryRaw,
} from '@screens/Consultations/types';

const baseUrl = '/apifo/consultations';

const getCategories = () =>
  axiosGet<ConsultationCategory[]>({ url: '/apifo/consultation-categories' });

const getAvailableSlots = () =>
  axiosGet<ConsultationAvailableSlotRaw[]>({
    url: `${baseUrl}/availableSlots`,
  });

const requestS3Url = (data: S3UrlRequest) =>
  axiosPost<S3UrlResponse, S3UrlRequest>({
    url: `${baseUrl}/s3-uploader`,
    params: { data },
  });

const createConsultation = (data: ConsultationCreate) =>
  axiosPost<ConsultationDetailRaw, ConsultationCreate>({
    url: baseUrl,
    params: { data },
  });

const consultationDetail = (params: { id: number }) =>
  axiosGet<ConsultationDetailRaw[], { id: number }>({
    url: baseUrl,
    params: { params },
  });

const upcomingConsultations = () =>
  axiosGet<UpcomingConsultationRaw[]>({ url: `${baseUrl}/coming` });
const historyList = () =>
  axiosGet<ConsultationHistoryRaw[]>({ url: `${baseUrl}/closed` });

const cancelConsultation = (data: { id: number }) =>
  axiosPost<void, { id: number }>({
    url: `${baseUrl}/cancel`,
    params: { data },
  });

export const getTokBoxToken = (visioId: string) =>
  axiosGet({ url: `/apifo/toktoken/${visioId}` });

export default {
  getCategories,
  getAvailableSlots,
  requestS3Url,
  create: createConsultation,
  getOne: consultationDetail,
  upcoming: upcomingConsultations,
  history: historyList,
  cancel: cancelConsultation,
  getTokBoxToken,
};
