import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    minHeight: 64,
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 12,
    backgroundColor: Colors.white100,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.duck500,
  },
  containerSelected: { backgroundColor: Colors.duck800, borderWidth: 0 },

  left: { flex: 1, justifyContent: 'center', gap: 8 },
  pill: {
    alignSelf: 'flex-start',
    marginLeft: -8,
    paddingHorizontal: 8,
    backgroundColor: Colors.yellow500,
    borderRadius: 10,
    lineHeight: 20,
    color: Colors.white100,
    fontFamily: Fonts.type.bold,
    fontSize: Fonts.size.small,
    textTransform: 'uppercase',
    overflow: 'hidden',
  },
  title: {
    color: Colors.duck800,
    fontFamily: Fonts.type.semiBold,
    fontSize: Fonts.size.title,
  },
  textSelected: { color: Colors.white100 },

  right: { justifyContent: 'center' },
  price: {
    textAlign: 'right',
    color: Colors.duck800,
    fontFamily: Fonts.type.bold,
    fontSize: Fonts.size.input,
  },
  priceMonthly: {
    textAlign: 'right',
    color: Colors.duck800,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.extraText,
  },
});
