import Article from '@commonTypes/apiResponses/Article';
import Folder from '@commonTypes/apiResponses/Folder';
import Guide from '@commonTypes/apiResponses/Guide';
import Masterclass from '@commonTypes/apiResponses/Masterclass';

import { DailyTip } from '@api-requests/api/cms/daily-tips/types';
import { Video } from '@api-requests/api/cms/partner-videos/types';
import { WeeklyTip } from '@api-requests/api/cms/weekly-tips/types';

export enum ContentType {
  article = 'article',
  guide = 'guide',
  masterclass = 'masterclass',
  dailyTip = 'dailyTip',
  folder = 'folder',
  video = 'video',
  weeklyTip = 'weeklyTip',
}

export type FormatAllContentList = {
  postList?: Article[];
  guideList?: Guide[];
  masterclassList?: Masterclass[];
  dailyTipsList?: DailyTip[];
  folderList?: Folder[];
  videoList?: Video[];
  weeklyTipsList?: Array<WeeklyTip>;
};

export type FormattedContentItem =
  | ({ type: ContentType.article } & Article)
  | ({ type: ContentType.guide } & Guide)
  | ({ type: ContentType.masterclass } & Masterclass)
  | ({ type: ContentType.dailyTip } & DailyTip)
  | ({ type: ContentType.folder } & Folder)
  | Video
  | WeeklyTip;
