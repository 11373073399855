import { push } from '@navigation/Actions';
import { WEB_ANONYMOUS_STACK } from '@webNavigation/routes';

import { Scenario, TEMPLATE_TYPE } from '@api-requests/api/cms/scenarii/types';

export const handlePreOnboardingNavigation = ({
  workflow,
  targetIndex = 0,
}: {
  workflow: Scenario | undefined;
  targetIndex?: number;
}) => {
  if (targetIndex < 0) {
    return;
  }
  const target = workflow?.[targetIndex]?.__component;
  if (!target) {
    return;
  }
  push(targetToNavMap[target], { currentIndex: targetIndex });
};

const targetToNavMap = {
  [TEMPLATE_TYPE.IMAGE]: WEB_ANONYMOUS_STACK.REMOTE_PRE_ONBOARDING_IMAGE_SCREEN,
  [TEMPLATE_TYPE.STEP]: WEB_ANONYMOUS_STACK.REMOTE_PRE_ONBOARDING_STEP_SCREEN,
} as const;
