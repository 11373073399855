import { useMemo } from 'react';
import { Control, useWatch } from 'react-hook-form';

const oneUpperRegex = /[A-Z]/;
const oneLowerRegex = /[a-z]/;
const oneNumberRegex = /[0-9]/;

interface PasswordChecks {
  passwordMinSizeCompleted: boolean;
  oneNbCompleted: boolean;
  oneLowerCompleted: boolean;
  oneUpperCompleted: boolean;
}

export const checkPassword = (password: string): PasswordChecks => {
  const result = {
    passwordMinSizeCompleted: false,
    oneNbCompleted: false,
    oneLowerCompleted: false,
    oneUpperCompleted: false,
  };

  if (password.length >= 8) {
    result.passwordMinSizeCompleted = true;
  }

  if (oneUpperRegex.test(password)) {
    result.oneUpperCompleted = true;
  }

  if (oneLowerRegex.test(password)) {
    result.oneLowerCompleted = true;
  }

  if (oneNumberRegex.test(password)) {
    result.oneNbCompleted = true;
  }

  return result;
};

export type passwordForm = {
  pass: string;
};
export type resetPasswordForm = {
  new_password: string;
  pwd_reset_code: string;
  email: string;
};

export const useCheckPasswordRules = (control: Control<passwordForm>) => {
  const password = useWatch({ control, name: 'pass' });
  return useMemo<PasswordChecks>(() => checkPassword(password), [password]);
};

export const useCheckResetPasswordRules = (
  control: Control<resetPasswordForm>,
) => {
  const password = useWatch({ control, name: 'new_password' });
  return useMemo<PasswordChecks>(() => checkPassword(password), [password]);
};
export default useCheckPasswordRules;
