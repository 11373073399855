import { Platform, StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  screen: {
    padding: 24,
    flex: 1,
    justifyContent: 'space-between',
    ...Platform.select({
      web: { backgroundColor: Colors.white100 },
      default: {},
    }),
  },

  heading: {
    color: Colors.duck800,
    fontFamily: Fonts.type.Recoleta.medium,
    fontSize: Fonts.size.h3,
  },

  infoContainer: {
    gap: 16,
    alignItems: 'center',
  },

  title: {
    color: Colors.duck800,
    fontFamily: Fonts.type.semiBold,
    fontSize: Fonts.size.h5,
    textAlign: 'center',
  },
  description: {
    color: Colors.duck700,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.title,
    textAlign: 'center',
  },
});
