import { OpenidProviders } from '@commonTypes/Auth';
import ACCESS from '@resources/constants/access';
import Logger from '@tools/Logger';

import { FEED_TYPE } from '@api-requests/api/main/child-feeding/types';
import { PRODUCT_TIER } from '@api-requests/api/main/payment/products/types';

import { EURO_ISO4217, logEvent, screenView, segmentClient } from './common';
import {
  ACCEPT_NOTIFICATION_PERMISSION,
  ACTIVATE_AFFILIATION,
  ADD_CHILD_HEIGHT,
  ADD_CHILD_WEIGHT,
  ADD_INITIAL_PREGNANCY_WEIGHT,
  ADD_PREGNANCY_WEIGHT,
  ADD_TODO_LIST,
  ADD_TODO_TASK,
  CHAT_SEND_MESSAGE,
  CHILD_CREATION,
  CHURN_COMPLETE,
  CHURN_START,
  CHURN_STEP_ANSWER,
  CONTENT_EVALUATION,
  CREATE_APPOINTMENTS,
  EXPLORE_FILTER,
  EXPLORE_FILTER_CONTENT,
  EXPLORE_SEARCH_BAR_FOCUS,
  EXPLORE_SELECT_FILTER,
  FAMILY_COPARENT_ACCEPT_INVITATION,
  FAMILY_COPARENT_DEACTIVATE_INVITATION,
  FAMILY_COPARENT_REFUSES_INVITATION,
  FAMILY_COPARENT_SELECT_INVITATION_BANNER,
  FAMILY_COPARENT_VIEW_INVITATION_MODAL,
  FAMILY_MASTER_DEACTIVATE_INVITATION,
  FAMILY_MASTER_DEACTIVATE_PENDING,
  FAMILY_MASTER_SHARE_ACCOUNT,
  FAMILY_MASTER_SHARE_LINK,
  FAMILY_PRIMARY_PARENT_INITIATE_SHARE,
  FAMILY_VIEW_INFO,
  LOG_LIVI_FROM_SCREEN,
  MASTERCLASS_PLAY_TRACK,
  MASTERCLASS_SELECT,
  MIDWIFE_SEND_MESSAGE,
  NOTIFICATIONS_SETTINGS_PRESS_ACTIVATE,
  NOTIFICATION_PERMISSION,
  ONBOARDING_STEP_ANSWER,
  OPEN_LIVI,
  OPEN_PAYMENT_SHEET,
  PREGNANCY_CREATION,
  PREGNANCY_DELETE,
  PROGRAM_SELECT,
  REACTIVATE_SUBSCRIPTION,
  RECIPES_SEARCH,
  REVIEW_IN_APP_OPENED,
  SEARCH_FAQ_PREGNANCY,
  SEARCH_FAQ_PURCHASE,
  SEARCH_LIBRARY,
  SELECT_APPOINTMENTS,
  SELECT_CHILD_WEIGHT,
  SELECT_DAILY_TIP,
  SELECT_FAQ_PREGNANCY_CONTENT,
  SELECT_FAQ_PURCHASE_CONTENT,
  SELECT_FEATURED_CONTENT,
  SELECT_ITEM,
  SELECT_MY_BELLY,
  SELECT_PLAN,
  SELECT_PREGNANCY_WEIGHT,
  SELECT_PREVIOUS_DAILY_TIP,
  SELECT_RECIPE_CONTENT,
  SELECT_TODO_LIST,
  SHARE_CONTENT,
  SHARE_DAILY_TIP,
  SHARE_FAQ_PURCHASE,
  SHARE_FOLDER,
  SHARE_GUIDE,
  SHARE_MASTERCLASS,
  SHARE_SPONSORSHIP,
  UNAVAILABLE_CHILD_HEIGHT,
  UNAVAILABLE_CHILD_WEIGHT,
  UNSUBSCRIBE,
  UNSUBSCRIBE_START,
  VIEW_CONTENT,
  VIEW_DAILY_TIP,
  VIEW_PAYMENT_SCREEN,
  VIEW_PREGNANCY_EVOLUTION,
  VIEW_PREGNANCY_WEEK_DETAILS,
  VIEW_SPONSORSHIP,
  VIEW_SUBSCRIPTION_MODAL,
  ADD_BELLY_PICTURE,
  SELECT_CALENDAR,
  VIEW_CHILD_EVOLUTION,
  VIEW_CHILD_MONTH_DETAILS,
  SELECT_CHILD_SLEEP,
  ADD_CHILD_SLEEP_SESSION,
  SELECT_CHILD_FEED,
  ADD_CHILD_FEED_MEASURE,
  UPGRADE_SUBSCRIPTION,
  VIEW_3D_BABY,
  DEACTIVATE_ACCOUNT,
  VIDEO_PLAY,
  SELECT_CONTRACTIONS,
  ADD_CONTRACTIONS,
  SELECT_DIAPER,
  ADD_DIAPER,
  WEEKLY_TIP,
  CYCLE_TRACKING,
  UPLOAD_FILES,
  ADD_CHILD_CRANIAL,
  UNAVAILABLE_CHILD_CRANIAL,
  SELECT_RECIPE,
} from './eventNames';
import type {
  ActivateAffiliationProps,
  AddPaymentInfoConsultationProps,
  AddPaymentInfoSubscriptionProps,
  ChatSendMessageProps,
  ChildCreationProps,
  ConsultationPurchaseProps,
  ContentEvaluationProps,
  ContentProps,
  LogChurnStepAnswerProps,
  LogLiviFromScreenProps,
  LogOnboardingStepAnswerProps,
  MasterclassProps,
  MasterclassTrackProps,
  MidwifeSendMessageProps,
  PregnancyCreationProps,
  PregnancyDeleteProps,
  ProgramProps,
  ScreenViewProps,
  SearchFaqPregnancyProps,
  SearchFaqPurchaseProps,
  SelectDailyTipProps,
  SelectFaqPregnancyContentProps,
  SelectFeaturedContentProps,
  SelectItemProps,
  SelectPlanProps,
  ShareDailyTipProps,
  ShareFaqPurchaseProps,
  ShareFolderProps,
  ShareGuideProps,
  ShareMasterclassProps,
  ShareProps,
  ViewDailyTipProps,
  ViewPaymentScreenProps,
  ViewSubscriptionModalProps,
  logTutorialProps,
} from './eventsTypes';

export const logViewSubscriptionModal = ({
  screen,
  modalName,
  isAutomaticDisplayed,
}: ViewSubscriptionModalProps) =>
  logEvent(VIEW_SUBSCRIPTION_MODAL, {
    screen,
    modalName,
    isAutomaticDisplayed,
  });

export const logSelectPlan = ({
  screen,
  item,
  value,
  currency = EURO_ISO4217,
}: SelectPlanProps) => logEvent(SELECT_PLAN, { screen, item, value, currency });

export const logLogin = (args: {
  method: 'email' | OpenidProviders | 'apple';
}) => logEvent('login', args);

export const logOnboardingStepAnswer = ({
  scenario,
  stepTitle,
  stepScreenName,
  answer,
}: LogOnboardingStepAnswerProps) =>
  logEvent(ONBOARDING_STEP_ANSWER, {
    scenario,
    stepTitle,
    stepScreenName,
    answer,
  });

export const logDeactivateAccount = () => logEvent(DEACTIVATE_ACCOUNT);

export const logExploreFilter = ({
  type,
}: {
  type: 'listen' | 'read' | 'watch';
}) => logEvent(EXPLORE_FILTER, { type });

export const logExploreSearchBarFocus = () =>
  logEvent(EXPLORE_SEARCH_BAR_FOCUS);

export const logExploreSelectFilter = () => logEvent(EXPLORE_SELECT_FILTER);

export const logExploreFilterContent = ({
  type,
  name,
}: {
  type: 'period' | 'categories';
  name: string;
}) => logEvent(EXPLORE_FILTER_CONTENT, { type, name });

export const logSignUp = (args: {
  method: 'email' | OpenidProviders | 'apple';
}) => logEvent('signup', args);

export const logTutorialBegin = ({ scenario }: logTutorialProps) =>
  logEvent('tutorial_begin', { scenario: scenario });

export const logTutorialComplete = ({ scenario }: logTutorialProps) =>
  logEvent('tutorial_complete', { scenario: scenario });

export const logShareSponsorship = (params: { method: string }) =>
  logEvent(SHARE_SPONSORSHIP, params);

export const logViewSponsorship = () => logEvent(VIEW_SPONSORSHIP);

export const logChatSendMessage = ({
  childAge,
  subjectId,
}: ChatSendMessageProps) =>
  logEvent(CHAT_SEND_MESSAGE, { child_age: childAge, subject_id: subjectId });

export const logMidwifeSendMessage = ({
  pregnancyWeeks,
  subjectId,
}: MidwifeSendMessageProps) =>
  logEvent(MIDWIFE_SEND_MESSAGE, {
    pregnancy_weeks: pregnancyWeeks,
    subject_id: subjectId,
  });

export const logChildCreation = ({ screen }: ChildCreationProps) =>
  logEvent(CHILD_CREATION, { screen });

export const logSelectItem = ({
  contentType,
  itemListName,
  itemListId,
}: SelectItemProps) =>
  logEvent(SELECT_ITEM, {
    content_type: contentType,
    item_list_name: itemListName,
    item_list_id: itemListId,
  });

export const logSelectFaqPregnancyContent = ({
  category,
  output,
}: SelectFaqPregnancyContentProps) =>
  logEvent(SELECT_FAQ_PREGNANCY_CONTENT, { category, output });

export const logSelectFaqPurchaseContent = ({
  categoryName,
}: {
  categoryName: string;
}) => logEvent(SELECT_FAQ_PURCHASE_CONTENT, { category_name: categoryName });

export const logSelectRecipe = (params: { screen: string }) =>
  logEvent(SELECT_RECIPE, params);
export const logRecipesSearch = ({ search }: { search: string }) =>
  logEvent(RECIPES_SEARCH, { search });

export const logSelectRecipeContent = ({
  title,
  ageMin,
  Type,
}: {
  title: string;
  ageMin: string;
  Type: string;
}) => logEvent(SELECT_RECIPE_CONTENT, { title, age_min: ageMin, type: Type });

export const logSearchFaqPregnancy = ({ search }: SearchFaqPregnancyProps) =>
  logEvent(SEARCH_FAQ_PREGNANCY, { search });

export const logSearchFaqPurchase = ({ search }: SearchFaqPurchaseProps) =>
  logEvent(SEARCH_FAQ_PURCHASE, { search });

export const logSearchLibrary = (search: string) =>
  logEvent(SEARCH_LIBRARY, { search });

export const logPurchase = ({
  itemBrand = '',
  value,
  tier,
  createdAt,
  paymentImage = '',
  coupon,
}: {
  itemBrand: string;
  value: number;
  createdAt: string;
  paymentImage: string;
  tier: PRODUCT_TIER | 'web';
  coupon: string | undefined;
}) =>
  logEvent('purchase', {
    items: [{ item_brand: itemBrand }],
    value,
    revenue: value,
    currency: EURO_ISO4217,
    tier,
    createdAt,
    paymentImage,
    coupon,
  });

export const logConsultationPurchase = ({
  value,
  currency = EURO_ISO4217,
}: ConsultationPurchaseProps) =>
  logEvent('purchase', {
    items: [{ item_brand: 'consultation' }],
    revenue: value,
    currency,
  });

export const logAddPaymentInfoConsultation = ({
  value,
  currency = EURO_ISO4217,
}: AddPaymentInfoConsultationProps) =>
  logEvent('add_payment_info', {
    items: [{ item_brand: 'consultation' }],
    revenue: value,
    currency,
  });

export const logAddPaymentInfoSubscription = ({
  subscriptionName,
  value,
  tier,
  coupon,
  paymentImage = '',
}: {
  subscriptionName: string;
  value: number;
  tier: PRODUCT_TIER;
  coupon: string | undefined;
  paymentImage: string;
}) =>
  logEvent('add_payment_info', {
    items: [{ item_brand: subscriptionName }],
    value,
    revenue: value,
    currency: EURO_ISO4217,
    tier,
    coupon,
    paymentImage,
  });

export const logUpgradeSubscription = (params: { productName: string }) =>
  logEvent(UPGRADE_SUBSCRIPTION, params);

export const logViewPaymentScreen = ({
  value,
  currency = EURO_ISO4217,
  subscriptionName,
  paymentImage = '',
}: ViewPaymentScreenProps) =>
  logEvent(VIEW_PAYMENT_SCREEN, {
    items: [{ item_brand: subscriptionName }],
    currency,
    revenue: value,
    paymentImage,
  });

export const logOpenPaymentSheetSubscription = ({
  value,
  currency = EURO_ISO4217,
  coupon,
  subscriptionName,
}: AddPaymentInfoSubscriptionProps) =>
  logEvent(OPEN_PAYMENT_SHEET, {
    coupon,
    items: [{ item_brand: subscriptionName }],
    revenue: value,
    currency,
  });

export const logSelectFeaturedContent = ({
  contentType,
  itemId,
  itemTitle,
  itemAgeLevel,
  itemCategory,
}: SelectFeaturedContentProps) =>
  logEvent(SELECT_FEATURED_CONTENT, {
    content_type: contentType,
    item_id: itemId,
    item_title: itemTitle,
    item_age_level: itemAgeLevel,
    item_category: itemCategory,
  });

export const logSelectDailyTip = ({
  tipId,
  tipCategory,
  weekNumber,
  pregnancy,
}: SelectDailyTipProps) =>
  logEvent(SELECT_DAILY_TIP, {
    content_type: 'daily_tip',
    tip_id: tipId,
    tip_category: tipCategory,
    week_number: weekNumber,
    pregnancy,
  });

export const logSelectPreviousDailyTip = ({
  tipId,
  tipCategory,
  weekNumber,
  pregnancy,
}: SelectDailyTipProps) => {
  logEvent(SELECT_PREVIOUS_DAILY_TIP, {
    content_type: 'daily_tip',
    tip_id: tipId,
    tip_category: tipCategory,
    week_number: weekNumber,
    pregnancy,
  });
};

export const logShare = ({
  contentType,
  itemId,
  itemSlug,
  itemAgeLevel,
  itemCategory,
  method,
  access = ACCESS.ALL,
}: ShareProps) =>
  logEvent(SHARE_CONTENT, {
    content_type: contentType,
    item_id: itemId,
    item_slug: itemSlug,
    item_age_level: itemAgeLevel,
    item_category: itemCategory,
    method,
    access,
  });

export const logShareDailyTip = ({
  tipId,
  tipCategory,
  method,
  weekNumber = '',
  pregnancy,
}: ShareDailyTipProps) =>
  logEvent(SHARE_DAILY_TIP, {
    tip_id: tipId,
    tip_category: tipCategory,
    method,
    week_number: weekNumber,
    pregnancy,
  });

export const logShareGuide = ({
  guideId,
  guideSlug,
  guideAgeLevel,
  guideCategory,
  method,
}: ShareGuideProps) =>
  logEvent(SHARE_GUIDE, {
    guide_id: guideId,
    guide_slug: guideSlug,
    guide_age_level: guideAgeLevel,
    guide_category: guideCategory,
    method,
  });

export const logShareMasterclass = ({
  masterclassId,
  masterclassTitle,
  masterclassAgeLevel,
  masterclassCategory,
  method,
}: ShareMasterclassProps) =>
  logEvent(SHARE_MASTERCLASS, {
    masterclass_id: masterclassId,
    masterclass_title: masterclassTitle,
    masterclass_age_level: masterclassAgeLevel,
    masterclass_category: masterclassCategory,
    method,
  });

export const logShareFolder = ({
  folderId,
  folderTitle,
  folderAgeLevel,
  folderCategory,
  method,
}: ShareFolderProps) =>
  logEvent(SHARE_FOLDER, {
    folder_id: folderId,
    folder_title: folderTitle,
    folder_age_level: folderAgeLevel,
    folder_category: folderCategory,
    method,
  });

export const logShareFaqPurchase = ({
  isPregnant,
  selectedChoiceType,
  itemId,
  itemCategory,
  method,
}: ShareFaqPurchaseProps) =>
  logEvent(SHARE_FAQ_PURCHASE, {
    isPregnant,
    selectedChoiceType,
    itemId,
    itemCategory,
    method,
  });

export const logScreenView = async ({
  screenName,
  screenClass,
}: ScreenViewProps) => {
  if (segmentClient) {
    screenView({
      screenName,
      screenClass,
    });
  } else {
    Logger.log('Screen view', screenName);
  }
};

export const logViewContent = ({
  contentType,
  itemId,
  itemSlug,
  itemCategory,
  itemAgeLevel,
  access = ACCESS.ALL,
}: ContentProps) =>
  logEvent(VIEW_CONTENT, {
    content_type: contentType,
    item_id: itemId,
    item_slug: itemSlug,
    item_category: itemCategory,
    item_agelevel: itemAgeLevel,
    access,
  });

export const logContentEvaluation = ({
  like,
  contentType,
  itemId,
  itemCategory,
  itemAgeLevel,
  itemSlug,
  access = ACCESS.ALL,
}: ContentEvaluationProps) =>
  logEvent(CONTENT_EVALUATION, {
    like,
    content_type: contentType,
    item_id: itemId,
    item_category: itemCategory,
    item_agelevel: itemAgeLevel,
    item_slug: itemSlug,
    access,
  });

export const logViewDailyTip = ({
  tipId,
  tipCategory,
  weekNumber = '',
  pregnancy,
}: ViewDailyTipProps) =>
  logEvent(VIEW_DAILY_TIP, {
    content_type: 'daily_tip',
    item_id: tipId,
    item_category: tipCategory,
    weekNumber,
    pregnancy,
  });

export const logProgramSelect = ({
  programId,
  ageLevel,
  categories,
  screen,
  title,
}: ProgramProps) =>
  logEvent(PROGRAM_SELECT, {
    program_id: programId,
    age_level: ageLevel,
    category: categories,
    screen,
    title,
  });

export const logPlayVideo = ({
  id,
  category,
  ageLevel,
  title,
  screen,
}: {
  id: number;
  category: string | undefined;
  ageLevel: string | undefined;
  title: string;
  screen: string;
}) =>
  logEvent(VIDEO_PLAY, {
    item_id: id,
    item_category: category,
    item_agelevel: ageLevel,
    title,
    screen,
  });

export const logMasterclassSelect = ({
  masterclassId,
  ageLevel,
  categories,
  expert,
  screen,
  title,
}: MasterclassProps) =>
  logEvent(MASTERCLASS_SELECT, {
    masterclass_id: masterclassId,
    age_level: ageLevel,
    expert,
    category: categories,
    screen,
    title,
  });

export const logMasterclassPlayTrack = ({
  masterclassId,
  masterclassTitle,
  ageLevel,
  categories,
  expert,
  trackId,
  trackOrder,
  trackAccess,
  from = 'classic',
}: MasterclassTrackProps) =>
  logEvent(MASTERCLASS_PLAY_TRACK, {
    masterclass_id: masterclassId,
    age_level: ageLevel,
    expert,
    category: categories,
    track_id: trackId,
    track_order: trackOrder,
    track_access: trackAccess,
    masterclass_title: masterclassTitle,
    from,
  });

export const logActivateAffiliation = ({
  plan,
  company,
}: ActivateAffiliationProps) =>
  logEvent(ACTIVATE_AFFILIATION, { plan, company });

export const logPregnancyCreation = ({
  screen,
  method,
}: PregnancyCreationProps) => logEvent(PREGNANCY_CREATION, { screen, method });

export const logPregnancyDelete = ({ cause }: PregnancyDeleteProps) =>
  logEvent(PREGNANCY_DELETE, { cause });

export const logUnsubscribe = ({ scenario = '' }: { scenario?: string }) =>
  logEvent(UNSUBSCRIBE, { scenario });

export const logUnsubscribeStart = () => logEvent(UNSUBSCRIBE_START);

export const logChurnComplete = ({ scenario = '' }: { scenario: string }) =>
  logEvent(CHURN_COMPLETE, { scenario });

export const logChurnStart = ({ scenario = '' }: { scenario: string }) =>
  logEvent(CHURN_START, { scenario });

export const logReactivateSubscription = () =>
  logEvent(REACTIVATE_SUBSCRIPTION);

export const logChurnStepAnswer = ({
  scenario,
  stepTitle,
  stepScreenName,
  answer,
}: LogChurnStepAnswerProps) =>
  logEvent(CHURN_STEP_ANSWER, {
    scenario,
    stepTitle,
    stepScreenName,
    answer,
  });

export const logReviewInAppOpened = () => logEvent(REVIEW_IN_APP_OPENED);

export const logOpenLivi = () => logEvent(OPEN_LIVI);

export const LogLiviFromScreen = ({ previousScreen }: LogLiviFromScreenProps) =>
  logEvent(LOG_LIVI_FROM_SCREEN, {
    previousScreen,
  });

export const logFamilyViewInfo = () => logEvent(FAMILY_VIEW_INFO);
export const logFamilyPrimaryParentInitiateShare = () =>
  logEvent(FAMILY_PRIMARY_PARENT_INITIATE_SHARE);
export const logFamilyMasterShareAccount = () =>
  logEvent(FAMILY_MASTER_SHARE_ACCOUNT);
export const logFamilyMasterShareLink = () =>
  logEvent(FAMILY_MASTER_SHARE_LINK);
export const logFamilyMasterDeactivatePending = () =>
  logEvent(FAMILY_MASTER_DEACTIVATE_PENDING);
export const logFamilyMasterDeactivateInvitation = () =>
  logEvent(FAMILY_MASTER_DEACTIVATE_INVITATION);
export const logFamilySlaveSelectInvitationBanner = ({
  Screen,
}: {
  Screen: string;
}) => logEvent(FAMILY_COPARENT_SELECT_INVITATION_BANNER, { Screen });
export const logFamilySlaveViewInvitationModal = ({
  Screen,
}: {
  Screen: string;
}) => logEvent(FAMILY_COPARENT_VIEW_INVITATION_MODAL, { Screen });
export const logFamilySlaveAcceptInvitation = () =>
  logEvent(FAMILY_COPARENT_ACCEPT_INVITATION);
export const logFamilySlaveRefusesInvitation = () =>
  logEvent(FAMILY_COPARENT_REFUSES_INVITATION);
export const logFamilySlaveDeactivateInvitation = () =>
  logEvent(FAMILY_COPARENT_DEACTIVATE_INVITATION);

export const logViewPregnancyEvolution = () =>
  logEvent(VIEW_PREGNANCY_EVOLUTION);
export const logViewPregnancyWeekDetails = ({
  weekNumber,
}: {
  weekNumber: number;
}) => logEvent(VIEW_PREGNANCY_WEEK_DETAILS, { weekNumber });

export const logViewChildEvolution = () => logEvent(VIEW_CHILD_EVOLUTION);
export const logViewChildMonthDetails = (params: { monthNumber: number }) =>
  logEvent(VIEW_CHILD_MONTH_DETAILS, params);

export const logPressActivateNotification = () =>
  logEvent(NOTIFICATIONS_SETTINGS_PRESS_ACTIVATE);

export const logSelectCalendar = (params: { screen: string }) =>
  logEvent(SELECT_CALENDAR, params);
export const logSelectAppointments = (params: { screen: string }) =>
  logEvent(SELECT_APPOINTMENTS, params);
export const logSelectPregnancyWeight = (params: { screen: string }) =>
  logEvent(SELECT_PREGNANCY_WEIGHT, params);
export const logSelectTodoList = (params: { screen: string }) =>
  logEvent(SELECT_TODO_LIST, params);
export const logSelectChildWeight = (params: { screen: string }) =>
  logEvent(SELECT_CHILD_WEIGHT, params);
export const logSelectMyBelly = (params: { screen: string }) =>
  logEvent(SELECT_MY_BELLY, params);
export const logAddBellyPicture = (params: { month: number }) =>
  logEvent(ADD_BELLY_PICTURE, params);

export const logCreateAppointments = (params: {
  screen: string;
  type: 'child' | 'parent';
  reminder: boolean;
}) => logEvent(CREATE_APPOINTMENTS, params);

export const logAddInitialPregnancyWeight = (params: { screen: string }) =>
  logEvent(ADD_INITIAL_PREGNANCY_WEIGHT, params);
export const logAddPregnancyWeight = (params: { screen: string }) =>
  logEvent(ADD_PREGNANCY_WEIGHT, params);

export const logAddTodoTask = (params: { screen: string; reminder: boolean }) =>
  logEvent(ADD_TODO_TASK, params);
export const logAddTodoList = (params: { screen: string }) =>
  logEvent(ADD_TODO_LIST, params);

export const logAddChildWeight = (params: {
  screen: string;
  childMonths: number;
}) => logEvent(ADD_CHILD_WEIGHT, params);
export const logAddChildHeight = (params: {
  screen: string;
  childMonths: number;
}) => logEvent(ADD_CHILD_HEIGHT, params);
export const logAddChildCranial = (params: {
  screen: string;
  childMonths: number;
}) => logEvent(ADD_CHILD_CRANIAL, params);
export const logUnavailableChildWeight = (params: { childAge: number }) =>
  logEvent(UNAVAILABLE_CHILD_WEIGHT, params);
export const logUnavailableChildHeight = (params: { childAge: number }) =>
  logEvent(UNAVAILABLE_CHILD_HEIGHT, params);
export const logUnavailableChildCranial = (params: { childAge: number }) =>
  logEvent(UNAVAILABLE_CHILD_CRANIAL, params);
export const logSelectChildSleep = (params: { screen: string }) =>
  logEvent(SELECT_CHILD_SLEEP, params);
export const logAddChildSleepSession = (params: {
  input: 'timer' | 'manual';
  childAgeInMonths: number;
}) => logEvent(ADD_CHILD_SLEEP_SESSION, params);

export const logSelectChildFeed = (params: { screen: string }) =>
  logEvent(SELECT_CHILD_FEED, params);
export const logAddChildFeedMeasure = (params: {
  type: FEED_TYPE;
  childAgeInMonths: number;
  input: 'timer' | 'manual';
}) => logEvent(ADD_CHILD_FEED_MEASURE, params);

export const logSelectContractions = () => logEvent(SELECT_CONTRACTIONS);
export const logAddContraction = () => logEvent(ADD_CONTRACTIONS);

export const logSelectDiaper = (params: { screen: string }) =>
  logEvent(SELECT_DIAPER, params);
export const logAddDiaper = () => logEvent(ADD_DIAPER);

export const logNotificationPermission = (params: { scenario: string }) =>
  logEvent(NOTIFICATION_PERMISSION, params);
export const logAcceptNotificationPermission = (params: {
  scenario: string;
  screen_name: string;
}) => logEvent(ACCEPT_NOTIFICATION_PERMISSION, params);

export const logView3DBaby = (params: { weekNumber: number }) =>
  logEvent(VIEW_3D_BABY, params);

export const logViewWeeklyTip = ({
  id,
  category,
  weekNumber,
  offset,
}: {
  id: number;
  category: string | undefined;
  weekNumber: number;
  offset?: number;
}) =>
  logEvent(WEEKLY_TIP.VIEW, {
    content_type: 'weekly_tip',
    item_id: id,
    item_category: category || 'none',
    weekNumber,
    offset: offset || 'none',
  });

export const logShareWeeklyTip = ({
  id,
  category,
  weekNumber,
  method,
}: {
  id: number;
  category: string | undefined;
  weekNumber: number;
  method: string;
}) =>
  logEvent(WEEKLY_TIP.SHARE, {
    item_id: id,
    item_category: category || 'none',
    weekNumber,
    method,
  });

/// Cycle Tracking
export const logOpenCycleTracking = (
  from: 'Home' | 'Profile' | 'DeepLink' | 'Notification',
) => logEvent(CYCLE_TRACKING.OPEN, { from });

export const logStartCycleTrackingOnboarding = () =>
  logEvent(CYCLE_TRACKING.START_ONBOARDING);

export const logEndCycleTrackingOnboardingPreferences = () =>
  logEvent(CYCLE_TRACKING.END_ONBOARDING_PREFERENCES);

export const logEndCycleTrackingOnboarding = () =>
  logEvent(CYCLE_TRACKING.END_ONBOARDING);

export const logAddFirstCycle = () => logEvent(CYCLE_TRACKING.ADD_FIRST_CYCLE);

export const logAddCycle = () => logEvent(CYCLE_TRACKING.ADD_CYCLE);

export const logAddCycleMenstruationDuration = () =>
  logEvent(CYCLE_TRACKING.ADD_CYCLE_MENSTRUATION_DURATION);

export const logAddCycleSymptom = () =>
  logEvent(CYCLE_TRACKING.ADD_CYCLE_SYMPTOM);

export const logAddBaseTemperature = () =>
  logEvent(CYCLE_TRACKING.ADD_BASE_TEMPERATURE);

export const logEditBaseTemperature = () =>
  logEvent(CYCLE_TRACKING.EDIT_BASE_TEMPERATURE);

export const logUserSelectedConceptionPurpose = () =>
  logEvent(CYCLE_TRACKING.USER_SELECTED_CONCEPTION_PURPOSE);

export const logUserSelectedTrackingPurpose = () =>
  logEvent(CYCLE_TRACKING.USER_SELECTED_TRACKING_PURPOSE);

export const logUserSelectedObservationMode = () =>
  logEvent(CYCLE_TRACKING.USER_SELECTED_OBSERVATION_MODE);

export const logUserSelectedPredictionMode = () =>
  logEvent(CYCLE_TRACKING.USER_SELECTED_PREDICTION_MODE);

export const logUploadSuccess = (params: { fileSizeBytes: number }) =>
  logEvent(UPLOAD_FILES.SUCCESS, params);

export const logUploadError = (params: {
  fileSizeBytes: number;
  message: string;
}) => logEvent(UPLOAD_FILES.ERROR_UPLOADING, params);

export const logUploadErrorInitializing = (params: {
  fileSizeBytes: number;
  message: string;
}) => logEvent(UPLOAD_FILES.ERROR_INITIALIZING, params);

export const logUploadRetry = () => logEvent(UPLOAD_FILES.RETRY);
