import React, { useEffect, useMemo } from 'react';
import { ScrollView, Text, View } from 'react-native';

import { YellowButton } from '@components/Buttons';
import AppIcon from '@components/ui/Icon';
import BackButton from '@components/ui/Pressables/Actions/BackButton';
import ScrollableTabBar from '@components/ui/ScrollableTabBar';
import { t } from '@config';

import {
  Product,
  PRODUCT_INTERVAL,
  PRODUCT_TIER,
} from '@api-requests/api/main/payment/products/types';

import styles from './styles';
import AvailablePerksList from '../../components/AvailablePerk/AvailablePerksList';
import PlanList from '../../components/PlanList';
import { SubscribeModalProps } from '../../types';
import { getDefaultPlan } from '../../utils/getDefaultPlan';
import { useSubscribeModalWordingContext } from '../../utils/useSubscribeModalWordingContext';

const TABS = [
  { text: t('SUBSCRIBE_MODAL.YEARLY') },
  { text: t('SUBSCRIBE_MODAL.MONTHLY') },
];

const SubscribeModal8 = ({
  hideModal,
  productList,
  selectedPlan,
  setSelectedPlan,
  onPress,
  targetTab,
}: SubscribeModalProps & { targetTab: number }) => {
  const { context, canTrial } = useSubscribeModalWordingContext(selectedPlan);
  useEffect(() => {
    const defaultPlan = getDefaultPlan(
      productList,
      PRODUCT_TIER.PREMIUM,
      targetTab === 0 ? PRODUCT_INTERVAL.YEAR : PRODUCT_INTERVAL.MONTH,
    );
    if (defaultPlan) {
      setSelectedPlan(defaultPlan);
    }
  }, [productList, setSelectedPlan, targetTab]);
  const onChangeTab = (index: number) => {
    const defaultPlan = getDefaultPlan(
      productList,
      PRODUCT_TIER.PREMIUM,
      index === 0 ? PRODUCT_INTERVAL.YEAR : PRODUCT_INTERVAL.MONTH,
    );
    if (defaultPlan) {
      setSelectedPlan(defaultPlan);
    }
  };
  const scrollToTab = useMemo(() => ({ scrollToTab: targetTab }), [targetTab]);
  const plans = useMemo(
    () =>
      productList?.reduce(
        (acc, plan) => {
          if (!acc[plan.interval]) {
            acc[plan.interval] = [];
          }
          acc[plan.interval].push(plan);
          return acc;
        },
        {
          [PRODUCT_INTERVAL.MONTH]: [] as Array<Product>,
          [PRODUCT_INTERVAL.YEAR]: [] as Array<Product>,
        },
      ),
    [productList],
  );

  const isPremium = selectedPlan?.tier === PRODUCT_TIER.PREMIUM;

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.scrollView}
      showsVerticalScrollIndicator={false}
    >
      <AppIcon style={styles.blobs} name="BlobsGrey" />
      {hideModal && (
        <BackButton
          style={styles.backButton}
          onPress={hideModal}
          testID="closeSubscribeModalButton"
        />
      )}
      <View style={styles.content}>
        <Text style={styles.title}>{t('SUBSCRIBE_MODAL.MODAL8.TITLE')}</Text>
        <View style={styles.pillWrapper}>
          {(canTrial || selectedPlan?.interval === PRODUCT_INTERVAL.MONTH) && (
            <Text style={styles.label}>
              {t('SUBSCRIBE_MODAL.MODAL8.OFFER', { context })}
            </Text>
          )}
        </View>
        <AvailablePerksList style={styles.perks} isPremium={isPremium} />
      </View>
      <ScrollableTabBar
        tabTitleInfos={TABS}
        scrollToTab={scrollToTab}
        onChangeTab={onChangeTab}
      >
        {() => [
          <PlanList
            style={styles.content}
            productList={plans?.[PRODUCT_INTERVAL.YEAR]}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
          />,
          <PlanList
            style={styles.content}
            productList={plans?.[PRODUCT_INTERVAL.MONTH]}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
          />,
        ]}
      </ScrollableTabBar>
      <YellowButton
        style={styles.buttonWrapper}
        disabled={!selectedPlan}
        onPress={() => onPress(selectedPlan!)}
        label={t('SUBSCRIBE_MODAL.MODAL8.CTA')}
        isLoading={false}
        testID="subscribeButton"
      />
      {canTrial && (
        <Text style={styles.trialInfo}>
          {t('SUBSCRIBE_MODAL.PLAN_SECTION.FREE_TRIAL_PERIOD')}
        </Text>
      )}
    </ScrollView>
  );
};

export default SubscribeModal8;
