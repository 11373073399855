import mixpanel from 'mixpanel-browser';

import { Kid } from '@commonTypes/Kid';
import { Pregnancy } from '@commonTypes/Pregnancy';
import Logger from '@tools/Logger';

import { PRODUCT_TIER } from '@api-requests/api/main/payment/products/types';

import { ScreenViewProps } from './eventsTypes';
import { IdentificationData } from './types';
import { formatIdentification } from './utils';

export let segmentClient = window.analytics;
export const EURO_ISO4217 = 'EUR';

export const toggleAnalyticsWithEnv = async (env: 'PROD' | 'STAGING') => {
  if (env !== 'PROD') {
    return;
  }
};

const identify = (id: string, payload: Record<string, string>) => {
  const { createdAt, ...rest } = payload;
  Logger.log('Identify', id, rest);
  mixpanel.identify(String(id));
  return segmentClient?.identify(String(id), rest);
};

export const logEvent = async (
  evt: string,
  params = {} as Record<string, any>,
) => {
  if (segmentClient) {
    params.comingFrom = 'web';
    try {
      mixpanel.track(evt, params);
      segmentClient.track(evt, params);
    } catch (e) {
      Logger.log(e);
    }
  } else {
    Logger.log('Event track', evt);
  }
};

export const resetAnalytics = () => segmentClient?.reset();

export const getDetailIdentification = async ({
  id,
  email,
  ...user
}: {
  id?: string | number;
  email?: string;
  subscriptionStatus?: string | null | undefined;
  subscriptionLevel?: PRODUCT_TIER | null | undefined;
  firstName?: string;
  kids?: Kid[];
  pregnancy?: Pregnancy | Record<string, never>;
  createdAt?: Date | string;
}): Promise<
  (IdentificationData & { id: string; email: string }) | undefined
> => {
  if (!id || !email) {
    return;
  }
  return {
    ...formatIdentification({ id, email, ...user }),
  };
};

const mixpanelIdentification = ({ email, id, ...rest }: IdentificationData) => {
  return {
    $distinct_id: id,
    $email: email,
    ...rest,
  };
};
export const setAnalyticsUserProperties = (user: IdentificationData) => {
  if (!user.id) {
    return;
  }
  const { id, ...identification } = user;
  const identificationForMixpanel = mixpanelIdentification(user);
  mixpanel.people.set(identificationForMixpanel);
  return identify(id!, identification);
};

export const screenView = async ({
  screenName,
  screenClass,
}: ScreenViewProps) => {
  mixpanel.track_pageview({ screenName, screenClass });
  return segmentClient?.page(screenName, { screen_class: screenClass });
};
