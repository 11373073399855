import { Platform, StyleSheet } from 'react-native';

import DEVICE from '@resources/constants/device';
import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  container: { height: '100%', backgroundColor: Colors.white100 },
  content: { paddingVertical: 48, paddingHorizontal: 24 },
  blobs: { position: 'absolute', top: 0, right: -54 },
  backIcon: { position: 'absolute', top: 24, right: 24 },

  header: { height: 120, justifyContent: 'space-between' },
  title: {
    color: Colors.duck700,
    fontFamily: Fonts.type.semiBold,
    fontSize: Fonts.size.currencySize,
  },
  label: {
    alignSelf: 'flex-start',
    paddingHorizontal: 16,
    backgroundColor: Colors.grey300,
    borderRadius: 20,
    lineHeight: 36,
    color: Colors.duck700,
    fontFamily: Fonts.type.semiBold,
    fontSize: Fonts.size.title,
    overflow: 'hidden',
  },

  recap: { marginVertical: 32 },

  price: {
    textAlign: 'center',
    color: Colors.duck500,
    fontFamily: Fonts.type.semiBold,
    fontSize: Fonts.size.extraText,
  },

  buttonWrapper: {
    marginTop: 8,
    width: DEVICE.WINDOW_WIDTH - 48,
    ...Platform.select({
      native: {
        elevation: 5,
        shadowColor: Colors.duck800,
        shadowOffset: { width: 10, height: 4 },
        shadowOpacity: 0.5,
        shadowRadius: 6,
      },
      default: {},
    }),
  },

  trialInfo: {
    marginTop: 24,
    color: Colors.duck800,
    fontFamily: Fonts.type.light,
    fontSize: Fonts.size.tiny,
  },
  plans: { marginTop: 24 },
});
