import React from 'react';
import { Text, View } from 'react-native';

import { PressableOpacity } from '@components/PressableOpacity';

import styles from './styles';
import type { RadioButtonTypes } from './types';

const RadioButton = ({
  selected = false,
  onPress = () => {},
  testID,
  disabled,
  ...other
}: RadioButtonTypes) => (
  <PressableOpacity
    onPress={onPress}
    disabled={disabled}
    style={styles.mainContainer}
    testID={testID}
  >
    <View style={[styles.radioBtn, selected && styles.activeRadioBtnBorder]}>
      {selected && <View style={styles.activeRadioBtnBackground} />}
    </View>
    {'label' in other ? (
      <Text
        style={[styles.optionTitle, selected ? styles.selected : undefined]}
      >
        {other.label}
      </Text>
    ) : (
      other.component
    )}
  </PressableOpacity>
);

export default RadioButton;
