export enum WEB_ANONYMOUS_STACK {
  INTRO_SCREEN = 'intro',
  PRE_ONBOARDING_SCREEN = 'preOnboarding',
  REMOTE_PRE_ONBOARDING_IMAGE_SCREEN = 'remotePreOnboardingImage',
  REMOTE_PRE_ONBOARDING_STEP_SCREEN = 'remotePreOnboardingStep',
}

export enum WEB_COMMON_SCREEN_STACK {
  HOME_SCREEN = 'homeScreen',
  SUBSCRIBE_SCREEN = 'subscribe',
  SUBSCRIBE_SUCCESS_SCREEN = 'subscribeSuccess',
  UNSUBSCRIBED_HOME_SCREEN = 'unsubscribedHomeScreen',
  LOGOUT_SCREEN = 'logoutScreen',
}
