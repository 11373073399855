import { i18n } from '@config/bootstrap/i18n';

import en from './en';
import fr from './fr';

const translations = { en, fr } as const;

let locale: 'en' | 'fr' =
  i18n.language === 'en' || i18n.language === 'fr' ? i18n.language : 'en';
const setLocale = (l: 'en' | 'fr') => {
  locale = l;
};

const translate = (code: string): string =>
  // @ts-ignore
  (translations[locale] || translations.en)[code] || code;

const getList = () => translations[locale];

const TranslationCountry = {
  setLocale,
  translate,
  getList,
};

export default TranslationCountry;
