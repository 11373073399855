import React from 'react';
import { StyleProp, Text, View, ViewStyle } from 'react-native';

import { FavoritesButton } from '@components/FavoritesButton';
import Image from '@components/Image';
import { t } from '@config';
import { navigate } from '@navigation/Actions';
import { HOME_STACK, NAVIGATOR_NAMES } from '@navigation/Routes';
import { Colors } from '@resources/themes';

import { WeeklyTip } from '@api-requests/api/cms/weekly-tips/types';
import { MayImageProps } from '@components/Image/types';

import styles from './styles';
import ContentCardUI from '../ContentCardUI';

interface WeeklyTipContentCardUIProps {
  style?: StyleProp<ViewStyle>;
  title: string;
  label: string | undefined;
  image: MayImageProps['source'];
  onPress: () => void;
  favoriteButton?: React.ReactElement | null;
  disabled?: boolean;
}
export function WeeklyTipContentCardUI({
  label,
  image,
  ...props
}: WeeklyTipContentCardUIProps) {
  const Cover = (
    <View style={styles.cardContainer}>
      {label && (
        <View style={styles.labelContainer}>
          <Text style={styles.label}>{label}</Text>
        </View>
      )}
      <View style={styles.imageContainer}>
        <Image style={styles.image} source={image} />
      </View>
    </View>
  );
  return (
    <ContentCardUI
      {...props}
      label={t('COMMON.WEEKLY_TIP')}
      contentColor={Colors.grey600}
      imageSection={Cover}
    />
  );
}

interface WeeklyTipContentCardProps {
  style?: StyleProp<ViewStyle>;
  item: WeeklyTip;
  disabled?: boolean;
}
export function WeeklyTipContentCard({
  style,
  item,
  disabled,
}: WeeklyTipContentCardProps) {
  const handlePress = () =>
    navigate(NAVIGATOR_NAMES.HOME_NAVIGATOR, {
      screen: HOME_STACK.WEEKLY_ACTIVITIES_SCREEN,
      params: { tip: item, tipId: undefined },
    });
  return (
    <WeeklyTipContentCardUI
      style={style}
      title={item.title}
      label={item.weeklyTipType?.label}
      image={item.weeklyTipType?.illustration?.url}
      onPress={handlePress}
      favoriteButton={<FavoritesButton type={'weeklyTips'} id={`${item.id}`} />}
      disabled={disabled}
    />
  );
}
