export const dynamicLinksOrigin = 'https://maysante.page.link';

/**
 * ⚠️ Don't test short link when you want to check the dynamics links
 * to avoid many errors and misinterpretations of firebase we mostly
 * use long link.
 * One example here with sponsor link:
 * 'https://maysante.page.link/?link=https://maysante.page.link/notifications&apn=com.maysante&ibi=com.may-sante',
 */
export enum DYNAMIC_LINKS {
  POSTS = '/posts',
  SHARE_POST = '/shareposts',
  GUIDE = '/guide',
  MASTERCLASS = '/masterclass',
  FOLDER = '/folder',
  TIP = '/tip',
  WEEKLY_TIP = '/weeklyTip',
  PREGNANCY = '/pregnancy',
  NOTIFICATION_SETTINGS = '/notifications',
  SHARE_ACCOUNT = '/shareAccount',
  SPONSOR = '/sponsor',
  SUBSCRIBE = '/subscribe',
  SUBSCRIPTION = '/subscription',
  PROMOTION_CODE = '/promo',
  CARD_LIST = '/card_list',
  JOURNALING = '/journaling',
  APPOINTMENT = '/appointment',
  CHILD_SLEEP = '/childSleep',
  CHILD_FEED = '/childFeed',
  FAQ_PREGNANCY = '/pregnancyFAQ',
  FAQ_PURCHASE = '/purchaseFAQ',
  CALENDAR = '/calendar',
  TODO = '/todo',
  KID_GROWTH = '/kidGrowth',
  BELLY = '/belly',
  MY_WEIGHT = '/myWeight',
  RECIPE = '/recipe',
  OPENID_SIGNIN = '/authorize/mobile/google',
  OPENID_SIGNUP = '/signup/mobile/google',
  OPENID_ATTACH = '/attach/mobile/google',
  FERTILITY = '/cycle',
  FERTILITY_INPUT = '/addCycle',
  INVITATION = '/invite',
  AFFILIATION = '/affiliation',
}
