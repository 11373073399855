import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { Platform } from 'react-native';

import { refreshToken } from '@api-requests/api/auth';
import { setStrapiAuthorizationBearer } from '@api-requests/api/cms/request';
import { storeCredentials } from '@api-requests/api/common';
import { setAuthorizationBearer } from '@api-requests/api/main/request';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import { Tokens } from '@commonTypes/Auth';
import { useDispatch } from '@commonTypes/redux';
import { AuthCreators } from '@redux/auth';
import { resetAll } from '@redux/reduxReset';
import { useQuery } from '@tanstack/react-query';

const defaultExpiracy = 5 * 60; // 5 minutes in seconds

export const TOKENS_QUERY_KEYS = ['accessToken'] as const;

export const useTokens = ({ enabled } = { enabled: false }) => {
  const result = useQuery<
    ReducedAxiosResponse<Tokens>,
    AxiosError,
    Tokens,
    typeof TOKENS_QUERY_KEYS
  >({
    queryKey: TOKENS_QUERY_KEYS,
    queryFn: refreshToken,
    refetchInterval: (res) =>
      (3 * (res?.state.data?.data?.jwtExpiresIn ?? defaultExpiracy) * 1000) / 4,
    refetchIntervalInBackground: false,
    enabled,
    select: ({ data }) => data,
    retry(failureCount, error) {
      if (error?.status === 401) {
        return false;
      }
      return failureCount < 3;
    },
  });

  const { isError, error } = result;

  const fetchedRefreshToken = result.data?.refreshToken;
  const fetchedJWT = result.data?.jwt;
  useEffect(() => {
    if (fetchedRefreshToken && enabled) {
      storeCredentials({ refreshToken: fetchedRefreshToken });
    }
  }, [enabled, fetchedRefreshToken]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (fetchedJWT && enabled) {
      dispatch(AuthCreators.authLogin({ token: fetchedJWT }));
    }
  }, [dispatch, fetchedJWT, enabled]);

  useEffect(() => {
    if (isError && enabled) {
      if (error?.status === 401) {
        dispatch(AuthCreators.setIsLogged({ password: true, isLogged: false }));
        setAuthorizationBearer('');
        setStrapiAuthorizationBearer('');
        if (Platform.OS === 'web') {
          dispatch(resetAll());
        }
      }
    } else {
    }
  }, [isError, error?.status, dispatch, enabled]);

  return result;
};
