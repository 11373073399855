import { resetAll } from '@redux/reduxReset';
import { createSlice } from '@reduxjs/toolkit';

const initialState = { isVisible: false };

const trackDescriptionModalSlice = createSlice({
  name: 'trackDescriptionModal',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    hide: (state) => {
      state.isVisible = false;
    },
    show: (state) => {
      state.isVisible = true;
    },
  },
});

export const TrackDescriptionModalCreators = trackDescriptionModalSlice.actions;
export default trackDescriptionModalSlice.reducer;
