import LottieView from 'lottie-react-native';
import React from 'react';
import { StyleSheet, Platform } from 'react-native';

import { useDisableBackButton } from '@hooks/useDisableBackButton';
import { navigate } from '@navigation/Actions';
import { NAVIGATOR_NAMES, ONBOARDING_STACK } from '@navigation/Routes';
import animation from '@resources/lottieAnimations/animationOnboarding.json';
import { Colors } from '@resources/themes';

const styles = StyleSheet.create({
  animation: {
    backgroundColor: Colors.white100,
    ...Platform.select({
      // Prevent animation overflow
      web: { flex: 1 },
      default: { height: '100%', width: '100%' },
    }),
  },
});

const goToFeatureSummary = () =>
  navigate(NAVIGATOR_NAMES.ONBOARDING_NAVIGATOR, {
    screen: ONBOARDING_STACK.FEATURES_SUMMARY,
  });

const AnimationScreen = () => {
  useDisableBackButton();
  return (
    <LottieView
      loop={false}
      autoPlay
      onAnimationFinish={goToFeatureSummary}
      source={animation}
      style={styles.animation}
    />
  );
};

export default AnimationScreen;
