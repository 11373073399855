import dayjs, { Dayjs, ManipulateType, OpUnitType } from 'dayjs';
import fr from 'dayjs/locale/fr';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.locale({
  ...fr,
  weekStart: 1,
});

export const dayjsTZ = (
  date?: string | number | Date | Dayjs | null | undefined,
  tz?: string | null,
  keepLocalTime?: boolean | undefined,
) =>
  tz
    ? tz === 'utc'
      ? dayjs(date).utc(keepLocalTime)
      : dayjs(date).tz(tz, keepLocalTime)
    : dayjs(date);

export const durationInDays = (
  start: Date | string,
  end: Date | string,
  tz?: string,
) => dayjsTZ(end, tz).diff(dayjsTZ(start, tz), 'day') + 1;

export const startOf = (date: string | Date, unit: OpUnitType, tz?: string) =>
  dayjsTZ(date, tz).startOf(unit).toDate();

export const endOf = (date: string | Date, unit: OpUnitType, tz?: string) =>
  dayjsTZ(date, tz).endOf(unit).toDate();

export const add = (
  date: string | Date,
  amount: number,
  unit?: ManipulateType,
  tz?: string,
  keepLocalTime?: boolean,
) => dayjsTZ(date, tz, keepLocalTime).add(amount, unit).toDate();

export const subtract = (
  date: string | Date,
  amount: number,
  unit?: ManipulateType,
  tz?: string,
  keepLocalTime?: boolean,
) => dayjsTZ(date, tz, keepLocalTime).subtract(amount, unit).toDate();

export const getHours = (date: string | Date, tz?: string) =>
  dayjsTZ(date, tz).hour();

export const isSameDay = (
  date1: Date | string,
  date2: Date | string,
  tz?: string,
): boolean => dayjsTZ(date1, tz).isSame(dayjsTZ(date2, tz), 'day');

export const isSame = (
  date1: Date | string,
  date2: Date | string,
  unit: OpUnitType,
  tz?: string,
  keepLocalTime?: boolean,
): boolean =>
  dayjsTZ(date1, tz, keepLocalTime).isSame(
    dayjsTZ(date2, tz, keepLocalTime),
    unit,
  );

export const isAfterDay = (
  date: Date | string,
  reference: Date | string,
  tz?: string,
): boolean => dayjsTZ(date, tz).isAfter(dayjsTZ(reference, tz), 'day');

export const isSunday = (date1: Date | string, tz?: string): boolean =>
  dayjsTZ(date1, tz).day() === 0;

export const startOfEventOrWeek = (
  start: Date | string,
  currentDay: Date | string,
): Date => {
  const startOfWeek = dayjs(currentDay).startOf('week');
  return dayjs(start).isBefore(startOfWeek)
    ? startOfWeek.toDate()
    : dayjs(start).toDate();
};

export const formatDate = (
  date: Date | string | Dayjs | number,
  format: string,
  tz?: string,
  keepLocalTime?: boolean,
): string => dayjsTZ(date, tz, keepLocalTime).format(format);

export const getDaysInInterval = (
  start: Date | string,
  end: Date | string,
  tz?: string,
): Dayjs[] => {
  const days = [] as Dayjs[];
  const startDay = dayjsTZ(start, tz);
  const endDay = dayjsTZ(end, tz);
  for (
    let day = startDay;
    day.isBefore(endDay, 'day');
    day = day.add(1, 'day')
  ) {
    days.push(day);
  }
  days.push(endDay);
  return days;
};

export const sameHourOtherDay = (
  hour: Date | string,
  day: Date | string,
  tz?: string,
) => {
  const hourDayjs = dayjsTZ(hour, tz);
  const diffMillis = hourDayjs.diff(hourDayjs.startOf('day'));
  const dayMillis = dayjsTZ(day, tz).startOf('day').toDate().getTime();

  return new Date(dayMillis + diffMillis);
};

export const getEventIntervalString = (
  start: string | Date,
  end: string | Date,
  day: string | Date,
  tz?: string,
) => {
  const startDayjs = dayjsTZ(start, tz);
  const endDayjs = dayjsTZ(end, tz);
  if (startDayjs.isSame(endDayjs)) {
    return 'A ' + formatDate(start, 'HH:mm', tz);
  } else if (isSameDay(start, end, tz)) {
    if (
      startDayjs.isSame(startDayjs.startOf('day')) &&
      endDayjs.isSame(endDayjs.endOf('day'))
    ) {
      return 'Toute la journée';
    }
    return (
      formatDate(start, 'HH:mm', tz) + ' à ' + formatDate(end, 'HH:mm', tz)
    );
  } else if (isSameDay(start, day, tz)) {
    return 'Début: ' + formatDate(start, 'HH:mm', tz);
  } else if (isSameDay(end, day, tz)) {
    return 'Fin: ' + formatDate(end, 'HH:mm', tz);
  } else {
    return 'Toute la journée';
  }
};

export const getSameDayPreviousWeek = (
  date: Date | string,
  tz?: string,
): Date => dayjsTZ(date, tz).subtract(1, 'w').toDate();

export const startOfPreviousMonth = (date: Date | string, tz?: string): Date =>
  dayjsTZ(date, tz).startOf('month').subtract(1, 'month').toDate();

export const endOfNextMonth = (
  date: Date | string,
  monthCount = 1,
  tz?: string,
): Date => dayjsTZ(date, tz).add(monthCount, 'month').endOf('month').toDate();

export const getAgeInMonths = (date: Date | string, tz?: string): number =>
  dayjsTZ(new Date(), tz).diff(dayjsTZ(date, tz), 'month', true);

/**
 * Get the number of hours a period spans across
 *
 * [ start; end [ - start is inclusive, end is exclusive
 * @param period
 * @returns
 * Integer number of hours
 * @example
 * getSpannedAcrossHours({
 *  start: new Date('2021-01-01T12:00:00'),
 *  end: new Date('2021-01-01T13:30:00'),
 *  }) // 2
 */
export const getSpannedAcrossHours = (period: {
  start: Date;
  end: Date | null;
}) => {
  const start = dayjs(period.start);
  const end = period.end ? dayjs(period.end) : dayjs();

  const periodStartHour = start.startOf('h');
  const periodEndHour = end.minute() === 0 ? end : end.endOf('h');

  return Math.round(periodEndHour.diff(periodStartHour, 'h', true));
};

/**
 * Round a date to the nearest value
 * @param date - Date to round
 * @param msValue - Value to round to, in milliseconds
 * @returns
 * Date rounded to the nearest value
 * @example
 * roundToNearestValue(new Date('2021-01-01T12:03:00'), 5 * 60 * 1000) // 2021-01-01T12:05:00
 */
export const roundToNearestValue = (date: Date, msValue: number) =>
  new Date(Math.round(date.getTime() / msValue) * msValue);

/**
 * Floor a date to the nearest value
 * @param date - Date to floor
 * @param msValue - Value to floor to, in milliseconds
 * Date floored to the nearest value
 * @example
 * floorToNearestValue(new Date('2021-01-01T12:03:00'), 5 * 60 * 1000) // 2021-01-01T12:00:00
 */
export const floorToNearestValue = (date: Date, msValue: number) =>
  new Date(Math.floor(date.getTime() / msValue) * msValue);

export const fromDateString = (date: string, tz?: string) => {
  return dayjs.tz(date, tz).toDate();
};

export const diffDate = (
  end: string,
  start: string,
  unit: OpUnitType = 'day',
) => {
  return dayjsTZ(end, undefined, true).diff(
    dayjsTZ(start, undefined, true),
    unit,
  );
};
