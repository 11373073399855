import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';

import KeyboardAvoidingSafeView from '@components/KeyboardAvoidingSafeView';
import { ANONYMOUS_STACK } from '@navigation/Routes';
import { Colors } from '@resources/themes';

import { AnonymousStackParamList, ScreenRoute } from '@navigation/types';

import EmailForm from './EmailForm';
import FirstnameForm from './FirstnameForm';
import PasswordForm from './PasswordForm';
import PhoneForm from './PhoneForm';

const styles = StyleSheet.create({
  container: Platform.select({
    web: { backgroundColor: Colors.white100 },
    default: {},
  }),
  hideKeyboardTouchableContainer: { flex: 1 },
});

const formRender = {
  firstName: FirstnameForm,
  email: EmailForm,
  pass: PasswordForm,
  phone: PhoneForm,
};

const SignUpScreen = ({
  route: { params: { step } = { step: 'firstName' } },
}: ScreenRoute<AnonymousStackParamList, ANONYMOUS_STACK.REGISTER>) => {
  const StepScreen = formRender[step];
  return (
    <KeyboardAvoidingSafeView style={styles.container}>
      <View style={styles.hideKeyboardTouchableContainer}>
        <StepScreen />
      </View>
    </KeyboardAvoidingSafeView>
  );
};

export default SignUpScreen;
