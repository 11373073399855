import React from 'react';

import { BorderlessButton, PrimaryButton } from '@components/Buttons';
import AlertModal from '@components/Modal/AlertModal';
import { t } from '@config';

interface DeleteKidModal {
  isVisible: boolean;
  onPress: () => void;
  onClose: () => void;
  isSubmitting: boolean;
}
const DeleteKidModal = ({
  isVisible,
  onPress,
  onClose,
  isSubmitting,
}: DeleteKidModal) => (
  <AlertModal
    isVisible={isVisible}
    illustrationSource={'SadSmileyIcon'}
    title={t('SETTINGS.KIDS.DELETE.TITLE')}
    description={t('SETTINGS.KIDS.DELETE.MSG')}
    onClose={onClose}
  >
    <PrimaryButton
      label={t('SETTINGS.KIDS.DELETE.BUTTON')}
      onPress={onPress}
      isLoading={isSubmitting}
    />
    <BorderlessButton
      label={t('SETTINGS.KIDS.DELETE.BUTTON_CANCEL')}
      onPress={onClose}
      isLoading={false}
      disabled={isSubmitting}
    />
  </AlertModal>
);

export default DeleteKidModal;
