import React, { useRef } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Svg, {
  Defs,
  LinearGradient as LibLinearGradient,
  Rect,
  Stop,
} from 'react-native-svg';

export type LinearGradientConfig = {
  offset: number | string;
  stopColor: string;
  stopOpacity: string;
}[];
interface LinearGradientProps {
  style?: StyleProp<Omit<ViewStyle, 'height' | 'width'>>;
  height?: string | number;
  width?: string | number;
  config: LinearGradientConfig;
  horizontal?: boolean;
}
const LinearGradient = ({
  style,
  height,
  width,
  config,
  horizontal,
}: LinearGradientProps) => {
  const gradientID = useRef(
    Math.random().toString(36).replace('.', ''),
  ).current;
  return (
    <Svg width={width} height={height} style={style}>
      <Rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill={`url(#${gradientID})`}
      />
      <Defs>
        <LibLinearGradient
          id={gradientID}
          x1="0"
          y1="0"
          {...(horizontal ? { x2: '100%', y2: '0' } : { x2: '0', y2: '100%' })}
        >
          {config.map((props, index) => (
            <Stop key={index} {...props} />
          ))}
        </LibLinearGradient>
      </Defs>
    </Svg>
  );
};

export default LinearGradient;
