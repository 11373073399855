import React, { forwardRef } from 'react';
import {
  Pressable,
  PressableProps,
  RegisteredStyle,
  StyleProp,
  ViewStyle,
  View,
} from 'react-native';
export const PressableOpacity = forwardRef<
  View,
  PressableProps & {
    style?: StyleProp<ViewStyle> | RegisteredStyle<ViewStyle>;
  }
>(
  (
    props: PressableProps & {
      style?: StyleProp<ViewStyle> | RegisteredStyle<ViewStyle>;
    },
    ref,
  ) => (
    <Pressable
      {...props}
      ref={ref}
      style={({
        pressed,
        hovered,
      }: {
        pressed?: boolean;
        hovered?: boolean;
      }) => [
        { opacity: pressed ? 0.5 : 1.0 },
        ...(hovered && !props.disabled
          ? [{ opacity: 0.7, cursor: 'pointer' as const }]
          : []),
        ...(Array.isArray(props.style) ? props.style : [props.style]),
      ]}
    />
  ),
);
