import axios from 'axios';
import { useCallback, useRef } from 'react';

import {
  logUploadError,
  logUploadErrorInitializing,
  logUploadSuccess,
} from '@analytics/events';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import { S3SignedUrl } from '@commonTypes/S3';
import useMutationCall from '@hooks/useMutationCall';

type UploadData<T extends Record<string, any> = {}> = {
  signedUrlRequest: (
    payload: { filename: string; ContentType: string } & T,
  ) => Promise<ReducedAxiosResponse<S3SignedUrl>>;
  signedUrlData: T;
};

export const singleUpload = async ({
  blob,
  data,
  status,
  onUploadProgress,
}: Pick<ReducedAxiosResponse<S3SignedUrl>, 'data' | 'status'> & {
  blob: Blob;
  onUploadProgress: (arg: number) => void;
}) => {
  if (status > 199 && status < 300) {
    try {
      const uploadResult = await axios.put(data.url, blob, {
        headers: {
          'Content-Type': blob.type,
        },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            progressEvent.loaded / (progressEvent.total ?? 100),
          );
          onUploadProgress(percentCompleted);
        },
      });

      if (uploadResult.status === 200) {
        logUploadSuccess({ fileSizeBytes: blob.size });
        return uploadResult;
      } else {
        throw new Error('Impossible de télécharger le fichier');
      }
    } catch (error: any) {
      logUploadError({
        fileSizeBytes: blob.size,
        message: error.message ?? '',
      });
      throw error;
    }
  } else {
    throw new Error('Initialisation du téléchargement impossible');
  }
};

export const useFileUpload = <T extends Record<string, any> = {}>(
  payload: UploadData<T>,
) => {
  const { mutateAsync: signedUrlRequest } = useMutationCall<
    ReducedAxiosResponse<S3SignedUrl>,
    any,
    { filename: string; ContentType: string } & T
  >({
    mutationFn: payload.signedUrlRequest,
    mutationKey: ['signedUrlRequest'],
  });

  const fileSize = useRef(0);
  const uploadFile = useCallback(
    async ({
      uri,
      onUploadProgress,
    }: {
      uri: string;
      onUploadProgress: (arg: number) => void;
    }) => {
      const blob = await (await fetch(uri)).blob();
      fileSize.current = blob.size;
      onUploadProgress(0);
      try {
        const signedRes = await signedUrlRequest({
          filename:
            // @ts-ignore
            blob?._data?.name ??
            Math.random().toString(36) + '.' + blob.type.split('/').pop(),
          ContentType: blob.type,
          ...payload.signedUrlData,
        });
        onUploadProgress(5);
        const { data, status } = signedRes;
        await singleUpload({
          blob,
          data,
          status,
          onUploadProgress: (fraction) => {
            onUploadProgress(5 + fraction * 90);
          },
        });
        return signedRes;
      } catch (error: any) {
        logUploadErrorInitializing({
          fileSizeBytes: fileSize.current,
          message: error.message ?? '',
        });
        throw error;
      }
    },
    [payload, signedUrlRequest],
  );

  return useMutationCall<
    ReducedAxiosResponse<S3SignedUrl>,
    any,
    {
      uri: string;
      onUploadProgress: (arg: number) => void;
    }
  >({
    mutationFn: uploadFile,
    mutationKey: ['uploadFile'],

    onSuccess(_, { onUploadProgress }) {
      onUploadProgress(100);
    },

    onError(_, { onUploadProgress }) {
      onUploadProgress(-1);
    },
  });
};
