import { resetAll } from '@redux/reduxReset';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Scenario } from '@api-requests/api/cms/scenarii/types';

const initialState: { scenario: Scenario } = { scenario: [] };

const webPreOnboardingWorkflowSlice = createSlice({
  name: 'webPreOnboardingWorkflow',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    set: (state, action: PayloadAction<Scenario>) => {
      state.scenario = action.payload;
    },
  },
});

export const webPreOnboardingWorkflowCreators =
  webPreOnboardingWorkflowSlice.actions;
export default webPreOnboardingWorkflowSlice.reducer;
