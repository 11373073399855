import { resetAll } from '@redux/reduxReset';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BreastfeedTimerSide {
  start: number;
  isRunning: boolean;
  lastPaused: number | null;
  now: number;
}
interface BreastfeedTimer {
  left: BreastfeedTimerSide;
  right: BreastfeedTimerSide;
}

const initialState: Record<string, BreastfeedTimer> = {};

const breastfeedTimerSlice = createSlice({
  name: 'breastfeedTimer',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    play: (
      state,
      action: PayloadAction<{ childId: number; side: 'left' | 'right' }>,
    ) => {
      let { start, lastPaused } = state[action.payload.childId]?.[
        action.payload.side
      ] ?? {
        start: Date.now(),
        lastPaused: null,
      };
      if (lastPaused) {
        // Offset start time by the elapsed time paused
        start += Date.now() - lastPaused;
      }

      state[action.payload.childId] = {
        ...state[action.payload.childId],
        [action.payload.side]: {
          start,
          isRunning: true,
          lastPaused: null,
          now: Date.now(),
        },
      };
    },

    pause: (
      state,
      action: PayloadAction<{ childId: number; side: 'left' | 'right' }>,
    ) => {
      state[action.payload.childId][action.payload.side].isRunning = false;
      state[action.payload.childId][action.payload.side].lastPaused =
        Date.now();
    },

    reset: (
      state,
      action: PayloadAction<{ childId: number; side: 'left' | 'right' }>,
    ) => {
      if (state[action.payload.childId]?.[action.payload.side]) {
        delete state[action.payload.childId][action.payload.side];
      }
    },

    setNow: (
      state,
      action: PayloadAction<{ childId: number; side: 'left' | 'right' }>,
    ) => {
      state[action.payload.childId][action.payload.side].now = Date.now();
    },
  },
});

export const breastfeedTimerCreators = breastfeedTimerSlice.actions;
export default breastfeedTimerSlice.reducer;
