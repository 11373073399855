import { parsePhoneNumber } from 'awesome-phonenumber';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { View, Text } from 'react-native';

import { useSelector, useDispatch } from '@commonTypes/redux';
import { PrimaryButton } from '@components/Buttons';
import BottomSheetModal from '@components/Modal/BottomSheetModal';
import { PressableOpacity } from '@components/PressableOpacity';
import CountryPickerInput from '@components/ui/hookFormInputs/CountryPickerInput';
import { TextInput } from '@components/ui/hookFormInputs/TextInput';
import { usePopup } from '@components/ui/Popup/hook';
import { t } from '@config';
import { navigate, push } from '@navigation/Actions';
import { ANONYMOUS_STACK } from '@navigation/Routes';
import Prefix from '@resources/countries/phonePrefixes';
import ProtectedModalIcon from '@resources/svg/protection-icon.svg';
import { signupFormCreator } from '@screens/Auth/SignUp/redux/signupInfos';
import RegisterNavBar from '@screens/Auth/SignUp/RegisterNavBar';

import { StaticContentsSlug } from '@screens/StaticContentsScreen/types';

import styles from './styles';
import { useCheckPhone, useSignup } from '../hooks/auth';

type PhoneForm = {
  phone: string;
  country: string;
};
const goToCgu = () =>
  navigate(ANONYMOUS_STACK.STATIC_CONTENTS_SCREEN, {
    slug: StaticContentsSlug.cgu,
  });
const goToCgv = () =>
  navigate(ANONYMOUS_STACK.STATIC_CONTENTS_SCREEN, {
    slug: StaticContentsSlug.cgv,
  });

const renderCguText = () => (
  <Text style={styles.cguNormalText}>
    {t('AUTH.SIGN_UP.CGU_CGC_TEXT1')}
    <Text onPress={goToCgu} style={styles.cguBoldText}>
      {` ${t('AUTH.SIGN_UP.CGU_CGC_TEXT2')} `}
    </Text>
    {t('AUTH.SIGN_UP.CGU_CGC_TEXT3')}
    <Text onPress={goToCgv} style={styles.cguBoldText}>
      {` ${t('AUTH.SIGN_UP.CGU_CGC_TEXT4')}`}
    </Text>
  </Text>
);

const PhoneForm = () => {
  const dispatch = useDispatch();
  const [isVisibleModal, setVisibleModal] = useState(false);
  const { mutateAsync: checkPhone } = useCheckPhone();
  const { mutateAsync: signup } = useSignup();
  const popUp = usePopup();

  const { control, handleSubmit, formState, setError } = useForm<PhoneForm>({
    defaultValues: { phone: '', country: 'FR' },
  });

  const { isSubmitting, isValid } = formState;
  const { name, email, password, newsletterAgreed } = useSelector(
    (state) => state.signupForm,
  );

  const handleValidate = handleSubmit(async (data: PhoneForm) => {
    dispatch(signupFormCreator.addFormCountry(data.country));

    const phoneFormatted =
      '+' + (Prefix.getPrefix(data.country) || '') + parseInt(data.phone, 10);

    const parsedPhone = parsePhoneNumber(phoneFormatted);
    if (!parsedPhone.valid) {
      setError('phone', {
        type: 'INVALID_PHONE_NUMBER',
      });
    } else {
      try {
        await checkPhone({ phone: phoneFormatted });
        dispatch(signupFormCreator.addFormPhone(phoneFormatted));
        try {
          await signup({
            email,
            firstName: name,
            password,
            phone: phoneFormatted,
            newsLetter: newsletterAgreed,
          });
          push(ANONYMOUS_STACK.VERIFICATION_CODE_SCREEN);
        } catch {
          // popUp not working
          popUp(t('FORM.ERRORS.COMMON'));
        }
      } catch {
        setError('phone', { type: 'INVALID_PHONE_NUMBER' });
      }
    }
  });

  return (
    <>
      <RegisterNavBar
        onPress={handleValidate}
        title={t('AUTH.SIGN_UP.PHONE_TITLE')}
        isLoading={isSubmitting}
        enabled={isValid}
      />
      <View style={styles.content}>
        <Text style={styles.inputLabel}>{t('AUTH.SIGN_UP.FORM_PHONE')}</Text>
        <TextInput
          autoCapitalize="words"
          blurOnSubmit={false}
          control={control}
          name="phone"
          inputMode="tel"
          onSubmitEditing={() => (isSubmitting ? null : handleValidate())}
          placeholder={t('AUTH.SIGN_UP.PHONE_PLACEHOLDER')}
          returnKeyType="send"
          testID="phoneInput"
          errorMessage={(fieldError) =>
            fieldError
              ? fieldError.type === 'server' && fieldError.message
                ? fieldError.message
                : t('FORM.PHONE_NUMBER_INCORRECT')
              : ''
          }
          rules={{ required: true }}
          autoFocus
          leftIcon={
            <CountryPickerInput
              control={control}
              name="country"
              rules={{ required: true }}
            />
          }
        />
        <PressableOpacity
          style={styles.detailContainer}
          onPress={() => setVisibleModal(true)}
        >
          <Text style={styles.detailText}>
            {t('AUTH.SIGN_UP.NEED_EXPLANATION')}
          </Text>
        </PressableOpacity>
        <View style={styles.cguContainer}>{renderCguText()}</View>
      </View>
      <BottomSheetModal
        isVisible={isVisibleModal}
        onClose={() => setVisibleModal(false)}
        style={styles.bottomModal}
      >
        <View style={styles.modalContainer}>
          <View style={styles.detailModalIcon}>
            <ProtectedModalIcon height={45} width={45} />
          </View>
          <Text style={styles.detailModalTitle}>
            {t('AUTH.SIGN_UP.NEED_EXPLANATION')}
          </Text>
          <Text style={styles.detailModalText}>
            {t('AUTH.SIGN_UP.NEED_EXPLANATION_DETAIL')}
          </Text>
          <PrimaryButton
            style={styles.buttonStyle}
            onPress={() => setVisibleModal(false)}
            isLoading={false}
            label={t('AUTH.SIGN_UP.BTN_UNDERSTOOD')}
          />
        </View>
      </BottomSheetModal>
    </>
  );
};

export default PhoneForm;
