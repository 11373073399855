enum ACCESS {
  ALL = 'all',
  PREMIUM = 'premium',
  BASIC = 'basic',
}

type ValueOf<T> = T[keyof T];

export type Access = ValueOf<typeof ACCESS>;

export default ACCESS;
