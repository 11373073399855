import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  Pressable,
  ScrollView,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';

import TextColor from '@components/ui/TextColor';
import DEVICE from '@resources/constants/device';
import { Colors, Fonts } from '@resources/themes';

import { useScrollSharedValues } from './hooks';
import {
  ScrollableTabbarHeaderProps,
  ScrollSharedValues,
  TabItem,
} from './types';

const MAX_STATIONARY_TAB_NUM = 3;

const TAB_HEIGHT = 40;
const TAB_MARGIN = 24;
export const TAB_FULL_HEIGHT = TAB_HEIGHT + TAB_MARGIN;

const styles = StyleSheet.create({
  container: { flex: 1 },
  mainContainer: {
    flexDirection: 'row',
    height: 40,
    marginBottom: 24,
  },
  scrollableTabContentIos: {
    flex: 1,
  },
  notScrollableContainer: {
    width: '100%',
  },
  scrollableTabContainer: {
    marginHorizontal: 20,
  },
  tabTitleContainer: {
    flex: 1,
  },
  activeTab: {
    backgroundColor: Colors.pink500,
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    width: 36,
    height: 4,
    borderRadius: 10,
    alignSelf: 'center',
  },
  tabTitleContentContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabText: {
    textAlign: 'center',
    fontSize: Fonts.size.h4,
    fontFamily: Fonts.type.Recoleta.medium,
    color: Colors.duck800,
  },
  childrenContainer: {
    width: DEVICE.WINDOW_WIDTH,
  },
  contentContainer: { flex: 1 },
});

function ScrollableTabBar<HeaderProps = any>({
  tabTitleStyle,
  tabStyle,
  isScrollable = false,
  tabTitleInfos,
  onChangeTab,
  scrollToTab,
  tabScrollViewStyle,
  header,
  children,
  scrollSharedValues: propsScrollSharedValues,
}: {
  tabTitleStyle?: StyleProp<TextStyle>;
  tabStyle?: StyleProp<ViewStyle>;
  isScrollable?: boolean;
  tabTitleInfos: TabItem[];
  onChangeTab?: (selectedTab: number) => void;
  scrollToTab?: { scrollToTab: number };
  tabScrollViewStyle?: StyleProp<ViewStyle>;
  header?: ScrollableTabbarHeaderProps<HeaderProps>;
  children: (arg: {
    activeTab: number;
    scrollSharedValues: ScrollSharedValues;
  }) => JSX.Element[];
  scrollSharedValues?: ScrollSharedValues;
}) {
  const [activeTab, setActiveTab] = useState(scrollToTab?.scrollToTab ?? 0);
  const contentOffset = useMemo(
    () => ({
      y: 0,
      x: (scrollToTab?.scrollToTab ?? 0) * DEVICE.WINDOW_WIDTH,
    }),
    [scrollToTab?.scrollToTab],
  );
  const ref = useRef<ScrollView>(null);
  const tabRef = useRef<ScrollView>(null);
  const tmpActiveTabRef = useRef<number>(-1);
  let tabWidthArray = useRef(Array(tabTitleInfos.length).fill(0));

  if (!isScrollable && tabTitleInfos.length > MAX_STATIONARY_TAB_NUM) {
    isScrollable = true;
  }

  const changeTab = useCallback(
    (
      key: number,
      { animated = true } = {} as {
        animated?: boolean;
      },
    ) => {
      if (activeTab !== key) {
        ref.current?.scrollTo({
          x: key * DEVICE.WINDOW_WIDTH,
          y: 0,
          animated,
        });
        tmpActiveTabRef.current = key;
      }
    },
    [activeTab],
  );

  const onScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const nextPage = Math.round(
        event.nativeEvent.contentOffset.x / DEVICE.WINDOW_WIDTH,
      );
      if (nextPage !== activeTab) {
        tabRef.current?.scrollTo({
          x: tabWidthArray.current[nextPage],
          y: 0,
          animated: true,
        });
        if (
          nextPage === tmpActiveTabRef.current ||
          tmpActiveTabRef.current === -1
        ) {
          onChangeTab && onChangeTab(nextPage);
          setActiveTab(nextPage);
          tmpActiveTabRef.current = -1;
        }
      }
    },
    [activeTab, onChangeTab],
  );

  const displayTabIcon = (tabInfos: TabItem) => {
    if (tabInfos?.icon) {
      return tabInfos?.icon;
    }
    return null;
  };

  useEffect(() => {
    // ScrollToTab value must be memoized to avoid weird behavior
    if (typeof scrollToTab?.scrollToTab === 'number') {
      changeTab(scrollToTab?.scrollToTab);
    }
    // Do not include change tab or it will cause erroneous behaviour
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToTab]);

  const defaultScrollSharedValues = useScrollSharedValues(
    header?.heightVariation,
  );
  const scrollSharedValues =
    propsScrollSharedValues ?? defaultScrollSharedValues;
  const { tabBarPosition, fullHeight } = scrollSharedValues;

  const customStyle = useAnimatedStyle(() => {
    return {
      height: (scrollSharedValues.scrollY.value * TAB_HEIGHT) / fullHeight,
    };
  }, [scrollSharedValues.scrollY, fullHeight]);

  const scrolledWidth = useRef(0);
  const HeaderComponent = header?.Component;

  const content = (
    <ScrollView
      ref={ref}
      style={styles.scrollableTabContentIos}
      disableIntervalMomentum
      onLayout={() => {
        if (typeof scrollToTab?.scrollToTab === 'number') {
          const key = scrollToTab.scrollToTab;
          if (tabWidthArray.current[key] !== scrolledWidth.current) {
            scrolledWidth.current = tabWidthArray.current[key];
            tabRef.current?.scrollTo({
              x: tabWidthArray.current[key],
              y: 0,
              animated: true,
            });
          }
        }
      }}
      horizontal
      pagingEnabled
      scrollEventThrottle={16}
      showsHorizontalScrollIndicator={false}
      onScroll={onScroll}
      onMomentumScrollEnd={(event) => {
        changeTab(
          Math.round(event.nativeEvent.contentOffset.x / DEVICE.WINDOW_WIDTH),
        );
      }}
      contentOffset={contentOffset}
    >
      {children({ activeTab, scrollSharedValues }).map((component, index) => (
        <View key={index} style={styles.childrenContainer}>
          {component}
        </View>
      ))}
    </ScrollView>
  );

  return (
    <View style={styles.container}>
      {HeaderComponent && (
        <header.Component
          scrollSharedValues={scrollSharedValues}
          {...header.props}
        />
      )}
      <Animated.View
        style={[styles.mainContainer, customStyle, tabScrollViewStyle]}
        onLayout={(evt) => {
          tabBarPosition.value =
            evt.nativeEvent.layout?.y + evt.nativeEvent.layout?.height;
        }}
      >
        <ScrollView
          ref={tabRef}
          scrollEnabled={isScrollable}
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={[
            styles.mainContainer,
            !isScrollable && styles.notScrollableContainer,
          ]}
        >
          {tabTitleInfos.map((tabInfos, index) => (
            <Pressable
              onPress={() => changeTab(index, { animated: false })}
              key={index}
              testID={tabInfos.testID}
              onLayout={(evt) => {
                if (tabWidthArray?.current[index] === 0) {
                  // get correct size to center component in the middle of the screen
                  tabWidthArray.current[index] =
                    evt.nativeEvent.layout?.x +
                    evt.nativeEvent.layout?.width / 2 -
                    DEVICE.WINDOW_WIDTH / 2;
                }
              }}
              style={[
                isScrollable
                  ? styles.scrollableTabContainer
                  : styles.tabTitleContainer,
                tabStyle,
              ]}
            >
              <View style={styles.tabTitleContentContainer}>
                {displayTabIcon(tabInfos)}
                <TextColor style={[styles.tabText, tabTitleStyle]}>
                  {tabInfos.text}
                </TextColor>
              </View>
              {activeTab === index ? <View style={styles.activeTab} /> : null}
            </Pressable>
          ))}
        </ScrollView>
      </Animated.View>
      <View style={styles.contentContainer}>{content}</View>
    </View>
  );
}

export default ScrollableTabBar;
