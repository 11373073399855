import React, { useEffect, useRef, useState } from 'react';

import { useWithinModal } from './WithinModalProvider';

let currentSetVisibleId: number | undefined;
let currentSetVisible:
  | React.Dispatch<React.SetStateAction<boolean>>
  | undefined; // to set isVisible to false for currently visible modal
let currentId = 0;

/**
 * This custom hook is used to have only one modal visible at a time.
 * It uses the above global variable to synchronize visible modals.
 *
 * The last modal setting its visible props to true is the only one that returns isVisible = true
 * @param {bool} visible
 */
export const useIsVisible = (visible: boolean) => {
  const withinModal = useWithinModal();
  const modalId = useRef(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (withinModal) {
      return;
    }
    currentId += 1;
    modalId.current = currentId;
    return () => {
      if (currentSetVisibleId === modalId.current) {
        currentSetVisible = undefined;
      }
    };
  }, [withinModal]);

  useEffect(() => {
    if (withinModal) {
      return;
    }
    if (visible) {
      currentSetVisibleId = modalId.current;
      if (currentSetVisible) {
        currentSetVisible(false);
      }
      setIsVisible(true);
      currentSetVisible = setIsVisible;
    }
  }, [visible, withinModal]);

  return withinModal ? visible : isVisible;
};
