import AsyncStorage from '@react-native-async-storage/async-storage';

const reducerVersion = '0.1';

const whitelist = [
  'analytics',
  'auth',
  'hasLoggedOnce',
  'user',
  'chatDraftMessages',
  'sleepTimers',
  'breastfeedTimers',
  'skinColor',
  'tabSponsorReminder',
  'sleepEntriesCount',
  'feedEntriesCount',
  'lastContraction',
  'symptomPreferences',
  'fertilityHintCardVisibility',
];

const REDUX_PERSIST = {
  reducerVersion,
  storeConfig: {
    key: 'root',
    storage: AsyncStorage,
    whitelist,
  },
};

export default REDUX_PERSIST;
