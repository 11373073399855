import { Platform, StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  screenView: {
    padding: 24,
    flex: 1,
    gap: 32,
    ...Platform.select({
      web: { backgroundColor: Colors.white100 },
      default: {},
    }),
  },

  infoLabel: {
    textAlign: 'center',
    color: Colors.duck700,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.title,
  },

  content: { gap: 24 },

  spacer: { flex: 1 },
});
