import { Platform, StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  screen: {
    height: '100%',
    ...Platform.select({
      web: { backgroundColor: Colors.white100 },
      default: {},
    }),
  },
  scrollView: { minHeight: '100%' },
  container: { height: '100%', padding: 24, gap: 32 },

  heading: {
    color: Colors.duck800,
    fontFamily: Fonts.type.Recoleta.medium,
    fontSize: Fonts.size.h3,
  },

  spacer: { flex: 1 },
  actionsContainer: { gap: 8 },

  legal: {
    color: Colors.duck700,
    fontFamily: Fonts.type.light,
    fontSize: Fonts.size.medium,
    textAlign: 'center',
  },
});
