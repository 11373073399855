import { Keyboard } from 'react-native';

import auth from '@api-requests/api/auth';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import useMutationCall from '@hooks/useMutationCall';
import { useRecaptchaQuery } from '@hooks/useRecaptchaQuery';
import { navigate } from '@navigation/Actions';
import { ANONYMOUS_STACK } from '@navigation/Routes';

import { ResetPasswordFormData } from '../types';

export interface ResetPasswordPayload extends ResetPasswordFormData {
  email: string;
}

export const useResetPassword = () => {
  const resetPassword = useRecaptchaQuery(
    auth.forgotPassword.submit,
    'resetPassword',
  );

  return useMutationCall<ReducedAxiosResponse<{}>, any, ResetPasswordPayload>({
    mutationFn: resetPassword,
    mutationKey: ['forgotPasswordSubmit'],

    onSuccess: () => {
      Keyboard.dismiss();

      navigate(ANONYMOUS_STACK.SUCCESS_RESET_PASSWORD_SCREEN);
    },
  });
};
