import React, { useCallback, useRef, useState } from 'react';
import {
  Control,
  Controller,
  ControllerRenderProps,
  UseFormStateReturn,
  useWatch,
} from 'react-hook-form';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { useUpdateChildren } from '@api-requests/api/main/children';
import ApiRequest from '@api-requests/api/main/endPoint';
import { ChildCreate, Kid } from '@commonTypes/Kid';
import { useDispatch } from '@commonTypes/redux';
import PictureResultPreview from '@components/Modal/PictureResultPreview';
import { PressableOpacity } from '@components/PressableOpacity';
import { usePopup } from '@components/ui/Popup/hook';
import { t } from '@config';
import { userCreators } from '@redux/user';
import Cam from '@resources/svg/white-camera.svg';
import { Colors } from '@resources/themes';
import { useFileUpload } from '@tools/useUploadFile';

import { PhotoUI } from './PhotoUI';

const styles = StyleSheet.create({
  profileImage: {
    borderRadius: 50,
    height: 85,
    width: 85,
    padding: 5,
  },
  imageContainer: {
    backgroundColor: Colors.duck400,
    borderRadius: 50,
    bottom: 0,
    padding: 5,
    position: 'absolute',
    right: 0,
  },
  kidCameraIconContainer: {
    backgroundColor: Colors.duck400,
    borderRadius: 50,
    bottom: 0,
    padding: 5,
    position: 'absolute',
    right: 0,
  },
  container: {
    borderColor: Colors.duck400,
    borderRadius: 50,
    borderWidth: 2,
  },
  fileInput: {
    display: 'none',
  },
});

const KidPhotoUploader = ({
  style,

  control,
  id,
}: {
  style?: StyleProp<ViewStyle>;

  control: Control<ChildCreate>;
  id?: Kid['id'];
}) => {
  const [selectedImage, setSelectedImage] = useState('');

  const { mutate: uploadFile } = useFileUpload({
    signedUrlRequest: ApiRequest.kids.requestAvatarSignedUrl,
    signedUrlData: {},
  });

  const { mutate: update } = useUpdateChildren(id);

  const inputFile = useRef<HTMLInputElement | null>(null);

  const sex = useWatch({ control, name: 'sex' });
  const dispatch = useDispatch();

  const popup = usePopup();

  const render = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<ChildCreate, 'image'>;
      formState: UseFormStateReturn<ChildCreate>;
    }) => {
      return (
        <>
          <PressableOpacity
            style={[styles.container, style]}
            onPress={() => inputFile.current?.click()}
          >
            <PhotoUI image={field.value} sex={sex} />
            <input
              type="file"
              onChange={(event) => {
                // setSelectedImage(event.target);
                if (event.target.files) {
                  setSelectedImage(
                    URL.createObjectURL(event?.target?.files[0]),
                  );
                }
              }}
              ref={inputFile}
              style={styles.fileInput}
              accept="image/*"
            />

            <View style={styles.kidCameraIconContainer}>
              <Cam />
            </View>
          </PressableOpacity>

          <PictureResultPreview
            isVisible={Boolean(selectedImage)}
            onUploadCompleted={(readUrl: string) => {
              if (id) {
                update(
                  { image: readUrl },
                  {
                    onSuccess: () => {
                      dispatch(
                        userCreators.addOrUpdateKid({ id, image: readUrl }),
                      );
                    },
                    onError: () => popup(t('KIDS.REQUEST_ERROR')),
                  },
                );
              }
              field.onChange(readUrl);
              setSelectedImage('');
            }}
            onCancel={() => setSelectedImage('')}
            uri={selectedImage}
            assetType="photo"
            uploadFile={uploadFile}
          />
        </>
      );
    },
    [style, sex, selectedImage, uploadFile, id, update, dispatch, popup],
  );
  return <Controller name={'image'} control={control} render={render} />;
};

export default KidPhotoUploader;
