import DURATION from '@resources/constants/duration';
import { dayjsTZ } from '@tools/date';

type Params = { date?: Date; utc?: 'utc' | null };

// Due Date
export const getMinimumDueDate = ({ date, utc = 'utc' }: Params = {}) =>
  dayjsTZ(date, utc, true).subtract(6, 'd').startOf('d').toDate();
export const getMaximumDueDate = ({ date, utc = 'utc' }: Params = {}) =>
  dayjsTZ(date, utc, true)
    .add(DURATION.PREGNANCY_WEEKS_DURATION, 'w')
    .endOf('d')
    .toDate();

// Last Period
export const getMinimumPeriodDate = ({ date, utc = 'utc' }: Params = {}) =>
  dayjsTZ(date, utc, true)
    .subtract(DURATION.AMENORRHEA_TOTAL_DAYS + 6, 'd')
    .startOf('d')
    .toDate();
export const getMaximumPeriodDate = ({ date, utc = 'utc' }: Params = {}) =>
  dayjsTZ(date, utc, true).subtract(2, 'w').endOf('d').toDate();
export const computeEndPregnancyFromPeriod = (inputDate: Date) =>
  dayjsTZ(inputDate, 'utc').add(DURATION.AMENORRHEA_TOTAL_DAYS, 'd').toDate();
export const computePeriodFromEndPregnancy = ({
  date,
  utc = 'utc',
}: Params = {}) =>
  dayjsTZ(date, utc).subtract(DURATION.AMENORRHEA_TOTAL_DAYS, 'd').toDate();

// Pregnancy Start
export const getMinimumPregnancyStartDate = ({
  date,
  utc = 'utc',
}: Params = {}) =>
  dayjsTZ(date, utc, true)
    .subtract(DURATION.PREGNANCY_DAYS_DURATION + 6, 'd')
    .startOf('d')
    .toDate();
export const getMaximumPregnancyStartDate = ({
  date,
  utc = 'utc',
}: Params = {}) => dayjsTZ(date, utc, true).endOf('d').toDate();
export const computeEndPregnancyFromPregnancyStart = (inputDate: Date) =>
  dayjsTZ(inputDate, 'utc')
    .add(DURATION.PREGNANCY_WEEKS_DURATION, 'w')
    .toDate();
export const computePregnancyStartFromEndPregnancy = ({
  date,
  utc = 'utc',
}: Params = {}) =>
  dayjsTZ(date, utc).subtract(DURATION.PREGNANCY_WEEKS_DURATION, 'w').toDate();
