const Recoleta = {
  black: 'Recoleta-Black',
  bold: 'Recoleta-Bold',
  light: 'Recoleta-Light',
  medium: 'Recoleta-Medium',
  regular: 'Recoleta-Regular',
  semiBold: 'Recoleta-SemiBold',
  thin: 'Recoleta-Thin',
} as const;

const RecoletaAlt = {
  black: 'RecoletaAlt-Black',
  bold: 'RecoletaAlt-Bold',
  light: 'RecoletaAlt-Light',
  medium: 'RecoletaAlt-Medium',
  regular: 'RecoletaAlt-Regular',
  semiBold: 'RecoletaAlt-SemiBold',
  thin: 'RecoletaAlt-Thin',
} as const;

const type = {
  black: 'Poppins-Black',
  blackItalic: 'Poppins-BlackItalic',
  bold: 'Poppins-Bold',
  boldItalic: 'Poppins-BoldItalic',
  extraBold: 'Poppins-ExtraBold',
  extraBoldItalic: 'Poppins-ExtraBoldItalic',
  extraLight: 'Poppins-ExtraLight',
  extraLightItalic: 'Poppins-ExtraLightItalic',
  italic: 'Poppins-Italic',
  light: 'Poppins-Light',
  lightItalic: 'Poppins-LightItalic',
  medium: 'Poppins-Medium',
  mediumItalic: 'Poppins-MediumItalic',
  regular: 'Poppins-Regular',
  semiBold: 'Poppins-SemiBold',
  semiBoldItalic: 'Poppins-SemiBoldItalic',
  thin: 'Poppins-Thin',
  thinItalic: 'Poppins-ThinItalic',
  Recoleta,
  RecoletaAlt,
} as const;

const size = {
  avatar: 28,
  big: 58,
  chatInputSize: 11,
  currencySize: 22,
  extraText: 12,
  h1: 36,
  h2: 32,
  h3: 28,
  h4: 24,
  h5: 20,
  h6: 17,
  input: 16,
  intermediary: 11,
  medium: 12,
  mediumUp: 13,
  regular: 15,
  small: 10,
  ten: 8,
  tiny: 8,
  title: 14,
} as const;

const lineHeight = {
  medium: 16,
} as const;

const Fonts = {
  type,
  size,
  lineHeight,
};

export default Fonts;
