import React from 'react';
import { StyleSheet, StyleProp, View, ViewStyle } from 'react-native';

import { t } from '@config';

import AvailablePerk from './';

const styles = StyleSheet.create({
  container: { gap: 8, paddingHorizontal: 16 },
});

interface AvailablePerksListProps {
  style?: StyleProp<ViewStyle>;
  isPremium: boolean;
}
const AvailablePerksList = ({ style, isPremium }: AvailablePerksListProps) => (
  <View style={[styles.container, style]}>
    <AvailablePerk label={t('SUBSCRIBE_MODAL.AVAILABLE_PERKS.1')} />
    <AvailablePerk label={t('SUBSCRIBE_MODAL.AVAILABLE_PERKS.2')} />
    <AvailablePerk label={t('SUBSCRIBE_MODAL.AVAILABLE_PERKS.3')} />
    <AvailablePerk
      label={t('SUBSCRIBE_MODAL.AVAILABLE_PERKS.4')}
      isAvailable={isPremium}
    />
    <AvailablePerk
      label={t('SUBSCRIBE_MODAL.AVAILABLE_PERKS.5')}
      isAvailable={isPremium}
    />
  </View>
);

export default AvailablePerksList;
