import { navigate, push, replace } from '@navigation/Actions';
import { NAVIGATOR_NAMES, ONBOARDING_STACK } from '@navigation/Routes';

import { Scenario, TEMPLATE_TYPE } from '@api-requests/api/cms/scenarii/types';

export const handleRemoteFormNavigation = ({
  workflow,
  targetIndex = 0,
  shouldReplace = false,
}: {
  workflow: Scenario | undefined;
  targetIndex?: number;
  shouldReplace?: boolean;
}) => {
  const pushScreen = shouldReplace ? replace : push;
  const navigateScreen = shouldReplace ? replace : navigate;
  if (targetIndex < 0) {
    return;
  }
  const target = workflow?.[targetIndex]?.__component;
  if (target) {
    pushScreen(NAVIGATOR_NAMES.ONBOARDING_NAVIGATOR, {
      screen: targetToNavMap[target],
      params: {
        workflow,
        currentIndex: targetIndex,
      },
    });
  } else {
    navigateScreen(NAVIGATOR_NAMES.ONBOARDING_NAVIGATOR, {
      screen: ONBOARDING_STACK.ANIMATION_SCREEN,
    });
  }
};

const targetToNavMap = {
  [TEMPLATE_TYPE.IMAGE]: ONBOARDING_STACK.REMOTE_FORM_IMAGE_TEMPLATE_SCREEN,
  [TEMPLATE_TYPE.STEP]: ONBOARDING_STACK.REMOTE_FORM_STEP_TEMPLATE_SCREEN,
} as const;
