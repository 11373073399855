import React from 'react';
import { useDispatch } from 'react-redux';

import { useVendorSignin } from '@hooks/openid';
import { navigate } from '@navigation/Actions';
import { ANONYMOUS_STACK } from '@navigation/Routes';
import { signupFormCreator } from '@screens/Auth/SignUp/redux/signupInfos';
import { useHandle2FASuccess } from '@screens/Auth/utils';

import { SignInWithApple } from './SignInWithApple';

export const LoginWithApple = () => {
  const { mutateAsync: signinWithApple } = useVendorSignin('apple');
  const successCallback = useHandle2FASuccess();
  const dispatch = useDispatch();

  return (
    <SignInWithApple
      successCallback={async (response) => {
        const result = await signinWithApple({
          identityToken: response.identityToken!,
        });
        if ('tokens' in result.data) {
          await successCallback(result.data, 'apple');
        } else {
          dispatch(
            signupFormCreator.addProviderData({
              ...result.data,
              provider: 'apple',
              providerToken: response.identityToken!,
            }),
          );
          navigate(ANONYMOUS_STACK.ATTACH_SUGGESTION_SCREEN);
        }
      }}
    />
  );
};
