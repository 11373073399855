import { Dimensions, Platform } from 'react-native';

import { WEB_WIDTH } from '@resources/constants/web';

const { width, height } = Platform.select({
  web: { width: WEB_WIDTH, height: Dimensions.get('window').height },
  default: {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
  },
});

const isIPhoneX = false;

const statusBarHeight = (() => {
  if (isIPhoneX) {
    return 27;
  }
  if (Platform.OS === 'ios') {
    return 15;
  }
  return 0;
})();

export default {
  width,
  height,
  small: width <= 240,
  medium: width >= 360 && width < 400,
  mediumAndDown: width < 360,
  mediumAndUp: width > 360,
  large: width <= 540 && width > 360,
  largeAndDown: width <= 540,
  largeAndUp: width > 360,
  heightLargeAndUp: height > 667,
  heightSemiLarge: height > 667 && height <= 812,
  xLarge: width > 540,
  smallDrawer: width < 300,
  marginHorizontal: 10,
  marginVertical: 10,
  section: 25,
  baseMargin: 10,
  doubleBaseMargin: 20,
  smallMargin: 5,
  doubleSection: 50,
  horizontalLineHeight: 1,
  searchBarHeight: 30,
  screenWidth: width < height ? width : height,
  screenWidthQuarter: (width < height ? width : height) / 4,
  screenWidthThird: (width < height ? width : height) / 3,
  screenWidthHalf: (width < height ? width : height) / 2,
  screenHeight: width < height ? height : width,
  realNavBarHeight: Platform.OS === 'ios' ? 84 : 54,
  navBarHeight: 54,
  statusBarHeight,
  tabBarHeight: 60,
  buttonRadius: 3,
  border: 4,
  drawerWidth: Platform.OS === 'ios' ? width * 0.68 : width * 0.75,
  heightInput: 50,
  paddingInputLeft: 5,
  stateBarHeight: Platform.OS === 'ios' ? 20 : 0,
  heightErrorContainer: 30,
  icons: {
    tiny: 15,
    small: 20,
    medium: 30,
    large: 45,
    xl: 50,
  },
  images: {
    small: 20,
    medium: 40,
    large: 60,
    logo: 100,
  },
  isIPhoneX,
  usableScreenWidth: width < height ? width : height - (isIPhoneX ? 78 : 0),
  usableScreenHeight: width < height ? height - (isIPhoneX ? 78 : 0) : width,
  svgDefaultSize: width <= 360 ? width / 7 : width / 6,
};
