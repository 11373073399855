import React, { ComponentProps, useCallback, useState } from 'react';
import { Controller, Path } from 'react-hook-form';
import { Platform, Text, TextInput as RNTextInput, View } from 'react-native';

import { PressableOpacity } from '@components/PressableOpacity';
import { t } from '@config';
import { hitSlop } from '@resources/constants/hitSlop';
import { Colors } from '@resources/themes';

import styles from './styles';
import { InputWrapper } from '../inputWrappers';
import { ExposedControllerProps, RenderInput } from '../types';

type TextInputProps<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
> = ExposedControllerProps<TFieldValues, TName> &
  Omit<ComponentProps<typeof RNTextInput>, 'onChange' | 'value'> & {
    leftIcon?: React.ReactElement;
  };

function RawTextInput<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
>(
  {
    name,
    control,
    label,
    rules,
    errorMessage,
    helperText,
    leftIcon,
    editable = true,
    ...other
  }: TextInputProps<TFieldValues, TName>,
  ref: React.ForwardedRef<RNTextInput>,
) {
  const [showPassword, setShowPassword] = useState(false);
  const render = useCallback(
    ({ field, fieldState, formState }: RenderInput<TFieldValues, TName>) => {
      const { style, multiline, secureTextEntry, ...props } = other;
      const isEditable = editable && !formState.isSubmitting;
      const readOnlyProps = Platform.select({
        web: {
          readOnly: !isEditable,
        },
        default: {
          editable: isEditable,
        },
      });
      return (
        <InputWrapper
          label={label}
          rules={rules}
          errorMessage={errorMessage}
          helperText={helperText ? helperText(field.value) : undefined}
          fieldState={fieldState}
        >
          <View
            style={
              style
                ? [styles.inputAndIconContainer, style]
                : styles.inputAndIconContainer
            }
          >
            {Boolean(leftIcon) && leftIcon}
            <RNTextInput
              style={[
                multiline ? styles.multilineInput : styles.input,
                !editable && styles.disabledInput,
              ]}
              textAlignVertical={multiline ? 'top' : 'center'}
              placeholderTextColor={Colors.grey600}
              {...readOnlyProps}
              {...field}
              value={field.value ? field.value.toString() : ''}
              onChangeText={(value) => field.onChange(value)}
              {...props}
              secureTextEntry={secureTextEntry && !showPassword}
              ref={ref}
            />
            {!!secureTextEntry && (
              <PressableOpacity
                onPress={() => setShowPassword((prev) => !prev)}
                style={styles.showPasswordTouchable}
                hitSlop={hitSlop.medium}
              >
                <Text
                  style={styles.showPasswordText}
                  testID={other.testID ? `${other.testID}-show` : undefined}
                >
                  {showPassword ? t('FORM.HIDE') : t('FORM.SHOW')}
                </Text>
              </PressableOpacity>
            )}
          </View>
        </InputWrapper>
      );
    },
    [
      editable,
      errorMessage,
      helperText,
      label,
      leftIcon,
      other,
      ref,
      rules,
      showPassword,
    ],
  );

  return (
    <Controller name={name} control={control} rules={rules} render={render} />
  );
}

export const TextInput = React.forwardRef(RawTextInput);
