import React, { useState } from 'react';
import { Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { PrimaryButton } from '@components/Buttons';
import DateButton from '@components/DateTimePicker/DateButton';
import { PressableOpacity } from '@components/PressableOpacity';
import AppIcon from '@components/ui/Icon';
import { NavBar } from '@components/ui/NavBar';
import { t } from '@config';
import { goBack, navigate, replace } from '@navigation/Actions';
import {
  COMMON_SCREEN_STACK,
  JOURNALING_STACK,
  NAVIGATOR_NAMES,
} from '@navigation/Routes';
import CheckboxText from '@screens/PaymentScreen/CheckboxText';
import { dayjsTZ, formatDate } from '@tools/date';

import { LoggedNavigatorParamList, ScreenRoute } from '@navigation/types';

import styles from './styles';
import { useAddPregnancy } from '../hooks/pregnancy';
import { METHOD } from '../types';
import {
  getMaximumDueDate,
  getMinimumDueDate,
} from '../utils/pregnancyCreationBoundaries';

const AddPregnancyScreen = ({
  route: {
    params: { targetScreen, originScreen },
  },
}: ScreenRoute<
  LoggedNavigatorParamList,
  COMMON_SCREEN_STACK.ADD_PREGNANCY_SCREEN
>) => {
  const addPregnancy = useAddPregnancy(METHOD.DUE_DATE, originScreen);

  const [expectedEnd, setExpectedEnd] = useState(
    formatDate(getMaximumDueDate({ utc: null }), 'YYYY-MM-DD'),
  );
  const [multiple, setMultiple] = useState(false);

  const handleDateChange = (selectedDate: Date | string | undefined) => {
    if (!selectedDate) {
      return;
    }
    if (
      selectedDate >= getMinimumDueDate({ utc: null }) &&
      selectedDate <= getMaximumDueDate({ utc: null })
    ) {
      // selected date is in local time (beginning of the day on iOS, end of the day on Android)
      // so we format it using local date
      setExpectedEnd(formatDate(selectedDate, 'YYYY-MM-DD'));
    }
  };

  const goToComputeDateTerm = () =>
    navigate(COMMON_SCREEN_STACK.COMPUTE_END_PREGNANCY, {
      pregnancyId: undefined,
      expectedEnd,
      multiple,
      originScreen,
      targetScreen,
    });

  const handleSubmit = () =>
    addPregnancy.mutate(
      {
        expectedEnd,
        multiple,
      },
      {
        onSuccess: () => {
          switch (targetScreen) {
            case JOURNALING_STACK.CONTRACTION_SCREEN:
            case JOURNALING_STACK.MY_WEIGHT_SCREEN:
              replace(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
                screen: targetScreen,
              });
              break;
            default:
              goBack();
              break;
          }
        },
      },
    );

  return (
    <SafeAreaView style={styles.container}>
      <NavBar title={t('PROFILE.PREGNANCY.ADD.TITLE')} />
      <View style={styles.formContainer}>
        <Text style={styles.adviceText}>
          {t('PROFILE.PREGNANCY.ADD.DESCRIPTION')}
        </Text>
        <View style={styles.datePicker}>
          <Text style={styles.dateLabel}>
            {t('PROFILE.PREGNANCY.DUE_INPUT')}
          </Text>
          <View style={styles.dateInput}>
            <AppIcon name="BabyIcon" />
            <DateButton
              value={dayjsTZ(expectedEnd).toDate()} // dayjsTZ with no arguments generates the date beginning of the day in local time
              onChange={handleDateChange}
              minimumDate={getMinimumDueDate({ utc: null })}
              maximumDate={getMaximumDueDate({ utc: null })}
            />
          </View>
        </View>
        <PressableOpacity onPress={goToComputeDateTerm} testID="dontKnowDate">
          <Text style={styles.unknownDateText}>
            {t('PROFILE.PREGNANCY.ADD.HELP')}
          </Text>
        </PressableOpacity>
      </View>
      <CheckboxText
        onChange={() => setMultiple((prev) => !prev)}
        selected={multiple}
        title={t('PROFILE.PREGNANCY.MULTIPLE')}
      />
      <View style={styles.spacer} />
      <PrimaryButton
        disabled={!expectedEnd}
        isLoading={addPregnancy.isPending}
        onPress={handleSubmit}
        label={t('FORM.ADD')}
        testID="addPregnancyButton"
      />
      <Text style={styles.secureText}>{t('PROFILE.LEGAL.SECURED_DATA')}</Text>
    </SafeAreaView>
  );
};

export default AddPregnancyScreen;
