import { t } from '@config';

import { Product, PRODUCT_INTERVAL, PRODUCT_TIER, ProductRaw } from '../types';

export const formatProduct = (
  product: ProductRaw,
  hasAlreadyPaid: boolean,
): Product => ({
  ...product,
  title: getTitle(product, hasAlreadyPaid),
  default: isDefaultProduct(product),
});

export const formatProductList = (
  { data }: { data: Array<ProductRaw> },
  hasAlreadyPaid: boolean,
) =>
  data
    .sort((a, b) => b.amount - a.amount)
    .map((d) => formatProduct(d, hasAlreadyPaid));

const getTitle = (
  { planNickname, withTrial }: Pick<ProductRaw, 'planNickname' | 'withTrial'>,
  hasAlreadyPaid: boolean,
) => {
  if (!withTrial) {
    return planNickname;
  }
  // Patch to be removed once versions are forced and backend gets updated
  if (planNickname.toLowerCase().includes('essai')) {
    if (hasAlreadyPaid) {
      return planNickname
        .split('-')
        .filter((name) => !name.toLowerCase().includes('essai'))
        .join('-')
        .trim();
    } else {
      return planNickname;
    }
  }
  if (hasAlreadyPaid) {
    return planNickname;
  }

  return `${planNickname} - ${t('SUBSCRIPTION.TRIAL')}`;
};

const isDefaultProduct = (product: Pick<ProductRaw, 'interval' | 'tier'>) =>
  product.tier === PRODUCT_TIER.PREMIUM &&
  product.interval === PRODUCT_INTERVAL.YEAR;
