import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = { value: false };

const hasLoggedOnceSlice = createSlice({
  name: 'hasLoggedOnce',
  initialState,
  reducers: {
    setValue: (state, { payload }: PayloadAction<boolean>) => {
      state.value = payload;
    },
  },
});

export const hasLoggedOnceCreators = hasLoggedOnceSlice.actions;
export default hasLoggedOnceSlice.reducer;
