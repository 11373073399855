import { createRef } from 'react';
import { Keyboard } from 'react-native';

import { CHAT_STACK } from '@navigation/Routes';
import { NavigationContainerRef, StackActions } from '@react-navigation/native';
import {
  WebAnonymousStackParamList,
  WebLoggedNavigatorParamList,
} from '@webNavigation/routeParams';
import {
  WEB_ANONYMOUS_STACK,
  WEB_COMMON_SCREEN_STACK,
} from '@webNavigation/routes';

import {
  AnonymousStackParamList,
  ChatStackParamList,
  LoggedNavigatorParamList,
} from './types';

type ParamList = LoggedNavigatorParamList &
  AnonymousStackParamList &
  Pick<
    WebAnonymousStackParamList,
    | WEB_ANONYMOUS_STACK.INTRO_SCREEN
    | WEB_ANONYMOUS_STACK.PRE_ONBOARDING_SCREEN
    | WEB_ANONYMOUS_STACK.REMOTE_PRE_ONBOARDING_IMAGE_SCREEN
    | WEB_ANONYMOUS_STACK.REMOTE_PRE_ONBOARDING_STEP_SCREEN
  > &
  Pick<
    WebLoggedNavigatorParamList,
    | WEB_COMMON_SCREEN_STACK.HOME_SCREEN
    | WEB_COMMON_SCREEN_STACK.SUBSCRIBE_SCREEN
    | WEB_COMMON_SCREEN_STACK.UNSUBSCRIBED_HOME_SCREEN
  >;
export const navigationRef = createRef<NavigationContainerRef<ParamList>>();

export function isInChatRoute(discussionId?: string) {
  const route = navigationRef.current?.getCurrentRoute();
  if (!route) {
    return false;
  }
  const isChatRoute = route.name === CHAT_STACK.ROOM_CHAT_SCREEN;
  if (!isChatRoute) {
    return false;
  }
  return (
    String(
      (route?.params as ChatStackParamList[CHAT_STACK.ROOM_CHAT_SCREEN])
        ?.discussionId,
    ) === String(discussionId)
  );
}

type NavigationArgs<RouteName extends keyof ParamList> =
  RouteName extends unknown
    ? undefined extends ParamList[RouteName]
      ?
          | [screen: RouteName]
          | [
              screen: RouteName,
              params: { noDismiss?: boolean } & ParamList[RouteName],
            ]
      : [
          screen: RouteName,
          params: { noDismiss?: boolean } & ParamList[RouteName],
        ]
    : never;

export function navigateWithKeyboard<RouteName extends keyof ParamList>(
  // use exact navigate type
  ...args: NavigationArgs<RouteName>
) {
  if (!navigationRef.current?.isReady()) {
    storedNavigation.push({ type: 'navigateWithKeyboard', args });
    return;
  }
  return navigationRef.current?.navigate(...args);
}

export function navigate<RouteName extends keyof ParamList>(
  // use exact navigate type
  ...args: NavigationArgs<RouteName>
) {
  if (!navigationRef.current?.isReady()) {
    storedNavigation.push({ type: 'navigate', args });
    return;
  }
  Keyboard.dismiss();
  return navigationRef.current?.navigate(...args);
}

export function push<RouteName extends keyof ParamList>(
  ...args: NavigationArgs<RouteName>
) {
  if (!navigationRef.current?.isReady()) {
    storedNavigation.push({ type: 'push', args });
    return;
  }
  const [name, params] = args;
  if (!params?.noDismiss) {
    Keyboard.dismiss();
  }

  // Pushing to a screen that is on the same navigator with `push('navigatorName', {screen: 'screenName'})`
  // will result in a new navigator being created despite the screen being on the same navigator
  // This breaks some navigation assumptions (e.g. navigating to a screen that is already in the stack won't pop to it because it'll be in a "different" navigator - thus breaking some navigation workflows)
  // We can however prevent this havoc by forcing pushes to same navigator to be treated as `push('screenName')` instead
  const state = navigationRef.current?.getState();
  if (state && params && 'screen' in params && params.screen) {
    const currentNavigator = state.routes[state.index].name;
    if (currentNavigator === name) {
      return navigationRef.current?.dispatch(
        StackActions.push(params.screen, params.params),
      );
    }
  }
  return navigationRef.current?.dispatch(StackActions.push(name, params));
}

export function replace<RouteName extends keyof ParamList>(
  ...args: NavigationArgs<RouteName>
) {
  if (!navigationRef.current?.isReady()) {
    storedNavigation.push({ type: 'replace', args });
    return;
  }
  const [name, params] = args;
  if (!params?.noDismiss) {
    Keyboard.dismiss();
  }
  return navigationRef.current?.dispatch(StackActions.replace(name, params));
}

export function goBack() {
  if (!navigationRef.current?.isReady()) {
    storedNavigation.push({ type: 'goBack', args: [''] });
    return;
  }
  Keyboard.dismiss();
  return navigationRef.current?.goBack();
}

export function canGoBack() {
  return navigationRef.current?.canGoBack();
}

export function pop(nbToPop?: number) {
  Keyboard.dismiss();
  return navigationRef.current?.dispatch(StackActions.pop(nbToPop));
}

type Actions =
  | 'navigate'
  | 'navigateWithKeyboard'
  | 'push'
  | 'replace'
  | 'pop'
  | 'goBack';
const storedNavigation: { type: Actions; args: NavigationArgs<any> }[] = [];
export function getStoredNavigation() {
  while (storedNavigation.length) {
    const { type, args } = storedNavigation.shift()!;
    switch (type) {
      case 'navigate':
        navigate(...args);
        break;
      case 'navigateWithKeyboard':
        navigateWithKeyboard(...args);
        break;
      case 'push':
        push(...args);
        break;
      case 'replace':
        replace(...args);
        break;
      case 'pop':
        pop(...args);
        break;
      case 'goBack':
        goBack();
        break;
    }
  }
}
