import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  prepareTouchId,
  enrollTouchId as enrollTouchIdApi,
} from '@api-requests/api/auth';
import { setStrapiAuthorizationBearer } from '@api-requests/api/cms/request';
import { storeCredentials } from '@api-requests/api/common';
import familyPendingInvite from '@api-requests/api/main/family-pending-invite/endPoint';
import { FAMILY_PENDING_INVITE_QUERY_KEYS } from '@api-requests/api/main/family-pending-invite/hooks';
import { setAuthorizationBearer } from '@api-requests/api/main/request';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import { OpenidProviders, Tokens } from '@commonTypes/Auth';
import useMutationCall from '@hooks/useMutationCall';
import { TOKENS_QUERY_KEYS } from '@hooks/useTokens';
import { navigate } from '@navigation/Actions';
import { NAVIGATOR_NAMES, SETTINGS_STACK } from '@navigation/Routes';
import { AuthCreators } from '@redux/auth';
import { UserData, userCreators } from '@redux/user';
import { useQueryClient } from '@tanstack/react-query';
import { enrichWithJWTExp } from '@tools/auth';
import { captureQueryError } from '@tools/captureQueryError';
import { captureException } from '@tools/sentry';
import { enrollTouchId } from '@tools/touchId';

import { DetailedFamilyInvitation } from '@api-requests/api/main/family-pending-invite/types';

const allOnboardingScenarios = ['onboarding-v1', 'onboarding-v2'];

export const useHandle2FASuccess = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutateAsync: prepareTouchIdAsync } = useMutationCall<
    ReducedAxiosResponse<{ encryptedMessage: string }>,
    any,
    {
      deviceId: string;
      touchIdPublicKey: string;
    }
  >({
    mutationFn: prepareTouchId,
    mutationKey: ['prepareTouchId'],
  });

  const { mutateAsync: enrollTouchIdApiAsync } = useMutationCall<
    ReducedAxiosResponse<{ id: string }>,
    any,
    {
      deviceId: string;
      touchIdPublicKey: string;
      plainMessage: string;
    }
  >({
    mutationFn: enrollTouchIdApi,
    mutationKey: ['prepareTouchId'],
  });

  return useCallback(
    async (
      data: { tokens: Tokens; appUser: UserData },
      provider: OpenidProviders | 'apple' | 'may',
    ) => {
      const scenarios = data.appUser.scenarios;
      const onboardingCompleted = allOnboardingScenarios.some((scenario) =>
        scenarios?.includes(scenario),
      );

      // fill store with data
      dispatch(userCreators.userSuccess(data.appUser));
      if (data.tokens?.jwt) {
        setAuthorizationBearer(data.tokens?.jwt);
        setStrapiAuthorizationBearer(data.tokens?.jwt);
      }

      try {
        await storeCredentials({ refreshFetchTime: Date.now(), provider });
        data.tokens &&
          (await queryClient.setQueryData<{ data: Tokens }>(TOKENS_QUERY_KEYS, {
            data: enrichWithJWTExp(data.tokens),
          }));
      } catch (error: any) {
        captureException(error);
      }

      dispatch(
        AuthCreators.authLogin({
          token: data.tokens?.jwt,
          isLogged: true,
          hasCompletedOnboarding: onboardingCompleted,
        }),
      );

      try {
        enrollTouchId(
          data.appUser.email,
          prepareTouchIdAsync,
          enrollTouchIdApiAsync,
        );
      } catch (error: any) {
        captureException(error);
      }

      if (!onboardingCompleted) {
        try {
          const pendingInvitation = await queryClient.fetchQuery<
            ReducedAxiosResponse<DetailedFamilyInvitation | null>
          >({
            queryKey: FAMILY_PENDING_INVITE_QUERY_KEYS.getReceived,
            queryFn: familyPendingInvite.getReceived,
          });

          if (
            pendingInvitation.data &&
            'appUser' in pendingInvitation.data &&
            pendingInvitation.status &&
            pendingInvitation.status === 200
          ) {
            navigate(NAVIGATOR_NAMES.SETTINGS_NAVIGATOR, {
              screen: SETTINGS_STACK.FAMILY_ACCOUNT_ALLOW_SCREEN,
              params: {
                linkedAccount: pendingInvitation.data,
                from: 'onboarding',
              },
            });
          }
        } catch (error: any) {
          captureQueryError(error);
        }
      }
    },
    [dispatch, enrollTouchIdApiAsync, prepareTouchIdAsync, queryClient],
  );
};
