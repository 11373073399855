import { StyleSheet } from 'react-native';

import { Metrics } from '@resources/themes';

export default StyleSheet.create({
  buttonContainer: {
    marginBottom: 50,
  },

  checkBoxContainer: {
    alignSelf: 'center',
    marginVertical: 10,
    width: Metrics.screenWidth / 1.2,
  },

  container: {
    height: '100%',
  },
  inputContainer: {
    marginHorizontal: 24,
  },
});
