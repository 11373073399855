import { File, MinimalFile } from '@commonTypes/File';

const sizeWeight = ['thumbnail', 'small', 'medium', 'large'] as const;

export const getBestImageOf = <
  T extends File | MinimalFile | string | undefined,
>(
  file: T,
  minimumSize: 'thumbnail' | 'small' | 'medium' | 'large' = 'thumbnail',
) => {
  if (!file) {
    return;
  }
  if (typeof file === 'string') {
    return file;
  }
  const startIndex = sizeWeight.indexOf(minimumSize);

  let result: string | undefined | null = '';

  sizeWeight.forEach((elem, index) => {
    if (index < startIndex || result) {
      return;
    } else if (file?.formats?.[elem]?.url) {
      result = file?.formats?.[elem]?.url;
    }
  });

  return result || file?.url;
};
