import useAuthenticatedQuery from '@hooks/useAuthenticatedQuery';
import { useQuery } from '@tanstack/react-query';
import { remoteConfig } from '@tools/firebase';

import { onboardingScenarios } from './endPoint';
import { formatScenario, FormatScenarioFilter } from '../utils/formatScenario';
import { useScenarioFilters } from '../utils/useScenarioFilters';

export const ONBOARDING_SCENARIOS_QUERY_KEYS = {
  GET: (slug: string) => ['onboardingScenarios', slug],
};

const useOnboardingScenarios = (slug: string, filters: FormatScenarioFilter) =>
  useAuthenticatedQuery({
    queryFn: () => onboardingScenarios.get(slug),
    queryKey: ONBOARDING_SCENARIOS_QUERY_KEYS.GET(slug),
    select: (res) => formatScenario(res, filters),
  });

export const useRemoteOnboardingScenarios = () => {
  const slug = remoteConfig().getValue('onboardingScenario').asString();
  const filters = useScenarioFilters();
  return useOnboardingScenarios(slug, filters);
};

export const useWebPreOnboardingScenarios = (
  filters?: FormatScenarioFilter,
) => {
  const slug = 'web';
  return useQuery({
    queryKey: ONBOARDING_SCENARIOS_QUERY_KEYS.GET(slug),
    queryFn: () => onboardingScenarios.get(slug),

    select: (res) => formatScenario(res, filters),
  });
};
