import ApiRequest from '@api-requests/api/main/endPoint';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import { ChildCreate, Kid } from '@commonTypes/Kid';
import useMutationCall from '@hooks/useMutationCall';

export const useUpdateChildren = (id: Kid['id'] = 0) => {
  return useMutationCall<ReducedAxiosResponse<Kid>, any, Partial<ChildCreate>>({
    mutationFn: (args: Partial<ChildCreate>) =>
      ApiRequest.kids.update(id, args),
    mutationKey: ['kids', 'create'],
  });
};
export const useCreateChildren = () => {
  return useMutationCall<ReducedAxiosResponse<Kid>, any, ChildCreate>({
    mutationFn: ApiRequest.kids.add,
    mutationKey: ['kids', 'update'],
  });
};

export const useDeleteChildren = () => {
  return useMutationCall<ReducedAxiosResponse<{}>, any, Kid['id']>({
    mutationFn: ApiRequest.kids.delete,
    mutationKey: ['kids', 'delete'],
  });
};
