import * as SecureStore from 'expo-secure-store';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Keyboard, Platform, Text, View } from 'react-native';

import { OpenidProviders } from '@commonTypes/Auth';
import { LoginWithApple } from '@components/AuthButtons/LoginWithApple';
import { LoginWithGoogle } from '@components/AuthButtons/LoginWithGoogle';
import { PrimaryButton } from '@components/Buttons';
import KeyboardAvoidingSafeScrollView from '@components/KeyboardAvoidingSafeScrollView';
import { TextInput } from '@components/ui/hookFormInputs/TextInput';
import { NavBar } from '@components/ui/NavBar';
import { t } from '@config';
import { navigate } from '@navigation/Actions';
import { ANONYMOUS_STACK } from '@navigation/Routes';

import { useForgotPassword } from './hooks/forgotPassword';
import styles from './styles';
import { Separator } from '../LoginScreen/components/Separator';

const ForgotPasswordScreen = () => {
  const { control, handleSubmit, formState, setError } = useForm({
    defaultValues: { email: '' },
  });
  const { isSubmitting } = formState;
  const { mutateAsync: forgotPassword } = useForgotPassword();
  const [previousProvider, setPreviousProvider] = useState<
    OpenidProviders | 'apple' | null
  >(null);
  const [authProvider, setAuthProvider] = useState<
    OpenidProviders | 'apple' | null
  >(
    Platform.select<OpenidProviders | 'apple'>({
      ios: 'apple',
      default: OpenidProviders.GOOGLE,
    }),
  );

  useEffect(() => {
    let mounted = true;
    const providerRetrieval = async () => {
      if (mounted) {
        const provider = await SecureStore.getItemAsync('previousProvider');
        if (provider && mounted && provider !== 'may') {
          setPreviousProvider(provider as OpenidProviders | 'apple');
        }
      }
    };
    providerRetrieval();
    return () => {
      mounted = false;
    };
  }, []);

  const handleValidate = handleSubmit(async ({ email }: { email: string }) => {
    Keyboard.dismiss();
    try {
      const results = await forgotPassword({ email: email.trim() });
      Keyboard.dismiss();
      const providers =
        (Platform.OS === 'ios'
          ? results.data?.providers
          : results.data?.providers?.filter((p) => p !== 'apple')) ?? [];
      if (providers.length) {
        setError('email', {
          type: `USE_PROVIDER.${providers[0]}`,
        });
        if (providers.includes(OpenidProviders.GOOGLE)) {
          // google login exists on all platform so we prefer it
          setAuthProvider(OpenidProviders.GOOGLE);
        } else {
          setAuthProvider(providers[0]);
        }
        setPreviousProvider(null);
      } else {
        navigate(ANONYMOUS_STACK.RESET_PASSWORD_SCREEN, { email });
      }
    } catch (err: any) {
      const status = err?.response?.status ?? err?.status ?? 0;
      if (status === 429) {
        setError('email', { type: 'TOO_MANY_ATTEMPTS' });
      } else if (status >= 400 && status < 500) {
        setError('email', { type: 'FORGOT_PASSWORD_EMAIL' });
      } else if (status === 0) {
        setError('email', { type: 'NETWORK_ERROR' });
      } else {
        setError('email', { type: 'UNKNOWN_ERROR' });
      }
    }
  });

  return (
    <KeyboardAvoidingSafeScrollView style={styles.container}>
      <NavBar title={t('AUTH.FORGOT_PASSWORD.TITLE')} />
      {(previousProvider === 'apple' ||
        previousProvider === OpenidProviders.GOOGLE) && (
        <Text style={styles.messageText}>
          {t(`AUTH.FORGOT_PASSWORD.OPEN_ID_HINT.${previousProvider}`)}
        </Text>
      )}
      {previousProvider === 'apple' && <LoginWithApple />}
      {previousProvider === OpenidProviders.GOOGLE && <LoginWithGoogle />}
      {(previousProvider === 'apple' ||
        previousProvider === OpenidProviders.GOOGLE) && <Separator />}
      <TextInput
        autoCapitalize="none"
        control={control}
        name="email"
        inputMode="email"
        onSubmitEditing={isSubmitting ? () => null : handleValidate}
        placeholder={t('FORM.EMAIL_PLACEHOLDER')}
        returnKeyType="send"
        textContentType="emailAddress"
        rules={{
          required: true,
          pattern: {
            value: /\s*\S+@\S+\.\S+\s*/,
            message: t('FORM.HOOK_FORMS_ERROR.FORGOT_PASSWORD_EMAIL'),
          },
        }}
        label={t('FORM.EMAIL')}
      />
      <View style={styles.spacer} />
      {authProvider === 'apple' && <LoginWithApple />}
      {authProvider === OpenidProviders.GOOGLE && <LoginWithGoogle />}
      <PrimaryButton
        isLoading={isSubmitting}
        onPress={handleValidate}
        label={t('AUTH.FORGOT_PASSWORD.BUTTON')}
      />
    </KeyboardAvoidingSafeScrollView>
  );
};

export default ForgotPasswordScreen;
