import React, { Dispatch, SetStateAction } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';

import { Colors } from '@resources/themes';

import { Product } from '@api-requests/api/main/payment/products/types';

import PlanTile from './PlanTile';

const styles = StyleSheet.create({ spacing: { gap: 16 } });

interface PlanListProps {
  style?: View['props']['style'];
  productList?: Array<Product>;
  selectedPlan: Product | null;
  setSelectedPlan: Dispatch<SetStateAction<Product | null>>;
}
const PlanList = ({
  style,
  productList,
  selectedPlan,
  setSelectedPlan,
}: PlanListProps) => (
  <View style={[styles.spacing, style]}>
    {selectedPlan && productList ? (
      productList.map((plan) => (
        <PlanTile
          key={plan.planId}
          plan={plan}
          onPress={() => setSelectedPlan(plan)}
          isSelected={selectedPlan.planId === plan.planId}
        />
      ))
    ) : (
      <ActivityIndicator color={Colors.duck800} size="large" />
    )}
  </View>
);

export default PlanList;
