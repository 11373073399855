import { FamilyInvitation, DetailedFamilyInvitation } from './types';
import { axiosGet, axiosPost, axiosPut } from '../request';

const baseUrl = '/apifo/family-pending-invite';

const createInvitation = (data: Pick<FamilyInvitation, 'targetEmail'>) =>
  axiosPost<FamilyInvitation, Pick<FamilyInvitation, 'targetEmail'>>({
    url: baseUrl,
    params: { data },
  });

const revokeInvitation = ({ id }: Pick<FamilyInvitation, 'id'>) =>
  axiosPut<void>({
    url: `${baseUrl}/${id}/revoke`,
  });

const acceptInvitation = ({ id }: Pick<FamilyInvitation, 'id'>) =>
  axiosPut<void>({
    url: `${baseUrl}/${id}/accept`,
  });

const declineInvitation = ({ id }: Pick<FamilyInvitation, 'id'>) =>
  axiosPut<void>({
    url: `${baseUrl}/${id}/decline`,
  });

const getReceivedInvitation = () =>
  axiosGet<DetailedFamilyInvitation | null>({
    url: `${baseUrl}/received`,
  });

const getSentInvitation = () =>
  axiosGet<DetailedFamilyInvitation | null>({
    url: `${baseUrl}/sent`,
  });

const getQRCode = () =>
  axiosGet<{ invite: string }>({
    url: `${baseUrl}/qrCode`,
  });

const acceptQRCode = (data: { invite: string }) =>
  axiosPut<DetailedFamilyInvitation, { invite: string }>({
    url: `${baseUrl}/qrCode/accept`,
    params: { data },
  });

export default {
  create: createInvitation,
  revoke: revokeInvitation,
  accept: acceptInvitation,
  decline: declineInvitation,
  getReceived: getReceivedInvitation,
  getSent: getSentInvitation,
  getQRCode,
  acceptQRCode,
};
