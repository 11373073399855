import { AppStateStatus } from 'react-native';

import { resetAll } from '@redux/reduxReset';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum APP_STATE {
  ACTIVE = 'active',
  BACKGROUND = 'background',
  INACTIVE = 'inactive',
}

const initialState = { current: APP_STATE.ACTIVE as AppStateStatus };

const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    updateAppState: (state, action: PayloadAction<AppStateStatus>) => {
      state.current = action.payload;
    },
  },
});

export const appStateCreators = appStateSlice.actions;
export default appStateSlice.reducer;
