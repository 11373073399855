import { OpenidProviders } from '@commonTypes/Auth';
import { resetAll } from '@redux/reduxReset';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  email: '',
  password: '',
  phone: '',
  newsletterAgreed: false,
  country: '',
  provider: undefined as OpenidProviders | 'apple' | undefined,
  providerToken: '',
  vendorMethod: false,
};

const signupSlice = createSlice({
  name: 'signupInfos',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    addFormName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    addFormEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    addFormNewsletter: (state, action: PayloadAction<boolean>) => {
      state.newsletterAgreed = action.payload;
    },
    addFormPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    addFormPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    addFormCountry: (state, action: PayloadAction<string>) => {
      state.country = action.payload;
    },
    addProviderData: (
      state,
      action: PayloadAction<{
        email: string;
        phone: string;
        provider: OpenidProviders | 'apple';
        providerToken: string;
        vendorMethod?: boolean;
      }>,
    ) => {
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.provider = action.payload.provider;
      state.providerToken = action.payload.providerToken;
      state.vendorMethod = action.payload.vendorMethod ?? false;
    },
    reset: () => initialState,
  },
});

export const signupFormCreator = signupSlice.actions;
export default signupSlice.reducer;
