export const ACCEPT_NOTIFICATION_PERMISSION = 'acceptNotificationPermission';
export const ACTIVATE_AFFILIATION = 'ActivateAffiliation';
export const ADD_FAVORITE_CONTENT = 'AddFavoriteContent';
export const CHAT_SEND_MESSAGE = 'ChatSendMessage';
export const CHILD_CREATION = 'ChildCreation';
export const CHURN_COMPLETE = 'ChurnComplete';
export const CHURN_START = 'ChurnStart';
export const CHURN_STEP_ANSWER = 'ChurnStepAnswer';
export const CONTENT_EVALUATION = 'ContentEvaluation';
export const DEACTIVATE_ACCOUNT = 'DeactivateAccount';
export const EXPLORE_FILTER = 'ExploreFilter';
export const EXPLORE_FILTER_CONTENT = 'ExploreFilterContent';
export const EXPLORE_SEARCH_BAR_FOCUS = 'ExploreSearchBarFocus';
export const EXPLORE_SELECT_FILTER = 'ExploreSelectFilter';
export const FAMILY_COPARENT_ACCEPT_INVITATION =
  'FamilyCoParentAcceptInvitation';
export const FAMILY_COPARENT_DEACTIVATE_INVITATION =
  'FamilyCoParentDeactivateInvitation';
export const FAMILY_COPARENT_REFUSES_INVITATION =
  'FamilyCoParentRefusesInvitation';
export const FAMILY_COPARENT_SELECT_INVITATION_BANNER =
  'FamilyCoParentSelectInvitationBanner';
export const FAMILY_COPARENT_VIEW_INVITATION_MODAL =
  'FamilyCoParentViewInvitationModal';
export const FAMILY_MASTER_DEACTIVATE_INVITATION =
  'FamilyPParentDeactivateInvite';
export const FAMILY_MASTER_DEACTIVATE_PENDING =
  'FamilyPParentDeactivatePendingInvite';
export const FAMILY_MASTER_SHARE_ACCOUNT = 'FamilyPParentShareAccount';
export const FAMILY_MASTER_SHARE_LINK = 'FamilyPParentShareLink';
export const FAMILY_PRIMARY_PARENT_INITIATE_SHARE =
  'FamilyPParentInitiateShare';
export const FAMILY_VIEW_INFO = 'ViewFamilyInfoModal';
export const LOG_LIVI_FROM_SCREEN = 'logLiviFromScreen';
export const MASTERCLASS_PLAY_TRACK = 'MasterclassPlayTrack';
export const MASTERCLASS_SELECT = 'MasterclassSelect';
export const MIDWIFE_SEND_MESSAGE = 'MidwifeSendMessage';
export const NOTIFICATIONS_SETTINGS_PRESS_ACTIVATE =
  'notificationsSettingsPressActivate';
export const NOTIFICATION_PERMISSION = 'notificationPermission';
export const ONBOARDING_STEP_ANSWER = 'OnboardingStepAnswer';
export const OPEN_LIVI = 'OpenLivi';
export const OPEN_PAYMENT_SHEET = 'OpenPaymentSheet';
export const PREGNANCY_CREATION = 'PregnancyCreation';
export const PREGNANCY_DELETE = 'PregnancyDelete';
export const PROGRAM_SELECT = 'ProgramSelect';
export const VIDEO_PLAY = 'VideoPlay';
export const REACTIVATE_SUBSCRIPTION = 'ReactivateSubscription';
export const SELECT_RECIPE = 'SelectRecipe';
export const RECIPES_SEARCH = 'RecipesSearch';
export const REVIEW_IN_APP_OPENED = 'ReviewInAppOpened';
export const SEARCH_FAQ_PREGNANCY = 'SearchFAQPregnancy';
export const SEARCH_FAQ_PURCHASE = 'searchFaqPurchase';
export const SEARCH_LIBRARY = 'SearchLibrary';
export const SELECT_DAILY_TIP = 'SelectDailyTip';
export const SELECT_FAQ_PREGNANCY_CONTENT = 'SelectFAQPregnancyContent';
export const SELECT_FAQ_PURCHASE_CONTENT = 'SelectFAQPurchaseContent';
export const SELECT_FEATURED_CONTENT = 'SelectFeaturedContent';
export const SELECT_ITEM = 'SelectItem';
export const SELECT_PLAN = 'SelectPlan';
export const SELECT_PREVIOUS_DAILY_TIP = 'SelectPreviousDailyTip';
export const SELECT_RECIPE_CONTENT = 'SelectRecipeContent';
export const SHARE_CONTENT = 'ShareContent';
export const SHARE_DAILY_TIP = 'ShareDailyTip';
export const SHARE_FAQ_PURCHASE = 'ShareFaqPurchase';
export const SHARE_FOLDER = 'ShareFolder';
export const SHARE_GUIDE = 'ShareGuide';
export const SHARE_MASTERCLASS = 'ShareMasterclass';
export const SHARE_SPONSORSHIP = 'ShareSponsorship';
export const UNSUBSCRIBE = 'Unsubscribe';
export const UNSUBSCRIBE_START = 'UnsubscribeStart';
export const UPGRADE_SUBSCRIPTION = 'UpgradeSubscription';
export const VIEW_3D_BABY = 'View3DBaby';
export const VIEW_CHILD_EVOLUTION = 'ViewChildEvolution';
export const VIEW_CHILD_MONTH_DETAILS = 'ViewChildMonthDetails';
export const VIEW_CONTENT = 'ViewContent';
export const VIEW_DAILY_TIP = 'ViewDailyTip';
export const VIEW_PAYMENT_SCREEN = 'ViewPaymentScreen';
export const VIEW_PREGNANCY_EVOLUTION = 'ViewPregnancyEvolution';
export const VIEW_PREGNANCY_WEEK_DETAILS = 'ViewPregnancyWeekDetails';
export const VIEW_SPONSORSHIP = 'ViewSponsorship';
export const VIEW_SUBSCRIPTION_MODAL = 'ViewSubscriptionModal';

// Home
export const WEEKLY_TIP = {
  VIEW: 'WeeklyTipView',
  SHARE: 'WeeklyTipShare',
} as const;

//Journaling
export const ADD_BELLY_PICTURE = 'AddBellyPicture';
export const SELECT_APPOINTMENTS = 'SelectAppointments';
export const SELECT_CALENDAR = 'SelectCalendar';
export const SELECT_CHILD_WEIGHT = 'SelectChildWeight';
export const SELECT_MY_BELLY = 'SelectMyBelly';
export const SELECT_PREGNANCY_WEIGHT = 'SelectPregnancyWeight';
export const SELECT_TODO_LIST = 'SelectTodoList';

export const CREATE_APPOINTMENTS = 'CreateAppointment';

export const ADD_INITIAL_PREGNANCY_WEIGHT = 'AddInitialPregnancyWeight';
export const ADD_PREGNANCY_WEIGHT = 'AddPregnancyWeight';

export const ADD_TODO_LIST = 'AddTodoList';
export const ADD_TODO_TASK = 'AddTodoTask';

export const ADD_CHILD_HEIGHT = 'AddChildHeight';
export const ADD_CHILD_WEIGHT = 'AddChildWeight';
export const ADD_CHILD_CRANIAL = 'AddChildCranial';

export const UNAVAILABLE_CHILD_HEIGHT = 'UnavailableChildHeight';
export const UNAVAILABLE_CHILD_WEIGHT = 'UnavailableChildWeight';
export const UNAVAILABLE_CHILD_CRANIAL = 'UnavailableChildCranial';

export const ADD_CHILD_SLEEP_SESSION = 'AddChildSleepSession';
export const SELECT_CHILD_SLEEP = 'SelectChildSleep';

export const ADD_CHILD_FEED_MEASURE = 'AddChildFeedMeasure';
export const SELECT_CHILD_FEED = 'SelectChildFeed';

export const SELECT_CONTRACTIONS = 'SelectContractions';
export const ADD_CONTRACTIONS = 'AddContractions';

export const SELECT_DIAPER = 'SelectDiaper';
export const ADD_DIAPER = 'AddDiaper';

// Cycle Tracking
export const CYCLE_TRACKING = {
  OPEN: 'OpenCycleTracking',
  START_ONBOARDING: 'StartCycleTrackingOnboarding',
  END_ONBOARDING_PREFERENCES: 'EndCycleTrackingOnboardingPreferences',
  END_ONBOARDING: 'EndCycleTrackingOnboarding',
  ADD_FIRST_CYCLE: 'AddFirstCycle',
  ADD_CYCLE: 'AddCycle',
  ADD_CYCLE_MENSTRUATION_DURATION: 'AddCycleMenstruationDuration',
  ADD_CYCLE_SYMPTOM: 'AddCycleSymptom',
  ADD_BASE_TEMPERATURE: 'AddBaseTemperature',
  EDIT_BASE_TEMPERATURE: 'EditBaseTemperature',
  USER_SELECTED_CONCEPTION_PURPOSE: 'userSelectedConceptionPurpose',
  USER_SELECTED_TRACKING_PURPOSE: 'userSelectedTrackingPurpose',
  USER_SELECTED_OBSERVATION_MODE: 'userSelectedObservationMode',
  USER_SELECTED_PREDICTION_MODE: 'userSelectedPredictionMode',
} as const;

export const UPLOAD_FILES = {
  SUCCESS: 'UploadSuccess',
  ERROR_UPLOADING: 'UploadError',
  ERROR_INITIALIZING: 'UploadErrorInitializing',
  RETRY: 'RetryUpload',
};
