import React, { useEffect } from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import RNAndroidKeyboardAdjust from 'rn-android-keyboard-adjust';

const styles = StyleSheet.create({
  container: { height: '100%' },
  contentContainerStyle: { flexGrow: 1 },
});

const KeyboardAvoidingSafeScrollView = (
  props: React.ComponentProps<typeof SafeAreaView> & {
    contentContainerStyle?: StyleProp<ViewStyle>;
  },
) => {
  useEffect(() => {
    if (Platform.OS === 'android') {
      RNAndroidKeyboardAdjust.setAdjustResize();
    }
    return () => {
      if (Platform.OS === 'android') {
        RNAndroidKeyboardAdjust.setAdjustPan();
      }
    };
  }, []);

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === 'android' ? 'height' : 'padding'}
    >
      <ScrollView
        style={styles.container}
        contentContainerStyle={[
          styles.contentContainerStyle,
          props.contentContainerStyle,
        ]}
        keyboardShouldPersistTaps="handled"
      >
        <SafeAreaView {...props} />
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default KeyboardAvoidingSafeScrollView;
