import merge from 'lodash/merge';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IdentificationData } from '@analytics/types';

import { resetAll } from './reduxReset';

const initialState: {
  identificationData: IdentificationData;
} = {
  identificationData: {},
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  initialState,
  reducers: {
    dispatchedIdentify: (
      state,
      { payload }: PayloadAction<IdentificationData & { id: string }>,
    ) => {
      if (payload.id !== state.identificationData.id) {
        state.identificationData = payload;
      } else {
        state.identificationData = merge(state.identificationData, payload);
      }
    },
    requestIdentification: (
      state,
      _action: PayloadAction<IdentificationData & { id: string }>,
    ) => state,
  },
});

export const analyticsCreators = analyticsSlice.actions;
export default analyticsSlice.reducer;
