import React from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';

import { BorderlessButton } from '@components/Buttons';
import { PressableOpacity } from '@components/PressableOpacity';
import { Colors, Fonts } from '@resources/themes';

import { StepHeader } from '../StepHeader';
import { ChoiceSubStep, ISubStepProps } from '../types';

const styles = StyleSheet.create({
  scrollViewContent: { gap: 16 },
  answerContainer: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderRadius: 10,
    alignItems: 'center',
    backgroundColor: Colors.grey300,
  },
  answer: {
    fontFamily: Fonts.type.semiBold,
    color: Colors.duck800,
    fontSize: Fonts.size.input,
    textAlign: 'center',
  },
  filler: { flex: 1 },
});

export const Choices = ({
  choices,
  onAnswerPress,
}: Pick<ChoiceSubStep, 'choices'> & Pick<ISubStepProps, 'onAnswerPress'>) => {
  return choices.map((answer, index) => (
    <PressableOpacity
      key={index}
      style={styles.answerContainer}
      onPress={() => onAnswerPress(answer)}
      testID={`possibleAnswer${index}`}
    >
      <Text style={styles.answer}>{answer.value}</Text>
    </PressableOpacity>
  ));
};

export function StepChoice({
  step: { title, description, choices, cancelLabel, cancelValue },
  onAnswerPress,
}: ISubStepProps<ChoiceSubStep>) {
  return (
    <>
      <StepHeader title={title} description={description} />
      <ScrollView
        contentContainerStyle={styles.scrollViewContent}
        showsVerticalScrollIndicator={false}
      >
        <Choices choices={choices} onAnswerPress={onAnswerPress} />
        {!!cancelLabel && !!cancelValue && (
          <>
            <View style={styles.filler} />
            <BorderlessButton
              isLoading={false}
              onPress={() => onAnswerPress(cancelValue)}
              testID="cancelAnswer"
              label={cancelLabel}
            />
          </>
        )}
      </ScrollView>
    </>
  );
}
