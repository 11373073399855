import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  SYMPTOMS: true,
  SEXUAL_RELATIONS: true,
  EMOTIONS: true,
  VAGINAL_DISCHARGE: true,
  TEST: true,
  MENSTRUATION_FLOW: true,
  PAIN: true,
};

const symptomsPreferenceSlice = createSlice({
  name: 'symptomsPreference',
  initialState,
  reducers: {
    setPreferences: (
      state,
      { payload }: PayloadAction<typeof initialState>,
    ) => {
      return payload;
    },
    reset: () => initialState,
  },
});

export const symptomsPreferenceCreators = symptomsPreferenceSlice.actions;
export default symptomsPreferenceSlice.reducer;
