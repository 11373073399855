import { axiosPost, axiosPut } from '@api-requests/api/main/request';
import { Pregnancy } from '@commonTypes/Pregnancy';

import {
  PregnancyCreate,
  PregnancyUpdate,
} from '@screens/Profile/Pregnancy/types';

const addPregnancy = (data: PregnancyCreate) =>
  axiosPost<Pregnancy>({ url: '/apifo/pregnancies', params: { data } });

const modifyPregnancy = (payload: { data: PregnancyUpdate; childId: number }) =>
  axiosPut<Pregnancy>({
    url: `/apifo/pregnancies/${payload.childId}`,
    params: { data: payload.data },
  });

export default {
  add: addPregnancy,
  edit: modifyPregnancy,
};
