import { Platform, StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  container: {
    padding: 24,
    flex: 1,
    gap: 16,
    ...Platform.select({
      web: { backgroundColor: Colors.white100 },
      default: {},
    }),
  },

  messageText: {
    color: Colors.pink700,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.title,
  },

  spacer: { flex: 1 },

  actions: { gap: 8 },
});
