import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  backIconContainer: { transform: [{ rotate: '-180deg' }] },

  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 25,
    paddingHorizontal: 25,
    width: '100%',
  },

  icon: {},

  nextTitle: {
    color: Colors.pink500,
    fontFamily: Fonts.type.medium,
    fontSize: Fonts.size.title,
    lineHeight: Fonts.size.title + 7,
    textTransform: 'uppercase',
  },

  nextTitleDisabled: {
    color: Colors.pink500 + Colors.transparent40,
  },

  title: {
    color: Colors.duck800,
    fontFamily: Fonts.type.Recoleta.medium,
    fontSize: Fonts.size.h3,
    lineHeight: Fonts.size.h3 + 3,
    marginBottom: 15,
  },

  titleContainer: {
    paddingHorizontal: 25,
    width: '100%',
  },
});
