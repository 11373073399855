import React, { useState } from 'react';
import { View, Text } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { PrimaryButton } from '@components/Buttons';
import DateButton from '@components/DateTimePicker/DateButton';
import { NavBar } from '@components/ui/NavBar';
import { t } from '@config';
import { navigate, pop } from '@navigation/Actions';
import {
  COMMON_SCREEN_STACK,
  JOURNALING_STACK,
  NAVIGATOR_NAMES,
} from '@navigation/Routes';
import { dayjsTZ, formatDate } from '@tools/date';

import { LoggedNavigatorParamList, ScreenRoute } from '@navigation/types';

import ComputeTermMethodBlock from './ComputeTermMethodBlock';
import styles from './styles';
import { useAddPregnancy, useUpdatePregnancy } from '../hooks/pregnancy';
import { METHOD } from '../types';
import {
  getMinimumPeriodDate,
  getMaximumPeriodDate,
  computeEndPregnancyFromPeriod,
  computePeriodFromEndPregnancy,
  getMinimumPregnancyStartDate,
  getMaximumPregnancyStartDate,
  computeEndPregnancyFromPregnancyStart,
  computePregnancyStartFromEndPregnancy,
} from '../utils/pregnancyCreationBoundaries';

const methodMap = {
  [METHOD.LAST_PERIOD]: {
    getMinimumDate: getMinimumPeriodDate,
    getMaximumDate: getMaximumPeriodDate,
    getInitial: computePeriodFromEndPregnancy,
    compute: computeEndPregnancyFromPeriod,
    inputLabel: t('PROFILE.PREGNANCY.COMPUTE.LAST_PERIOD').toLowerCase(),
  },
  [METHOD.PREGNANCY_START]: {
    getMinimumDate: getMinimumPregnancyStartDate,
    getMaximumDate: getMaximumPregnancyStartDate,
    getInitial: computePregnancyStartFromEndPregnancy,
    compute: computeEndPregnancyFromPregnancyStart,
    inputLabel: t('PROFILE.PREGNANCY.COMPUTE.PREGNANCY_START').toLowerCase(),
  },
};

const ComputeEndPregnancy = ({
  route: {
    params: { pregnancyId, expectedEnd, multiple, originScreen, targetScreen },
  },
}: ScreenRoute<
  LoggedNavigatorParamList,
  COMMON_SCREEN_STACK.COMPUTE_END_PREGNANCY
>) => {
  const [method, setMethod] = useState<
    METHOD.PREGNANCY_START | METHOD.LAST_PERIOD
  >(METHOD.LAST_PERIOD);
  const addPregnancy = useAddPregnancy(method, originScreen);
  const updatePregnancy = useUpdatePregnancy();

  const [inputDate, setInputDate] = useState(
    methodMap[method].getInitial({
      date: dayjsTZ(expectedEnd, null).toDate(),
      utc: null,
    }),
  );
  const [computedExpectedEnd, setComputedExpectedEnd] = useState<Date | null>(
    null,
  );
  const handleMethodChange = (
    newMethod: METHOD.PREGNANCY_START | METHOD.LAST_PERIOD,
  ) => {
    setMethod(newMethod);
    setInputDate(
      methodMap[newMethod].getInitial({
        date: dayjsTZ(expectedEnd, null).toDate(),
        utc: null,
      }),
    );
    setComputedExpectedEnd(null);
  };
  const handleDateChange = (selectedDate: Date | string | undefined) => {
    if (!selectedDate) {
      return;
    }
    const newDate = dayjsTZ(selectedDate, 'utc', true).toDate();
    if (
      newDate >= methodMap[method].getMinimumDate() &&
      newDate <= methodMap[method].getMaximumDate()
    ) {
      setInputDate(newDate);
      setComputedExpectedEnd(methodMap[method].compute(newDate));
    }
  };

  const handleSubmit = () => {
    if (!computedExpectedEnd) {
      return;
    }
    const payload = {
      multiple,
      born: false,
      interrupted: false,
      expectedEnd: formatDate(computedExpectedEnd, 'YYYY-MM-DD'),
    };
    if (!pregnancyId) {
      addPregnancy.mutate(payload, {
        onSuccess: () => {
          switch (targetScreen) {
            case JOURNALING_STACK.CONTRACTION_SCREEN:
            case JOURNALING_STACK.MY_WEIGHT_SCREEN:
              pop(2);
              navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
                screen: targetScreen,
              });
              break;
            default:
              pop(2);
              break;
          }
        },
      });
    } else {
      updatePregnancy.mutate(
        { childId: pregnancyId, data: payload },
        {
          onSuccess: () => {
            pop(2);
          },
        },
      );
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <NavBar title={t('PROFILE.PREGNANCY.COMPUTE.TITLE')} />
      <View style={styles.computeMethod}>
        <Text style={styles.subtitle}>
          {t('PROFILE.PREGNANCY.COMPUTE.METHOD')}
        </Text>
        <ComputeTermMethodBlock
          method={method}
          setMethod={handleMethodChange}
        />
      </View>
      <View style={styles.input}>
        <Text style={styles.dateLabel}>
          {t('PROFILE.PREGNANCY.COMPUTE.INPUT', {
            method: methodMap[method].inputLabel,
          })}
        </Text>
        <DateButton
          value={inputDate}
          onChange={handleDateChange}
          minimumDate={methodMap[method].getMinimumDate({ utc: null })}
          maximumDate={methodMap[method].getMaximumDate({ utc: null })}
        />
      </View>
      {computedExpectedEnd && (
        <View style={styles.result}>
          <Text style={styles.subtitle}>
            {t('PROFILE.PREGNANCY.COMPUTE.RESULT')}
          </Text>
          <Text style={styles.termDate}>
            {formatDate(computedExpectedEnd, 'DD MMMM YYYY')}
          </Text>
        </View>
      )}
      <View style={styles.spacer} />
      <PrimaryButton
        disabled={!computedExpectedEnd}
        isLoading={updatePregnancy.isPending || addPregnancy.isPending}
        onPress={handleSubmit}
        label={t('FORM.ADD')}
      />
      <Text style={styles.secureText}>{t('PROFILE.LEGAL.SECURED_DATA')}</Text>
    </SafeAreaView>
  );
};

export default ComputeEndPregnancy;
