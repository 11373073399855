import { Platform, StyleSheet } from 'react-native';

import { Fonts } from '@resources/themes';
import Colors from '@resources/themes/Colors';

export default StyleSheet.create({
  container: {
    padding: 6,
    flexDirection: 'row',
    gap: 12,
    ...Platform.select({
      web: { backgroundColor: Colors.grey300 },
      default: { backgroundColor: Colors.white100 },
    }),
    borderRadius: 10,
  },
  block: {
    paddingVertical: 8,
    paddingHorizontal: 4,
    flex: 1,
    gap: 8,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
  },
  selected: { backgroundColor: Colors.duck300 },
  label: {
    color: Colors.duck800,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.medium,
    textAlign: 'center',
  },
  imageIcon: {
    width: 24,
    height: 24,
  },
});
