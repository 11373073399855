import { COMMON_SCREEN_STACK, TAB_NAMES } from '@navigation/Routes';

export const rootTabs: string[] = [
  TAB_NAMES.HOME,
  TAB_NAMES.CHAT,
  TAB_NAMES.JOURNALING,
  TAB_NAMES.EXPLORE,
];
export const displayMiniPlayerScreens = [
  ...rootTabs,
  COMMON_SCREEN_STACK.MASTERCLASS_SCREEN,
];
