import React from 'react';
import { StyleProp, Text, View, ViewStyle } from 'react-native';

import AppIcon from '@components/ui/Icon';
import { PressableNative } from '@components/ui/Pressables/PressableNative';
import { Colors } from '@resources/themes';

import styles from './styles';

export interface CheckBoxProps {
  style?: StyleProp<ViewStyle>;
  value: boolean;
  onChange: (newValue: boolean) => void;
  isError?: boolean;
  children: string | React.ReactNode;
}
function CheckBox({
  style,
  value,
  onChange,
  isError,
  children,
}: CheckBoxProps) {
  return (
    <View style={[styles.container, style]}>
      <PressableNative
        style={[styles.checkBox, isError && styles.checkBoxError]}
        onPress={() => onChange(!value)}
        testID="checkbox"
      >
        {value && (
          <AppIcon name={'CheckmarkSlim'} color={Colors.white100} size={20} />
        )}
      </PressableNative>
      <Text style={[styles.label, isError && styles.labelError]}>
        {children}
      </Text>
    </View>
  );
}

export default CheckBox;
