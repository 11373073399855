export interface ProductRaw {
  planId: string;
  productId: string;
  planNickname: string;
  amount: number;
  intervalCount: number;
  interval: PRODUCT_INTERVAL;
  tier: PRODUCT_TIER;
  withTrial: boolean;
}

export enum PRODUCT_INTERVAL {
  MONTH = 'month',
  YEAR = 'year',
}
export enum PRODUCT_TIER {
  PREMIUM = 'premium',
  ESSENTIAL = 'essential',
}

export interface Product extends ProductRaw {
  title: string;
  default: boolean;
}
