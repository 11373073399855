import React from 'react';
import { StyleSheet, Text, ScrollView, Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { Markdown } from '@components';
import { NavBar } from '@components/ui/NavBar';
import { NavBarPlaceholder } from '@components/ui/NavBar/NavBarPlaceholder';
import { t } from '@config';
import { ANONYMOUS_STACK, COMMON_SCREEN_STACK } from '@navigation/Routes';
import { Colors, Fonts } from '@resources/themes';
import { formatDate } from '@tools/date';

import {
  AnonymousStackParamList,
  LoggedNavigatorParamList,
  ScreenRoute,
} from '@navigation/types';

import CguPlaceholder from './components/CguPlaceholder';
import { useStaticContent } from './hooks/useStaticContent';

const markdownOverriddenStyles = StyleSheet.create({
  link: {
    color: Colors.pink400,
    fontFamily: Fonts.type.italic,
    textDecorationLine: 'underline',
  },
});

const styles = StyleSheet.create({
  container: {
    padding: 24,
    ...Platform.select({
      web: { backgroundColor: Colors.white100 },
      default: {},
    }),
  },
  date: {
    marginTop: 8,
    color: Colors.pink500,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.medium,
  },
});

type StaticContentsRoute =
  | ScreenRoute<
      LoggedNavigatorParamList,
      COMMON_SCREEN_STACK.STATIC_CONTENTS_SCREEN
    >
  | ScreenRoute<
      AnonymousStackParamList,
      ANONYMOUS_STACK.STATIC_CONTENTS_SCREEN
    >;
const StaticContentsScreen = ({
  route: {
    params: { slug },
  },
}: StaticContentsRoute) => {
  const { data, isLoading } = useStaticContent(slug);

  if (isLoading || !data?.title || !data?.content) {
    return (
      <SafeAreaView style={styles.container}>
        <NavBarPlaceholder />
        <CguPlaceholder />
      </SafeAreaView>
    );
  }

  return (
    <ScrollView style={styles.container}>
      <SafeAreaView>
        <NavBar title={data.title} />
        {data.lastUpdate && (
          <Text style={styles.date}>
            {t('SETTINGS.CGU_CGV.CGU.NAV_TITLE', {
              date: formatDate(data.lastUpdate, 'DD/MM/YYYY'),
            })}
          </Text>
        )}
        <Markdown
          content={data.content}
          overriddenStyles={markdownOverriddenStyles}
        />
      </SafeAreaView>
    </ScrollView>
  );
};

export default StaticContentsScreen;
