import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  container: {
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.duck300,
    borderRadius: 10,
  },

  textIcon: { marginHorizontal: 12, fontSize: Fonts.size.h3 },

  text: { marginRight: 10, flex: 1 },
  firstRow: { flexDirection: 'row', alignItems: 'center' },
  title: {
    flex: 1,
    fontFamily: Fonts.type.semiBold,
    fontSize: Fonts.size.medium,
    color: Colors.duck800,
  },
  message: {
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.medium,
    color: Colors.duck700,
  },
});
