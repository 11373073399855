export type Scenarios = 'onboarding-v1' | 'onboarding-v2';

export interface Tokens {
  jwt?: string;
  refreshToken?: string;
  jwtExpiresIn: number;
  refreshTokenExpiresIn: number;
  jwtExp: number;
}

export enum OpenidProviders {
  GOOGLE = 'google',
}

export type AuthProviders = OpenidProviders | 'apple' | 'may';
