import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';

import { t } from '@config';
import DEVICE from '@resources/constants/device';
import DoctorImg from '@resources/images/illu_docteur-seule-chatain.webp';
import { Colors, Fonts } from '@resources/themes';

const IMAGE_SIZE = DEVICE.WINDOW_WIDTH * 0.5;
const styles = StyleSheet.create({
  container: { gap: 24 },
  imageContainer: {
    alignSelf: 'center',
    width: IMAGE_SIZE,
    aspectRatio: 1,
  },
  image: { height: '100%', width: '100%' },
  emptyStateLabel: {
    color: Colors.duck700,
    fontSize: Fonts.size.title,
    fontFamily: Fonts.type.regular,
    textAlign: 'center',
  },
});

export function FamilyOverviewEmptyState() {
  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image style={styles.image} source={DoctorImg} resizeMode={'contain'} />
      </View>
      <Text style={styles.emptyStateLabel}>
        {t('ADD_KID.ONBOARDING.EMPTY_STATE.TEXT')}
      </Text>
    </View>
  );
}
