import { remove as removeDiacritics } from 'diacritics';
import * as SecureStore from 'expo-secure-store';
import { NativeModules } from 'react-native';

import * as Sentry from '@tools/sentry';

const eciesModule = NativeModules.ECIES;

const DEVICE_ID = 'DEVICE_ID';
const deviceIdKey = (email: string) =>
  `${DEVICE_ID}-${removeDiacritics(email)
    .replace('@', '--')
    .replace('+', '__')
    .replace(new RegExp('[^\\w_\\-\\.]+', 'g'), '')}`;
const eciesKey = (deviceId: string | null) => `ECIES-${deviceId}`;

export default class ECIES {
  private deviceId: string;
  private eciesDescription: string;
  private email: string;

  private constructor(
    deviceId: string,
    eciesDescription: string,
    email: string,
  ) {
    this.deviceId = deviceId;
    this.eciesDescription = eciesDescription;
    this.email = email;
  }
  static getDeviceId = async (email: string): Promise<string> => {
    if (!email) {
      return '';
    }
    let deviceId: string | null = null;
    try {
      try {
        deviceId = await SecureStore.getItemAsync(deviceIdKey(email));
      } catch (err: any) {
        try {
          await SecureStore.deleteItemAsync(deviceIdKey(email));
        } catch (_) {}
      }
      let desc: string | null = null;
      try {
        desc = await SecureStore.getItemAsync(eciesKey(deviceId));
      } catch (err: any) {
        try {
          await SecureStore.deleteItemAsync(eciesKey(deviceId));
        } catch (_) {}
      }

      return deviceId && desc ? deviceId : '';
    } catch (err: any) {
      Sentry.captureException(err);
      return '';
    }
  };

  static getDeviceECIES = async (
    deviceId: string,
    email: string,
  ): Promise<ECIES | null> => {
    if (!deviceId) {
      return null;
    }
    let storedData: string | null = null;
    try {
      storedData = await SecureStore.getItemAsync(eciesKey(deviceId));
    } catch (err: any) {}
    if (storedData) {
      return new ECIES(deviceId, storedData, email);
    }

    const eciesDescription = await eciesModule.generateKey(deviceId);
    if (eciesDescription) {
      return new ECIES(deviceId, eciesDescription, email);
    }

    return null;
  };

  public save = (): Promise<void> => {
    SecureStore.setItemAsync(deviceIdKey(this.email), this.deviceId);
    return SecureStore.setItemAsync(
      `ECIES-${this.deviceId}`,
      this.eciesDescription,
    );
  };

  public getPublicKey = (): Promise<string> =>
    eciesModule.getPublicKey(this.eciesDescription);

  public decryptBufferEcies = (encryptedMessage: string): Promise<string> =>
    eciesModule.decryptBufferEcies(this.eciesDescription, encryptedMessage);

  public delete = async (): Promise<void> => {
    eciesModule.delete(this.eciesDescription);
    await SecureStore.deleteItemAsync(deviceIdKey(this.email));
    return SecureStore.deleteItemAsync(eciesKey(this.deviceId));
  };
}
