import React from 'react';
import { Image, StyleProp, StyleSheet, ViewStyle } from 'react-native';

import Guide from '@commonTypes/apiResponses/Guide';
import { Trackers } from '@commonTypes/apiResponses/Trackers';
import { useSelector } from '@commonTypes/redux';
import { FavoritesButton } from '@components/FavoritesButton';
import SubscribedPill from '@components/Pills/SubscribedPill';
import { ReadMarker } from '@components/Pills/ViewMarker';
import { t } from '@config/bootstrap/i18n';
import { navigate } from '@navigation/Actions';
import { COMMON_SCREEN_STACK } from '@navigation/Routes';
import ACCESS from '@resources/constants/access';
import { Colors } from '@resources/themes';
import { getImageAuthorization } from '@tools/getImageAuthorization';

import ContentCardUI from '../ContentCardUI';
import ReadingTime from '../Fields/ReadingTime';

const styles = StyleSheet.create({ cover: StyleSheet.absoluteFillObject });

interface GuideUIProps {
  style?: StyleProp<ViewStyle>;
  title: string;
  highlightedTitle: string | undefined;
  uri?: string;
  readingTime: string | undefined | null;
  onPress: () => void;
  read: boolean;
  premium: boolean;
  disabled?: boolean;
  favoriteButton?: React.ReactElement | null;
}
export const GuideUi = ({
  uri,
  readingTime,
  read,
  premium,
  ...props
}: GuideUIProps) => {
  const contentColor = read ? Colors.grey600 : Colors.duck800;
  const token = useSelector((state) => state.auth.token);
  const Cover = (
    <>
      <Image
        style={styles.cover}
        source={{ uri, headers: getImageAuthorization(uri, token) }}
      />
      {read && <ReadMarker />}
      {premium && <SubscribedPill small />}
    </>
  );
  const Field = readingTime && (
    <ReadingTime readingTime={readingTime} contentColor={contentColor} />
  );
  return (
    <ContentCardUI
      {...props}
      label={t('COMMON.GUIDE')}
      contentColor={contentColor}
      imageSection={Cover}
      fields={Field}
    />
  );
};

interface GuideCardProps {
  style: StyleProp<ViewStyle>;
  item: Guide;
  trackers?: Trackers;
  screen: string;
  disabled?: boolean;
  toggleAllContentPremium?: boolean;
  toggleAllContentFree?: boolean;
}
const GuideCard = ({
  item,
  trackers,
  screen,
  toggleAllContentPremium,
  toggleAllContentFree,
  ...props
}: GuideCardProps) => {
  const handlePress = () =>
    navigate(COMMON_SCREEN_STACK.GUIDE_SLIDER, {
      guide: item,
      slug: undefined,
    });
  return (
    <GuideUi
      {...props}
      title={item.title}
      highlightedTitle={item.highlightedTitle}
      uri={item.cover}
      readingTime={item.readingTime}
      onPress={handlePress}
      read={!!trackers?.guides?.[item.id]}
      premium={
        !toggleAllContentFree &&
        (item.access === ACCESS.PREMIUM || !!toggleAllContentPremium)
      }
      favoriteButton={
        <FavoritesButton type={'guides'} id={`${item.id}`} item={item} />
      }
    />
  );
};

export default GuideCard;
