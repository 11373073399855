import React, { useEffect } from 'react';
import { Image, Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { setAnalyticsUserProperties } from '@analytics/common.web';
import { BorderlessButton, PrimaryButton } from '@components/Buttons';
import { t } from '@config';
import { navigate } from '@navigation/Actions';
import { ANONYMOUS_STACK } from '@navigation/Routes';
import DoctorImg from '@resources/images/illu_docteur-seule-chatain.webp';
import LogoImg from '@resources/images/logo.webp';
import { WEB_ANONYMOUS_STACK } from '@webNavigation/routes';

import styles from './styles';

const goToLogin = () => navigate(ANONYMOUS_STACK.LOGIN);

const goToPreOnboarding = () =>
  navigate(WEB_ANONYMOUS_STACK.PRE_ONBOARDING_SCREEN);

const IntroScreen = () => {
  useEffect(() => {
    const url = new URL(window.location.href);
    const distinctId = url.searchParams.get('distinct_id');
    if (distinctId) {
      setAnalyticsUserProperties({ id: distinctId });
    }
  }, []);

  return (
    <SafeAreaView style={styles.container}>
      <Image source={LogoImg} style={styles.logoImg} />
      <View style={styles.imageContainer}>
        <Image style={styles.image} source={DoctorImg} resizeMode="contain" />
      </View>
      <Text style={styles.primaryText}>{t('AUTH.INTRO.TITLE')}</Text>
      <PrimaryButton
        onPress={goToPreOnboarding}
        label={t('AUTH.INTRO.BUTTON')}
        isLoading={false}
      />
      <BorderlessButton
        style={styles.secondaryButton}
        onPress={goToLogin}
        label={t('AUTH.INTRO.SUBSCRIPTION')}
        isLoading={false}
      />
    </SafeAreaView>
  );
};

export default IntroScreen;
