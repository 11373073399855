import { useSelector } from '@commonTypes/redux';
import useAuthenticatedQuery from '@hooks/useAuthenticatedQuery';

import { products } from './endPoint';
import { formatProductList } from './utils/formatProduct';

export const PRODUCTS_QUERY_KEYS = {
  ALL: ['products'] as const,
  GET_ALL: (paidPlan: boolean) =>
    [...PRODUCTS_QUERY_KEYS.ALL, 'get', 'all', paidPlan] as const,
};

export const useProductList = (options: { enabled?: boolean } = {}) => {
  const paidPlan = useSelector((state) => !!state.user.data.paidPlan);
  return useAuthenticatedQuery({
    queryFn: products.getAll,
    queryKey: PRODUCTS_QUERY_KEYS.GET_ALL(paidPlan),
    select: (data) => formatProductList(data, paidPlan),
    staleTime: 0,
    gcTime: 0,
    ...options,
  });
};
