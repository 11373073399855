import React, { useState } from 'react';
import { Platform, Pressable, StyleSheet, View } from 'react-native';

import { useDispatch, useSelector } from '@commonTypes/redux';
import { SignUpWithApple } from '@components/AuthButtons/SignupWithApple';
import { SignupWithGoogle } from '@components/AuthButtons/SignupWithGoogle';
import { SignUpWithMay } from '@components/AuthButtons/SignUpWithMay';
import { signupFormCreator } from '@screens/Auth/SignUp/redux/signupInfos';
import { remoteConfig } from '@tools/firebase';

import CheckBoxLegal from './CheckBoxLegal';
import CheckBoxNewsletter from './CheckBoxNewsletter';
import LegalText from './LegalText';
import BottomSheetModal from '../BottomSheetModal';

const styles = StyleSheet.create({
  modal: { paddingBottom: 24, paddingHorizontal: 24, gap: 16 },
  actions: { gap: 12 },
});

const AuthProviderModal = ({
  isVisible,
  onClose,
}: {
  isVisible: boolean;
  onClose: () => void;
}) => {
  const loginWithApple =
    remoteConfig().getValue('loginWithApple').asBoolean() &&
    Platform.OS === 'ios';
  const loginWithGoogle = remoteConfig()
    .getValue('loginWithGoogle')
    .asBoolean();

  const dispatch = useDispatch();
  const [acceptedEULA, setAcceptedEULA] = useState(false);
  const [EULAError, setEULAError] = useState(false);
  const acceptedNewsletter = useSelector(
    (state) => state.signupForm.newsletterAgreed,
  );

  return (
    <BottomSheetModal
      isVisible={isVisible}
      onClose={onClose}
      style={styles.modal}
    >
      <View style={styles.actions}>
        <CheckBoxLegal
          value={acceptedEULA}
          onChange={setAcceptedEULA}
          isError={EULAError && !acceptedEULA}
          onActionPress={onClose}
        />
        <CheckBoxNewsletter
          value={acceptedNewsletter}
          onChange={(v) => {
            dispatch(signupFormCreator.addFormNewsletter(v));
          }}
        />
      </View>
      <Pressable onPress={() => setEULAError(true)} disabled={acceptedEULA}>
        <View
          style={styles.actions}
          pointerEvents={acceptedEULA ? 'auto' : 'none'}
        >
          {loginWithGoogle && <SignupWithGoogle onClose={onClose} />}
          {loginWithApple && <SignUpWithApple />}
          <SignUpWithMay onPress={onClose} />
        </View>
      </Pressable>
      <LegalText onActionPress={onClose} />
    </BottomSheetModal>
  );
};

export default AuthProviderModal;
