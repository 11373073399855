import { resetAll } from '@redux/reduxReset';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContractionSession } from '@api-requests/api/main/contractions-tracker/types';

const THRESHOLD = 1000 * 60 * 60; // 1 hour

type LastContraction = {
  timestamp: number | null;
  contractionSession: ContractionSession | null;
};
const initialState: LastContraction = {
  timestamp: null,
  contractionSession: null,
};

const lastContractionSlice = createSlice({
  name: 'lastContraction',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    saveSession: (state, action: PayloadAction<ContractionSession>) => {
      state.contractionSession = action.payload;
      state.timestamp = Date.now();
    },
    checkSession: (state) => {
      if (!state.contractionSession || !state.timestamp) {
        return initialState;
      }

      if (Date.now() - state.timestamp > THRESHOLD) {
        return initialState;
      }
      return state;
    },
    reset: () => initialState,
  },
});

export const lastContractionCreators = lastContractionSlice.actions;
export default lastContractionSlice.reducer;
