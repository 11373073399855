import { axiosGet, axiosPost, axiosPut } from '@api-requests/api/cms/request';
import { Trackers } from '@commonTypes/apiResponses/Trackers';

import { TrackInfos } from '@tools/updateTrackersData/types';

const getAllTracked = () => axiosGet<Trackers>('/trackers');

const addTrackDailyTips = (data: { daily_tip: number }) =>
  axiosPost('/daily-tip-trackers', { data });

const addTrackPregnancyWeekContent = (data: { pregnancyWeekContent: number }) =>
  axiosPost('/pregnancy-week-content-trackers', { data });
// Actually returns an array of the whole PregnancyProgression object
const getTrackPregnancyWeekContent = () =>
  axiosGet<{ id: number }[]>('/pregnancy-week-content-trackers');

const addTrackPost = (data: { article: number }) =>
  axiosPost('/post-trackers', { data });

const addTrackGuide = (data: { guide: number }) =>
  axiosPost('/guide-trackers', { data });

const addTrackMusic = (data: Omit<TrackInfos, 'updated_at'>) =>
  axiosPost('/track-trackers', { data });
const modifyTrackMusic = (data: Omit<TrackInfos, 'updated_at'>) =>
  axiosPut('/track-trackers', { data });

export default {
  getAll: getAllTracked,
  dailyTips: { add: addTrackDailyTips },
  pregnancy: {
    add: addTrackPregnancyWeekContent,
    get: getTrackPregnancyWeekContent,
  },
  post: { add: addTrackPost },
  guide: { add: addTrackGuide },
  masterclassTrack: { add: addTrackMusic, change: modifyTrackMusic },
};
