import React, { useState } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { useNotificationPermissionScenario } from '@api-requests/api/cms/queries/useNotificationPermissionScenario';
import { useRemoteOnboardingScenarios } from '@api-requests/api/cms/scenarii/onboarding-scenarios/hooks';
import { AddFamilyMemberBottomSheet } from '@components/AddFamilyMemberBottomSheet';
import { BorderlessButton, PrimaryButton } from '@components/Buttons';
import FamilyMembersList from '@components/FamilyMembersList';
import { t } from '@config';
import useCheckIfHasKid from '@hooks/useCheckIfHasKid';
import useCheckIfPregnant from '@hooks/useCheckIfPregnant';
import { useDisableBackButton } from '@hooks/useDisableBackButton';
import { hasNotificationPermission } from '@hooks/useNotifications';
import { navigate } from '@navigation/Actions';
import { NAVIGATOR_NAMES, ONBOARDING_STACK } from '@navigation/Routes';
import DEVICE from '@resources/constants/device';

import DismissibleInfo from './components/DismissibleInfo';
import { FamilyOverviewEmptyState } from './components/FamilyOverviewEmptyState';
import SkipStepModal from './components/SkipStepModal';
import styles from './styles';
import { handleRemoteFormNavigation } from '../RemoteFormScreens/handleRemoteFormNavigation';

const FamilyOverview = () => {
  useDisableBackButton();
  const hasKids = useCheckIfHasKid();
  const isPregnant = useCheckIfPregnant();
  const hasFamilyMembers = hasKids || isPregnant;

  const [showAddFamilyMemberBottomSheet, setShowAddFamilyMemberBottomSheet] =
    useState(false);

  const { data: workflow } = useRemoteOnboardingScenarios();

  const [showSkipStepModal, setShowSkipStepModal] = useState(false);

  const notificationPermissionScenario = useNotificationPermissionScenario();
  const handlePressNext = async () => {
    // Show notification permission screen on ios & android 13+ only.
    // Before Android 13+, notification consent was opt-in
    const notificationsEnabled = await hasNotificationPermission();
    if (
      (DEVICE.IS_IOS || DEVICE.IS_ANDROID_13_OR_UPPER) &&
      !notificationPermissionScenario.isError &&
      !notificationsEnabled
    ) {
      navigate(NAVIGATOR_NAMES.ONBOARDING_NAVIGATOR, {
        screen: ONBOARDING_STACK.NOTIFICATION_PERMISSION_SCREEN,
        params: { skipOnboardingWorkflow: false },
      });
    } else {
      handleRemoteFormNavigation({ workflow });
    }
  };

  const handleLaterPress = async () => {
    setShowSkipStepModal(false);
    await handlePressNext();
  };

  return (
    <>
      <ScrollView
        style={styles.screen}
        contentContainerStyle={styles.scrollView}
      >
        <SafeAreaView style={styles.container}>
          {hasFamilyMembers && <DismissibleInfo />}
          <Text style={styles.heading}>
            {t('ADD_KID.BLOCK.NAV_FAMILY_TITLE')}
          </Text>
          {hasFamilyMembers ? (
            <>
              <FamilyMembersList
                onAddPress={() => setShowAddFamilyMemberBottomSheet(true)}
              />
              <View style={styles.spacer} />
              <View style={styles.actionsContainer}>
                <PrimaryButton
                  onPress={handlePressNext}
                  label={t('ADD_KID.BUTTONS.NEXT')}
                  isLoading={false}
                />
              </View>
            </>
          ) : (
            <>
              <FamilyOverviewEmptyState />
              <View style={styles.spacer} />
              <View style={styles.actionsContainer}>
                <PrimaryButton
                  onPress={() => setShowAddFamilyMemberBottomSheet(true)}
                  label={t('ADD_KID.ONBOARDING.EMPTY_STATE.CTA')}
                  isLoading={false}
                />
                <BorderlessButton
                  label={t('ADD_KID.ONBOARDING.EMPTY_STATE.LATER')}
                  onPress={() => setShowSkipStepModal(true)}
                  isLoading={false}
                />
              </View>
            </>
          )}
          <Text style={styles.legal}>{t('PROFILE.LEGAL.SECURED_DATA')}</Text>
        </SafeAreaView>
      </ScrollView>
      <SkipStepModal
        isVisible={showSkipStepModal}
        onPress={() => {
          setShowSkipStepModal(false);
          setShowAddFamilyMemberBottomSheet(true);
        }}
        onLaterPress={handleLaterPress}
        onClose={() => setShowSkipStepModal(false)}
      />
      <AddFamilyMemberBottomSheet
        isVisible={showAddFamilyMemberBottomSheet}
        onClose={() => setShowAddFamilyMemberBottomSheet(false)}
      />
    </>
  );
};

export default FamilyOverview;
