import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Text, View } from 'react-native';
import { Platform } from 'react-native';

import { logLogin } from '@analytics/events';
import { storeCredentials } from '@api-requests/api/common';
import { useDispatch } from '@commonTypes/redux';
import { LoginWithApple } from '@components/AuthButtons/LoginWithApple';
import { LoginWithGoogle } from '@components/AuthButtons/LoginWithGoogle';
import { BorderlessButton, PrimaryButton } from '@components/Buttons';
import KeyboardAvoidingSafeScrollView from '@components/KeyboardAvoidingSafeScrollView';
import AuthProviderModal from '@components/Modal/AuthProvider';
import { PressableOpacity } from '@components/PressableOpacity';
import { useNextOnSubmit } from '@components/ui/hookFormInputs/hooks';
import { TextInput } from '@components/ui/hookFormInputs/TextInput';
import { t } from '@config';
import { navigate, navigateWithKeyboard } from '@navigation/Actions';
import { ANONYMOUS_STACK } from '@navigation/Routes';
import { AuthCreators } from '@redux/auth';
import { hasLoggedOnceCreators } from '@redux/has-logged-once';
import { userCreators } from '@redux/user';
import { hitSlop } from '@resources/constants/hitSlop';
import { remoteConfig } from '@tools/firebase';
import { setSentryUser } from '@tools/sentry';

import styles from '@screens/Auth/LoginScreen/styles';

import { Separator } from './components/Separator';
import { useLogin } from './hooks';
import { LoginForm } from './types';
import { signupFormCreator } from '../SignUp/redux/signupInfos';

const goToForgotPassword = () => navigate(ANONYMOUS_STACK.FORGOT_PASSWORD);

const LoginScreen = () => {
  const dispatch = useDispatch();

  const { mutateAsync: login } = useLogin();
  const [displaySignupModal, setDisplaySignupModal] = useState(false);

  const loginWithApple =
    remoteConfig().getValue('loginWithApple').asBoolean() &&
    Platform.OS === 'ios';
  const loginWithGoogle = remoteConfig()
    .getValue('loginWithGoogle')
    .asBoolean();

  const { control, formState, handleSubmit, reset, setError } =
    useForm<LoginForm>({ mode: 'onTouched' });
  const handleSubmitPressed = handleSubmit(async (data) => {
    const payload = { ...data, email: data.email.trim().toLowerCase() };
    try {
      const { data: result } = await login(payload);
      if (result.id) {
        setSentryUser(result.id);
      }
      reset();
      dispatch(hasLoggedOnceCreators.setValue(true));
      logLogin({ method: 'email' });
      storeCredentials(payload);
      dispatch(userCreators.userSuccess(result));
      dispatch(AuthCreators.authLogin(result));
      dispatch(signupFormCreator.addFormPassword(payload.password));
      dispatch(signupFormCreator.addFormEmail(payload.email));
      dispatch(signupFormCreator.addFormPhone(result.phone));
      navigateWithKeyboard(ANONYMOUS_STACK.VERIFICATION_CODE_SCREEN);
    } catch (err: any) {
      reset(payload);
      setError('password', {
        type: 'server',
        message:
          err?.status === 429
            ? t('FORM.ERRORS.RATE_LIMIT')
            : t('FORM.ERRORS.LOGIN.PASSWORD'),
      });
    }
  });

  const { isValid, isSubmitting } = formState;
  const nextSubmitData = useNextOnSubmit(
    2,
    isSubmitting ? () => null : handleSubmitPressed,
  );

  return (
    <>
      <KeyboardAvoidingSafeScrollView style={styles.container}>
        <Text style={styles.heading}>{t('AUTH.LOGIN.TITLE')}</Text>
        {(loginWithGoogle || loginWithApple) && (
          <View style={styles.altAuth}>
            {loginWithGoogle && <LoginWithGoogle />}
            {loginWithApple && <LoginWithApple />}
          </View>
        )}
        <Separator />
        <View style={styles.form}>
          <TextInput
            control={control}
            name="email"
            label={t('FORM.EMAIL')}
            placeholder={t('FORM.EMAIL_PLACEHOLDER')}
            rules={{
              required: true,
              pattern: {
                value: /\s*\S+@\S+\.\S+\s*/,
                message: 'Entered value does not match email format',
              },
            }}
            testID="emailInput"
            errorMessage={(fieldError) =>
              fieldError ? t(`FORM.ERRORS.LOGIN.EMAIL.${fieldError.type}`) : ''
            }
            inputMode="email"
            textContentType="emailAddress"
            {...nextSubmitData[0]}
          />
          <TextInput
            control={control}
            name="password"
            label={t('FORM.PASSWORD')}
            placeholder={t('FORM.PASSWORD_PLACEHOLDER')}
            rules={{ required: true }}
            textContentType="oneTimeCode"
            testID="passwordInput"
            errorMessage={(fieldError) =>
              fieldError
                ? fieldError.type === 'server' && fieldError.message
                  ? fieldError.message
                  : t('FORM.ERRORS.LOGIN.PASSWORD')
                : ''
            }
            secureTextEntry
            {...nextSubmitData[1]}
          />
        </View>
        <View style={styles.filler} />
        <View style={styles.actions}>
          <PressableOpacity
            onPress={goToForgotPassword}
            style={styles.forgotPasswordView}
            hitSlop={hitSlop.medium}
          >
            <Text style={styles.forgotPasswordText}>
              {t('AUTH.LOGIN.FORGOT_PASSWORD')}
            </Text>
          </PressableOpacity>
          <PrimaryButton
            disabled={!isValid || isSubmitting}
            isLoading={isSubmitting}
            onPress={handleSubmitPressed}
            testID="loginButton"
            label={t('AUTH.LOGIN.BUTTON')}
          />
          <BorderlessButton
            onPress={() => setDisplaySignupModal(true)}
            label={t('AUTH.LOGIN.REGISTER')}
            isLoading={false}
          />
        </View>
      </KeyboardAvoidingSafeScrollView>
      <AuthProviderModal
        isVisible={displaySignupModal}
        onClose={() => setDisplaySignupModal(false)}
      />
    </>
  );
};

export default LoginScreen;
