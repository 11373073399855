import { OpenidProviders } from '@commonTypes/Auth';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { resetAll } from './reduxReset';

export type OpenIdData = {
  codeVerifier: string;
  provider: OpenidProviders | null;
};

const initialState: OpenIdData = {
  codeVerifier: '',
  provider: null,
};

const openIdSlice = createSlice({
  name: 'openId',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    prepareRedirection: (
      state,
      {
        payload,
      }: PayloadAction<{
        codeVerifier: string;
        provider: OpenidProviders;
      }>,
    ) => {
      state.codeVerifier = payload.codeVerifier;
      state.provider = payload.provider;
    },
    clear: () => initialState,
  },
});
export const OpenIdCreators = openIdSlice.actions;
export default openIdSlice.reducer;
