import { StyleSheet } from 'react-native';

import { Colors, Fonts, Metrics } from '@resources/themes';

export default StyleSheet.create({
  container: { height: '100%' },

  content: { marginHorizontal: 24 },

  inputLabel: {
    color: Colors.duck800,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.title,
    marginBottom: 8,
  },

  detailContainer: { marginBottom: 36 },
  detailText: {
    color: Colors.duck700,
    fontFamily: Fonts.type.medium,
    fontSize: Fonts.size.intermediary,
    textDecorationLine: 'underline',
  },

  cguContainer: { marginBottom: 62 },
  cguNormalText: {
    color: Colors.duck700,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.intermediary,
  },
  cguBoldText: {
    color: Colors.duck800,
    fontFamily: Fonts.type.bold,
    fontSize: Fonts.size.intermediary,
  },

  modalContainer: { flex: 1 },
  detailModalIcon: { alignSelf: 'center', marginBottom: 30 },
  detailModalTitle: {
    color: Colors.duck700,
    fontFamily: Fonts.type.semiBold,
    maxWidth: Metrics.screenWidth / 1.2 - 40,
  },
  detailModalText: {
    color: Colors.duck700,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.medium,
    marginBottom: 10,
    marginTop: 16,
    maxWidth: Metrics.screenWidth / 1.2 - 40,
  },
  buttonStyle: { marginTop: 24 },
  bottomModal: { paddingBottom: 24, paddingHorizontal: 24 },
});
