import { resetAll } from '@redux/reduxReset';
import { createSlice } from '@reduxjs/toolkit';

export interface ContractionTimer {
  start: number | null;
  isRunning: boolean | null;
  lastPaused: number | null;
  now: number | null;
}
const initialState: ContractionTimer = {
  start: null,
  isRunning: null,
  lastPaused: null,
  now: null,
};

const contractionTimerSlice = createSlice({
  name: 'contractionTimer',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    play: (state) => {
      let start = state.start || Date.now();
      if (state.lastPaused) {
        // Offset start time by the elapsed time paused
        start += Date.now() - state.lastPaused;
      }
      state.start = start;
      state.isRunning = true;
      state.lastPaused = null;
      state.now = Date.now();
    },
    pause: (state) => {
      state.isRunning = false;
      state.lastPaused = Date.now();
    },
    setNow: (state) => {
      state.now = Date.now();
    },
    reset: () => initialState,
  },
});

export const contractionTimerCreators = contractionTimerSlice.actions;
export default contractionTimerSlice.reducer;
