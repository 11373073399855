import merge from 'lodash/merge';
import { Platform } from 'react-native';

import { AuthProviders } from '@commonTypes/Auth';
import { resetAll } from '@redux/reduxReset';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  isLogged: boolean;
  password: boolean;
  token: string | null;
  scenarios: string[];
  hasCompletedOnboarding: boolean;
  email?: string;
  phone?: string;
  exp: number;
  providers?: AuthProviders[];
}
const initialState = {
  isLogged: false,
  password: false,
  token: null as string | null,
  scenarios: [] as string[],
  hasCompletedOnboarding: true,
  exp: 0,
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    authLogin: (state, action: PayloadAction<Partial<AuthState>>) => {
      merge(state, action.payload);
      state.password = false;
      if (Platform.OS === 'web') {
        state.token = null;
      }
    },
    authLogout: () => initialState,
    addScenario: (state, action: PayloadAction<string>) => {
      if (!state.scenarios) {
        state.scenarios = [action.payload];
      } else if (!state.scenarios.includes(action.payload)) {
        state.scenarios.push(action.payload);
      }
    },
    clear: () => initialState,
    setIsLogged: (
      state,
      action: PayloadAction<{ password?: boolean; isLogged: boolean }>,
    ) => {
      merge(state, action.payload);
      if (Platform.OS === 'web') {
        state.token = null;
      }
    },
    setAuthProviders: (state, action: PayloadAction<AuthProviders[]>) => {
      state.providers = action.payload;
    },
  },
});

export const AuthCreators = authSlice.actions;
export default authSlice.reducer;
