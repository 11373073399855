import auth from '@api-requests/api/auth';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import { useRecaptchaQuery } from '@hooks/useRecaptchaQuery';
import { useMutation } from '@tanstack/react-query';

import { LoginForm } from './types';

export const useLogin = () => {
  const login = useRecaptchaQuery(auth.login, 'login');

  return useMutation<
    ReducedAxiosResponse<{
      id: number;
      email: string;
      phone: string;
    }>,
    any,
    LoginForm
  >({ mutationKey: ['1FALogin'], mutationFn: login });
};
