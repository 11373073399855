import { Platform, StyleSheet } from 'react-native';

import { Fonts, Colors } from '@resources/themes';

export default StyleSheet.create({
  container: {
    height: '100%',
    padding: 24,
    gap: 32,
    ...Platform.select({
      web: { backgroundColor: Colors.white100 },
      default: {},
    }),
  },

  formContainer: { gap: 24 },
  adviceText: {
    color: Colors.duck700,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.title,
  },
  datePicker: { gap: 8 },
  dateLabel: {
    color: Colors.duck800,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.title,
  },
  dateInput: { alignItems: 'center', flexDirection: 'row', gap: 12 },
  unknownDateText: {
    color: Colors.duck700,
    fontFamily: Fonts.type.light,
    fontSize: Fonts.size.medium,
    textDecorationLine: 'underline',
  },

  spacer: { flex: 1 },

  secureText: {
    color: Colors.duck700,
    fontFamily: Fonts.type.light,
    fontSize: Fonts.size.medium,
    textAlign: 'center',
  },
});
