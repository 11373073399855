import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  popup: {
    position: 'absolute',
    top: 0,
    paddingVertical: 16,
    paddingHorizontal: 24,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.duck800,
    borderRadius: 10,
    zIndex: 5,
  },

  message: {
    color: Colors.white100,
    fontFamily: Fonts.type.semiBold,
    fontSize: Fonts.size.extraText,
  },
});
