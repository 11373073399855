import React, { ReactNode } from 'react';
import { StyleProp, Text, ViewStyle } from 'react-native';
import { RenderRules } from 'react-native-markdown-display';

import { Markdown } from '@components';
import { RULES } from '@components/Markdown';
import { Colors, Fonts } from '@resources/themes';

interface HighlightProps {
  style?: StyleProp<ViewStyle>;
  content: ReactNode;
  currentColor?: string;
}

const rules: RenderRules = {
  [RULES.TEXTGROUP]: (node, children, _, styles) => (
    <Text
      key={node.key}
      style={styles.textgroup}
      numberOfLines={2}
      ellipsizeMode={'tail'}
    >
      {children}
    </Text>
  ),
};

const Highlight = ({
  style,
  content,
  currentColor = Colors.duck800,
}: HighlightProps) => (
  <Markdown
    style={style}
    content={content}
    overriddenRules={rules}
    overriddenStyles={{
      text: {
        fontSize: Fonts.size.title,
        fontFamily: Fonts.type.medium,
        color: currentColor,
      },
    }}
  />
);

export default Highlight;
