import { logSignUp } from '@analytics/events';
import auth from '@api-requests/api/auth';
import { storeCredentials } from '@api-requests/api/common';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import { useSelector, useDispatch } from '@commonTypes/redux';
import { useRecaptchaQuery } from '@hooks/useRecaptchaQuery';
import { AuthCreators } from '@redux/auth';
import { hasLoggedOnceCreators } from '@redux/has-logged-once';
import { userCreators } from '@redux/user';
import { useMutation } from '@tanstack/react-query';

import { signupFormCreator } from '../redux/signupInfos';

export const useCheckEmail = () => {
  const checkEmail = useRecaptchaQuery(auth.checkEmail, 'checkEmail');
  return useMutation<ReducedAxiosResponse, any, { email: string }>({
    mutationFn: checkEmail,
  });
};

export const useCheckPhone = () => {
  const checkPhone = useRecaptchaQuery(auth.checkPhone, 'checkPhone');
  return useMutation<ReducedAxiosResponse, any, { phone: string }>({
    mutationFn: checkPhone,
  });
};

export interface SignupPayload {
  email: string;
  firstName: string;
  password: string;
  phone: string;
  newsLetter: boolean;
}
export const useSignup = () => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.signupForm);
  const { email, password, phone } = formData;

  const credentialsToStore = { email, password, provider: 'may' } as const;
  const signup = useRecaptchaQuery(auth.signup, 'signup');

  return useMutation<ReducedAxiosResponse, any, SignupPayload>({
    mutationKey: ['signup'],
    mutationFn: signup,
    onSuccess: () => {
      dispatch(hasLoggedOnceCreators.setValue(true));

      storeCredentials(credentialsToStore);

      const { password: _, ...user } = formData;

      dispatch(signupFormCreator.addFormPassword(password));
      dispatch(userCreators.userSuccess(user));
      dispatch(
        AuthCreators.authLogin({
          email: email,
          phone: phone,
          scenarios: [],
        }),
      );
      logSignUp({ method: 'email' });
    },
  });
};
