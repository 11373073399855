import React from 'react';
import {
  ActivityIndicator,
  ImageSourcePropType,
  Text,
  View,
} from 'react-native';
import { Linecap } from 'react-native-svg';

import Illustration from '@components/Illustration';
import { PressableOpacity } from '@components/PressableOpacity';
import AppIcon from '@components/ui/Icon';
import { IconName } from '@resources/svg';
import { Colors } from '@resources/themes';

import styles from './styles';

interface OptionProps {
  onPress?: () => void;
  disabled?: boolean;
  loading?: boolean;
  label: string;
  illustrationSource: IconName | ImageSourcePropType | undefined;
  size?: number;
  iconColor?: string;
  strokeLinecap?: Linecap;
  strokeWidth?: number;
  backgroundColor?: string;
  withArrow?: boolean;
  hasSeparator?: boolean;
  testID?: string;
}
function Option({
  onPress,
  disabled,
  loading,
  label,
  illustrationSource,
  size = 24,
  iconColor = Colors.duck500,
  strokeLinecap,
  strokeWidth,
  backgroundColor = Colors.duck300,
  withArrow = true,
  hasSeparator,
  testID,
}: OptionProps) {
  return (
    <PressableOpacity
      style={[
        styles.container,
        hasSeparator && styles.separator,
        disabled && styles.disabled,
      ]}
      onPress={onPress}
      disabled={disabled}
      testID={testID}
    >
      <View style={[styles.badge, { backgroundColor }]}>
        <Illustration
          source={illustrationSource}
          size={size}
          iconColor={iconColor}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
        />
      </View>
      <Text style={styles.label}>{label}</Text>
      {withArrow && !disabled && !loading && (
        <View style={styles.arrow}>
          <AppIcon name={'Arrow'} color={Colors.duck800} size={16} />
        </View>
      )}
      {loading && <ActivityIndicator color={Colors.duck800} />}
    </PressableOpacity>
  );
}

export default Option;
