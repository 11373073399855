import { Platform, StyleSheet } from 'react-native';

import DEVICE from '@resources/constants/device';
import { Colors, Fonts } from '@resources/themes';

const CODE_FIELD_CELL_COUNT = 8;
// Screen width - horizontal margins
const CODE_FIELD_WIDTH = DEVICE.WINDOW_WIDTH - 48 * 2;
const INTER_CELL_MARGIN = 8;

export default StyleSheet.create({
  container: {
    height: '100%',
    padding: 24,
    gap: 40,
    ...Platform.select({
      web: { backgroundColor: Colors.white100 },
      default: {},
    }),
  },

  msg: {
    marginHorizontal: 32,
    color: Colors.duck800,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.input,
    textAlign: 'center',
  },

  codeArea: { alignItems: 'center', gap: 24 },
  codeField: { width: CODE_FIELD_WIDTH },
  cell: {
    alignItems: 'center',
    borderBottomColor: Colors.grey500,
    borderBottomWidth: 1,
    height: 64,
    justifyContent: 'center',
    width:
      (CODE_FIELD_WIDTH - (CODE_FIELD_CELL_COUNT - 1) * INTER_CELL_MARGIN) /
      CODE_FIELD_CELL_COUNT,
  },
  cellFocused: {
    borderBottomColor: Colors.duck800,
    borderBottomWidth: 2,
  },
  cellText: {
    color: Colors.duck800,
    fontFamily: Fonts.type.Recoleta.medium,
    fontSize: Fonts.size.h1,
    textAlign: 'center',
  },
  cellTextDisabled: { opacity: 0.5 },

  error: {
    color: Colors.pink700,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.regular,
  },

  spacer: { flex: 1 },

  resend: { alignItems: 'center', gap: 8 },
  resendLabel: {
    color: Colors.duck800,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.title,
  },
});
