import { Platform, StyleSheet } from 'react-native';

import DEVICE from '@resources/constants/device';
import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  container: { height: '100%', backgroundColor: Colors.white100 },
  scrollView: { paddingVertical: 48 },
  content: { paddingHorizontal: 24 },

  blobs: { position: 'absolute', top: 0, right: -40 },
  backButton: { position: 'absolute', top: 24, right: 24 },
  title: {
    color: Colors.duck700,
    fontFamily: Fonts.type.semiBold,
    fontSize: Fonts.size.currencySize,
  },

  tabBar: { marginTop: 72 },
  perks: { marginTop: 24, marginBottom: 32 },

  buttonWrapper: {
    marginTop: 32,
    width: DEVICE.WINDOW_WIDTH - 48,
    ...Platform.select({
      native: {
        elevation: 5,
        shadowColor: Colors.duck800,
        shadowOffset: { width: 10, height: 4 },
        shadowOpacity: 0.5,
        shadowRadius: 6,
      },
      default: {},
    }),
  },
  additional: {
    marginTop: 16,
    textAlign: 'center',
    color: Colors.duck800,
    fontFamily: Fonts.type.light,
    fontSize: Fonts.size.extraText,
  },

  trialInfo: {
    marginTop: 24,
    marginHorizontal: 24,
    color: Colors.duck800,
    fontFamily: Fonts.type.light,
    fontSize: Fonts.size.tiny,
  },
});
