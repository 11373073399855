import { ReducedAxiosResponse } from '@apiTypes/utils';
import useMutationCall from '@hooks/useMutationCall';
import checkoutSession from '@web/src/api/main/checkoutSession';

export const useCheckoutSession = () =>
  useMutationCall<
    ReducedAxiosResponse<{ url: string }>,
    any,
    { productId: string }
  >({
    mutationFn: checkoutSession.create,
    mutationKey: ['createCheckoutSession'],
  });
