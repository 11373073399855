import { resetAll } from '@redux/reduxReset';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SleepTimer {
  start: number;
  isRunning: boolean;
  lastPaused: number | null;
  now: number;
}

const initialState: Record<string, SleepTimer> = {};

const sleepTimersSlice = createSlice({
  name: 'sleepTimers',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    play: (state, action: PayloadAction<{ childId: number }>) => {
      let { start, lastPaused } = state[action.payload.childId] ?? {
        start: Date.now(),
        lastPaused: null,
      };
      if (lastPaused) {
        // Offset start time by the elapsed time paused
        start += Date.now() - lastPaused;
      }
      state[action.payload.childId] = {
        start,
        isRunning: true,
        lastPaused: null,
        now: Date.now(),
      };
    },
    pause: (state, action: PayloadAction<{ childId: number }>) => {
      state[action.payload.childId].isRunning = false;
      state[action.payload.childId].lastPaused = Date.now();
    },
    reset: (state, action: PayloadAction<{ childId: number }>) => {
      delete state[action.payload.childId];
    },
    setNow: (state, action: PayloadAction<{ childId: number }>) => {
      if (state[action.payload.childId]) {
        state[action.payload.childId].now = Date.now();
      }
    },
  },
});

export const sleepTimerCreators = sleepTimersSlice.actions;
export default sleepTimersSlice.reducer;
