import React, { useCallback, useMemo } from 'react';
import { Controller, Path } from 'react-hook-form';
import { View } from 'react-native';

import { t } from '@config';
import { Colors } from '@resources/themes';

import styles from './styles';
import { InputWrapper } from '../inputWrappers';
import { ExposedControllerProps, RenderInput } from '../types';

type DateTimeInputProps<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
> = ExposedControllerProps<TFieldValues, TName> & {
  mode?: 'date' | 'time' | 'datetime';
  minimumDate?: Date;
  maximumDate?: Date;
  inline?: boolean;
  onChange?: (date: Date) => void;
  leftIcon?: React.ReactElement;
};

export function DateTimeInput<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
>({
  name,
  control,
  mode = 'date',
  maximumDate,
  minimumDate,
  label,
  rules,
  inline = true,
  errorMessage = (error) =>
    error?.type ? t('FORM.HOOK_FORMS_ERROR.' + error.type) : ' ',
  helperText,
  leftIcon,
}: DateTimeInputProps<TFieldValues, TName>) {
  const maxDateFormatted = useMemo(() => {
    if (maximumDate) {
      const dateObj = new Date(maximumDate);

      const year = dateObj.getFullYear().toString().padStart(4, '0');
      const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
      const day = dateObj.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    return;
  }, [maximumDate]);

  const minDateFormatted = useMemo(() => {
    if (minimumDate) {
      const min_dateObj = new Date(minimumDate);

      const min_year = min_dateObj.getFullYear().toString().padStart(4, '0');
      const min_month = (min_dateObj.getMonth() + 1)
        .toString()
        .padStart(2, '0');
      const min_day = min_dateObj.getDate().toString().padStart(2, '0');

      return `${min_year}-${min_month}-${min_day}`;
    }
    return;
  }, [minimumDate]);
  const render = useCallback(
    ({
      field: { ref, ...field },
      fieldState,
    }: RenderInput<TFieldValues, TName>) => {
      return (
        <InputWrapper
          label={label}
          rules={rules}
          inline={inline}
          errorMessage={errorMessage}
          helperText={helperText ? helperText(field.value) : undefined}
          fieldState={fieldState}
        >
          <View style={styles.inputAndIconContainer}>
            {leftIcon ?? null}
            <input
              type={mode}
              // eslint-disable-next-line react-native/no-inline-styles
              style={{
                minWidth: 80,
                borderWidth: 0,
                backgroundColor: Colors.grey400,
                color: Colors.duck800,
                borderRadius: '8px',
                padding: '4px 8px',
                marginLeft: 8,
                outlineWidth: 0,
                display: 'block',
                position: 'relative',
              }}
              min={minDateFormatted}
              max={maxDateFormatted}
              onChange={(event) => {
                field.onChange(event.target.value);
              }}
              defaultValue={maxDateFormatted}
            />
          </View>
        </InputWrapper>
      );
    },
    [
      errorMessage,
      helperText,
      inline,
      label,
      leftIcon,
      maxDateFormatted,
      minDateFormatted,
      mode,
      rules,
    ],
  );

  return (
    <Controller name={name} control={control} rules={rules} render={render} />
  );
}
