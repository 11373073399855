enum colors {
  // All colors of design model
  white100 = '#FFFFFF',
  black500 = '#000000',
  yellow100 = '#FDFBF4',
  yellow200 = '#FEF4A3',
  yellow300 = '#F6E3AC',
  yellow400 = '#F2D88C',
  yellow500 = '#F4CB56',
  grey300 = '#F3F2F2',
  grey400 = '#E7E7E7',
  grey500 = '#D0D0D0',
  grey600 = '#A6A6A6',
  grey700 = '#8D8D8D',
  pink200 = '#F7DADA',
  pink300 = '#F9D2D1',
  pink400 = '#EEC2C0',
  pink500 = '#f2a5a3',
  pink600 = '#F3827F',
  pink700 = '#FF5555',
  green500 = '#66C859',
  blue500 = '#4A9BB5',
  blue600 = '#40859C',
  blue700 = '#356F82',
  blue800 = '#2B5A69',
  ferra400 = '#DD9871',
  ferra700 = '#7D5260',
  brown500 = '#7B5A2D',
  duck100 = '#EAEFED',
  duck200 = '#DFE8E8',
  duck300 = '#d8e5e1',
  duck400 = '#9db4b4',
  duck500 = '#6A9493',
  duck700 = '#506769',
  duck800 = '#365150',
  duck900 = '#2F4544',

  // transparent variable to add after a design color
  transparent100 = 'FF',
  transparent95 = 'F2',
  transparent90 = 'E6',
  transparent85 = 'D9',
  transparent80 = 'CC',
  transparent75 = 'BF',
  transparent70 = 'B3',
  transparent65 = 'A6',
  transparent60 = '99',
  transparent55 = '8C',
  transparent50 = '80',
  transparent45 = '73',
  transparent40 = '66',
  transparent35 = '59',
  transparent30 = '4D',
  transparent25 = '40',
  transparent20 = '33',
  transparent15 = '26',
  transparent10 = '1A',
  transparent5 = '0D',
  transparent0 = '00',
}

export default colors;
