import React, { PropsWithChildren } from 'react';
import { ImageSourcePropType, ScrollView, Text, View } from 'react-native';
import { Linecap } from 'react-native-svg';

import Illustration from '@components/Illustration';
import { IconName } from '@resources/svg';
import { Colors } from '@resources/themes';

import styles from './styles';
import Modal from '../';

interface AlertModalProps extends PropsWithChildren {
  isVisible: boolean;
  title: string | undefined;
  description?: string | undefined;
  illustrationSource: IconName | ImageSourcePropType | undefined;
  iconColor?: string;
  size?: number;
  strokeWidth?: string | number;
  strokeLinecap?: Linecap;
  onClose?: () => void;
  onDismiss?: () => void;
}
function AlertModal({
  isVisible,
  onClose,
  onDismiss,
  illustrationSource,
  iconColor = Colors.duck800,
  size = 48,
  strokeWidth,
  strokeLinecap,
  title,
  description,
  children,
}: AlertModalProps) {
  return isVisible ? (
    <Modal
      animationType="fade"
      withBackdrop
      isVisible
      onClose={onClose}
      onDismiss={onDismiss}
    >
      <View style={styles.modal}>
        <ScrollView
          style={styles.card}
          contentContainerStyle={styles.inner}
          showsVerticalScrollIndicator={false}
          bounces={false}
        >
          <Illustration
            source={illustrationSource}
            size={size}
            iconColor={iconColor}
            strokeWidth={strokeWidth}
            strokeLinecap={strokeLinecap}
          />
          <View style={styles.textContainer}>
            <Text style={styles.title}>{title}</Text>
            {description && (
              <Text style={styles.description}>{description}</Text>
            )}
          </View>
          <View style={styles.actionContainer}>{children}</View>
        </ScrollView>
      </View>
    </Modal>
  ) : null;
}

export default AlertModal;
