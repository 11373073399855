import React, { Ref, useMemo } from 'react';
import {
  Pressable,
  PressableAndroidRippleConfig,
  PressableProps,
  RegisteredStyle,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';

import DEVICE from '@resources/constants/device';
import { Colors } from '@resources/themes';

const ANDROID_RIPPLE_PROPS = {
  color: `${Colors.black500}${Colors.transparent50}`,
  foreground: true,
};

export const PressableNative = React.forwardRef(
  (
    props: PressableProps & {
      style?: StyleProp<ViewStyle> | RegisteredStyle<ViewStyle>;
      android_ripple?: null | PressableAndroidRippleConfig | undefined;
    },
    ref: Ref<View>,
  ) => {
    const style = useMemo(
      () =>
        ({ pressed, hovered }: { pressed?: boolean; hovered?: boolean }) =>
          [
            DEVICE.IS_IOS && { opacity: pressed ? 0.5 : 1.0 },
            ...(hovered && !props.disabled
              ? [{ opacity: 0.7, cursor: 'pointer' as const }]
              : []),
            { overflow: 'hidden' } as const,
            ...(Array.isArray(props.style) ? props.style : [props.style]),
          ],
      [props.disabled, props.style],
    );
    return (
      <Pressable
        ref={ref}
        {...props}
        style={style}
        android_ripple={
          props.android_ripple ? props.android_ripple : ANDROID_RIPPLE_PROPS
        }
      />
    );
  },
);
