import { useCallback, useEffect } from 'react';

import { useRecaptcha } from './useRecaptcha';

export function useRecaptchaQuery<
  T extends { recaptchaToken?: string },
  U = any,
>(
  request: (arg: T) => Promise<U>,
  id: string,
): (arg: Omit<T, 'recaptchaToken'>) => Promise<U> {
  const { executeRecaptcha } = useRecaptcha();

  useEffect(() => {
    try {
      executeRecaptcha?.(`init_${id}`).catch(() => {});
    } catch (e) {}
  }, [executeRecaptcha, id]);

  return useCallback(
    async (args: Omit<T, 'recaptchaToken'>) => {
      if (executeRecaptcha) {
        try {
          const token = await executeRecaptcha?.(`query_${id}`);
          const response = await request({
            recaptchaToken: token,
            ...args,
          } as T);
          return response;
        } catch (e) {
          return request(args as T);
        }
      } else {
        return request(args as T);
      }
    },
    [executeRecaptcha, id, request],
  );
}
