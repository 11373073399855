import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import AppIcon from '@components/ui/Icon';
import { IconName } from '@resources/svg';
import { Colors, Fonts } from '@resources/themes';

const styles = StyleSheet.create({
  container: {
    marginTop: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    marginLeft: 4,
    flex: 1,
    fontSize: Fonts.size.small,
    fontFamily: Fonts.type.medium,
  },
});

const Field = ({
  iconName,
  label,
  contentColor = Colors.duck800,
  numberOfLines,
}: {
  iconName: IconName;
  label: string;
  contentColor?: string;
  numberOfLines?: number | undefined;
}) => (
  <View style={styles.container}>
    <AppIcon name={iconName} size={16} color={contentColor} />
    <Text
      numberOfLines={numberOfLines}
      style={[styles.label, { color: contentColor }]}
    >
      {label}
    </Text>
  </View>
);

export default Field;
