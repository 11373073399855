import React from 'react';
import { StyleProp, Text, View, ViewStyle } from 'react-native';

import { logSelectDailyTip } from '@analytics/events';
import { FavoritesButton } from '@components/FavoritesButton';
import { ReadMarker } from '@components/Pills/ViewMarker';
import AppIcon from '@components/ui/Icon';
import { t } from '@config';
import { navigate } from '@navigation/Actions';
import { COMMON_SCREEN_STACK } from '@navigation/Routes';
import { Colors } from '@resources/themes';

import { DailyTip } from '@api-requests/api/cms/daily-tips/types';

import styles from './styles';
import ContentCardUI from '../ContentCardUI';

interface DailyTipUiProps {
  style?: StyleProp<ViewStyle>;
  title: string;
  label: string;
  onPress: () => void;
  read: boolean;
  favoriteButton?: React.ReactElement | null;
  disabled?: boolean;
}
export const DailyTipUi = ({ label, read, ...props }: DailyTipUiProps) => {
  const contentColor = read ? Colors.grey600 : Colors.duck800;
  const Cover = (
    <View style={styles.cardContainer}>
      {read && <ReadMarker />}
      {label && (
        <View style={styles.labelContainer}>
          <Text style={styles.label}>{label}</Text>
        </View>
      )}
      <AppIcon name={'CalendarColored'} />
    </View>
  );
  return (
    <ContentCardUI
      {...props}
      label={t('COMMON.DAILY_TIP')}
      contentColor={contentColor}
      imageSection={Cover}
    />
  );
};

interface DailyTipProps {
  style?: StyleProp<ViewStyle>;
  item: DailyTip;
  disabled?: boolean;
}
const DailyTipCard = ({ style, item, disabled }: DailyTipProps) => {
  const handlePress = () => {
    logSelectDailyTip({
      tipId: item.id,
      tipCategory: item.daily_tip_type?.label,
      weekNumber: String(item.weekNumber),
      pregnancy: item.pregnancy,
    });
    navigate(COMMON_SCREEN_STACK.TIP_DETAIL_SCREEN, {
      tip: item,
      tipId: undefined,
    });
  };
  return (
    <DailyTipUi
      style={style}
      title={item.title}
      label={item.daily_tip_type?.label}
      onPress={handlePress}
      read={true}
      favoriteButton={<FavoritesButton type={'dailyTips'} id={`${item.id}`} />}
      disabled={disabled}
    />
  );
};

export default DailyTipCard;
