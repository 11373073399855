import React from 'react';

import { SelectionBlockGroup } from '@components/ui/SelectionBlock';
import { t } from '@config';

import { METHOD } from '../../types';

const blocOptions = [
  {
    iconName: 'RollBackIcon',
    label: t('PROFILE.PREGNANCY.COMPUTE.LAST_PERIOD'),
    value: METHOD.LAST_PERIOD,
  },
  {
    iconName: 'PregnantIcon',
    label: t('PROFILE.PREGNANCY.COMPUTE.PREGNANCY_START'),
    value: METHOD.PREGNANCY_START,
  },
] as const;
const ComputeTermMethodBlock = ({
  method,
  setMethod,
}: {
  method: METHOD.LAST_PERIOD | METHOD.PREGNANCY_START;
  setMethod: (method: METHOD.LAST_PERIOD | METHOD.PREGNANCY_START) => void;
}) => (
  <SelectionBlockGroup
    value={method}
    onChange={setMethod}
    options={blocOptions}
  />
);

export default ComputeTermMethodBlock;
