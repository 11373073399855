export const MODALS_NAME = {
  API_ERROR_ALERT_MODAL: 'apiErrorAlertModal',
  AUDIO_MODAL: 'audioModal',
  PAYMENT_ALERT_MODAL: 'paymentAlertModal',
  SUBSCRIBE_MODAL: 'subscribeModal',
  VERSION_ALERT_MODAL: 'versionAlertModal',
  WEBVIEW_MODAL: 'webviewModal',
};

export enum COMMON_SCREEN_STACK {
  LIVI_SCREEN = 'liviScreen',
  LIVI_DETAILS = 'liviDetails',
  ADD_KID_SCREEN = 'addKidScreen',
  SUBSCRIPTION_PAYMENT_SCREEN = 'paymentScreenSubscription',
  SUBSCRIPTION_SUCCESS_SCREEN = 'subscriptionSuccessScreen',
  MASTERCLASS_SCREEN = 'masterClassScreen',
  VIDEO_PLAYER_SCREEN = 'videoPlayerScreen',
  GUIDE_SLIDER = 'guideSlider',
  POST_DETAIL_SCREEN = 'postDetailScreen',
  ADD_PREGNANCY_SCREEN = 'addPregnancy',
  PREGNANCY_DETAIL_SCREEN = 'pregnancyDetailScreen',
  COMPUTE_END_PREGNANCY = 'computeEndPregnancy',
  TIP_DETAIL_SCREEN = 'tipDetailScreen',
  TIP_HISTORY_SCREEN = 'tipHistoryScreen',
  KID_DETAIL_SCREEN = 'kidDetailScreen',
  FOLDER_SCREEN = 'folderScreen',
  STATIC_CONTENTS_SCREEN = 'staticContentsScreen',
  FAVORITES_SCREEN = 'favoritesScreen',
  LMDM_INFO_SCREEN = 'lmdmInfoScreen',
  NOTIFICATIONS = 'settingsNotificationsScreen',
  NOTIFICATION_PERMISSION = 'NotificationPermission',
  PROFILE_SCREEN = 'profileScreen',
  INFO_SCREEN = 'infoScreen',
  CMS_INFO_SCREEN = 'cmsInfoScreen',
}

export enum HOME_STACK {
  SPONSOR_SCREEN = 'sponsorScreen',
  EXPERT_DETAILS = 'expertDetails',
  SUGGESTED_ITEMS_SCREEN = 'suggestedItemsScreen',
  SELECTED_ITEM_DETAIL_SCREEN = 'selectedItemDetailScreen',
  EXPERT_INFO = 'expertInfo',
  PREGNANCY_PROGRESSION_SCREEN = 'pregnancyProgressionScreen',
  CHILD_PROGRESSION_SCREEN = 'childProgressionScreen',
  BABY_SCENE_SCREEN = 'babySceneScreen',
  FEATURED_CONTENT_REDIRECTION_SCREEN = 'featuredContentRedirectionScreen',
  WEEKLY_ACTIVITIES_HISTORY_SCREEN = 'weeklyActivitiesHistoryScreen',
  WEEKLY_ACTIVITIES_SCREEN = 'weeklyActivitiesScreen',
}

export enum CHAT_STACK {
  ROOM_CHAT_SCREEN = 'roomChatScreen',
}

export enum CONSULTATION_STACK {
  ADD_CONTEXT_SCREEN = 'addContextScreen',
  ADD_TO_AGENDA_SCREEN_CONSULTATION = 'addToAgendaScreenConsultation',
  APPOINTMENT_SCREEN_CONSULTATION = 'appointmentScreenConsultation',
  CHOICE_CHILD_SCREEN_CONSULTATION = 'choiceChildScreen',
  COMMUNICATION_TYPE_SCREEN = 'communicationTypeScreen',
  CONSULTATION = 'consultationScreen',
  CONSULTATION_DETAIL_SCREEN = 'consultationDetailScreen',
  CONSULTATION_INFO_SCREEN = 'consultationInfoScreen',
  CONSULTATION_RECAP_SCREEN = 'consultationRecapScreen',
  CONSULTATION_SUCCESS_SCREEN = 'consultationSuccessScreen',
  CONSULTATION_PURCHASE_SCREEN = 'consultationPurchaseScreen',
  VISIO_CONF_SCREEN = 'visioConfScreen',
}

export enum JOURNALING_STACK {
  JOURNALING_SCREEN = 'journalingScreen',
  APPOINTMENT_FORM_SCREEN = 'appointmentFormScreen',
  APPOINTMENT_SCREEN = 'appointmentScreen',
  CALENDAR_SCREEN = 'calendarScreen',
  MY_WEIGHT_SCREEN = 'myWeightScreen',
  ADD_START_WEIGHT_SCREEN = 'addStartWeightScreen',
  ADD_WEIGHT_SCREEN = 'addWeightScreen',
  EDIT_WEIGHT_SCREEN = 'editWeightScreen',
  TODO_SCREEN = 'todoScreen',
  BELLY_SCREEN = 'bellyScreen',
  NEW_TASK_SCREEN = 'newTaskScreen',
  TODO_SUGGESTION_DETAIL_SCREEN = 'todoSuggestionDetailScreen',
  MANAGE_TODO_LISTS_SCREEN = 'manageTodoListsScreen',
  KID_GROWTH_SCREEN = 'kidGrowthScreen',
  ADD_MEASURE_SCREEN = 'addMeasureScreen',
  EDIT_MEASURE_SCREEN = 'editMeasureScreen',
  FAQ_PREGNANCY = 'faqPregnancy',
  FAQ_PURCHASE = 'faqPurchase',
  RECIPE_SCREEN = 'recipeScreen',
  RECIPE_DETAIL_SCREEN = 'recipeDetailScreen',
  RECIPE_INFO_SCREEN = 'recipeInfoScreen',
  SLEEP_SCREEN = 'sleepScreen',
  SLEEP_HISTORY_SCREEN = 'sleepHistoryScreen',
  ADD_SLEEP_SCREEN = 'addSleepScreen',
  EDIT_SLEEP_SCREEN = 'editSleepScreen',
  CHILD_FEED_SCREEN = 'childFeedScreen',
  CHILD_FEED_HISTORY_SCREEN = 'childFeedHistoryScreen',
  ADD_BABY_BOTTLE_SCREEN = 'addBabyBottleScreen',
  EDIT_BABY_BOTTLE_SCREEN = 'editBabyBottleScreen',
  ADD_BREASTFEED_SCREEN = 'addBreastfeedScreen',
  EDIT_BREASTFEED_SCREEN = 'editBreastfeedScreen',
  CONTRACTION_SCREEN = 'ContractionTrackerScreen',
  CONTRACTION_BREATHING_SCREEN = 'ContractionBreathingScreen',
  CONTRACTION_HISTORY_SCREEN = 'ContractionHistoryScreen',
  CONTRACTION_DETAIL_SCREEN = 'ContractionDetailScreen',
  DIAPER_SCREEN = 'diaperScreen',
  DIAPER_HISTORY_SCREEN = 'diaperHistoryScreen',
  ADD_DIAPER_SCREEN = 'addDiaperScreen',
  EDIT_DIAPER_SCREEN = 'editDiaperScreen',
  FERTILITY_ONBOARDING_SCREEN = 'fertilityOnboardingScreen',
  FERTILITY_SCREEN = 'fertilityScreen',
  ADD_CYCLE_SCREEN = 'addCycleScreen',
  ADD_TEMPERATURE_SCREEN = 'addTemperatureScreen',
  DATA_CYCLE_HISTORY_SCREEN = 'DataCycleHistoryScreen',
  FERTILITY_PARAMETER_SCREEN = 'FertilityParameterScreen',
  ADD_SYMPTOM_SCREEN = 'AddSymptomScreen',
}

export enum SETTINGS_STACK {
  AFFILIATION_SCREEN = 'affiliationScreen',
  AFFILIATION_DETAIL_SCREEN = 'affiliationDetailScreen',
  AFFILIATION_SUCCESS_SCREEN = 'affiliationSuccessScreen',
  CARD_LIST_SCREEN = 'cardListScreen',
  CONTACT_SCREEN = 'contactScreen',
  DEACTIVATION_SCREEN = 'deactivationScreen',
  HISTORY_SCREEN = 'historyScreen',
  INFORMATION_SCREEN = 'informationScreen',
  NOTIFICATIONS = 'settingsNotificationsScreen',
  SETTINGS_SCREEN = 'settingsScreen',
  STORYBOOK = 'storybook',
  SUBSCRIPTION_SCREEN = 'subscriptionScreen',
  CANCEL_SUBSCRIPTION_SCREEN = 'cancelSubscriptionScreen',
  CANCEL_SUBSCRIPTION_FORM_SCREEN = 'cancelSubscriptionFormScreen',
  CHURN_REMOTE_FORM_IMAGE_TEMPLATE_SCREEN = 'churnRemoteFormImageTemplateScreen',
  CHURN_REMOTE_FORM_STEP_TEMPLATE_SCREEN = 'churnRemoteFormStepTemplateScreen',
  VALIDATE_UNSUBSCRIPTION_SCREEN = 'validateUnsubscriptionScreen',
  FAMILY_ACCOUNT_SCREEN = 'familyAccountScreen',
  FAMILY_ACCOUNT_ACCEPT_SUCCESS_SCREEN = 'familyAccountAcceptSuccessScreen',
  FAMILY_ACCOUNT_ALLOW_SCREEN = 'familyAccountAllowScreen',
  OPENID_SIGNIN = 'openIdSignin',
}

export enum NAVIGATOR_NAMES {
  ROOT_NAVIGATOR = 'rootNavigator',
  LOGGED_NAVIGATOR = 'loggedNavigator',
  TAB_BAR_NAVIGATOR = 'tabBarNavigator',
  CONSULTATION_NAVIGATOR = 'consultationNavigator',
  SETTINGS_NAVIGATOR = 'settingsNavigator',
  PROFILE_NAVIGATOR = 'profileNavigator',
  CHAT_NAVIGATOR = 'chatNavigator',
  LIBRARY_NAVIGATOR = 'libraryNavigator',
  HOME_NAVIGATOR = 'homeNavigator',
  ONBOARDING_NAVIGATOR = 'onboardingNavigator',
  JOURNALING_NAVIGATOR = 'journalingNavigator',
}

export enum TAB_NAMES {
  HOME = 'homeTab',
  EXPLORE = 'exploreTab',
  CHAT = 'chatTab',
  JOURNALING = 'journalingTab',
}

export enum ONBOARDING_STACK {
  SUCCESS_REGISTER = 'successRegister',
  INTRO_FORM_SCREEN1 = 'introFormScreen1',
  INTRO_FORM_SCREEN2 = 'introFormScreen2',
  INTRO_FORM_SCREEN3 = 'introFormScreen3',
  FAMILY_OVERVIEW = 'onboardingFamilyOverview',
  REMOTE_FORM_IMAGE_TEMPLATE_SCREEN = 'remoteFormImageTemplateScreen',
  REMOTE_FORM_STEP_TEMPLATE_SCREEN = 'remoteFormStepTemplateScreen',
  NOTIFICATION_PERMISSION_SCREEN = 'NotificationPermissionScreen',
  ONBOARDING_IMAGE_TEMPLATE_SCREEN = 'onBoardingImageTemplateScreen',
  ONBOARDING_STEP_TEMPLATE_SCREEN = 'onBoardingStepTemplateScreen',
  FEATURES_SUMMARY = 'featuresSummary',
  ANIMATION_SCREEN = 'AnimationScreen',
}

export enum ANONYMOUS_STACK {
  SWIPER_SCREEN = 'swiperScreen',
  REGISTER = 'register',
  LOGIN = 'login',
  OPENID_SIGNIN = 'openIdSignin',
  VERIFICATION_CODE_SCREEN = 'verificationCodeScreen',
  ATTACH_SUGGESTION_SCREEN = 'attachSuggestionScreen',
  FORGOT_PASSWORD = 'forgotPassword',
  RESET_PASSWORD_SCREEN = 'resetPasswordScreen',
  SUCCESS_RESET_PASSWORD_SCREEN = 'successResetPasswordScreen',
  STATIC_CONTENTS_SCREEN = 'staticContentsScreen',
}
