import React from 'react';
import {
  Image,
  ImageSourcePropType,
  PressableProps,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import { Linecap } from 'react-native-svg';

import AppIcon from '@components/ui/Icon';
import { PressableNative } from '@components/ui/Pressables/PressableNative';
import { IconName } from '@resources/svg';
import { Colors } from '@resources/themes';

const styles = StyleSheet.create({
  container: {
    height: 40,
    aspectRatio: 1,
    borderRadius: 20,
    backgroundColor: Colors.white100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  backgroundImage: { width: '100%', height: '100%', position: 'absolute' },
});

interface ActionsProps extends PressableProps {
  style?: StyleProp<ViewStyle>;
  iconName: IconName | ImageSourcePropType;
  iconColor?: string;
  size?: number;
  strokeWidth?: string | number;
  strokeLinecap?: Linecap;
  backgroundImage?: ImageSourcePropType;
}
const Actions = ({
  style,
  iconName,
  iconColor = Colors.pink500,
  size = 24,
  strokeWidth,
  strokeLinecap,
  backgroundImage,
  ...pressableProps
}: ActionsProps) => (
  <PressableNative style={[styles.container, style]} {...pressableProps}>
    {backgroundImage && (
      <Image style={styles.backgroundImage} source={backgroundImage} />
    )}
    {typeof iconName === 'string' ? (
      <AppIcon
        name={iconName}
        size={size}
        color={iconColor}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
      />
    ) : (
      <Image source={iconName} style={{ width: size, height: size }} />
    )}
  </PressableNative>
);

export default Actions;
