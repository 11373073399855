import { ProductRaw } from './types';
import { axiosGet } from '../../request';

const baseUrl = '/apifo/products';

const getProductList = () => axiosGet<Array<ProductRaw>>({ url: baseUrl });

export const products = {
  getAll: getProductList,
};
