import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  buttonContainer: {
    marginBottom: 50,
  },

  container: {
    height: '100%',
  },

  inputContainer: {
    width: '100%',
    paddingHorizontal: 24,
  },
});
