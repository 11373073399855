import * as Sentry from '@sentry/browser';

export const captureException = (
  error: Error,
  additional?: Record<string, any>,
) => {
  Sentry.captureException(error, additional);
};

export const captureMessage = (
  message: string,
  additional?: Record<string, any>,
) => {
  Sentry.captureMessage(message, additional);
};

export const setSentryUser = (userId: number) => {
  if (__DEV__) {
    return;
  }
  Sentry.setUser({ id: userId.toString() });
};
export const unsetSentryUser = () => {
  if (__DEV__) {
    return;
  }
  Sentry.setUser({});
};
