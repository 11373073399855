import { useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import {
  SharedValue,
  useAnimatedScrollHandler,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useSelector } from '@commonTypes/redux';
import { displayMiniPlayerScreens } from '@components/Audio/MiniAudioPlayer/constants';
import { useTrackPlayer } from '@hooks/useTrackPlayer';
import { TAB_NAMES } from '@navigation/Routes';

import { ScrollSharedValues } from './types';

const usePlatformScrollHandler =
  Platform.OS === 'ios'
    ? (scrollRaw: SharedValue<number>, activeTab: boolean) =>
        useAnimatedScrollHandler(
          {
            onScroll(event) {
              // trick found here to avoid problems with iOS https://github.com/software-mansion/react-native-reanimated/issues/2285
              scrollRaw.value = withTiming(event.contentOffset.y, {
                duration: 10,
              });
            },
          },
          [activeTab],
        )
    : (scrollRaw: SharedValue<number>, activeTab: boolean) =>
        useAnimatedScrollHandler(
          {
            onScroll(event) {
              // do not use iOS trick on Android : it creates horrible scroll glitches
              scrollRaw.value = event.contentOffset.y;
            },
          },
          [activeTab],
        );

const decelerationFactor = 2;
export const useScrollHandler = (
  activeTab: boolean,
  scrollSharedValues: ScrollSharedValues,
) => {
  const scrollRaw = useSharedValue(0);
  const scrollHandler = usePlatformScrollHandler(scrollRaw, activeTab);

  const previousValue = useSharedValue(
    decelerationFactor * scrollSharedValues.fullHeight,
  );

  const scrollY = useDerivedValue(() => {
    const scrollValue = scrollRaw.value;
    const collapsedPosition = Math.max(
      scrollSharedValues.fullHeight * decelerationFactor, // Cannot be lower than the navbar height (ie handle scroll value can be < 0)
      Math.min(
        scrollValue + scrollSharedValues.fullHeight * decelerationFactor,
        Math.max(previousValue.value, scrollValue),
      ),
    );
    previousValue.value = collapsedPosition;

    return (collapsedPosition - scrollRaw.value) / decelerationFactor;
  }, [scrollRaw]);

  useEffect(() => {
    if (activeTab) {
      scrollSharedValues.changeScrollHandler(scrollY);
    }
  }, [activeTab, scrollSharedValues, scrollY]);

  return scrollHandler;
};

export const useMarginBottom = (additionalMargin: number = 0) => {
  const { bottom } = useSafeAreaInsets();
  const currentRoute = useSelector(
    (state) => state.routes.currentRoute ?? TAB_NAMES.HOME,
  );
  const { playbackState } = useTrackPlayer();
  const isAudioPlayerVisible = Boolean(playbackState.activeTrack);

  const appliedInset = Object.values(TAB_NAMES).some(
    (route: string) => currentRoute === route,
  )
    ? 0
    : bottom;

  const displayMiniPlayer =
    isAudioPlayerVisible && displayMiniPlayerScreens.includes(currentRoute);
  return additionalMargin + (displayMiniPlayer ? 80 : appliedInset);
};

export const useScrollSharedValues = (
  heightVariation = 0,
  outOfScrollbarResidualHeight = 0,
) => {
  const fullHeight = 40 + heightVariation;
  const height = useSharedValue(fullHeight);
  const [scrollY, setScrollY] = useState(height);
  const tabBarPosition = useSharedValue(40 + fullHeight);
  return useMemo<ScrollSharedValues>(
    () => ({
      scrollY,
      fullHeight,
      tabBarPosition,
      outOfScrollbarResidualHeight,
      // used by scroll handler to update scrollY and force re-render that will re-trigger derivedStyles if correctly set with scrollY dependency
      changeScrollHandler: setScrollY,
      reset: () => {
        height.value = fullHeight;
        tabBarPosition.value = 40 + fullHeight;
      },
    }),
    [fullHeight, height, outOfScrollbarResidualHeight, scrollY, tabBarPosition],
  );
};
