import React from 'react';
import { StyleSheet, View } from 'react-native';

import PlaceholderLine from '@components/Placeholder/PlaceholderLine';
import { Colors } from '@resources/themes';

const styles = StyleSheet.create({
  container: { marginTop: 24 },
  paragraph: { marginTop: 12, gap: 8 },
  random: { margin: 4, borderRadius: 9 },
  line: { borderRadius: 24 },
});

const getRandom = (min: number, max: number) =>
  Math.round(Math.random() * (max - min) + min);

const CguPlaceholder = () => {
  return (
    <View style={styles.container}>
      <PlaceholderLine
        style={styles.line}
        startColor={Colors.pink500}
        width={'60%'}
        height={14}
      />
      {Array.from({ length: 4 }).map((_, paragraphKey) => (
        <View key={paragraphKey} style={styles.paragraph}>
          <PlaceholderLine style={styles.line} width={'78%'} height={24} />
          <View>
            {Array.from({ length: getRandom(3, 6) }).map((__, lineKey) => (
              <View key={lineKey}>
                <PlaceholderLine
                  style={styles.random}
                  width={`${getRandom(80, 100)}%`}
                  height={16}
                />
              </View>
            ))}
          </View>
        </View>
      ))}
    </View>
  );
};

export default CguPlaceholder;
