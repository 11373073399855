import axios, { AxiosResponseHeaders } from 'axios';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';

import { ReducedAxiosResponse } from '@apiTypes/utils';
import { DeviceAuth } from '@services/deviceAuthentication';

/* ------ config authorization bearer ------ */
let strapiAuthorizationBearer: string = '';
export const setStrapiAuthorizationBearer = (ab: string | null | undefined) => {
  strapiAuthorizationBearer = ab ?? '';
};

let baseURL: string = '';
export const setURL = (url: string = '') => {
  baseURL = url;
};

export type responseType = {
  success: boolean;
  errorCode: string;
  data: {};
};

const getAdditionalHeaders = async (params: {
  data?: Record<string, any> | string;
}): Promise<Record<string, string>> => {
  if (Platform.OS === 'web') {
    return {};
  }
  const payload = params && params.data ? params.data : { empty: true };
  const deviceAuth = await DeviceAuth.generateSignature(payload);
  return {
    'X-Device-Auth': deviceAuth,
    'X-Version': DeviceInfo.getVersion(),
  };
};

export const axiosGet = async <T = any>(
  url: string,
  params?: any,
  headers?: AxiosResponseHeaders,
): Promise<ReducedAxiosResponse<T>> => {
  const additionalHeaders = await getAdditionalHeaders(params);
  return new Promise((resolve, reject) => {
    axios({
      baseURL,
      method: 'GET',
      url,
      timeout: 20000,
      ...params,
      headers: {
        'Content-type': 'application/json',
        ...additionalHeaders,
        ...prepareRequestSendToken(params, headers),
      },
      withCredentials: true,
    })
      .then(
        ({ request, config, headers: _h, ...payload }) => {
          resolve(
            Object.assign(payload, { config: { params: config.params } }),
          );
        },
        ({ request, ...payload }) => {
          if (payload.response) {
            reject(payload.response);
          } else {
            reject(payload);
          }
        },
      )
      .catch((e) => {
        reject(e);
      });
  });
};

export const axiosPostRequest = async <T = any>(
  url: string,
  params: {},
  headers: {},
): Promise<ReducedAxiosResponse<T>> => {
  const additionalHeaders = await getAdditionalHeaders(params);
  return new Promise((resolve, reject) => {
    axios({
      baseURL,
      method: 'POST',
      url,
      timeout: 20000,
      ...params,
      headers: {
        'Content-type': 'application/json',
        ...additionalHeaders,
        ...headers,
      },
      withCredentials: true,
    })
      .then(
        ({ request, config, headers: _h, ...payload }) => {
          resolve(
            Object.assign(payload, { config: { params: config.params } }),
          );
        },
        ({ request, ...payload }) => {
          if (payload.response) {
            reject(payload.response);
          } else {
            reject(payload);
          }
        },
      )
      .catch((e) => {
        reject(e);
      });
  });
};

export const axiosPutRequest = async <T = any>(
  url: string,
  params: {},
  headers: {},
): Promise<ReducedAxiosResponse<T>> => {
  const additionalHeaders = await getAdditionalHeaders(params);
  return new Promise((resolve, reject) => {
    axios({
      baseURL,
      method: 'PUT',
      url,
      timeout: 20000,
      ...params,
      headers: {
        'Content-type': 'application/json',
        ...additionalHeaders,
        ...headers,
      },
      withCredentials: true,
    })
      .then(
        ({ request, config, headers: _h, ...payload }) => {
          resolve(
            Object.assign(payload, { config: { params: config.params } }),
          );
        },
        ({ request, ...payload }) => {
          if (payload.response) {
            reject(payload.response);
          } else {
            reject(payload);
          }
        },
      )
      .catch((e) => {
        reject(e);
      });
  });
};

export const axiosDelRequest = async <T = any>(
  url: string,
  params: {},
  headers: {},
): Promise<ReducedAxiosResponse<T>> => {
  const additionalHeaders = await getAdditionalHeaders(params);
  return new Promise((resolve, reject) => {
    axios({
      baseURL,
      method: 'DELETE',
      url,
      timeout: 20000,
      ...params,
      headers: {
        'Content-type': 'application/json',
        ...additionalHeaders,
        ...headers,
      },
      withCredentials: true,
    })
      .then(
        ({ request, config, headers: _h, ...payload }) => {
          resolve(
            Object.assign(payload, { config: { params: config.params } }),
          );
        },
        ({ request, ...payload }) => {
          if (payload.response) {
            reject(payload.response);
          } else {
            reject(payload);
          }
        },
      )
      .catch((e) => {
        reject(e);
      });
  });
};

/* ------ Prepare request ------ */
type BaseParams = {
  sendToken?: boolean;
};
const prepareRequestSendToken = (
  params: BaseParams,
  headers: AxiosResponseHeaders = {} as AxiosResponseHeaders,
) => {
  if (
    Platform.OS !== 'web' &&
    strapiAuthorizationBearer &&
    (params?.sendToken === undefined || params.sendToken)
  ) {
    return {
      'may-jwt': strapiAuthorizationBearer,
      ...headers,
    };
  }
  return headers;
};

/* ------ Request POST ------ */
type PostParams = {
  data?: {};
} & BaseParams;
export const axiosPost = <T = any>(url: string, params: PostParams = {}) => {
  const reqParams = { data: params.data || {} };

  const headers = prepareRequestSendToken(params);
  return axiosPostRequest<T>(url, reqParams, headers);
};

/* ------ Request PUT ------ */
type PutParams = {
  data?: {};
} & BaseParams;
export const axiosPut = <T = any>(url: string, params: PutParams = {}) => {
  const reqParams = { data: params.data || {} };

  const headers = prepareRequestSendToken(params);
  return axiosPutRequest<T>(url, reqParams, headers);
};

/* ------ Request DELETE ------ */
type DeleteParams = {
  params?: {};
} & BaseParams;
export const axiosDelete = <T = any>(
  url: string,
  params: DeleteParams = {},
) => {
  const reqParams = { params: params.params || {} };

  const headers = prepareRequestSendToken(params);
  return axiosDelRequest<T>(url, reqParams, headers);
};
