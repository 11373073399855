import { StyleSheet } from 'react-native';

import { Colors } from '@resources/themes';

export default StyleSheet.create({
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
  },

  oneRuleContainer: {
    alignItems: 'center',
    flex: 0.4,
    flexDirection: 'row',
  },

  text: {
    color: Colors.duck800,
  },
});
