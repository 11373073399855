import React, { useContext } from 'react';

export const popupGenerator = () => {
  const PopupContext = React.createContext<(text: string) => void>(() => null);
  const PopupProvider = ({
    displayText,
    ...other
  }: {
    displayText: (text: string) => void;
  } & Omit<React.ComponentProps<typeof PopupContext.Provider>, 'value'>) => (
    <PopupContext.Provider value={displayText} {...other} />
  );
  const usePopup = () => useContext(PopupContext);

  return [PopupProvider, usePopup] as const;
};

export const [PopupProvider, usePopup] = popupGenerator();
