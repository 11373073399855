import React, { useEffect } from 'react';
import { StyleProp, Text, View, ViewStyle } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
} from 'react-native-reanimated';

import BackButton from '@components/ui/Pressables/Actions/BackButton';

import styles from './styles';

interface ProgressTopBarProps {
  style?: StyleProp<ViewStyle>;
  title: string;
  progress: number;
  progressBarStyle?: StyleProp<ViewStyle>;
  progressContainerStyle?: StyleProp<ViewStyle>;
  total: number;
  onPress: () => void;
  showBack?: boolean;
}
const ProgressTopBar = ({
  style,
  title,
  progress,
  progressContainerStyle,
  progressBarStyle,
  total,
  onPress,
  showBack,
}: ProgressTopBarProps) => {
  const animatedProgress = useSharedValue(0);

  const animatedStyle = useAnimatedStyle(() => {
    return { width: `${animatedProgress.value}%` };
  }, [animatedProgress]);

  useEffect(() => {
    animatedProgress.value = withTiming((progress / total) * 100, {
      duration: 500,
    });
  }, [animatedProgress, progress, total]);

  const header = total > 1 ? `${title} ${progress}/${total}` : title;

  return (
    <View style={[styles.container, style]}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{header}</Text>
      </View>
      {total > 1 && (
        <View style={[styles.progressContainer, progressContainerStyle]}>
          <Animated.View
            style={[styles.progress, animatedStyle, progressBarStyle]}
          />
        </View>
      )}
      {(progress > 1 || showBack) && (
        <BackButton style={styles.backButton} onPress={onPress} />
      )}
    </View>
  );
};

export default ProgressTopBar;
