import { resetAll } from '@redux/reduxReset';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ConsultationCreate } from '../types';

const initialState: ConsultationCreate & { price: number } = {
  categoryId: 0,
  childId: 0,
  startDate: '',
  endDate: '',
  reason: '',
  phoneOnly: false,
  files: [],
  price: 0,
};

const appointmentSlice = createSlice({
  name: 'bookedAppointment',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setCategoryId: (state, action: PayloadAction<number>) => {
      state.categoryId = action.payload;
    },
    setPrice: (state, action: PayloadAction<number>) => {
      state.price = action.payload;
    },
    setChildId: (state, action: PayloadAction<number>) => {
      state.childId = action.payload;
    },
    setSchedule: (
      state,
      action: PayloadAction<{ startDate: string; endDate: string }>,
    ) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setReason: (state, action: PayloadAction<string>) => {
      state.reason = action.payload.trim();
    },
    addFile: (state, action: PayloadAction<string>) => {
      state.files = [...state.files, action.payload];
    },
    removeFile: (state, action: PayloadAction<string>) => {
      state.files = state.files.filter((file) => file !== action.payload);
    },
    setPhoneOnly: (state, action: PayloadAction<boolean>) => {
      state.phoneOnly = action.payload;
    },
    resetAppointment: () => initialState,
  },
});

export const appointmentCreators = appointmentSlice.actions;
export default appointmentSlice.reducer;
