import { resetAll } from '@redux/reduxReset';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {} as { [discussionId: number]: string };

const draftChatMessageSlice = createSlice({
  name: 'chatDraftMessages',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setDraft: (
      state,
      action: PayloadAction<{ discussionId: number; message: string }>,
    ) => {
      state[action.payload.discussionId] = action.payload.message;
    },
  },
});

export const draftChatMessageCreators = draftChatMessageSlice.actions;
export default draftChatMessageSlice.reducer;
