import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  container: { flexDirection: 'row', alignItems: 'center', gap: 16 },

  checkBox: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    aspectRatio: 1,
    backgroundColor: Colors.yellow500,
    borderRadius: 4,
  },
  checkBoxError: { borderColor: Colors.pink700, borderWidth: 1 },

  label: {
    flex: 1,
    color: Colors.duck800,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.title,
  },
  labelError: { color: Colors.pink700 },
});
