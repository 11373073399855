import React, { useEffect, useMemo } from 'react';
import { View, Text, ScrollView } from 'react-native';

import { YellowButton } from '@components/Buttons';
import AppIcon from '@components/ui/Icon';
import Action from '@components/ui/Pressables/Actions';
import { t } from '@config/bootstrap/i18n';
import { Colors } from '@resources/themes';
import { getChatOpeningHours } from '@tools/getChatOpeningHours';

import {
  PRODUCT_INTERVAL,
  PRODUCT_TIER,
} from '@api-requests/api/main/payment/products/types';

import styles from './styles';
import EventsRecap from '../../components/EventsRecap';
import PlanList from '../../components/PlanList';
import { SubscribeModalProps } from '../../types';
import { useSubscribeModalWordingContext } from '../../utils/useSubscribeModalWordingContext';

const SubscribeModal6 = ({
  hideModal,
  productList,
  selectedPlan,
  setSelectedPlan,
  onPress,
}: SubscribeModalProps) => {
  const { isDifferentFromParis } = getChatOpeningHours();
  const { context, canTrial } = useSubscribeModalWordingContext(selectedPlan);
  const premiumPlans = useMemo(
    () => productList?.filter((plan) => plan.tier === PRODUCT_TIER.PREMIUM),
    [productList],
  );
  useEffect(() => {
    const defaultPlan = premiumPlans?.[0];
    if (defaultPlan) {
      setSelectedPlan(defaultPlan);
    }
  }, [premiumPlans, setSelectedPlan]);
  const hasTrial = !!selectedPlan?.withTrial;

  return (
    <View style={styles.container}>
      <ScrollView
        contentContainerStyle={styles.content}
        showsVerticalScrollIndicator={false}
      >
        <AppIcon style={styles.blobs} name={'Blobs'} />
        {hideModal && (
          <Action
            style={styles.backIcon}
            onPress={hideModal}
            iconName={'Arrow'}
            iconColor={Colors.yellow500}
            testID="closeSubscribeModalButton"
          />
        )}
        <View style={styles.header}>
          <Text style={styles.title}>
            {t('SUBSCRIBE_MODAL.MODAL6.TITLE', { context })}
          </Text>
          {hasTrial && canTrial && (
            <Text style={styles.label}>
              {t('SUBSCRIBE_MODAL.MODAL6.TRIAL')}
            </Text>
          )}
        </View>
        <EventsRecap
          style={styles.recap}
          tier={selectedPlan?.tier}
          hasTrial={hasTrial}
          canTrial={canTrial}
          canUpgrade={selectedPlan?.interval === PRODUCT_INTERVAL.MONTH}
          isDifferentFromParis={isDifferentFromParis}
        />
        {selectedPlan && (
          <Text style={styles.price}>
            {hasTrial && canTrial && t('SUBSCRIBE_MODAL.MODAL6.PRICE_TRIAL')}
            {t(`SUBSCRIBE_MODAL.PLAN_SECTION.PLAN.${selectedPlan.interval}`, {
              amount: (selectedPlan.amount / 100).toFixed(2),
              interval_count: selectedPlan.intervalCount || '',
            })}
          </Text>
        )}
        <YellowButton
          style={styles.buttonWrapper}
          disabled={!selectedPlan}
          onPress={() => onPress(selectedPlan!)}
          label={t('SUBSCRIBE_MODAL.MODAL6.CTA')}
          isLoading={false}
          testID="subscribeButton"
        />
        {canTrial && (
          <Text style={styles.trialInfo}>
            {t('SUBSCRIBE_MODAL.PLAN_SECTION.FREE_TRIAL_PERIOD')}
          </Text>
        )}
        <PlanList
          style={styles.plans}
          productList={premiumPlans}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
        />
      </ScrollView>
    </View>
  );
};

export default SubscribeModal6;
