import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  cardContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: Colors.duck300,
    alignItems: 'center',
    justifyContent: 'center',
  },

  labelContainer: {
    marginBottom: 16,
    paddingHorizontal: 6,
    borderWidth: 1,
    borderColor: Colors.duck800,
    borderRadius: 7,
  },
  label: {
    color: Colors.duck800,
    fontFamily: Fonts.type.medium,
    fontSize: Fonts.size.ten,
    lineHeight: 12,
  },
});
