import { AxiosError } from 'axios';

import {
  Login1FA,
  Login2FA,
  TouchId,
  check2fa,
  request2faCode,
  requestTouchId2faCode,
} from '@api-requests/api/auth';
import { storeCredentials } from '@api-requests/api/common';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import { OpenidProviders, Tokens } from '@commonTypes/Auth';
import { useDispatch } from '@commonTypes/redux';
import { useAttachWithVendor, useFinishAttachWith } from '@hooks/openid';
import { useRecaptchaQuery } from '@hooks/useRecaptchaQuery';
import { userCreators, UserData } from '@redux/user';
import { useMutation } from '@tanstack/react-query';
import { captureException } from '@tools/sentry';

import { useHandle2FASuccess } from '../utils';

export const useSend2FASms = () => {
  const _request2faCode = useRecaptchaQuery(request2faCode, 'request2faCode');

  return useMutation<ReducedAxiosResponse<{}>, any, Login1FA>({
    mutationFn: _request2faCode,
  });
};

export const useSend2FATouchId = () =>
  useMutation<
    ReducedAxiosResponse<{ encryptedMessage: string }>,
    AxiosError,
    TouchId
  >({ mutationFn: requestTouchId2faCode });
export const use2FALogin = (vendorMethod: boolean) => {
  const dispatch = useDispatch();
  const successCallback = useHandle2FASuccess();
  const check2fa_ = useRecaptchaQuery(check2fa, 'check2fa');

  const { mutate: attach } = useFinishAttachWith();
  const { mutate: attachWithApple } = useAttachWithVendor('apple');
  const { mutate: attachWithGoogle } = useAttachWithVendor(
    OpenidProviders.GOOGLE,
  );

  return useMutation<
    ReducedAxiosResponse<{
      tokens: Tokens;
      appUser: UserData;
    }>,
    { status: number },
    Login2FA
  >({
    mutationFn: check2fa_,
    onSuccess: async (data, input) => {
      await successCallback(data.data, 'may');

      dispatch(
        userCreators.userSuccess({
          ...data.data.appUser,
          detail: true,
        }),
      );

      try {
        await storeCredentials({
          email: 'email' in input ? input.email : undefined,
          password: 'password' in input ? input.password : undefined,
          refreshToken: data.data.tokens?.refreshToken,
          provider: 'providerToken' in input ? input.provider : 'may',
        });
      } catch (error: any) {
        captureException(error);
      }
      if ('providerToken' in input) {
        if (input.provider === 'apple') {
          await attachWithApple({
            identityToken: input.providerToken,
          });
        } else if (vendorMethod && input.provider === OpenidProviders.GOOGLE) {
          await attachWithGoogle({
            identityToken: input.providerToken,
          });
        } else {
          attach({
            provider: input.provider,
            providerToken: input.providerToken,
          });
        }
      }
    },
  });
};
