import React, { ReactNode } from 'react';
import { StyleProp, Text, View, ViewStyle } from 'react-native';

import AppIcon from '@components/ui/Icon';
import { IconName } from '@resources/svg';
import { Colors } from '@resources/themes';

import styles from './styles';

interface SectionProps {
  style?: StyleProp<ViewStyle>;
  icon: IconName;
  iconSize?: number;
  iconDescription: string;
  title: string;
  description: string;
  children: ReactNode;
}

const Section = ({
  style,
  icon,
  iconSize = 16,
  iconDescription,
  title,
  description,
  children,
}: SectionProps) => (
  <View style={style}>
    <View style={styles.header}>
      <View style={styles.checkMark}>
        <AppIcon name={'CheckmarkSlim'} size={20} color={Colors.white100} />
      </View>
      <AppIcon name={icon} size={iconSize} color={Colors.duck800} />
      <Text style={styles.iconDescription}>{iconDescription}</Text>
    </View>
    <View style={styles.content}>
      <View style={styles.line} />
      <View style={styles.sectionContent}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.description}>{description}</Text>
        {children}
      </View>
    </View>
  </View>
);

export default Section;
