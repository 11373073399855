import portalSession from '@api-requests/api/main/portalSession';
import useAuthenticatedQuery from '@hooks/useAuthenticatedQuery';
import { keepPreviousData } from '@tanstack/react-query';

export const usePortalSession = (enabled = false) =>
  useAuthenticatedQuery({
    queryFn: portalSession.get,
    queryKey: ['createPortalSession'],
    enabled,
    select: (data) => data?.data,
    gcTime: 0,
    staleTime: 0,
    placeholderData: keepPreviousData,
  });
