import React, { useEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { Platform } from 'react-native';
import { useDispatch } from 'react-redux';

import { OpenidProviders } from '@commonTypes/Auth';
import { useSelector } from '@commonTypes/redux';
import { EmailUsedModal } from '@components/Modal/Signin/EmailUsedModal';
import { NoAccountAssociatedModal } from '@components/Modal/Signin/NoAccountAssociatedModal';
import { SignInFailedModal } from '@components/Modal/Signin/SignInFailedModal';
import {
  useFinishAttachWith,
  useFinishLoginWith,
  useFinishSignupWith,
} from '@hooks/openid';
import { USER_QUERY_KEYS } from '@hooks/useUserDetail';
import { canGoBack, goBack, navigate } from '@navigation/Actions';
import { ANONYMOUS_STACK } from '@navigation/Routes';
import { useQueryClient } from '@tanstack/react-query';

import { AnonymousStackParamList, ScreenRouteParams } from '@navigation/types';

import { signupFormCreator } from '../SignUp/redux/signupInfos';
import { useHandle2FASuccess } from '../utils';

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const OpenIdSignin = ({
  route: { params },
}: ScreenRouteParams<
  AnonymousStackParamList,
  ANONYMOUS_STACK.OPENID_SIGNIN
>) => {
  const { redirectUrl, provider, purpose } = params;

  const [signinFailed, setSigninFailed] = useState(false);
  const [noAssociatedAccount, setNoAssociatedAccount] = useState(false);
  const [emailInUse, setEmailInUse] = useState(false);
  const dispatch = useDispatch();
  const newsLetter = useSelector((state) => state.signupForm.newsletterAgreed);

  const { mutate: signUp } = useFinishSignupWith();
  const { mutate: login } = useFinishLoginWith();
  const { mutate: attach } = useFinishAttachWith();
  const successCallback = useHandle2FASuccess();
  let { provider: stateProvider, codeVerifier } = useSelector(
    (state) => state.openId ?? {},
  );
  if (Platform.OS === 'web') {
    stateProvider = sessionStorage.getItem('provider') as OpenidProviders;
    codeVerifier = sessionStorage.getItem('codeVerifier') as string;
  }
  const queryClient = useQueryClient();
  useEffect(() => {
    if (stateProvider !== provider) {
      setSigninFailed(true);
    }
  }, [stateProvider, provider]);

  useEffect(() => {
    if (!redirectUrl) {
      setSigninFailed(true);
    } else if (purpose === 'signup') {
      signUp(
        {
          redirectUrl: redirectUrl.replace('maysante://', 'https://'),
          provider,
          codeVerifier,
          newsLetter,
        },
        {
          onSuccess({ data }) {
            successCallback(data, provider);
          },
          onError(error) {
            const status = error?.response?.status || error?.status;
            if (status === 400) {
              setEmailInUse(true);
            } else {
              setSigninFailed(true);
            }
          },
        },
      );
    } else if (purpose === 'attach') {
      attach(
        {
          redirectUrl: redirectUrl.replace('maysante://', 'https://'),
          provider,
          codeVerifier,
        },
        {
          onSuccess() {
            queryClient.invalidateQueries({
              queryKey: USER_QUERY_KEYS.detail,
            });
            canGoBack() ? goBack() : navigate(ANONYMOUS_STACK.LOGIN);
          },
          onError(error) {
            const status = error?.response?.status || error?.status;
            if (status === 400) {
              setEmailInUse(true);
            } else {
              setSigninFailed(true);
            }
          },
        },
      );
    } else {
      login(
        {
          redirectUrl: redirectUrl.replace('maysante://', 'https://'),
          provider,
          codeVerifier,
        },
        {
          onSuccess: ({ data }) => {
            if ('tokens' in data) {
              successCallback(data, provider);
            } else {
              if (data.phone) {
                dispatch(
                  signupFormCreator.addProviderData({ ...data, provider }),
                );
                navigate(ANONYMOUS_STACK.ATTACH_SUGGESTION_SCREEN);
              } else {
                setEmailInUse(true);
              }
            }
          },
          onError: (error) => {
            const status = error?.response?.status || error?.status;
            if (status === 404) {
              setNoAssociatedAccount(true);
            } else {
              setSigninFailed(true);
            }
          },
        },
      );
    }
  }, [
    redirectUrl,
    provider,
    purpose,
    codeVerifier,
    signUp,
    login,
    successCallback,
    attach,
    queryClient,
    dispatch,
    newsLetter,
  ]);

  return (
    <View style={styles.container}>
      <ActivityIndicator />
      <NoAccountAssociatedModal
        isVisible={noAssociatedAccount}
        onClose={() => {
          setNoAssociatedAccount(false);
          canGoBack() ? goBack() : navigate(ANONYMOUS_STACK.LOGIN);
        }}
        provider={provider}
      />
      <SignInFailedModal
        isVisible={signinFailed}
        onClose={() => {
          setSigninFailed(false);
          canGoBack() ? goBack() : navigate(ANONYMOUS_STACK.LOGIN);
        }}
      />
      <EmailUsedModal
        isVisible={emailInUse}
        onClose={() => {
          setEmailInUse(false);
          canGoBack() ? goBack() : navigate(ANONYMOUS_STACK.LOGIN);
        }}
        provider={provider}
        purpose="attach"
      />
    </View>
  );
};
