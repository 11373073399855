export enum SkinColor {
  LIGHT_SKIN = 'lightSkin',
  MEDIUM_LIGHT_SKIN = 'mediumLightSkin',
  MEDIUM_SKIN = 'mediumSkin',
  MEDIUM_DARK_SKIN = 'mediumDarkSkin',
  DARK_SKIN = 'darkSkin',
}

export const skinColorsMapping: Record<SkinColor, string> = {
  [SkinColor.LIGHT_SKIN]: '#F5DDC0',
  [SkinColor.MEDIUM_LIGHT_SKIN]: '#DABC9A',
  [SkinColor.MEDIUM_SKIN]: '#B8916E',
  [SkinColor.MEDIUM_DARK_SKIN]: '#936644',
  [SkinColor.DARK_SKIN]: '#80553F',
};
