import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import analytics from '@redux/analytics';
import appState from '@redux/app-state-update';
import auth from '@redux/auth';
import hasLoggedOnce from '@redux/has-logged-once';
import listenerMiddleware from '@redux/listenerMiddleware';
import user from '@redux/user';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import signupForm from '@screens/Auth/SignUp/redux/signupInfos';
import webPreOnboardingWorkflow from '@webScreens/PreOnboardingScreens/store/webPreOnboardingWorkflowSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['hasLoggedOnce', 'analytics', 'auth', 'user'],
};

const rootReducers = combineReducers({
  analytics,
  auth,
  user,
  signupForm,
  hasLoggedOnce,
  appState,
  webPreOnboardingWorkflow,
});

const resettableAppReducer = (...args: Parameters<typeof rootReducers>) =>
  rootReducers(...args);

const persistedReducer = persistReducer(persistConfig, resettableAppReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).prepend(listenerMiddleware.middleware),
});

export const persistor = persistStore(store);

export type WebRootState = ReturnType<typeof store.getState>;
