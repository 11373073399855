import upperFirst from 'lodash/upperFirst';
import React from 'react';

import { OpenidProviders } from '@commonTypes/Auth';
import { PrimaryButton } from '@components/Buttons';
import AlertModal from '@components/Modal/AlertModal';
import { t } from '@config';

export const NoAccountAssociatedModal = ({
  isVisible,
  onClose,
  provider,
}: {
  isVisible: boolean;
  onClose: () => void;
  provider: OpenidProviders | 'apple';
}) => {
  return (
    <AlertModal
      isVisible={isVisible}
      illustrationSource={'StopIcon'}
      title={t('AUTH.SIGN_IN.ERROR.NO_ACCOUNT_TITLE')}
      description={t('AUTH.SIGN_IN.ERROR.NO_ACCOUNT_MESSAGE', {
        provider: upperFirst(provider),
      })}
      onClose={onClose}
    >
      <PrimaryButton
        label={t('AUTH.SIGN_IN.ERROR.BUTTON')}
        onPress={onClose}
        isLoading={false}
      />
    </AlertModal>
  );
};
