import { initializeApp } from 'firebase/app';
import {
  getRemoteConfig,
  getValue,
  fetchAndActivate,
} from 'firebase/remote-config';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBnQcLKSPRFosBxRgRJFlFNt9-lEsuf5P0',
  authDomain: 'may-app-53f1b.firebaseapp.com',
  databaseURL: 'https://may-app-53f1b.firebaseio.com',
  projectId: 'may-app-53f1b',
  storageBucket: 'may-app-53f1b.appspot.com',
  messagingSenderId: '553241036865',
  appId: '1:553241036865:web:7bf4066d1af99fb47d5ec0',
  measurementId: 'G-N5YD39VSN8',
};

const app = initializeApp(firebaseConfig);

export const bootstrapRemoteConfig = () => {
  const rc = getRemoteConfig(app);

  rc.defaultConfig = {
    SubscribeModalName: 6,
    onboardingScenario: '1',
    notificationPermissionScenario: '1',
    minimumRequiredVersion: '1.3.1',
    seenMessageLimit: '2022-10-08T00:00:00.000Z',
    churnScenario: '',
    staticPaymentImageSlug: '',
  };

  if (__DEV__) {
    rc.settings.minimumFetchIntervalMillis = 300;
  }
  fetchAndActivate(rc);
};

export const remoteConfig = () => {
  const rc = getRemoteConfig(app);
  return {
    getValue: (key: string) => {
      return getValue(rc, key);
    },
    fetchAndActivate: () => {
      return fetchAndActivate(rc);
    },
  };
};
