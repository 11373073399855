import React from 'react';
import {
  ControllerFieldState,
  ControllerProps,
  FieldError,
  Path,
} from 'react-hook-form';
import { StyleSheet, Text, View } from 'react-native';

import { t } from '@config';
import { Colors, Fonts } from '@resources/themes';
const styles = StyleSheet.create({
  inputName: {
    color: Colors.duck800,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.title,
    marginTop: 4,
    marginBottom: 4,
  },

  inputNameError: {
    color: Colors.pink700,
  },
  helperText: {
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.medium,
    marginTop: 5,
    width: '100%',
  },
  inlineContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  columnContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

export function InputWrapper<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
>({
  children,
  label = '',
  fieldState,
  inline = false,
  errorMessage = (error) =>
    error?.type
      ? t('FORM.HOOK_FORMS_ERROR.' + error.type, {
          defaultValue: error?.message ?? '',
        })
      : '',
  helperText = '',
  rules,
}: Pick<ControllerProps<TFieldValues, TName>, 'rules'> & {
  children: React.ReactNode | React.ReactNode[];
  label?: string;
  inline?: boolean;
  fieldState: ControllerFieldState;
  errorMessage?: (arg?: FieldError) => string;
  helperText?: string;
}) {
  const errorText = errorMessage(fieldState.error);
  return (
    <View>
      <View style={inline ? styles.inlineContainer : styles.columnContainer}>
        {label ? (
          <Text
            style={
              fieldState.error
                ? [styles.inputName, styles.inputNameError]
                : styles.inputName
            }
          >
            {rules?.required && !label?.endsWith('*') ? `${label}*` : label}
          </Text>
        ) : (
          <View />
        )}

        {children}
      </View>
      {(errorText || helperText) && (
        <Text
          style={
            errorText
              ? [styles.helperText, styles.inputNameError]
              : styles.helperText
          }
        >
          {errorText || helperText}
        </Text>
      )}
    </View>
  );
}
