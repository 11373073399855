import React from 'react';

import Option from '@components/Option';
import { t } from '@config';
import { Colors } from '@resources/themes';

export function AddChildOption({
  onPress,
  hasSeparator,
}: {
  onPress: () => void;
  hasSeparator?: boolean;
}) {
  return (
    <Option
      label={t('ADD_KID.BLOCK.CHILD_BORN')}
      illustrationSource={'BoyIcon'}
      size={32}
      onPress={onPress}
      hasSeparator={hasSeparator}
      testID="addChildOption"
    />
  );
}

export function AddPregnancyOption({ onPress }: { onPress: () => void }) {
  return (
    <Option
      label={t('ADD_KID.BLOCK.WAIT_CHILD')}
      illustrationSource={'EggIcon'}
      size={32}
      onPress={onPress}
      backgroundColor={Colors.pink200}
      testID="addPregnancyOption"
    />
  );
}
