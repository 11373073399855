import { resetAll } from '@redux/reduxReset';
import { createSlice } from '@reduxjs/toolkit';

const initialState = { showModal: false };

const storageModalPermissionSlice = createSlice({
  name: 'storageModalPermission',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    showModal: (state) => {
      state.showModal = true;
    },
    hideModal: (state) => {
      state.showModal = false;
    },
  },
});

export const storageModalPermissionCreators =
  storageModalPermissionSlice.actions;
export default storageModalPermissionSlice.reducer;
