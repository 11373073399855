import React from 'react';

import CheckBox, { CheckBoxProps } from '@components/ui/CheckBox';
import { t } from '@config';

function CheckBoxNewsletter(props: Omit<CheckBoxProps, 'children'>) {
  return <CheckBox {...props}>{t('AUTH.SIGN_UP.CHECKBOX_NEWS')}</CheckBox>;
}

export default CheckBoxNewsletter;
