import React from 'react';
import { Image, Text, View } from 'react-native';

import { BorderlessButton, PrimaryButton } from '@components/Buttons';
import { t } from '@config';
import { navigate } from '@navigation/Actions';
import doctorImg from '@resources/images/illu_docteur-seule-chatain.webp';
import { redirectToStore } from '@web/src/utils/redirectToStore';
import { WEB_COMMON_SCREEN_STACK } from '@webNavigation/routes';

import styles from './styles';

const goToSubscribeScreen = () =>
  navigate(WEB_COMMON_SCREEN_STACK.SUBSCRIBE_SCREEN, {
    targetTab: undefined,
  });

export function UnsubscribedHomeScreen() {
  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image style={styles.image} source={doctorImg} resizeMode="contain" />
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.title}>{t('AUTH.DOWNLOAD.TITLE')}</Text>
        <Text style={styles.description} numberOfLines={3}>
          {t('AUTH.DOWNLOAD.DESCRIPTION')}
        </Text>
      </View>
      <View>
        <PrimaryButton
          label={t('AUTH.DOWNLOAD.BUTTON')}
          onPress={redirectToStore}
          isLoading={false}
        />
        <BorderlessButton
          label={t('AUTH.DOWNLOAD.SUBSCRIBE')}
          onPress={goToSubscribeScreen}
          isLoading={false}
        />
      </View>
    </View>
  );
}
