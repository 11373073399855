import { Platform, StyleSheet } from 'react-native';

import DEVICE from '@resources/constants/device';
import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  modal: {
    marginTop: 'auto',
    marginBottom: 'auto',
    alignItems: 'center',
  },
  card: {
    width: DEVICE.SCREEN_WIDTH - 64,
    maxHeight: DEVICE.SCREEN_HEIGHT - 80,
    backgroundColor: Colors.white100,
    borderRadius: 16,
    overflow: 'hidden',
    borderTopWidth: 16,
    borderColor: Colors.duck800,
    ...Platform.select({
      web: {},
      default: {
        elevation: 2,
        shadowOffset: { width: 0, height: 10 },
        shadowOpacity: 1,
        shadowRadius: 10,
        shadowColor: Colors.duck700,
      },
    }),
  },
  inner: {
    paddingVertical: 24,
    paddingHorizontal: 16,
    alignItems: 'center',
    gap: 24,
  },

  textContainer: { gap: 16, alignItems: 'center' },
  title: {
    color: Colors.duck800,
    fontFamily: Fonts.type.Recoleta.medium,
    fontSize: Fonts.size.h3,
    textAlign: 'center',
  },
  description: {
    color: Colors.duck700,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.mediumUp,
    textAlign: 'center',
  },

  actionContainer: { gap: 8 },
});
