import AFFILIATION from '@resources/i18n/ui/en/affiliation.json';
import API_ERROR from '@resources/i18n/ui/en/apiError.json';
import AUTH from '@resources/i18n/ui/en/auth.json';
import CAMERA from '@resources/i18n/ui/en/camera.json';
import COUNTRIES from '@resources/i18n/ui/en/countries.json';
import CRASH_LOG from '@resources/i18n/ui/en/crashLog.json';
import FAQ_PREGNANCY from '@resources/i18n/ui/en/faqPregnancy.json';
import FAVORITE from '@resources/i18n/ui/en/favorite.json';
import FORM from '@resources/i18n/ui/en/form.json';
import GENERAL_ERROR from '@resources/i18n/ui/en/generalError.json';
import GUIDE from '@resources/i18n/ui/en/guide.json';
import HOME from '@resources/i18n/ui/en/home.json';
import LOGGED_OUT_HOME from '@resources/i18n/ui/en/loggedOutHome.json';
import MENU from '@resources/i18n/ui/en/menu.json';
import PAYMENT from '@resources/i18n/ui/en/payment.json';
import PHOTO_LIBRARY_MODAL from '@resources/i18n/ui/en/photoLibraryModal.json';
import PROFILE from '@resources/i18n/ui/en/profile.json';
import SETTINGS from '@resources/i18n/ui/en/settings.json';
import LIBRARY from '@resources/i18n/ui/fr/library.json';
import POST from '@resources/i18n/ui/fr/post.json';

export default {
  API_ERROR,
  AUTH,
  CAMERA,
  COUNTRIES,
  CRASH_LOG,
  FORM,
  HOME,
  LIBRARY,
  GUIDE,
  LOGGED_OUT_HOME,
  MENU,
  PAYMENT,
  POST,
  PHOTO_LIBRARY_MODAL,
  SETTINGS,
  PROFILE,
  FAVORITE,
  AFFILIATION,
  GENERAL_ERROR,
  FAQ_PREGNANCY,
};
