import React, { useState } from 'react';
import { Linking, Platform } from 'react-native';

import { OpenidProviders } from '@commonTypes/Auth';
import { useDispatch } from '@commonTypes/redux';
import { NoAccountAssociatedModal } from '@components/Modal/Signin/NoAccountAssociatedModal';
import { SignInFailedModal } from '@components/Modal/Signin/SignInFailedModal';
import { OpenIdCreators } from '@redux/openid';
import { captureQueryError } from '@tools/captureQueryError';

import { SignInButton } from './SignInButton';

/*
 * Complicated button because flows are multiple :
 *
 *  On Android, with Google Play Services, we use OneTap. The flow is then direct :
 *   - the one tap UI returns a token
 *   - token is sent to the backend as a proof of identity
 *
 *  On iOS and web, we use the web flow, which is a bit more complicated :
 *   - we call the backend to get an authorizationUrl and a codeVerifier
 *   - we open a navigator to the authorizationUrl
 *   - user logs in in the navigator
 *   - we get redirected to the app with a code in OpenIdSignIn component
 *   - we send the code to the backend to get a token
 */

export const SignInWithGoogle = ({
  onPress,
  isSubmitting,
  actionLabel,
  onOneTapSuccess,
}: {
  onPress: (
    provider: OpenidProviders,
    tokenSuccess: (
      token: string,
      userInfo?: {
        familyName?: string | null;
        givenName?: string | null;
        name?: string | null;
      },
    ) => void,
    onAuthorizationUrlSuccess: ({
      data,
    }: {
      data: {
        authorizationUrl: string;
        codeVerifier: string;
      };
    }) => void,
    onAuthorizationUrlError: (error: any) => void,
  ) => Promise<void>;
  onOneTapSuccess: (
    token: string,
    userInfo?: {
      familyName?: string | null;
      givenName?: string | null;
      name?: string | null;
    },
  ) => Promise<any>;
  isSubmitting: boolean;
  actionLabel?: 'CONTINUE_WITH' | 'ATTACH_WITH';
}) => {
  const dispatch = useDispatch();
  const [displayError, setDisplayError] = useState(false);
  const [unknownUser, setUnknownUser] = useState(false);
  return (
    <>
      <SignInButton
        onPress={() =>
          onPress(
            OpenidProviders.GOOGLE,
            async (...args) => {
              try {
                const result = await onOneTapSuccess(...args);
                return result;
              } catch (err: any) {
                if (err?.status === 404 || err?.response?.status === 404) {
                  setUnknownUser(true);
                } else {
                  setDisplayError(true);
                  captureQueryError(err);
                }
                return;
              }
            },
            ({ data }) => {
              if (Platform.OS === 'web') {
                // we use sessionStorage as we don't want to rely on redux-persist which is not always fast enough
                sessionStorage.setItem('codeVerifier', data.codeVerifier);
                sessionStorage.setItem('provider', OpenidProviders.GOOGLE);
                window.location.href = data.authorizationUrl;
                return;
              } else {
                dispatch(
                  OpenIdCreators.prepareRedirection({
                    codeVerifier: data.codeVerifier,
                    provider: OpenidProviders.GOOGLE,
                  }),
                );
              }
              Linking.canOpenURL(data.authorizationUrl)
                .then((supported) => {
                  if (supported) {
                    Linking.openURL(data.authorizationUrl);
                  } else {
                    setDisplayError(true);
                  }
                })
                .catch(() => setDisplayError(true));
            },
            () => setDisplayError(true),
          )
        }
        isSubmitting={isSubmitting}
        provider={OpenidProviders.GOOGLE}
        actionLabel={actionLabel}
      />
      <SignInFailedModal
        isVisible={displayError}
        onClose={() => setDisplayError(false)}
      />
      <NoAccountAssociatedModal
        isVisible={unknownUser}
        onClose={() => setUnknownUser(false)}
        provider={OpenidProviders.GOOGLE}
      />
    </>
  );
};
