import { combineReducers } from 'redux';

import skinColor from '@components/Baby3D/store';
import apiError from '@components/Modal/ApiErrorAlert/Redux/api-error-alert-redux';
import subscribeModal from '@components/Modal/RootSubscribeModal/redux/subscribe-modal';
import tabBarHeight from '@components/ui/CustomTabBar/store/tabBarHeightSlice';
import tabSponsorReminder from '@components/ui/CustomTabBar/store/tabSponsorReminderSlice';
import analytics from '@redux/analytics';
import appState from '@redux/app-state-update';
import auth from '@redux/auth';
import chatDraftMessages from '@redux/draftChatMessage';
import hasLoggedOnce from '@redux/has-logged-once';
import openid from '@redux/openid';
import routes from '@redux/routes';
import storageModalPermission from '@redux/storage-modal-permission';
import TrackDescriptionModal from '@redux/track-description-modal';
import freeContentCounter from '@redux/triggerSubModalCounter';
import user from '@redux/user';
import signupForm from '@screens/Auth/SignUp/redux/signupInfos';
import consultationAppointment from '@screens/Consultations/redux/consultationAppointment';
import breastfeedTimers from '@screens/Journaling/ChildFeedScreen/AddBreastfeedScreen/redux/breastfeedTimerSlice';
import feedEntriesCount from '@screens/Journaling/ChildFeedScreen/store/feedEntriesCountSlice';
import contractionTimer from '@screens/Journaling/ContractionScreen/store/contractionTimerSlice';
import lastContraction from '@screens/Journaling/ContractionScreen/store/lastContractionSlice';
import symptomPreferences from '@screens/Journaling/FertilityScreen/store/symptomsPreferenceSlice';
import sleepEntriesCount from '@screens/Journaling/SleepScreen/AddSleepScreen/redux/sleepEntriesCountSlice';
import sleepTimers from '@screens/Journaling/SleepScreen/AddSleepScreen/redux/sleepTimerSlice';
import fertilityHintCardVisibility from '@screens/LoggedInHome/components/FamilySlider/Cards/FertilityCard/store/fertilityHintCardVisibility';
import faqPurchase from '@screens/LoggedInHome/FaqPurchase/redux/faq-purchase';
import webPreOnboardingWorkflow from '@webScreens/PreOnboardingScreens/store/webPreOnboardingWorkflowSlice';

export default combineReducers({
  auth,
  appState,
  apiError,
  analytics,
  consultationAppointment,
  routes,
  storageModalPermission,
  hasLoggedOnce,
  modal: combineReducers({
    music: TrackDescriptionModal,
  }),
  subscribeModal,
  user,
  signupForm,
  faqPurchase,
  freeContentCounter,
  chatDraftMessages,
  sleepTimers,
  sleepEntriesCount,
  symptomPreferences,
  feedEntriesCount,
  breastfeedTimers,
  skinColor,
  openId: openid,
  tabBarHeight,
  tabSponsorReminder,
  lastContraction,
  contractionTimer,
  fertilityHintCardVisibility,
  webPreOnboardingWorkflow,
});
