import { useMemo, useRef } from 'react';
import { TextInput } from 'react-native';

/**
 * generates instructions to handle focus on next pressing enter
 * @param count number of inputs to handle
 * @returns Array containing onSubmitEditing, blurOnSubmit and ref inputs
 */
export const useNextOnSubmit = (count: number, lastAction?: () => void) => {
  const inputRefs = useRef<(TextInput | null)[]>(Array(count).fill(null));
  return useMemo(
    () =>
      Array(count)
        .fill(0)
        .map((val, index) => {
          const res: { [key: string]: any } = {};
          if (index < count - 1) {
            res.onSubmitEditing = () => inputRefs.current[index + 1]?.focus();
            res.blurOnSubmit = false;
            res.returnKeyType = 'next';
          } else {
            res.returnKeyType = 'done';
            res.onSubmitEditing = lastAction;
          }
          if (index > 0) {
            res.ref = (input: TextInput) => {
              inputRefs.current[index] = input;
            };
          }
          if (index === 0) {
            res.autoFocus = true;
          }
          return res;
        }),
    [count, lastAction],
  );
};
