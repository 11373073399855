import React from 'react';

import { OpenidProviders } from '@commonTypes/Auth';
import { PrimaryButton } from '@components/Buttons';
import AlertModal from '@components/Modal/AlertModal';
import { t } from '@config';

export const EmailUsedModal = ({
  isVisible,
  onClose,
  provider,
  purpose,
}: {
  isVisible: boolean;
  onClose: () => void;
  provider: OpenidProviders | 'apple';
  purpose?: 'attach' | 'login' | 'signup';
}) => {
  return (
    <AlertModal
      isVisible={isVisible}
      illustrationSource={'StopIcon'}
      title={t('AUTH.SIGN_IN.ERROR.EMAIL_ALREADY_USED_TITLE')}
      description={t(
        `AUTH.SIGN_IN.ERROR.${
          purpose === 'attach'
            ? 'EMAIL_ALREADY_USED_ATTACH_MESSAGE'
            : 'EMAIL_ALREADY_USED_MESSAGE'
        }`,
        { provider },
      )}
      onClose={onClose}
    >
      <PrimaryButton
        label={t('AUTH.SIGN_IN.ERROR.BUTTON')}
        onPress={onClose}
        isLoading={false}
      />
    </AlertModal>
  );
};
