// Doing this in plain JS because of the following issue with dayjs / Hermes
// https://github.com/iamkun/dayjs/issues/1377
const FR_OPENING_TIME = 8;
const FR_CLOSING_TIME = 22;

export const getChatOpeningHours = (now = new Date()) => {
  // Compute current UTC offset for 'Europe/Paris' considering DST
  const year = now.getUTCFullYear();
  // Last Sunday of March at 02am local time: UTC+1 -> UTC+2
  const dstStartDate = getLastSunday(year, 2, 2);
  // Last Sunday of October at 03am local time: UTC+2 -> UTC+1
  const dstEndDate = getLastSunday(year, 9, 3);
  const isDST = now >= dstStartDate && now < dstEndDate;
  const parisCurrentUTCOffset = isDST ? 2 : 1;
  const currentUTCOffset = Math.abs(now.getTimezoneOffset() / 60);

  // Converting opening times from 'Europe/Paris' to UTC
  const openingTime = new Date(now);
  openingTime.setUTCHours(FR_OPENING_TIME - parisCurrentUTCOffset, 0, 0, 0);
  const closingTime = new Date(now);
  closingTime.setUTCHours(FR_CLOSING_TIME - parisCurrentUTCOffset, 0, 0, 0);

  // Add a day whenever openingTimes spans over midnight
  if (closingTime < openingTime) {
    closingTime.setDate(closingTime.getDate() + 1);
  }

  return {
    isOnline: now >= openingTime && now <= closingTime,
    openingHour: formatHour(openingTime),
    closingHour: formatHour(closingTime),
    isDifferentFromParis: currentUTCOffset !== parisCurrentUTCOffset,
  };
};

const formatHour = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${hours}h${minutes ? minutes.toString().padStart(2, '0') : ''}`;
};

const getLastSunday = (year: number, monthIndex: number, hours: number) => {
  const firstDayNextMonth = new Date(year, monthIndex + 1, 0);
  const lastSundayDate =
    firstDayNextMonth.getDate() - firstDayNextMonth.getDay();
  return new Date(year, monthIndex, lastSundayDate, hours);
};
