import React from 'react';
import { Image, ImageProps, Text, View } from 'react-native';

import { PressableOpacity } from '@components/PressableOpacity';
import AppIcon from '@components/ui/Icon';
import { IconName } from '@resources/svg';
import { Colors } from '@resources/themes';

import styles from './styles';

type IconOrSource =
  | {
      source?: never;
      iconName: IconName;
    }
  | {
      source: ImageProps['source'];
      iconName?: never;
    };

type BlockProps = {
  selected: boolean;
  label: string;
  onPress: () => void;
  testID?: string;
} & IconOrSource;

export const SelectionBlock = ({
  selected,
  iconName,
  source,
  label,
  onPress,
  testID,
}: BlockProps) => (
  <PressableOpacity
    style={[styles.block, selected && styles.selected]}
    disabled={selected}
    onPress={onPress}
    testID={testID}
  >
    {iconName ? (
      <AppIcon name={iconName} size={24} color={Colors.pink700} />
    ) : (
      <Image source={source} style={styles.imageIcon} />
    )}
    <Text style={styles.label}>{label}</Text>
  </PressableOpacity>
);

type BlockDefinition<T extends string | number> = {
  label: string;
  value: T;
} & IconOrSource;

export interface SelectionBlockGroupProps<T extends string | number> {
  options: readonly [BlockDefinition<T>, BlockDefinition<T>];
  value: T | undefined;
  onChange: (arg: T) => void;
}

export function SelectionBlockGroup<T extends string | number>({
  options,
  value,
  onChange,
}: SelectionBlockGroupProps<T>) {
  return (
    <View style={styles.container}>
      {options.map(({ value: optionValue, ...option }) => (
        <SelectionBlock
          key={`block-${optionValue}`}
          selected={value === optionValue}
          onPress={() => onChange(optionValue)}
          testID={String(optionValue)}
          {...option}
        />
      ))}
    </View>
  );
}
