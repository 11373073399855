import { axiosGet } from '../../request';
import { ScenarioRaw } from '../types';

const baseUrl = '/onboarding-scenarios/';

const getOnboardingScenarios = (slug: string | number) =>
  axiosGet<ScenarioRaw>(`${baseUrl}${slug}`);

export const onboardingScenarios = {
  get: getOnboardingScenarios,
};
