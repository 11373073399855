import React from 'react';
import { InteractionManager, StyleSheet } from 'react-native';

import BottomSheetModal from '@components/Modal/BottomSheetModal';
import { navigate } from '@navigation/Actions';
import { COMMON_SCREEN_STACK } from '@navigation/Routes';
import { useRoute } from '@react-navigation/native';

import { AddChildOption, AddPregnancyOption } from './AddFamilyMemberOptions';

const styles = StyleSheet.create({
  container: { paddingHorizontal: 24, paddingBottom: 24 },
});

const goToAddChild = (originScreen: string) =>
  navigate(COMMON_SCREEN_STACK.ADD_KID_SCREEN, {
    targetScreen: undefined,
    originScreen,
  });
const goToAddPregnancy = (originScreen: string) =>
  navigate(COMMON_SCREEN_STACK.ADD_PREGNANCY_SCREEN, {
    targetScreen: undefined,
    originScreen,
  });

export function AddFamilyMemberBottomSheet(
  props: Pick<AddFamilyMemberUIProps, 'isVisible' | 'onClose'>,
) {
  const route = useRoute();
  return (
    <AddFamilyMemberBottomSheetUI
      {...props}
      onAddPregnancyPress={() => goToAddPregnancy(route.name)}
      onAddKidPress={() => goToAddChild(route.name)}
    />
  );
}

interface AddFamilyMemberUIProps {
  isVisible: boolean;
  onClose: () => void;
  onAddKidPress: () => void;
  onAddPregnancyPress: () => void;
}
export function AddFamilyMemberBottomSheetUI({
  isVisible,
  onClose,
  onAddKidPress,
  onAddPregnancyPress,
}: AddFamilyMemberUIProps) {
  return (
    <BottomSheetModal
      style={styles.container}
      isVisible={isVisible}
      onClose={onClose}
    >
      <AddChildOption
        onPress={() => {
          onClose();
          InteractionManager.runAfterInteractions(onAddKidPress);
        }}
        hasSeparator={!!onAddPregnancyPress}
      />
      <AddPregnancyOption
        onPress={() => {
          onClose();
          InteractionManager.runAfterInteractions(onAddPregnancyPress);
        }}
      />
    </BottomSheetModal>
  );
}
