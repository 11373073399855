import { axiosGet } from '@api-requests/api/cms/request';

import {
  StaticContentRaw,
  StaticContentsSlug,
} from '@screens/StaticContentsScreen/types';

const getStaticContent = (slug: StaticContentsSlug) =>
  axiosGet<StaticContentRaw>(`/static-contents/${slug}`);

export default { getOne: getStaticContent };
