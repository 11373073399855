import { NativeModules } from 'react-native';
import Config from 'react-native-config';

import * as Sentry from '@tools/sentry';

const DeviceAuthModule = NativeModules.DeviceAuth;

export class DeviceAuth {
  private static MAY_KEY =
    Config.AUTHENTICATION_KEY ?? '1234567890abcdef1234567890abcdef';

  static async generateSignature(
    payload: Record<string, boolean> | string,
  ): Promise<string> {
    try {
      let objectPayload =
        typeof payload === 'string' ? JSON.parse(payload) : payload;
      const serializePayload = JSON.stringify(
        objectPayload,
        Object.keys(objectPayload).sort(),
      );

      const res = await DeviceAuthModule.generateSignature(
        serializePayload,
        DeviceAuth.MAY_KEY,
      );
      return res;
    } catch (e: any) {
      Sentry.captureException(e);
      return '';
    }
  }
}
