import React, { PropsWithChildren } from 'react';
import { Text, View, ImageSourcePropType, ScrollView } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { PrimaryButton } from '@components/Buttons';
import Image from '@components/Image';
import BackButton from '@components/ui/Pressables/Actions/BackButton';

import styles from './styles';

interface ImageTemplateProps extends ImageTemplatePropsUI {
  description: string;
}
export function ImageTemplate({ description, ...props }: ImageTemplateProps) {
  return (
    <ImageTemplateUI {...props}>
      <Text style={styles.description}>{description}</Text>
    </ImageTemplateUI>
  );
}

interface ImageTemplatePropsUI {
  image: ImageSourcePropType;
  title: string;
  CTALabel: string;
  onNext: () => void;
  showBack?: boolean;
}
export function ImageTemplateUI({
  image,
  title,
  CTALabel,
  onNext,
  showBack,
  children,
}: PropsWithChildren<ImageTemplatePropsUI>) {
  return (
    <ScrollView
      style={styles.background}
      contentContainerStyle={styles.scrollContent}
    >
      <SafeAreaView style={styles.fill}>
        <View style={styles.container}>
          <View style={styles.imageContainer}>
            <Image source={image} style={styles.image} resizeMode="contain" />
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.title}>{title}</Text>
            {children}
          </View>
          <PrimaryButton
            onPress={onNext}
            label={CTALabel}
            isLoading={false}
            testID="imageTemplateButton"
          />
          {showBack && <BackButton style={styles.backButton} />}
        </View>
      </SafeAreaView>
    </ScrollView>
  );
}
