import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Platform, Text, TextInput, View } from 'react-native';
import {
  CodeField,
  Cursor,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import RNOtpVerify from 'react-native-otp-verify';
import { SafeAreaView } from 'react-native-safe-area-context';

import { logEvent } from '@analytics/common';
import { getCredentials } from '@api-requests/api/common';
import { OpenidProviders } from '@commonTypes/Auth';
import { useDispatch, useSelector } from '@commonTypes/redux';
import { BorderlessButton } from '@components/Buttons';
import { NavBar } from '@components/ui/NavBar';
import { t } from '@config';
import { useFocusField } from '@hooks/useFocusField';
import { canGoBack, goBack, navigate } from '@navigation/Actions';
import { ANONYMOUS_STACK } from '@navigation/Routes';
import { AuthCreators } from '@redux/auth';
import { Colors } from '@resources/themes';
import { performTouchId } from '@tools/touchId';

import { use2FALogin, useSend2FASms, useSend2FATouchId } from './hooks';
import styles from './styles';
import { signupFormCreator } from '../SignUp/redux/signupInfos';

const LOCKED_SMS_TIMEOUT_CAP = 30 * 1000; // 30 seconds
const LOCKED_SMS_TIMEOUT_MULTIPLIER = 5 * 1000; // 5 seconds

const handleBackPress = () => {
  if (canGoBack()) {
    goBack();
  } else {
    navigate(ANONYMOUS_STACK.LOGIN);
  }
};

const getOneFACredentials = async (
  email: string,
  password: string,
  providerToken: string,
  provider?: OpenidProviders | 'apple',
  emailFromState?: string,
) => {
  const { password: passwordFromStore } = await getCredentials();
  const sentEmail = email || emailFromState;
  const sentPassword = password || passwordFromStore;

  if (provider && providerToken) {
    return { provider, providerToken };
  } else if (sentEmail && sentPassword) {
    return { email: sentEmail, password: sentPassword };
  }

  return;
};

const VerificationCodeScreen = () => {
  const [value, setValue] = useState('');
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });
  const codeRef = useRef<TextInput | null>(null);
  const { email, phone, password, provider, providerToken, vendorMethod } =
    useSelector((state) => state.signupForm);
  const appState = useSelector((state) => state.appState?.current);

  const emailFromState = useSelector((state) => state.user.data.email);

  const dispatch = useDispatch();
  const phoneFromState = useSelector((state) => state.user.data.phone);

  const {
    mutate: send2faCode,
    isPending: isLoadingSMS,
    isError: isErrorCode,
    error: errorCode,
  } = useSend2FASms();
  const { mutateAsync: sendTouchIdCode, isPending: isLoadingTouchId } =
    useSend2FATouchId();
  const {
    mutate: login2FA,
    isPending: isLoading2FA,
    isError: is2FAError,
    error: error2FA,
  } = use2FALogin(vendorMethod);

  const [lockedSms, setLockedSms] = useState(false);
  const lockedSmsCount = useRef(0);
  // prevent from spamming sms
  useEffect(() => {
    let mounted = true;
    if (lockedSms) {
      lockedSmsCount.current += 1;
      setTimeout(() => {
        if (mounted) {
          setLockedSms(false);
        }
      }, Math.min(LOCKED_SMS_TIMEOUT_CAP, LOCKED_SMS_TIMEOUT_MULTIPLIER * Math.sqrt(lockedSmsCount.current)));
    }
    return () => {
      mounted = false;
    };
  }, [lockedSms]);

  const isLoading = isLoadingSMS || isLoadingTouchId || isLoading2FA;

  const resendSms = useCallback(async () => {
    const oneFA = await getOneFACredentials(
      email,
      password,
      providerToken,
      provider,
      emailFromState,
    );
    if (!oneFA) {
      logEvent('weird_2fa_case_verif_screen');
      dispatch(AuthCreators.authLogout());
      return;
    }
    send2faCode(oneFA, {
      onSuccess: () => {
        dispatch(signupFormCreator.reset());
        setLockedSms(true);
      },
    });
  }, [
    dispatch,
    email,
    emailFromState,
    password,
    provider,
    providerToken,
    send2faCode,
  ]);

  const hasePerformedFirstRequest = useRef(false);

  const sentEmail = email || emailFromState || '';
  useEffect(() => {
    const firstRequest = async () => {
      const oneFA = await getOneFACredentials(
        sentEmail,
        password,
        providerToken,
        provider,
      );
      if (!oneFA) {
        logEvent('weird_2fa_case_verif_screen');
        dispatch(AuthCreators.authLogout());
        return;
      }

      const plainMessage = await performTouchId(
        oneFA,
        providerToken ? email : sentEmail,
        sendTouchIdCode,
      );

      if (!plainMessage) {
        send2faCode(oneFA, {
          onSuccess: () => {
            setLockedSms(true);
          },
          onError: (e) => {
            if (e.status === 401) {
              navigate(ANONYMOUS_STACK.LOGIN);
            }
          },
        });
      } else {
        login2FA({
          ...oneFA,
          code: plainMessage,
        });
      }
    };

    if (appState === 'active' && !hasePerformedFirstRequest.current) {
      hasePerformedFirstRequest.current = true;
      firstRequest();
    }
  }, [
    appState,
    dispatch,
    email,
    login2FA,
    password,
    provider,
    providerToken,
    send2faCode,
    sendTouchIdCode,
    sentEmail,
  ]);

  const [isSubmitting, setSubmitting] = useState(false);

  const validate = useCallback(
    async (code: string) => {
      setValue(code);

      if (code.length === 8) {
        setSubmitting(true);
        if (Platform.OS === 'android') {
          RNOtpVerify.removeListener();
        }
        const oneFA = await getOneFACredentials(
          email,
          password,
          providerToken,
          provider,
          emailFromState,
        );
        if (!oneFA) {
          logEvent('weird_2fa_case_verif_screen');
          dispatch(AuthCreators.authLogout());
          return;
        }
        if (!isLoading2FA) {
          login2FA(
            {
              code,
              ...oneFA,
            },
            {
              onSettled: () => {
                setSubmitting(false);
              },
            },
          );
        } else {
          setSubmitting(false);
        }
      }
    },
    [
      dispatch,
      email,
      emailFromState,
      isLoading2FA,
      login2FA,
      password,
      provider,
      providerToken,
      setSubmitting,
    ],
  );

  useEffect(() => {
    if (Platform.OS === 'android') {
      const otpHandler = (message: string) => {
        const otp = /(\d{8})/g.exec(message);
        if (otp) {
          RNOtpVerify.removeListener();
          validate(otp[1]);
        }
      };
      RNOtpVerify.getOtp().then(() => RNOtpVerify.addListener(otpHandler));
    }
  }, [validate]);

  let error;
  if (is2FAError) {
    if (error2FA?.status === 429) {
      error = t('FORM.ERRORS.RATE_LIMIT');
    } else {
      error = t('FORM.CODE_INCORRECT');
    }
  } else if (isErrorCode) {
    if (errorCode?.status === 429) {
      error = t('FORM.ERRORS.RATE_LIMIT');
    } else {
      error = t('AUTH.VERIFICATION_CODE.SEND_ERROR');
    }
  }

  useFocusField(codeRef);

  return (
    <SafeAreaView style={styles.container}>
      <NavBar
        title={t('AUTH.VERIFICATION_CODE.TITLE')}
        onBackPress={handleBackPress}
      />
      <Text style={styles.msg}>
        {t('AUTH.VERIFICATION_CODE.MSG', {
          phoneNumber: phone || phoneFromState,
        })}
      </Text>
      <View style={styles.codeArea}>
        <CodeField
          {...props}
          cellCount={8}
          inputMode="numeric"
          onChangeText={isSubmitting ? () => null : validate}
          ref={codeRef}
          rootStyle={styles.codeField}
          renderCell={({ index, symbol, isFocused }) => (
            <View
              // Make sure that you pass onLayout={getCellOnLayoutHandler(index)} prop to root component of "Cell"
              key={index}
              onLayout={getCellOnLayoutHandler(index)}
              style={[styles.cell, isFocused && styles.cellFocused]}
              testID={`code-${index}`}
            >
              <Text
                style={[
                  styles.cellText,
                  isSubmitting && styles.cellTextDisabled,
                ]}
              >
                {symbol || (isFocused ? <Cursor /> : null)}
              </Text>
            </View>
          )}
          testID={'codeInput'}
          textContentType="oneTimeCode"
          value={value}
        />
        {error && <Text style={styles.error}>{error}</Text>}
      </View>
      <View style={styles.spacer} />
      <View style={styles.resend}>
        <Text style={styles.resendLabel}>
          {t('AUTH.VERIFICATION_CODE.LINK_TITLE')}
        </Text>
        <BorderlessButton
          textColor={Colors.pink500}
          onPress={resendSms}
          label={t('AUTH.VERIFICATION_CODE.LINK')}
          isLoading={isLoading}
          disabled={lockedSms}
        />
      </View>
    </SafeAreaView>
  );
};

export default VerificationCodeScreen;
