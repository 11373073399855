import React from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';

import { Trackers } from '@commonTypes/apiResponses/Trackers';
import ContentCard from '@components/ui/Cards/ContentCard';
import { Colors, Fonts } from '@resources/themes';

import { CategorizedContent } from '@api-requests/api/cms/selected/types';

const styles = StyleSheet.create({
  container: { marginTop: 24 },
  subtitle: {
    marginBottom: 16,
    color: Colors.grey700,
    fontFamily: Fonts.type.medium,
    fontSize: Fonts.size.extraText,
  },
  cardSpacing: { marginTop: 12 },
});

interface SelectedSectionUIProps {
  style?: StyleProp<ViewStyle>;
  title?: string;
  list: Array<CategorizedContent>;
  screen: string;
  trackers: Trackers | undefined;
  disabled?: boolean;
}

const SelectedSectionUI = ({
  style,
  title,
  list,
  screen,
  trackers,
  disabled,
}: SelectedSectionUIProps) => (
  <View style={[styles.container, style]}>
    {title && <Text style={styles.subtitle}>{title}</Text>}
    {list.map((content, index) => (
      <ContentCard
        key={index}
        style={index ? styles.cardSpacing : undefined}
        item={content}
        contentType={content.type}
        screen={screen}
        trackers={trackers}
        disabled={disabled}
      />
    ))}
  </View>
);

export default SelectedSectionUI;
