import React from 'react';

import { BorderlessButton, PrimaryButton } from '@components/Buttons';
import AlertModal from '@components/Modal/AlertModal';
import { t } from '@config';

interface SkipStepModalProps {
  isVisible: boolean;
  onPress: () => void;
  onClose: () => void;
  onLaterPress: () => void;
}
function SkipStepModal({
  isVisible,
  onPress,
  onClose,
  onLaterPress,
}: SkipStepModalProps) {
  return (
    <AlertModal
      isVisible={isVisible}
      illustrationSource={'QuestionIcon'}
      title={t('FORM.NO_CHILD')}
      description={t('FORM.NO_CHILD_DESC')}
      onClose={onClose}
    >
      <PrimaryButton
        label={t('FORM.ADD_CHILD')}
        onPress={onPress}
        isLoading={false}
      />
      <BorderlessButton
        label={t('FORM.LATER')}
        onPress={onLaterPress}
        isLoading={false}
      />
    </AlertModal>
  );
}

export default SkipStepModal;
