import { resetAll } from '@redux/reduxReset';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = 0;
const tabBarHeightSlice = createSlice({
  name: 'tabBarHeight',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setHeight: (_, action: PayloadAction<number>) => action.payload,
  },
});

export const tabBarHeightCreators = tabBarHeightSlice.actions;
export default tabBarHeightSlice.reducer;
