import { Platform, StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  container: {
    padding: 24,
    gap: 32,
    flex: 1,
    ...Platform.select({
      web: { backgroundColor: Colors.white100 },
      default: {},
    }),
  },
  heading: {
    color: Colors.duck800,
    fontFamily: Fonts.type.Recoleta.medium,
    fontSize: Fonts.size.h3,
    lineHeight: 32,
  },

  altAuth: { gap: 16 },
  form: { gap: 24 },

  filler: { flex: 1 },

  forgotPasswordView: { alignSelf: 'center' },
  forgotPasswordText: {
    color: Colors.pink700,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.medium,
  },
  actions: { gap: 16 },
});
