import { resetAll } from '@redux/reduxReset';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {} as { previousRoute?: string; currentRoute?: string };

const routeSlice = createSlice({
  name: 'routes',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setCurrentRoute: (state, action: PayloadAction<string>) => {
      state.previousRoute = state.currentRoute;
      state.currentRoute = action.payload;
    },
  },
});

export const routeCreators = routeSlice.actions;
export default routeSlice.reducer;
