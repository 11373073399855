import { resetAll } from '@redux/reduxReset';
import { createSlice } from '@reduxjs/toolkit';

const initialState = { dismissed: false };

const fertilityHintCardVisibilitySlice = createSlice({
  name: 'fertilityHintCardVisibility',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    dismiss: (state) => {
      state.dismissed = true;
    },
    reset: () => initialState,
  },
});

export const fertilityHintCardVisibilityCreators =
  fertilityHintCardVisibilitySlice.actions;
export default fertilityHintCardVisibilitySlice.reducer;
