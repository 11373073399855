import { OpenidProviders, Tokens } from '@commonTypes/Auth';
import { UserData } from '@redux/user';

import { axiosGet, axiosPost } from './main/request';

export const initSignUp = ({
  provider,
  recaptchaToken,
}: {
  provider: OpenidProviders;
  recaptchaToken: string | undefined;
}) =>
  axiosGet<{
    authorizationUrl: string;
    codeVerifier: string;
  }>({
    url: `/apifo/signup/endpoint/${provider}`,
    recaptchaToken,
  });

export const finishSignUp = ({
  provider,
  ...data
}: {
  provider: OpenidProviders;
  codeVerifier: string;
  redirectUrl: string | undefined;
  newsLetter?: boolean;
}) =>
  axiosPost<{ tokens: Tokens; appUser: UserData }>({
    url: `/apifo/signup/finalize/${provider}`,
    params: { data },
  });

export const signupWithVendor = (data: {
  identityToken: string;
  provider: OpenidProviders.GOOGLE | 'apple';
  givenName?: string;
  familyName?: string;
  newsLetter?: boolean;
}) =>
  axiosPost<{ tokens: Tokens; appUser: UserData }>({
    url: '/apifo/signup/vendor',
    params: { data },
  });
