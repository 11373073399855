import React from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

const styles = StyleSheet.create({
  labelContainer: {
    paddingHorizontal: 8,
    borderRadius: 11,
    borderWidth: 1,
    borderColor: Colors.duck800,
  },
  label: {
    fontFamily: Fonts.type.medium,
    fontSize: Fonts.size.small,
    color: Colors.duck800,
    lineHeight: 20,
  },
});

const Label = ({
  children,
  style,
}: {
  style?: StyleProp<ViewStyle>;
  children: string;
}) => (
  <View style={[styles.labelContainer, style]}>
    <Text style={styles.label} numberOfLines={1}>
      {children}
    </Text>
  </View>
);

export default Label;
