import upperFirst from 'lodash/upperFirst';
import React from 'react';
import { StyleSheet } from 'react-native';

import { OpenidProviders } from '@commonTypes/Auth';
import { SecondaryButton } from '@components/Buttons';
import { t } from '@config';
import { IconName } from '@resources/svg';

const IconNames: Record<OpenidProviders | 'apple' | 'may', IconName> = {
  google: 'Google',
  apple: 'Apple',
  may: 'Mail',
};

const styles = StyleSheet.create({ button: { width: '100%' } });

export const SignInButton = ({
  provider,
  onPress,
  isSubmitting,
  actionLabel = 'CONTINUE_WITH',
}: {
  provider: OpenidProviders | 'apple' | 'may';
  onPress: () => void;
  isSubmitting: boolean;
  actionLabel?: 'CONTINUE_WITH' | 'ATTACH_WITH';
}) => {
  return (
    <SecondaryButton
      style={styles.button}
      onPress={onPress}
      iconName={IconNames[provider]}
      label={t(`AUTH.SIGN_IN.${actionLabel}`, {
        provider: provider === 'may' ? "l'email" : upperFirst(provider),
      })}
      isLoading={isSubmitting}
    />
  );
};
