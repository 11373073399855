import React, { useState } from 'react';

import { OpenidProviders } from '@commonTypes/Auth';
import { useSelector } from '@commonTypes/redux';
import { EmailUsedModal } from '@components/Modal/Signin/EmailUsedModal';
import { SignInFailedModal } from '@components/Modal/Signin/SignInFailedModal';
import { useInitSignupWith, useSignupWithVendor } from '@hooks/openid';
import { useOneTapSigninOrFallback } from '@hooks/useOneTapSigning';
import { useHandle2FASuccess } from '@screens/Auth/utils';

import { SignInWithGoogle } from './SignInWithGoogle';

export const SignupWithGoogle = ({ onClose }: { onClose: () => void }) => {
  const oidcSignup = useInitSignupWith();
  const { mutate: initSignup, isLoading: isSigningIn } =
    useOneTapSigninOrFallback(oidcSignup, onClose);

  const { mutateAsync: signupWithGoogle } = useSignupWithVendor(
    OpenidProviders.GOOGLE,
  );
  const successCallback = useHandle2FASuccess();
  const [signinFailed, setSigninFailed] = useState(false);
  const [emailInUse, setEmailInUse] = useState(false);
  const newsLetter = useSelector((state) => state.signupForm.newsletterAgreed);

  return (
    <>
      <SignInFailedModal
        isVisible={signinFailed}
        onClose={() => {
          setSigninFailed(false);
        }}
      />
      <EmailUsedModal
        isVisible={emailInUse}
        onClose={() => {
          setEmailInUse(false);
        }}
        provider={'apple'}
      />
      <SignInWithGoogle
        onPress={initSignup}
        onOneTapSuccess={async (token: string, userInfo) => {
          try {
            const result = await signupWithGoogle({
              identityToken: token,
              givenName: userInfo?.givenName ?? '',
              familyName: userInfo?.familyName ?? '',
              newsLetter,
            });
            await successCallback(result.data, OpenidProviders.GOOGLE);
          } catch (error: any) {
            const status = error?.response?.status || error?.status;
            if (status === 400) {
              setEmailInUse(true);
            } else {
              setSigninFailed(true);
            }
          }
        }}
        isSubmitting={isSigningIn}
      />
    </>
  );
};
