import React, { useEffect } from 'react';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';

import { Colors } from '@resources/themes';

import { Props } from './types';

const PlaceholderLine = ({
  style,
  startColor = Colors.duck300,
  endColor = Colors.grey400,
  width,
  height,
}: Props) => {
  const progress = useSharedValue(0);

  useEffect(() => {
    progress.value = withRepeat(
      withTiming(1 - progress.value, { duration: 1000 }),
      -1,
      true,
    );
  }, [progress]);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      backgroundColor: interpolateColor(
        progress.value,
        [0, 1],
        [startColor, endColor],
      ),
    };
  }, []);
  // @ts-ignore
  return <Animated.View style={[animatedStyle, style, { width, height }]} />;
};

export default PlaceholderLine;
