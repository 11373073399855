import { Reducer, Store } from 'redux';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import { GlobalState } from '@commonTypes/redux';
import createReducer from '@redux';
import { AuthCreators } from '@redux/auth';
import listenerMiddleware from '@redux/listenerMiddleware';
import { configureStore } from '@reduxjs/toolkit';

import REDUX_PERSIST from './persistStore';

const baseReducer: Reducer<GlobalState> = (state, action) => {
  if (action && action.type === AuthCreators.clear.type) {
    return createReducer(
      {
        hasLoggedOnce: state?.hasLoggedOnce,
      } as GlobalState,
      action,
    );
  }
  return createReducer(state, action);
};

const createStore = () => {
  const store: Store<ReturnType<typeof createReducer>> = configureStore({
    reducer: persistReducer<GlobalState>(
      REDUX_PERSIST.storeConfig,
      baseReducer,
    ),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).prepend(listenerMiddleware.middleware),
    devTools: __DEV__,
  });

  return store;
};

export const store = createStore();
export const persistor = persistStore(store);
