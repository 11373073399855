import staticContents from '@api-requests/api/staticContents';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import { useQuery } from '@tanstack/react-query';

import { StaticContent, StaticContentRaw, StaticContentsSlug } from '../types';

export const useStaticContent = (slug: StaticContentsSlug) =>
  useQuery<ReducedAxiosResponse<StaticContentRaw>, any, StaticContent>({
    queryKey: ['staticContent', slug],
    queryFn: () => staticContents.getOne(slug),
    enabled: !!slug,
    select: ({ data }) => ({
      ...data,
      lastUpdate: data.lastUpdate ? new Date(data.lastUpdate) : null,
    }),
  });
