import React from 'react';

import { t } from '@config';
import { Colors } from '@resources/themes';

import Field from './';

const ReadingTime = ({
  contentColor = Colors.duck800,
  readingTime,
}: {
  contentColor?: string;
  readingTime: string;
}) => (
  <Field
    iconName={'Clock'}
    contentColor={contentColor}
    label={t('CMS_CARD.READING_TIME', { readingTime })}
  />
);

export default ReadingTime;
