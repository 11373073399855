import {
  Product,
  PRODUCT_INTERVAL,
  PRODUCT_TIER,
} from '@api-requests/api/main/payment/products/types';

export const getDefaultPlan = (
  productList: Product[] | undefined,
  targetTier: PRODUCT_TIER,
  targetInterval: PRODUCT_INTERVAL,
) =>
  productList?.find(
    (product) =>
      product.tier === targetTier && product.interval === targetInterval,
  ) ?? null;
