import debounce from 'lodash/debounce';
import React, { useEffect } from 'react';
import { AppState, Linking, StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';

import { OpenidProviders } from '@commonTypes/Auth';
import { useSelector } from '@commonTypes/redux';
import { PrimaryButton } from '@components/Buttons';
import { useTokens } from '@hooks/useTokens';
import { getStoredNavigation, navigationRef } from '@navigation/Actions';
import { ANONYMOUS_STACK } from '@navigation/Routes';
import {
  getStateFromPath,
  NavigationContainer,
} from '@react-navigation/native';
import { appStateCreators } from '@redux/app-state-update';
import { WEB_WIDTH } from '@resources/constants/web';
import { Colors } from '@resources/themes';
import {
  WEB_ANONYMOUS_STACK,
  WEB_COMMON_SCREEN_STACK,
} from '@webNavigation/routes';
import WebNavigator from '@webNavigation/WebNavigator';

const styles = StyleSheet.create({
  webContainer: {
    // @ts-ignore
    height: '100vh',
    // @ts-ignore
    width: '100vw',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  appContainer: {
    height: '100%',
    maxWidth: WEB_WIDTH,
    minWidth: 300,
    backgroundColor: Colors.white100,
    flex: 1,
    justifyContent: 'center',
  },
});

// add here only routes that can be accessed directly
const config = {
  screens: {
    [ANONYMOUS_STACK.LOGIN]: '/login',
    [ANONYMOUS_STACK.FORGOT_PASSWORD]: '/forgotPassword',
    [ANONYMOUS_STACK.REGISTER]: '/register',
    [ANONYMOUS_STACK.OPENID_SIGNIN]: '/authorize/google',
    [WEB_ANONYMOUS_STACK.INTRO_SCREEN]: '/intro',
    [WEB_COMMON_SCREEN_STACK.SUBSCRIBE_SUCCESS_SCREEN]: '/download',
    [WEB_COMMON_SCREEN_STACK.LOGOUT_SCREEN]: '/logout',
  },
};

/**
 * Whenever route changes, ref gets updated multiple times, which prevents us from using the onReady prop
 *
 * Doing this instead to update ref through a callback passed to the ref prop
 */
const debouncedNavigatorUpdate = debounce(getStoredNavigation, 100);

const RootNavigator = () => (
  <View style={styles.webContainer}>
    <View style={styles.appContainer}>
      <NavigationContainer
        linking={{
          prefixes: ['https://www.may.app', 'http://localhost:5173'],
          config,
          getStateFromPath(path, options) {
            if (path.startsWith('/register')) {
              // Force register to be called at the first step
              return getStateFromPath('/register', options);
            } else if (path.startsWith('/authorize/google')) {
              return {
                routes: [
                  {
                    name: ANONYMOUS_STACK.OPENID_SIGNIN,
                    path,
                    params: {
                      redirectUrl: window.location.href,
                      provider: OpenidProviders.GOOGLE,
                      purpose: 'authorize',
                    },
                  },
                ],
              };
            } else if (path.startsWith('/signup/google')) {
              return {
                routes: [
                  {
                    name: ANONYMOUS_STACK.OPENID_SIGNIN,
                    path,
                    params: {
                      redirectUrl: window.location.href,
                      provider: OpenidProviders.GOOGLE,
                      purpose: 'signup',
                    },
                  },
                ],
              };
            } else {
              return getStateFromPath(path, options);
            }
          },
        }}
        ref={(navigator) => {
          if (navigator) {
            debouncedNavigatorUpdate();
          }
          // @ts-ignore
          navigationRef.current = navigator;
        }}
      >
        <WebNavigator />
      </NavigationContainer>
    </View>
  </View>
);

const NormalApp = () => {
  const isLogged = useSelector((state) => state.auth.isLogged);
  useTokens({ enabled: !!isLogged });
  const dispatch = useDispatch();

  useEffect(() => {
    const listener = AppState.addEventListener('change', (nextAppState) => {
      if (nextAppState === 'active') {
        dispatch(appStateCreators.updateAppState(nextAppState));
      }
    });
    return () => listener.remove();
  }, [dispatch]);

  return <RootNavigator />;
};

const DeepLinkApp = ({ link }: { link: string }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <View style={styles.webContainer}>
      <View style={styles.appContainer}>
        <PrimaryButton
          isLoading={isLoading}
          label="Retourner dans l'application"
          onPress={() => {
            setIsLoading(true);
            Linking.openURL(link).then(() => setIsLoading(false));
          }}
        />
      </View>
    </View>
  );
};

const App = () => {
  const [isOnNormalDomain, setIsOnNormalDomain] =
    React.useState<boolean>(false);
  const [link, setLink] = React.useState<string | null>(null);
  // link.may - care.fr;

  useEffect(() => {
    if (
      window.location.href.includes('http://link') ||
      window.location.href.includes('https://link')
    ) {
      setIsOnNormalDomain(false);
      const url = window.location.href;
      const path = url.split(/https?:\/\/link[^/]+/).pop();
      if (path) {
        setLink(`maysante://maysante.page.link${path}`);
      } else {
        setLink(null);
        setIsOnNormalDomain(true);
      }
    } else {
      setIsOnNormalDomain(true);
    }
  }, []);

  if (!isOnNormalDomain && !link) {
    return null;
  }
  if (link) {
    return <DeepLinkApp link={link} />;
  }
  return <NormalApp />;
};

export default App;
