import React from 'react';
import { useDispatch } from 'react-redux';

import { OpenidProviders } from '@commonTypes/Auth';
import { useInitLoginWith, useVendorSignin } from '@hooks/openid';
import { useOneTapSigninOrFallback } from '@hooks/useOneTapSigning';
import { navigate } from '@navigation/Actions';
import { ANONYMOUS_STACK } from '@navigation/Routes';
import { signupFormCreator } from '@screens/Auth/SignUp/redux/signupInfos';
import { useHandle2FASuccess } from '@screens/Auth/utils';

import { SignInWithGoogle } from './SignInWithGoogle';

export const LoginWithGoogle = () => {
  const oidcLogin = useInitLoginWith();
  const { mutate: initSignin, isLoading: isSigningIn } =
    useOneTapSigninOrFallback(oidcLogin);

  const { mutateAsync: signInWithGoogle } = useVendorSignin(
    OpenidProviders.GOOGLE,
  );
  const successCallback = useHandle2FASuccess();
  const dispatch = useDispatch();

  return (
    <SignInWithGoogle
      onPress={initSignin}
      onOneTapSuccess={async (token: string) => {
        const result = await signInWithGoogle({
          identityToken: token,
        });
        if ('tokens' in result.data) {
          await successCallback(result.data, OpenidProviders.GOOGLE);
        } else {
          dispatch(
            signupFormCreator.addProviderData({
              ...result.data,
              provider: OpenidProviders.GOOGLE,
              providerToken: token!,
              vendorMethod: true,
            }),
          );
          navigate(ANONYMOUS_STACK.ATTACH_SUGGESTION_SCREEN);
        }
      }}
      isSubmitting={isSigningIn}
    />
  );
};
