import React from 'react';

import { StepHeader } from '../StepHeader';
import { CustomSubStep, ISubStepProps } from '../types';

export function StepCustom({
  step: { title, description, component: Component },
  ...props
}: ISubStepProps<CustomSubStep>) {
  return (
    <>
      <StepHeader title={title} description={description} />
      <Component {...props} />
    </>
  );
}
