import useCheckIfHasKid from '@hooks/useCheckIfHasKid';
import useCheckIfPregnant from '@hooks/useCheckIfPregnant';

import { FormatScenarioFilter } from './formatScenario';
import { SCENARIO_TARGET } from '../types';

export const useScenarioFilters = (): FormatScenarioFilter => {
  const isPregnant = useCheckIfPregnant();
  const hasKid = useCheckIfHasKid();

  const filters = isPregnant
    ? { [SCENARIO_TARGET.PREGNANCY]: true }
    : hasKid
    ? { [SCENARIO_TARGET.CHILDREN]: true }
    : {};

  return {
    [SCENARIO_TARGET.ALL]: true,
    ...filters,
  };
};
