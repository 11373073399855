import type { DebounceSettings } from 'lodash';
import React from 'react';

import { useDebouncedCallback } from '@tools/useDebouncedCallback';

/**
 * A custom hook that returns a debounced version of the callback function.
 * The callback function will be delayed by the specified delay time.
 * If the callback function changes, the debounced function will still use the latest version of the callback.
 */
export const useForcedDebouncedCallback = <T extends (...args: any) => any>(
  callback: T,
  delay: number,
  options?: DebounceSettings,
) => {
  const callbackRef = React.useRef(callback);
  callbackRef.current = callback;

  return useDebouncedCallback(
    React.useCallback((...args) => {
      const func = callbackRef.current;
      return func(...args);
    }, []),
    delay,
    options,
  );
};
