import {
  AuthenticationType,
  authenticateAsync,
  isEnrolledAsync,
  supportedAuthenticationTypesAsync,
} from 'expo-local-authentication';
import { v4 as uuidv4 } from 'uuid';

import { Login1FA, TouchId } from '@api-requests/api/auth';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import { t } from '@config';
import ECIES from '@services/ecies';
import * as Sentry from '@tools/sentry';

export const enrollTouchId = async (
  email: string | undefined,
  prepareTouchId: (arg: {
    deviceId: string;
    touchIdPublicKey: string;
  }) => Promise<ReducedAxiosResponse<{ encryptedMessage: string }>>,
  performEnrollTouchId: (arg: {
    deviceId: string;
    touchIdPublicKey: string;
    plainMessage: string;
  }) => Promise<ReducedAxiosResponse<{}>>,
) => {
  if (!email) {
    return;
  }

  try {
    const previousDeviceId = await ECIES.getDeviceId(email);
    const previousEcies = await ECIES.getDeviceECIES(previousDeviceId, email);

    if (previousEcies) {
      return;
    }

    const canEnroll = await isEnrolledAsync();
    if (canEnroll) {
      const authenticationType = await supportedAuthenticationTypesAsync();
      if (authenticationType.length > 0) {
        let promptMessage = t('AUTH.VERIFICATION_CODE.TOUCHID');
        if (authenticationType[0] === AuthenticationType.FACIAL_RECOGNITION) {
          promptMessage = t('AUTH.VERIFICATION_CODE.FACEID');
        } else if (authenticationType[0] === AuthenticationType.IRIS) {
          promptMessage = t('AUTH.VERIFICATION_CODE.IRIS');
        }
        const touchIdOK = await authenticateAsync({
          disableDeviceFallback: true,
          promptMessage,
          cancelLabel: t('AUTH.VERIFICATION_CODE.CANCEL'),
        });

        if (touchIdOK.success) {
          const deviceId = uuidv4();
          const ecies = await ECIES.getDeviceECIES(deviceId, email);
          if (ecies) {
            const publicKey = await ecies.getPublicKey();
            if (!publicKey) {
              return;
            }
            const { data, status } = await prepareTouchId({
              deviceId,
              touchIdPublicKey: publicKey,
            });
            if (!status || status > 300) {
              return;
            }

            const plainMessage = await ecies.decryptBufferEcies(
              data.encryptedMessage,
            );
            if (plainMessage) {
              const { status: enrollStatus } = await performEnrollTouchId({
                deviceId,
                plainMessage,
                touchIdPublicKey: publicKey,
              });

              if (enrollStatus === 200) {
                ecies.save();
              }
            }
          }
        }
      }
    }
  } catch (err: any) {
    if (!err.ignoreErrorMonitoring) {
      Sentry.captureException(err);
    }
  }
};

export const performTouchId = async (
  oneFA: Login1FA,
  email: string,
  requestTouchId2faCode: (
    arg: TouchId,
  ) => Promise<ReducedAxiosResponse<{ encryptedMessage: string }>>,
): Promise<string | undefined> => {
  if (!email || !oneFA) {
    return;
  }
  const deviceId = await ECIES.getDeviceId(email);
  if (!deviceId) {
    return;
  }

  const isEnrolled = await isEnrolledAsync();
  if (!isEnrolled) {
    return;
  }

  const authenticationType = await supportedAuthenticationTypesAsync();
  if (authenticationType.length > 0) {
    let promptMessage = t('AUTH.VERIFICATION_CODE.TOUCHID');
    if (authenticationType[0] === AuthenticationType.FACIAL_RECOGNITION) {
      promptMessage = t('AUTH.VERIFICATION_CODE.FACEID');
    } else if (authenticationType[0] === AuthenticationType.IRIS) {
      promptMessage = t('AUTH.VERIFICATION_CODE.IRIS');
    }
    const touchIdOK = await authenticateAsync({
      disableDeviceFallback: true,
      promptMessage,
      cancelLabel: t('AUTH.VERIFICATION_CODE.CANCEL'),
    });
    const ecies = await ECIES.getDeviceECIES(deviceId, email);

    if (ecies && touchIdOK.success) {
      try {
        const { status, data } = await requestTouchId2faCode({
          ...oneFA,
          deviceId,
        });

        if (status >= 400 && status < 500) {
          await ecies.delete();
          return;
        }

        if (status >= 200 && status < 300) {
          const encryptedMessage = data.encryptedMessage;
          const plainMessage = await ecies.decryptBufferEcies(encryptedMessage);
          if (!plainMessage) {
            await ecies.delete();
            return;
          }

          return plainMessage;
        }
      } catch {
        await ecies.delete();
        return;
      }
    }
  }
  return;
};
