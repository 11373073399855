import { useMemo } from 'react';

import { useSelector } from '@commonTypes/redux';
import { SUBSCRIPTION_STATUS } from '@resources/constants/User';

import { PRODUCT_TIER } from '@api-requests/api/main/payment/products/types';

export type Authorization = {
  subscribed: boolean;
  paid: boolean;
  hasStripePlan: boolean;
  hasContentAccess: boolean;
  hasChatAccess: boolean;
  subscriptionLevel: PRODUCT_TIER | null;
  hasFreeConsultation: boolean;
  showSubscriptionDetail: boolean;
  subscriptionStatus: SUBSCRIPTION_STATUS | null;
  loaded: boolean;
};

const defaultAuthorization: Authorization = {
  // subscribed or affiliated
  subscribed: false,
  // paid
  paid: false,
  // Has a stripe plan (to be managed on the web portal for example)
  hasStripePlan: false,
  // For posts, masterclasses, guides, programs...
  hasContentAccess: false,
  // Access to the chat detail
  hasChatAccess: false,
  // Subscription tier
  subscriptionLevel: null,
  // Display details and/or subscription plan list
  showSubscriptionDetail: true,
  // Can benefit from free consultations
  hasFreeConsultation: false,
  subscriptionStatus: null,
  loaded: false,
};

export const subscribedStatus = [
  SUBSCRIPTION_STATUS.ACTIVE,
  SUBSCRIPTION_STATUS.AFFILIATED,
  SUBSCRIPTION_STATUS.BONUS,
  SUBSCRIPTION_STATUS.TRIALING,
];

export const useAuthorizations = () => {
  const {
    id,
    subscriptionStatus,
    consultationsNumber = 0,
    subscriptionLevel,
  } = useSelector((state) => state.user.data ?? {});

  return useMemo(() => {
    if (!id) {
      return defaultAuthorization;
    }

    const newAuthorization: Authorization = { ...defaultAuthorization };
    newAuthorization.loaded = true;

    if (subscriptionStatus && subscribedStatus.includes(subscriptionStatus)) {
      newAuthorization.paid = subscriptionStatus !== SUBSCRIPTION_STATUS.BONUS;
      newAuthorization.subscribed = true;
      newAuthorization.hasContentAccess = true;
      newAuthorization.subscriptionLevel = getSubscriptionLevel(
        subscriptionLevel,
        subscriptionStatus,
      );
      newAuthorization.hasChatAccess =
        subscriptionLevel === PRODUCT_TIER.PREMIUM;
      newAuthorization.showSubscriptionDetail = true;
      newAuthorization.hasStripePlan = [
        SUBSCRIPTION_STATUS.ACTIVE,
        SUBSCRIPTION_STATUS.TRIALING,
      ].includes(subscriptionStatus);

      if (subscriptionStatus === SUBSCRIPTION_STATUS.AFFILIATED) {
        newAuthorization.showSubscriptionDetail = false;
        newAuthorization.hasFreeConsultation = (consultationsNumber || 0) > 0;
      }
    }

    newAuthorization.subscriptionStatus = subscriptionStatus || null;
    return newAuthorization;
  }, [id, subscriptionStatus, subscriptionLevel, consultationsNumber]);
};

const getSubscriptionLevel = (
  subscriptionLevel: PRODUCT_TIER | undefined | null,
  subscriptionStatus: SUBSCRIPTION_STATUS | undefined,
) => {
  if (!subscriptionStatus || !subscriptionLevel) {
    return null;
  }

  if (
    subscriptionStatus === SUBSCRIPTION_STATUS.AFFILIATED ||
    subscriptionStatus === SUBSCRIPTION_STATUS.BONUS
  ) {
    return PRODUCT_TIER.PREMIUM;
  }

  return subscriptionLevel;
};
