import FAQ_PURCHASE from '@resources//i18n/ui/fr/faqPurchase.json';
import ADD_KID from '@resources/i18n/ui/fr/addKid.json';
import AFFILIATION from '@resources/i18n/ui/fr/affiliation.json';
import API_ERROR from '@resources/i18n/ui/fr/apiError.json';
import AUTH from '@resources/i18n/ui/fr/auth.json';
import BABY_3D from '@resources/i18n/ui/fr/baby3D.json';
import BANNER from '@resources/i18n/ui/fr/banner.json';
import CAMERA from '@resources/i18n/ui/fr/camera.json';
import CHAT from '@resources/i18n/ui/fr/chat.json';
import CHILD_PROGRESSION from '@resources/i18n/ui/fr/childProgression.json';
import CMS_CARD from '@resources/i18n/ui/fr/cmsCard.json';
import COMMON from '@resources/i18n/ui/fr/common.json';
import CONSULTATIONS from '@resources/i18n/ui/fr/consultations.json';
import COUNTRIES from '@resources/i18n/ui/fr/countries.json';
import CRASH_LOG from '@resources/i18n/ui/fr/crashLog.json';
import DAILY_TIPS_HISTORY from '@resources/i18n/ui/fr/dailyTipsHistory.json';
import ERROR_MODAL from '@resources/i18n/ui/fr/errorModal.json';
import EXPLORE from '@resources/i18n/ui/fr/explore.json';
import FAMILY_ACCOUNT from '@resources/i18n/ui/fr/familyAccount.json';
import FAQ_PREGNANCY from '@resources/i18n/ui/fr/faqPregnancy.json';
import FAVORITE from '@resources/i18n/ui/fr/favorite.json';
import FERTILITY from '@resources/i18n/ui/fr/fertility.json';
import FOLDER from '@resources/i18n/ui/fr/folder.json';
import FORM from '@resources/i18n/ui/fr/form.json';
import FORMATIONS from '@resources/i18n/ui/fr/formations.json';
import GENERAL_ERROR from '@resources/i18n/ui/fr/generalError.json';
import GLOBAL from '@resources/i18n/ui/fr/global.json';
import HOME from '@resources/i18n/ui/fr/home.json';
import JOURNALING from '@resources/i18n/ui/fr/journaling.json';
import KID_PROGRAM from '@resources/i18n/ui/fr/kidProgram.json';
import LIBRARY from '@resources/i18n/ui/fr/library.json';
import LOGGED_OUT_HOME from '@resources/i18n/ui/fr/loggedOutHome.json';
import MASTERCLASS from '@resources/i18n/ui/fr/masterclass.json';
import MENU from '@resources/i18n/ui/fr/menu.json';
import ON_BOARDING from '@resources/i18n/ui/fr/onBoarding.json';
import PAYMENT from '@resources/i18n/ui/fr/payment.json';
import PHOTO_LIBRARY from '@resources/i18n/ui/fr/photoLibraryModal.json';
import POST from '@resources/i18n/ui/fr/post.json';
import PREGNANCY_PROGRESSION from '@resources/i18n/ui/fr/pregnancyProgression.json';
import PROFILE from '@resources/i18n/ui/fr/profile.json';
import RECIPES from '@resources/i18n/ui/fr/recipes.json';
import SEARCH from '@resources/i18n/ui/fr/search.json';
import SETTINGS from '@resources/i18n/ui/fr/settings.json';
import SPONSOR from '@resources/i18n/ui/fr/sponsor.json';
import SUBSCRIBE_BLOC from '@resources/i18n/ui/fr/subscribeBloc.json';
import SUBSCRIBE_MODAL from '@resources/i18n/ui/fr/subscribeModal.json';
import SUBSCRIPTION from '@resources/i18n/ui/fr/subscription.json';
import UNSUBSCRIBE from '@resources/i18n/ui/fr/unsubscribe.json';
import VISIO from '@resources/i18n/ui/fr/visio.json';
import WEEKLY_ACTIVITIES from '@resources/i18n/ui/fr/weeklyActivities.json';

export default {
  ADD_KID,
  AFFILIATION,
  API_ERROR,
  AUTH,
  BABY_3D,
  BANNER,
  CAMERA,
  CHAT,
  CHILD_PROGRESSION,
  CMS_CARD,
  COMMON,
  CONSULTATIONS,
  COUNTRIES,
  CRASH_LOG,
  DAILY_TIPS_HISTORY,
  ERROR_MODAL,
  EXPLORE,
  FAMILY_ACCOUNT,
  FAQ_PREGNANCY,
  FAQ_PURCHASE,
  FAVORITE,
  FERTILITY,
  FOLDER,
  FORM,
  FORMATIONS,
  GENERAL_ERROR,
  GLOBAL,
  HOME,
  JOURNALING,
  KID_PROGRAM,
  LIBRARY,
  LOGGED_OUT_HOME,
  MASTERCLASS,
  MENU,
  ON_BOARDING,
  PAYMENT,
  PHOTO_LIBRARY,
  POST,
  PREGNANCY_PROGRESSION,
  PROFILE,
  RECIPES,
  SEARCH,
  SETTINGS,
  SPONSOR,
  SUBSCRIBE_BLOC,
  SUBSCRIBE_MODAL,
  SUBSCRIPTION,
  UNSUBSCRIBE,
  VISIO,
  WEEKLY_ACTIVITIES,
};
