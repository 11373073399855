import { Dimensions, StyleSheet } from 'react-native';

import DEVICE from '@resources/constants/device';
import { Colors, Fonts } from '@resources/themes';

const { height } = Dimensions.get('window');

export default StyleSheet.create({
  container: {
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
  },

  contentContainer: {
    width: DEVICE.WINDOW_WIDTH * 0.8,
    alignItems: 'center',
  },
  backdrop: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: `${Colors.black500}${Colors.transparent30}`,
  },
  loadingContainer: {
    ...StyleSheet.absoluteFillObject,
    elevation: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
  },

  loadingOpacity: {
    ...StyleSheet.absoluteFillObject,
    display: 'flex',
    borderRadius: 12,
    backgroundColor: Colors.black500,
    opacity: 0.6,
  },

  loadingText: {
    fontFamily: Fonts.type.regular,
    color: Colors.white100,
    opacity: 1,
    textAlign: 'center',
  },

  errorText: {
    fontFamily: Fonts.type.semiBold,
    color: Colors.pink700,
    opacity: 1,
    textAlign: 'center',
  },

  retryButton: {
    marginTop: 16,
  },

  image: {
    borderRadius: 12,
    height: height * 0.55,
    width: '100%',
  },

  loaderContainer: {
    alignItems: 'center',
    height: height * 0.55,
    justifyContent: 'center',
    width: '100%',
  },

  greenButton: {
    backgroundColor: Colors.duck800,
  },
  sendButton: {
    elevation: 11,
    alignItems: 'center',
    borderRadius: 25,
    bottom: -25,
    height: 50,
    justifyContent: 'center',
    position: 'absolute',
    right: 10,
    width: 50,
  },

  cancelButton: {
    right: 70,
  },

  whiteButton: {
    backgroundColor: Colors.white100,
  },

  disabledButton: {
    opacity: 0.5,
  },
});
