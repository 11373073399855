import useMutationCall from '@hooks/useMutationCall';

import { profiles } from './endPoint';

export const PROFILE_QUERY_KEYS = {
  ALL: ['profiles'] as const,
  ADD: () => [...PROFILE_QUERY_KEYS.ALL, 'add'] as const,
} as const;

export const useAddProfileDetails = () =>
  useMutationCall({
    mutationFn: profiles.add,
    mutationKey: PROFILE_QUERY_KEYS.ADD(),
  });
