import merge from 'lodash/merge';

import { PatientProfile } from '@commonTypes/AppUser';
import { Kid } from '@commonTypes/Kid';
import { Pregnancy } from '@commonTypes/Pregnancy';
import { resetAll } from '@redux/reduxReset';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SUBSCRIPTION_STATUS } from '@resources/constants/User';

import { CoParent } from '@api-requests/api/main/family/types';
import { DetailedFamilyInvitation } from '@api-requests/api/main/family-pending-invite/types';
import { PRODUCT_TIER } from '@api-requests/api/main/payment/products/types';

export interface User {
  id: number;
  email: string;
  phone: string;
  firstName: string;
  lastName?: string;
}

export interface UserData {
  id?: number;
  email?: string;
  phone?: string;
  consultationsNumber?: number;
  familyId?: string;
  firstName?: string;
  lastName?: string;
  kids?: Kid[];
  pregnancy?: Pregnancy | Record<string, never>;
  previousPregnancy?: Pregnancy;
  scenarios?: string[];
  sharedFamily?: boolean;
  subscriptionStatus?: SUBSCRIPTION_STATUS;
  subscriptionLevel?: PRODUCT_TIER | null;
  coparent?: CoParent | null;
  pendingInvite?: DetailedFamilyInvitation;
  profile?: PatientProfile;
  lastRefresh?: number;
  paidPlan?: boolean;
}

interface KidUpdate extends Partial<Kid> {
  id: Kid['id'];
}

interface UserState {
  data: UserData;
  isFetching: boolean;
}
const initialState: UserState = { data: {}, isFetching: false };
const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetching = payload;
    },
    userSuccess: (
      state,
      {
        payload: { createdAt, detail, ...payload },
      }: PayloadAction<
        Partial<
          UserState['data'] & { createdAt: string | Date; detail?: boolean }
        >
      >,
    ) => {
      // When changing user id, we need to update the whole user object : the user account has changed
      if (payload.id && state.data.id !== payload.id) {
        state.data = payload;
        state.isFetching = false;
        return;
      }
      // merge can provide strange result fo arrays when indexes are modified
      if (state.data.kids?.length !== payload.kids?.length && payload.kids) {
        state.data.kids = payload.kids;
      }
      if (payload.pregnancy) {
        state.data.pregnancy = payload.pregnancy;
      }
      if (payload.previousPregnancy) {
        state.data.previousPregnancy = payload.previousPregnancy;
      }
      if (payload.scenarios) {
        state.data.scenarios = payload.scenarios;
      }
      state.data = merge(state.data, payload);
      state.isFetching = false;
      if (detail) {
        state.data.lastRefresh = Date.now();
      }
    },
    addOrUpdateKid: (state, { payload }: PayloadAction<KidUpdate>) => {
      const nextKids = state?.data?.kids ?? [];
      const updateKidIndex = nextKids.findIndex((kid) => kid.id === payload.id);
      if (updateKidIndex !== -1) {
        nextKids[updateKidIndex] = merge(nextKids[updateKidIndex], payload);
      } else {
        // @ts-ignore
        // create new kid
        nextKids.push(payload);
      }
      state.data.kids = nextKids;
      state.isFetching = false;
    },
    userClear: () => initialState,
    updateUserPregnancy: (
      state,
      { payload }: PayloadAction<Pregnancy | Record<string, never>>,
    ) => {
      if (!payload.active) {
        if (state.data.pregnancy?.id) {
          // @ts-ignore
          state.data.previousPregnancy = state.data.pregnancy;
        }
        delete state.data.pregnancy;
      } else {
        state.data.pregnancy = payload;
      }
      state.isFetching = false;
    },
  },
});

export const userCreators = userSlice.actions;
export default userSlice.reducer;
