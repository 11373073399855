import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  header: { flexDirection: 'row', alignItems: 'center' },

  checkMark: {
    marginRight: 24,
    height: 24,
    aspectRatio: 1,
    borderRadius: 12,
    backgroundColor: Colors.pink500,
    alignItems: 'center',
    justifyContent: 'center',
  },

  iconDescription: {
    marginLeft: 8,
    color: Colors.duck800,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.title,
  },

  content: { flexDirection: 'row', marginTop: 8 },
  line: {
    width: 2,
    height: '100%',
    marginHorizontal: 11,
    backgroundColor: Colors.pink500,
    borderRadius: 1,
  },
  sectionContent: { flex: 1, marginLeft: 24, marginBottom: 16 },
  title: {
    color: Colors.duck700,
    fontFamily: Fonts.type.semiBold,
    fontSize: Fonts.size.input,
  },
  description: {
    marginBottom: 16,
    color: Colors.duck700,
    fontFamily: Fonts.type.light,
    fontSize: Fonts.size.small,
  },
});
