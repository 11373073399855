import { addTypeInList } from '@tools/formatData/formatAllContentList';

import { ContentType } from '@tools/formatData/formatAllContentList/types';

import { CategorizedContent, SelectionRaw } from '../types';

export const formatSelection = (
  selection: SelectionRaw | undefined,
): CategorizedContent[] => {
  if (!selection) {
    return [];
  }
  const articles = addTypeInList(ContentType.article, selection.posts);
  const guides = addTypeInList(ContentType.guide, selection.guides);
  const program = selection.program
    ? [{ ...selection.program, type: ContentType.folder } as const]
    : [];
  const masterclass = selection.masterclass
    ? [{ ...selection.masterclass, type: ContentType.masterclass } as const]
    : [];
  return [...articles, ...guides, ...program, ...masterclass];
};
