import { storeCredentials } from '@api-requests/api/common';
import { Tokens } from '@commonTypes/Auth';

export const enrichWithJWTExp = (data: Tokens): Tokens => {
  if (data.jwtExp) {
    return data;
  }

  const refreshFetchTime = Date.now();
  storeCredentials({ refreshFetchTime });
  const jwtExp =
    Math.max(
      // 60 s or 95% (for short lived token ie test in local) of the token validity to have margin over network latency
      0.95 * data.jwtExpiresIn,
      data.jwtExpiresIn - 60,
    ) *
      1000 +
    refreshFetchTime;

  return { ...data, jwtExp };
};
