import { Platform } from 'react-native';
import { UnknownAction } from 'redux';

import { logEvent } from '@analytics/common';
import { refreshToken } from '@api-requests/api/auth';
import { onboardingScenarios } from '@api-requests/api/cms/scenarii/onboarding-scenarios/endPoint';
import {
  getCredentials,
  isTokenExpired,
  NoTokenError,
  RefreshTokenExpiredError,
  RefreshTokenFailedError,
  ApiError,
  storeCredentials,
} from '@api-requests/api/common';
import ApiRequest from '@api-requests/api/main/endPoint';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import { GlobalState } from '@commonTypes/redux';
import { TOKENS_QUERY_KEYS } from '@hooks/useTokens';
import { AuthCreators } from '@redux/auth';
import { queryClient } from '@services/queryClient';
import { captureQueryError } from '@tools/captureQueryError';

export const excludedRoute = [
  ApiRequest.auth.check2fa,
  ApiRequest.auth.forgotPassword.reset,
  ApiRequest.auth.forgotPassword.submit,
  ApiRequest.auth.login,
  ApiRequest.auth.refreshToken,
  ApiRequest.auth.checkEmail,
  ApiRequest.auth.checkPhone,
  ApiRequest.auth.signup,
  ApiRequest.auth.request2faCode,
  ApiRequest.auth.requestTouchId2faCode,
  onboardingScenarios.get,
];

const checkAuthorization = async (
  state: GlobalState,
  dispatch: (action: UnknownAction) => void,
) => {
  const token = state.auth.token;
  if (!token) {
    throw new NoTokenError("Access token can't be found");
  }
  const tokenExpired = isTokenExpired(token);
  if (tokenExpired) {
    // Let's see if we can refresh our access token with our refresh token
    const { refreshToken: storedRefresh } = await getCredentials();
    if (!storedRefresh) {
      throw new NoTokenError("Refresh token can't be found");
    }
    const refreshTokenExpired = isTokenExpired(storedRefresh);
    if (refreshTokenExpired) {
      throw new RefreshTokenExpiredError();
    }
    let res;
    try {
      res = await queryClient?.ensureQueryData({
        queryKey: TOKENS_QUERY_KEYS,
        queryFn: refreshToken,
      });
    } catch (err) {
      throw new RefreshTokenFailedError();
    }

    if (!res || !res.data?.jwt) {
      throw new RefreshTokenFailedError();
    }

    dispatch(AuthCreators.authLogin({ token: res.data.jwt }));
    if (res.data.refreshToken) {
      await storeCredentials({ refreshToken: res.data.refreshToken });
    }
  }
};

export const queryWrapper = async <
  U extends ReducedAxiosResponse = ReducedAxiosResponse<any>,
  T extends (...arg: any[]) => Promise<U> = () => Promise<U>,
>(
  {
    state,
    dispatch,
  }: {
    state: GlobalState;
    dispatch: (action: UnknownAction) => void;
  },
  fn: T,
  ...args: Parameters<T>
): Promise<U> => {
  if (!excludedRoute.includes(fn)) {
    // Route needs auth, let's check it out
    try {
      await checkAuthorization(state, dispatch);
    } catch (err: any) {
      // Error is raised if no token is available, refresh token is expired or failed
      const { password } = await getCredentials();
      // Quick fix, should never happen but add it just to be sure,
      // should be removed when 'weird 2fa case' has been resolved
      if (password) {
        dispatch(AuthCreators.setIsLogged({ password: true, isLogged: false }));
      } else if (Platform.OS !== 'web') {
        logEvent('weird_2fa_case_http', {
          type: 'sagas',
          functionName: fn.name,
        });
        dispatch(AuthCreators.authLogout());
      }
      throw new ApiError(err.message);
    }
  }

  try {
    return fn(...args);
  } catch (error: any) {
    captureQueryError(error);
    throw error;
  }
};
