import React from 'react';
import { InteractionManager, StyleSheet, Text } from 'react-native';

import CheckBox, { CheckBoxProps } from '@components/ui/CheckBox';
import { t } from '@config';
import { navigate } from '@navigation/Actions';
import { ANONYMOUS_STACK } from '@navigation/Routes';
import { Fonts } from '@resources/themes';

import { StaticContentsSlug } from '@screens/StaticContentsScreen/types';

const styles = StyleSheet.create({
  accent: { fontFamily: Fonts.type.semiBold },
});

const goToLegal = (slug: StaticContentsSlug) =>
  navigate(ANONYMOUS_STACK.STATIC_CONTENTS_SCREEN, { slug });

type CheckBoxLegalProps = Omit<CheckBoxProps, 'children'> & {
  onActionPress: () => void;
};
function CheckBoxLegal({ onActionPress, ...props }: CheckBoxLegalProps) {
  return (
    <CheckBox {...props}>
      {t('PAYMENT.CGV_TEXT_1')}
      <Text
        style={styles.accent}
        onPress={() => {
          onActionPress();
          InteractionManager.runAfterInteractions(() =>
            goToLegal(StaticContentsSlug.cgu),
          );
        }}
      >
        {t('PAYMENT.CGU')}
      </Text>
      {t('PAYMENT.CGV_TEXT_3')}
      <Text
        style={styles.accent}
        onPress={() => {
          onActionPress();
          InteractionManager.runAfterInteractions(() =>
            goToLegal(StaticContentsSlug.privacy),
          );
        }}
      >
        {t('PAYMENT.PRIVACY_POLICY')}
      </Text>
    </CheckBox>
  );
}

export default CheckBoxLegal;
