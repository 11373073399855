import React from 'react';
import { Image } from 'react-native';

import { MayImageProps } from './types';

const MayImage = (props: MayImageProps) => {
  const { source } = props;
  const usedSource =
    typeof source === 'string'
      ? { uri: source }
      : typeof source === 'object' && 'url' in source
      ? { uri: source.url }
      : source;
  return <Image {...props} source={usedSource} />;
};

export default MayImage;
