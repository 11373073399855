import { Kid } from '@commonTypes/Kid';
import { Pregnancy } from '@commonTypes/Pregnancy';
import useAuthenticatedQuery from '@hooks/useAuthenticatedQuery';
import { getFamilyAgesParams } from '@tools/getFamilyAgesParams';

import selection from './endPoint';
import { formatRes } from './utils/formatRes';

export const SELECTED_QUERY_KEYS = {
  get: (
    pregnancy: Pregnancy | Record<string, never>,
    childrenList: Array<Kid>,
  ) => ['selectedList', pregnancy, childrenList],
};

export const useSelectedQuery = (
  childrenList: Array<Kid>,
  pregnancy: Pregnancy | Record<string, never>,
) => {
  const childrenUnderSix = childrenList.filter(({ birthDay }) => {
    const sixYearsAgo = new Date();
    sixYearsAgo.setFullYear(sixYearsAgo.getFullYear() - 6);
    return new Date(birthDay).getTime() > sixYearsAgo.getTime();
  });
  const childrenUnderSixMap = Object.fromEntries(
    childrenUnderSix.map((child) => [child.id.toString(), child]),
  );
  return useAuthenticatedQuery({
    queryFn: () =>
      selection.get(
        getFamilyAgesParams({ kidList: childrenUnderSix, pregnancy }),
      ),
    queryKey: SELECTED_QUERY_KEYS.get(pregnancy, childrenUnderSix),
    select: ({ data }) => formatRes(data, childrenUnderSixMap),
  });
};
