import auth from '@api-requests/api/auth';
import useMutationCall from '@hooks/useMutationCall';
import { useRecaptchaQuery } from '@hooks/useRecaptchaQuery';

export const useForgotPassword = () => {
  const forgotPassword = useRecaptchaQuery(
    auth.forgotPassword.reset,
    'forgotPasswordReset',
  );

  return useMutationCall({
    mutationFn: forgotPassword,
    mutationKey: ['forgotPasswordReset'],
  });
};
