import React from 'react';

import { useGetAllTrackers } from '@api-requests/api/cms/queries/useGetAllTrackers';
import { useSelector } from '@commonTypes/redux';

import WebAnonymousNavigator from './WebAnonymousNavigator';
import WebLoggedNavigator from './WebLoggedNavigator';

const WebNavigator = () => {
  /**
   * On logout store gets reset -> isLogged is false
   * This mounts the anonymous navigator and triggers a redirection to its initial route
   */
  const isLogged = useSelector((state) => state.auth.isLogged);
  useGetAllTrackers({ enabled: isLogged });

  if (!isLogged) {
    return <WebAnonymousNavigator />;
  }
  return <WebLoggedNavigator />;
};

export default WebNavigator;
