import useAuthenticatedQuery from '@hooks/useAuthenticatedQuery';
import { keepPreviousData } from '@tanstack/react-query';

import { auth } from './endPoint';

export const WEB_AUTH_QUERY_KEYS = {
  LOGOUT: ['logout'],
};

export const useLogout = () =>
  useAuthenticatedQuery({
    queryFn: auth.logout,
    queryKey: WEB_AUTH_QUERY_KEYS.LOGOUT,
    gcTime: 0,
    staleTime: 0,
    placeholderData: keepPreviousData,
  });
