import React from 'react';

const withinModalContext = React.createContext(false);
export const WithinModalProvider = withinModalContext.Provider;
/**
 * Modals within modals are handled differently and do not need the patch provided by useIsVisible
 *
 * Be aware that siblings nested modals will thus not benefit from the patch and might break when mounted simultaneously
 * but at this point this is certainly more of an UX issue rather than a technical one
 */
export const useWithinModal = () => React.useContext(withinModalContext);
