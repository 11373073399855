import React from 'react';

import { GENDER } from '@commonTypes/Kid';
import Option from '@components/Option';
import { t } from '@config';
import useCheckIfPregnant from '@hooks/useCheckIfPregnant';
import { navigate } from '@navigation/Actions';
import { COMMON_SCREEN_STACK } from '@navigation/Routes';
import { useRoute } from '@react-navigation/native';
import { Colors } from '@resources/themes';

const variants = {
  [GENDER.M]: { color: Colors.duck300, icon: 'BoyIcon' },
  [GENDER.F]: { color: Colors.yellow300, icon: 'GirlIcon' },
} as const;

interface ChildOptionsProps {
  avatar: string | undefined;
  sex: GENDER;
  firstName: string;
  onPress: () => void;
}
export function ChildOption({
  avatar,
  sex,
  firstName,
  onPress,
}: ChildOptionsProps) {
  return (
    <Option
      onPress={onPress}
      label={firstName}
      illustrationSource={avatar ? { uri: avatar } : variants[sex].icon}
      size={avatar ? 40 : 32}
      backgroundColor={variants[sex].color}
      withArrow={false}
      hasSeparator
    />
  );
}

export function PregnancyOption({ onPress }: { onPress: () => void }) {
  return (
    <Option
      onPress={onPress}
      label={t('PROFILE.FUTURE_CHILD')}
      illustrationSource={'EggIcon'}
      size={32}
      backgroundColor={Colors.pink200}
      withArrow={false}
      hasSeparator
    />
  );
}

const goToAddChild = (originScreen: string) =>
  navigate(COMMON_SCREEN_STACK.ADD_KID_SCREEN, {
    targetScreen: undefined,
    originScreen,
  });

export function AddMemberOption({ onPress }: { onPress: () => void }) {
  const isPregnant = useCheckIfPregnant();
  const route = useRoute();
  return (
    <AddMemberOptionUI
      onPress={isPregnant ? () => goToAddChild(route.name) : onPress}
      childOnly={isPregnant}
    />
  );
}
export function AddMemberOptionUI({
  onPress,
  childOnly,
}: {
  onPress: () => void;
  childOnly: boolean;
}) {
  return (
    <Option
      onPress={onPress}
      label={
        childOnly
          ? t('ADD_KID.BUTTONS.ADD_KID')
          : t('ADD_KID.BUTTONS.ADD_KID_OR_PREGNANCY')
      }
      illustrationSource={'Plus'}
      backgroundColor={Colors.duck800}
      iconColor={Colors.white100}
      strokeWidth={2}
      strokeLinecap="round"
      withArrow={false}
      hasSeparator={false}
    />
  );
}
