import React, { useState } from 'react';
import { Platform, StyleSheet, TextInput, View } from 'react-native';

import { PrimaryButton, SecondaryButton } from '@components/Buttons';
import { Colors, Fonts } from '@resources/themes';

import { StepHeader } from '../StepHeader';
import { InputSubStep, ISubStepProps } from '../types';

const styles = StyleSheet.create({
  flex: { flex: 1, gap: 16 },
  textArea: {
    padding: 20,
    height: 104,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.duck800,
    fontFamily: Fonts.type.light,
    fontSize: Fonts.size.medium,
    color: Colors.duck800,
    ...Platform.select({
      // web "textAlignVertical" style is deprecated
      web: {},
      default: { textAlignVertical: 'top' },
    }),
  },
  button: { position: 'absolute', bottom: 56 },
  filler: { flex: 1 },
});

export function StepInput({
  step,
  onAnswerPress,
}: ISubStepProps<InputSubStep>) {
  const [value, setValue] = useState('');
  return (
    <View style={styles.flex}>
      <StepHeader title={step.title} description={step.description} />
      <TextInput
        style={styles.textArea}
        value={value}
        onChangeText={setValue}
        placeholder={step.inputPlaceholder}
        placeholderTextColor={Colors.grey600}
        multiline
        numberOfLines={4}
      />
      <PrimaryButton
        style={styles.button}
        label={step.CTALabel}
        onPress={() => onAnswerPress({ value, key: value })}
        isLoading={false}
      />
      {!!step.cancelLabel && !!step.cancelValue && (
        <>
          <View style={styles.filler} />
          <SecondaryButton
            isLoading={false}
            onPress={() => onAnswerPress(step.cancelValue)}
            testID="cancelAnswer"
          />
        </>
      )}
    </View>
  );
}
