import { StyleSheet } from 'react-native';

import { Colors } from '@resources/themes';

export default StyleSheet.create({
  container: {
    paddingTop: 32,
    paddingHorizontal: 24,
    gap: 24,
    height: '100%',
    backgroundColor: Colors.white100,
  },
});
