import React, { useMemo } from 'react';
import { Text, View } from 'react-native';

import { PressableNative } from '@components/ui/Pressables/PressableNative';
import { t } from '@config/bootstrap/i18n';

import {
  Product,
  PRODUCT_INTERVAL,
} from '@api-requests/api/main/payment/products/types';

import styles from './styles';

interface PlanTileProps {
  style?: View['props']['style'];
  plan: Pick<
    Product,
    'amount' | 'default' | 'interval' | 'intervalCount' | 'title'
  >;
  onPress: () => void;
  isSelected: boolean;
}
const PlanTile = ({ style, plan, onPress, isSelected }: PlanTileProps) => {
  const pricePerMonth = useMemo(() => {
    if (plan.interval === PRODUCT_INTERVAL.MONTH && plan.intervalCount === 1) {
      return;
    }
    if (plan.interval === PRODUCT_INTERVAL.YEAR) {
      return (plan.amount / 1200).toFixed(2);
    }
    return (plan.amount / 100 / plan.intervalCount).toFixed(2);
  }, [plan.amount, plan.interval, plan.intervalCount]);

  return (
    <PressableNative
      onPress={onPress}
      style={[styles.container, isSelected && styles.containerSelected, style]}
    >
      <View style={styles.left}>
        {plan.default && (
          <Text style={styles.pill}>
            {t('SUBSCRIBE_MODAL.PLAN_SECTION.BEST_OFFER')}
          </Text>
        )}
        <Text style={[styles.title, isSelected && styles.textSelected]}>
          {plan.title}
        </Text>
      </View>
      <View style={styles.right}>
        <Text style={[styles.price, isSelected && styles.textSelected]}>
          {t(`SUBSCRIBE_MODAL.PLAN_SECTION.PLAN.${plan.interval}`, {
            amount: (plan.amount / 100).toFixed(2),
            interval_count: plan.intervalCount > 1 ? plan.intervalCount : '',
          })}
        </Text>
        {pricePerMonth && (
          <Text
            style={[styles.priceMonthly, isSelected && styles.textSelected]}
          >
            {t('SUBSCRIBE_MODAL.PLAN_SECTION.PRICE_PER_MONTH', {
              pricePerMonth,
            })}
          </Text>
        )}
      </View>
    </PressableNative>
  );
};

export default PlanTile;
