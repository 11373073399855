import {
  addScenario,
  check2fa,
  checkEmail,
  checkPhone,
  enrollTouchId,
  forgotPasswordRequest,
  forgotPasswordSubmit,
  login,
  prepareTouchId,
  refreshToken,
  request2faCode,
  requestTouchId2faCode,
  signup,
} from '@api-requests/api/auth';
import { getListCard } from '@api-requests/api/card';
import {
  finalizeUpload,
  getSignedUrl,
  getUnseenMessages,
  postKidsMessage,
  postPregnancyMessage,
} from '@api-requests/api/chat';
import { getTokBoxToken } from '@api-requests/api/consultations';
import { setPushNotificationsToken } from '@api-requests/api/pushNotifications';
import {
  addKid,
  deleteKid,
  requestAvatarSignedUrl,
  updateKid,
} from '@api-requests/api/user';

export default {
  auth: {
    forgotPassword: {
      reset: forgotPasswordRequest,
      submit: forgotPasswordSubmit,
    },
    login,
    refreshToken,
    signup,
    checkEmail,
    checkPhone,
    request2faCode,
    check2fa,
    prepareTouchId,
    enrollTouchId,
    requestTouchId2faCode,
    addScenario: addScenario,
  },

  chat: {
    sendKidsMessage: postKidsMessage,
    sendPregnancyMessage: postPregnancyMessage,
    getSignedUrl,
    getUnseenMessages,
    finalizeUpload,
  },

  getTokBoxToken,

  kids: {
    add: addKid,
    requestAvatarSignedUrl: requestAvatarSignedUrl,
    update: updateKid,
    delete: deleteKid,
  },

  settings: {
    paymentCard: {
      list: getListCard,
    },
  },

  setPushNotificationsToken,
};
