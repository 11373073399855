import { Platform, StyleSheet } from 'react-native';

import { Colors } from '@resources/themes';

export default StyleSheet.create({
  container: {
    height: '100%',
    padding: 24,
    gap: 32,
    ...Platform.select({
      web: { backgroundColor: Colors.white100 },
      default: {},
    }),
  },
});
