import { resetAll } from '@redux/reduxReset';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  noNetwork: false,
  badGateway: {} as Record<string, boolean>,
};

const apiErrorAlertSlice = createSlice({
  name: 'apiError',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    noNetworkAlertShow: (state) => {
      state.noNetwork = true;
    },
    noNetworkAlertHide: (state) => {
      state.noNetwork = false;
    },
    badGatewayAlertShow: (
      state,
      action: PayloadAction<{
        baseURL: string;
      }>,
    ) => {
      if (!state.badGateway[action.payload.baseURL]) {
        state.badGateway[action.payload.baseURL] = true;
      }
    },
    badGatewayAlertHide: (
      state,
      action: PayloadAction<{
        baseURL: string;
      }>,
    ) => {
      if (state.badGateway[action.payload.baseURL]) {
        state.badGateway[action.payload.baseURL] = false;
      }
    },
    apiErrorAlertHide: (state) => {
      state.noNetwork = false;
      Object.keys(state.badGateway).forEach((key) => {
        state.badGateway[key] = false;
      });
    },
  },
});

export const ApiErrorAlertCreators = apiErrorAlertSlice.actions;
export default apiErrorAlertSlice.reducer;
