import { Platform } from 'react-native';

import {
  axiosGet,
  axiosPost,
  setAuthorizationBearer,
} from '@api-requests/api/main/request';
import { Device } from '@apiTypes/device';
import { OpenidProviders, Scenarios, Tokens } from '@commonTypes/Auth';
import { UserData } from '@redux/user';
import { ResetPasswordPayload } from '@screens/Auth/ResetPasswordScreen/hooks/reset';
import { SignupPayload } from '@screens/Auth/SignUp/hooks/auth';
import { enrichWithJWTExp } from '@tools/auth';

import { setStrapiAuthorizationBearer } from './cms/request';

export type Login1FA =
  | {
      email: string;
      password: string;
    }
  | { provider: OpenidProviders | 'apple'; providerToken: string };

export type TouchId = Login1FA & {
  deviceId: string;
};

export type Login2FA = Login1FA & {
  code: string;
};

export const signup = ({
  recaptchaToken,
  ...data
}: SignupPayload & { recaptchaToken: string | undefined }) =>
  axiosPost<void>({
    url: '/apifo/signup',
    params: { data },
    recaptchaToken,
  });

export const checkEmail = ({
  recaptchaToken,
  ...data
}: {
  email: string;
  recaptchaToken: string | undefined;
}) =>
  axiosPost<void>({
    url: '/apifo/signup/email',
    params: { data },
    recaptchaToken,
  });

export const checkPhone = ({
  recaptchaToken,
  ...data
}: {
  phone: string;
  recaptchaToken: string | undefined;
}) =>
  axiosPost({
    url: '/apifo/signup/phone',
    params: { data },

    recaptchaToken,
  });

export const forgotPasswordRequest = ({
  recaptchaToken,
  ...payload
}: { email: string } & { recaptchaToken: string | undefined }) =>
  axiosPost<{ providers?: (OpenidProviders | 'apple')[] }>({
    url: '/apifo/auth/reset_email',
    params: { data: payload },

    recaptchaToken,
  });

export const forgotPasswordSubmit = ({
  recaptchaToken,
  ...data
}: ResetPasswordPayload & { recaptchaToken: string | undefined }) =>
  axiosPost<{}>({
    url: '/apifo/auth/reset_password',
    params: { data },

    recaptchaToken,
  });

export const check2fa = ({
  recaptchaToken,
  ...payload
}: Login2FA & {
  recaptchaToken: string | undefined;
}) =>
  axiosPost<{
    tokens: Tokens;
    appUser: UserData;
  }>({
    url: '/apifo/auth/2fa',
    params: { data: { ...payload, platform: Platform.OS } },

    recaptchaToken,
  });

export const request2faCode = ({
  recaptchaToken,
  ...payload
}: Login1FA & {
  recaptchaToken: string | undefined;
}) => {
  return axiosPost<{}>({
    url: '/apifo/auth/send_sms2fa',
    params: { data: payload },

    recaptchaToken,
  });
};

export const requestTouchId2faCode = (payload: TouchId) =>
  axiosPost<{ encryptedMessage: string }>({
    url: '/apifo/auth/send_touchId2fa',
    params: { data: payload },
  });

export const prepareTouchId = (payload: {
  deviceId: string;
  touchIdPublicKey: string;
}) =>
  axiosPost<{ encryptedMessage: string }>({
    url: '/apifo/device/prepareTouchId',
    params: {
      data: payload,
    },
  });

export const enrollTouchId = (payload: {
  deviceId: string;
  touchIdPublicKey: string;
  plainMessage: string;
}) =>
  axiosPost<Pick<Device, 'id'>>({
    url: '/apifo/device/enrollTouchId',
    params: { data: payload },
  });

export const refreshToken = async () => {
  const tokenQueryResults = await axiosPost<Tokens>({
    url: '/apifo/auth/refresh',

    refreshAuthentication: true,
  });
  const fetchedJWT = tokenQueryResults.data.jwt;
  if (fetchedJWT) {
    setAuthorizationBearer(fetchedJWT);
    setStrapiAuthorizationBearer(fetchedJWT);
  }
  return {
    ...tokenQueryResults,
    data: enrichWithJWTExp(tokenQueryResults.data),
  };
};

export const login = ({
  recaptchaToken,
  ...payload
}: {
  email: string;
  password: string;
  recaptchaToken: string | undefined;
}) =>
  axiosPost<{
    id: number;
    email: string;
    phone: string;
  }>({
    url: '/apifo/auth/login',
    params: { data: payload },

    recaptchaToken,
  });

export const initSignIn = ({
  provider,
  recaptchaToken,
}: {
  provider: OpenidProviders;
  recaptchaToken: string | undefined;
}) =>
  axiosGet<{
    authorizationUrl: string;
    codeVerifier: string;
  }>({
    url: `/apifo/auth/signinEndpoint/${provider}`,
    recaptchaToken,
  });

export const finishSignIn = ({
  provider,
  redirectUrl,
  codeVerifier,
}: {
  provider: OpenidProviders;
  codeVerifier: string;
  redirectUrl: string | undefined;
}) =>
  axiosPost<
    | { tokens: Tokens; appUser: UserData }
    | { email: string; phone: string; providerToken: string }
  >({
    url: `/apifo/auth/signinValidation/${provider}`,
    params: { data: { redirectUrl, codeVerifier } },
  });

export const initAttach = ({
  provider,
  recaptchaToken,
}: {
  provider: OpenidProviders;
  recaptchaToken: string | undefined;
}) =>
  axiosGet<{
    authorizationUrl: string;
    codeVerifier: string;
  }>({
    url: `/apifo/auth/attachEndpoint/${provider}`,
    recaptchaToken,
  });

export const finishAttach = ({
  provider,
  ...data
}: {
  provider: OpenidProviders;
} & (
  | {
      codeVerifier: string;
      redirectUrl: string | undefined;
    }
  | { providerToken: string }
)) =>
  axiosPost<{ tokens: Tokens; appUser: UserData }>({
    url: `/apifo/auth/attachValidation/${provider}`,
    params: { data },
  });

export const attachWithVendor = (data: {
  identityToken: string;
  provider: OpenidProviders.GOOGLE | 'apple';
  givenName?: string;
  familyName?: string;
}) =>
  axiosPost<{ message: string }>({
    url: '/apifo/auth/attachWithVendor',
    params: { data },
  });

export const vendorSignin = (data: {
  identityToken: string;
  provider: OpenidProviders.GOOGLE | 'apple';
}) =>
  axiosPost<
    { tokens: Tokens; appUser: UserData } | { email: string; phone: string }
  >({
    url: '/apifo/auth/vendorSignin',
    params: { data },
  });

export const addScenario = (payload: { scenario: Scenarios }) =>
  axiosPost<void>({
    url: '/apifo/scenario',
    params: { data: payload },
  });

const deactivateAccount = () =>
  axiosPost<void>({ url: '/apifo/auth/deactivate' });

export default {
  forgotPassword: {
    reset: forgotPasswordRequest,
    submit: forgotPasswordSubmit,
  },
  login,
  initSignIn,
  finishSignIn,
  initAttach,
  finishAttach,
  attachWithVendor,
  vendorSignin,
  refreshToken,
  signup,
  checkEmail,
  checkPhone,
  request2faCode,
  check2fa,
  prepareTouchId,
  enrollTouchId,
  requestTouchId2faCode,
  addScenario,
  deactivate: deactivateAccount,
};
