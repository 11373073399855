import { resetAll } from '@redux/reduxReset';
import { createSlice } from '@reduxjs/toolkit';

const initialState = { freeContentDisplayed: 0 };

const freeContentCounterSlice = createSlice({
  name: 'freeContentCounter',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    resetCounter: (state) => {
      state.freeContentDisplayed = 0;
    },
    incrementCounter(state) {
      state.freeContentDisplayed += 1;
    },
  },
});

export const freeContentCounterCreators = freeContentCounterSlice.actions;
export default freeContentCounterSlice.reducer;
