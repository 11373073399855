import React from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';

import Highlight from '@components/Markdown/Highlight';
import Label from '@components/Pills/Label';
import { PressableOpacity } from '@components/PressableOpacity';
import { Colors, Fonts } from '@resources/themes';

const styles = StyleSheet.create({
  container: { flexDirection: 'row', height: 128 },
  imageContainer: { width: 96, borderRadius: 10, overflow: 'hidden' },
  content: { flex: 1, marginLeft: 20 },
  infoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  highlight: { marginBottom: -10 },
  text: {
    marginTop: 10,
    fontSize: Fonts.size.title,
    fontFamily: Fonts.type.medium,
  },
});

interface ContentCardUIProps {
  style?: StyleProp<ViewStyle>;
  onPress: () => void;
  favoriteButton?: React.ReactNode;
  imageSection: React.ReactNode;
  label: string;
  title: string;
  highlightedTitle?: string | undefined;
  contentColor?: string;
  fields?: React.ReactNode;
  disabled?: boolean;
  testID?: string;
}

const ContentCardUI = ({
  style,
  onPress,
  favoriteButton = null,
  imageSection,
  label,
  title,
  highlightedTitle,
  contentColor = Colors.duck800,
  fields,
  disabled,
  testID,
}: ContentCardUIProps) => (
  <PressableOpacity
    testID={testID}
    style={[styles.container, style]}
    onPress={onPress}
    disabled={disabled}
  >
    <View style={styles.imageContainer}>{imageSection}</View>
    <View style={styles.content}>
      <View style={styles.infoRow}>
        <Label>{label}</Label>
        {favoriteButton}
      </View>
      {highlightedTitle ? (
        <Highlight
          style={styles.highlight}
          content={highlightedTitle}
          currentColor={contentColor}
        />
      ) : (
        <Text
          style={[styles.text, { color: contentColor }]}
          numberOfLines={2}
          ellipsizeMode={'tail'}
        >
          {title}
        </Text>
      )}
      {fields}
    </View>
  </PressableOpacity>
);

export default ContentCardUI;
