import React, { useRef } from 'react';

import ArticleType from '@commonTypes/apiResponses/Article';
import FolderType from '@commonTypes/apiResponses/Folder';
import GuideType from '@commonTypes/apiResponses/Guide';
import MasterclassType from '@commonTypes/apiResponses/Masterclass';
import { remoteConfig } from '@tools/firebase';
import { typedMemo } from '@tools/typedMemo';

import { DailyTip } from '@api-requests/api/cms/daily-tips/types';
import { Video } from '@api-requests/api/cms/partner-videos/types';
import { WeeklyTip } from '@api-requests/api/cms/weekly-tips/types';
import { ContentType } from '@tools/formatData/formatAllContentList/types';

import ArticleCard from './Article';
import DailyTipCard from './DailyTip';
import FolderCard from './Folder';
import GuideCard from './Guide';
import MasterclassCard from './Masterclass';
import { ContentCardCommonProps, ContentCardProps } from './types';
import VideoCard from './Video';
import { WeeklyTipContentCard } from './WeeklyTip';

const ContentCard = <T extends ContentType>({
  style,
  contentType,
  item,
  additionalStyle,
  trackers,
  screen,
  disabled,
  index,
}: ContentCardProps<T> & ContentCardCommonProps) => {
  const toggleAllContentPremium = remoteConfig()
    .getValue('toggleAllContentPremium')
    .asBoolean();
  const toggleAllContentFree = remoteConfig()
    .getValue('toggleAllContentFree')
    .asBoolean();
  const styleRef = useRef([style, additionalStyle]);
  switch (contentType) {
    case ContentType.folder:
      return (
        <FolderCard
          style={styleRef.current}
          item={item as FolderType}
          screen={screen}
          disabled={disabled}
        />
      );
    case ContentType.guide:
      return (
        <GuideCard
          style={styleRef.current}
          item={item as GuideType}
          screen={screen}
          trackers={trackers}
          disabled={disabled}
          toggleAllContentPremium={toggleAllContentPremium}
          toggleAllContentFree={toggleAllContentFree}
        />
      );
    case ContentType.article:
      return (
        <ArticleCard
          style={styleRef.current}
          item={item as ArticleType}
          screen={screen}
          trackers={trackers}
          disabled={disabled}
          toggleAllContentPremium={toggleAllContentPremium}
          toggleAllContentFree={toggleAllContentFree}
          testID={`contentCard-article${index}`}
        />
      );
    case ContentType.masterclass:
      return (
        <MasterclassCard
          style={styleRef.current}
          item={item as MasterclassType}
          screen={screen}
          trackers={trackers}
          disabled={disabled}
        />
      );
    case ContentType.dailyTip:
      return (
        <DailyTipCard
          style={styleRef.current}
          item={item as DailyTip}
          disabled={disabled}
        />
      );
    case ContentType.video:
      return (
        <VideoCard
          style={styleRef.current}
          item={item as Video}
          screen={screen}
          trackers={trackers}
          disabled={disabled}
        />
      );
    case ContentType.weeklyTip:
      return (
        <WeeklyTipContentCard
          style={styleRef.current}
          item={item as WeeklyTip}
          disabled={disabled}
        />
      );
    default:
      return null;
  }
};

export default typedMemo(ContentCard);
