import React from 'react';

import { ANONYMOUS_STACK } from '@navigation/Routes';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import AttachSuggestion from '@screens/Auth/AttachSuggestion';
import ForgotPasswordScreen from '@screens/Auth/ForgotPassword';
import LoginScreen from '@screens/Auth/LoginScreen';
import { OpenIdSignin } from '@screens/Auth/OpenIdSignin';
import ResetPasswordScreen from '@screens/Auth/ResetPasswordScreen';
import SignUpScreen from '@screens/Auth/SignUp';
import SuccessResetPasswordScreen from '@screens/Auth/SuccessResetPasswordScreen';
import VerificationCodeScreen from '@screens/Auth/VerificationCode';
import StaticContentsScreen from '@screens/StaticContentsScreen';
import { WebAnonymousStackParamList } from '@webNavigation/routeParams';
import { WEB_ANONYMOUS_STACK } from '@webNavigation/routes';
import IntroScreen from '@webScreens/Intro';
import {
  PreOnboardingFormScreen,
  RemotePreOnboardingImageScreen,
  RemotePreOnboardingStepScreen,
} from '@webScreens/PreOnboardingScreens';

const Stack = createNativeStackNavigator<WebAnonymousStackParamList>();
const WebAnonymousNavigator = () => {
  const initialRoute = WEB_ANONYMOUS_STACK.INTRO_SCREEN;

  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={initialRoute}
    >
      <Stack.Screen
        name={WEB_ANONYMOUS_STACK.INTRO_SCREEN}
        component={IntroScreen}
      />
      <Stack.Screen
        name={WEB_ANONYMOUS_STACK.PRE_ONBOARDING_SCREEN}
        component={PreOnboardingFormScreen}
      />
      <Stack.Screen
        name={WEB_ANONYMOUS_STACK.REMOTE_PRE_ONBOARDING_IMAGE_SCREEN}
        component={RemotePreOnboardingImageScreen}
      />
      <Stack.Screen
        name={WEB_ANONYMOUS_STACK.REMOTE_PRE_ONBOARDING_STEP_SCREEN}
        component={RemotePreOnboardingStepScreen}
      />
      <Stack.Screen name={ANONYMOUS_STACK.REGISTER} component={SignUpScreen} />
      <Stack.Screen name={ANONYMOUS_STACK.LOGIN} component={LoginScreen} />
      <Stack.Screen
        name={ANONYMOUS_STACK.FORGOT_PASSWORD}
        component={ForgotPasswordScreen}
      />
      <Stack.Screen
        name={ANONYMOUS_STACK.RESET_PASSWORD_SCREEN}
        component={ResetPasswordScreen}
      />
      <Stack.Screen
        name={ANONYMOUS_STACK.SUCCESS_RESET_PASSWORD_SCREEN}
        component={SuccessResetPasswordScreen}
      />
      <Stack.Screen
        name={ANONYMOUS_STACK.VERIFICATION_CODE_SCREEN}
        component={VerificationCodeScreen}
      />
      <Stack.Screen
        name={ANONYMOUS_STACK.STATIC_CONTENTS_SCREEN}
        component={StaticContentsScreen}
      />
      <Stack.Screen
        name={ANONYMOUS_STACK.OPENID_SIGNIN}
        component={OpenIdSignin}
      />
      <Stack.Screen
        name={ANONYMOUS_STACK.ATTACH_SUGGESTION_SCREEN}
        component={AttachSuggestion}
      />
    </Stack.Navigator>
  );
};

export default WebAnonymousNavigator;
