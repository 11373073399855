import { axiosGet } from '@api-requests/api/main/request';

export interface StripeCards {
  customerId: string;
  paymentSource: Record<string, any>;
  ephemeralKey: string;
  setupIntent: string;
}

export const getListCard = () =>
  axiosGet<StripeCards>({
    url: '/apifo/payment_sources/list',
  });
