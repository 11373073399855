import React from 'react';
import {
  Image,
  Platform,
  SafeAreaView,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { logTutorialComplete } from '@analytics/events';
import { useSelectedQuery } from '@api-requests/api/cms/selected/hooks';
import { useDispatch, useSelector } from '@commonTypes/redux';
import { PrimaryButton } from '@components/Buttons';
import LinearGradient, {
  LinearGradientConfig,
} from '@components/Gradients/LinearGradient';
import { subscribeModalCreators } from '@components/Modal/RootSubscribeModal/redux/subscribe-modal';
import AppIcon from '@components/ui/Icon';
import { t } from '@config';
import useCheckIfPregnant from '@hooks/useCheckIfPregnant';
import { useDisableBackButton } from '@hooks/useDisableBackButton';
import { usePostScenario } from '@hooks/usePostScenario';
import { navigate } from '@navigation/Actions';
import {
  NAVIGATOR_NAMES,
  ONBOARDING_STACK,
  TAB_NAMES,
} from '@navigation/Routes';
import { CommonActions, useNavigation } from '@react-navigation/native';
import DEVICE from '@resources/constants/device';
import journaling from '@resources/images/journaling.webp';
import kidChatPreview from '@resources/images/kidChatPreview.webp';
import pregnancyChatPreview from '@resources/images/pregnancyChatPreview.webp';
import { Colors } from '@resources/themes';
import SelectedForYouUI from '@screens/LoggedInHome/components/SelectedForYou/SelectedForYouUI';
import { remoteConfig } from '@tools/firebase';
import { getChatOpeningHours } from '@tools/getChatOpeningHours';
import { WEB_COMMON_SCREEN_STACK } from '@webNavigation/routes';

import { OnboardingStackParamList, ScreenRoute } from '@navigation/types';

import Section from './components/Section';
import styles from './styles';

const GradientConfig: LinearGradientConfig = [
  { offset: '0%', stopColor: Colors.white100, stopOpacity: '0' },
  { offset: '100%', stopColor: Colors.white100, stopOpacity: '1' },
];
const FeaturesSummaryScreen = ({
  route: { name: screen },
}: ScreenRoute<
  OnboardingStackParamList,
  ONBOARDING_STACK.FEATURES_SUMMARY
>) => {
  const navigation = useNavigation();
  useDisableBackButton();
  const { bottom } = useSafeAreaInsets();
  const dispatch = useDispatch();
  const isPregnant = useCheckIfPregnant();
  const firstName = useSelector((state) => state?.user?.data?.firstName ?? '');
  const kidList = useSelector((state) => state?.user?.data?.kids ?? []);
  const pregnancy = useSelector((state) => state?.user?.data?.pregnancy ?? {});
  const { data: selection } = useSelectedQuery(kidList, pregnancy);

  const { mutate: postScenario, isPending: isPostingScenario } =
    usePostScenario();

  const handleStartPress = () => {
    const scenario = remoteConfig().getValue('onboardingScenario').asString();
    logTutorialComplete({ scenario });
    postScenario({ scenario: 'onboarding-v2' });
    if (Platform.OS === 'web') {
      navigate(WEB_COMMON_SCREEN_STACK.SUBSCRIBE_SCREEN, {
        targetTab: undefined,
      });
    } else {
      navigate(NAVIGATOR_NAMES.TAB_BAR_NAVIGATOR, { screen: TAB_NAMES.HOME });
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: NAVIGATOR_NAMES.TAB_BAR_NAVIGATOR }],
        }),
      );
    }
    dispatch(
      subscribeModalCreators.show({
        origin: ONBOARDING_STACK.FEATURES_SUMMARY,
      }),
    );
  };

  const { isDifferentFromParis } = getChatOpeningHours();

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView
        contentContainerStyle={styles.scrollView}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.header}>
          <AppIcon name={'MayTitle'} height={40} width={110} />
          <Text style={styles.title}>
            {t('ON_BOARDING.FEATURE.HEADER_TITLE', { firstName })}
          </Text>
          <Text style={styles.text}>
            {t('ON_BOARDING.FEATURE.HEADER_TEXT')}
          </Text>
        </View>
        <Section
          icon={'ChatBubble'}
          iconDescription={t('ON_BOARDING.FEATURE.CHAT.ICON_MSG')}
          title={t('ON_BOARDING.FEATURE.CHAT.TITLE')}
          description={
            isDifferentFromParis
              ? t('ON_BOARDING.FEATURE.CHAT.TEXT_TZ')
              : t('ON_BOARDING.FEATURE.CHAT.TEXT')
          }
        >
          <View style={styles.imageContainer}>
            <Image
              resizeMode={'contain'}
              source={isPregnant ? pregnancyChatPreview : kidChatPreview}
              style={styles.image}
            />
          </View>
        </Section>
        <Section
          style={styles.section}
          icon={'Card'}
          iconDescription={t('ON_BOARDING.FEATURE.SELECTED_FOR_ME.ICON_MSG')}
          title={t('ON_BOARDING.FEATURE.SELECTED_FOR_ME.TITLE')}
          description={t('ON_BOARDING.FEATURE.SELECTED_FOR_ME.TEXT')}
        >
          <SelectedForYouUI
            selection={selection}
            screen={screen}
            amenorrheaWeeks={pregnancy?.amenorrheaWeeks}
            disabled
          />
        </Section>
        <Section
          style={styles.section}
          icon={'JournalingOutline'}
          iconSize={20}
          iconDescription={t('ON_BOARDING.FEATURE.JOURNALING.ICON_MSG')}
          title={t('ON_BOARDING.FEATURE.JOURNALING.TITLE')}
          description={t('ON_BOARDING.FEATURE.JOURNALING.TEXT')}
        >
          <View style={styles.journalingContainer}>
            <Image
              resizeMode={'contain'}
              source={journaling}
              style={styles.image}
            />
          </View>
        </Section>
      </ScrollView>
      <LinearGradient
        // SafeInset + Button height + Button margin
        style={[styles.bgGradient, { bottom: bottom + 48 + 32 }]}
        config={GradientConfig}
        width={DEVICE.WINDOW_WIDTH}
        height={24}
      />
      <PrimaryButton
        style={styles.buttonWrapper}
        onPress={handleStartPress}
        label={t('ON_BOARDING.FEATURE.BUTTON')}
        isLoading={isPostingScenario}
        testID="startButton"
      />
    </SafeAreaView>
  );
};

export default FeaturesSummaryScreen;
