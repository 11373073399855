import { AxiosResponse } from 'axios';

import { OpenidProviders } from '@commonTypes/Auth';
import { UseMutationResult } from '@tanstack/react-query';

export const useOneTapSigninOrFallback = (
  {
    mutate: initOIDC,
    isPending: isOidcLoading,
  }: UseMutationResult<
    AxiosResponse<{
      authorizationUrl: string;
      codeVerifier: string;
    }>,
    any,
    { provider: OpenidProviders }
  >,
  onSettled: () => void = () => null,
) => {
  const result = async (
    provider: OpenidProviders,
    _tokenSuccess: (
      token: string,
      userInfo?: {
        familyName?: string | null;
        givenName?: string | null;
        name?: string | null;
      },
    ) => any,
    onAuthorizationUrlSuccess: ({
      data,
    }: {
      data: {
        authorizationUrl: string;
        codeVerifier: string;
      };
    }) => void,
    onAuthorizationUrlError: (error: any) => void,
  ) => {
    initOIDC(
      { provider },
      {
        onSuccess: onAuthorizationUrlSuccess,
        onError: onAuthorizationUrlError,
        onSettled,
      },
    );
  };

  return { mutate: result, isLoading: isOidcLoading };
};
