import { Platform } from 'react-native';

// SVG icons containing gradient should be split for web : the gradient must be defined in the index.html while the rest should be in the svg file.
// See EggIcon for an example.

import Apple from '@resources/svg/apple.svg';
import Arrow from '@resources/svg/arrow.svg';
import Article from '@resources/svg/article.svg';
import Audio from '@resources/svg/audio.svg';
import BabyBottle from '@resources/svg/baby-bottle.svg';
import BabyHalfInEggIcon from '@resources/svg/baby-half-in-egg.svg';
import BabyIcon from '@resources/svg/babyIcon.svg';
import BackArrowPinkIcon from '@resources/svg/back-arrow-pink.svg';
import BellOutlineIcon from '@resources/svg/bell-outline.svg';
import BellIcon from '@resources/svg/bell.svg';
import BigSearchGlassIcon from '@resources/svg/big-search-glass.svg';
import BlobsGrey from '@resources/svg/blobs-grey.svg';
import Blobs from '@resources/svg/blobs.svg';
import Book from '@resources/svg/book.svg';
import BoyIcon from '@resources/svg/boy-v2.svg';
import BreastFeed from '@resources/svg/breastfeed.svg';
import CakeIcon from '@resources/svg/cake.svg';
import CalendarColored from '@resources/svg/calendar-colored.svg';
import CalendarSimplified from '@resources/svg/calendar-simplified.svg';
import CalendarIcon from '@resources/svg/calendar.svg';
import CameraOutline from '@resources/svg/camera-outline.svg';
import CameraReverseOutline from '@resources/svg/camera-reverse-outline.svg';
import Camera from '@resources/svg/camera.svg';
import CameraShadowIcon from '@resources/svg/camera_shadow.svg';
import Card from '@resources/svg/card.svg';
import ChartDots from '@resources/svg/chart-dots.svg';
import Chart from '@resources/svg/chart.svg';
import ChatOutlineIcon from '@resources/svg/chat-outline.svg';
import ChatIcon from '@resources/svg/chat.svg';
import ChatBubble from '@resources/svg/chatBubble.svg';
import CheckIconPink from '@resources/svg/check-icon-pink.svg';
import CheckmarkSlim from '@resources/svg/checkmark-slim.svg';
import Checkmark from '@resources/svg/checkmark.svg';
import ClipboardTicked from '@resources/svg/clipboard-ticked.svg';
import Clipboard from '@resources/svg/clipboard.svg';
import Clock from '@resources/svg/clock.svg';
import CrossIcon from '@resources/svg/cross.svg';
import CrownFill from '@resources/svg/crown-fill.svg';
import Crown from '@resources/svg/crown.svg';
import CrystalBall from '@resources/svg/crystal-ball.svg';
import Download from '@resources/svg/download.svg';
import EggIcon from '@resources/svg/egg-v2.svg';
import EggWebIcon from '@resources/svg/egg-web.svg';
import EmailPinkIcon from '@resources/svg/email_pink.svg';
import ExploreOutline from '@resources/svg/explore-outline.svg';
import Explore from '@resources/svg/explore.svg';
import FavoriteBoldFill from '@resources/svg/favoriteBold-fill.svg';
import FavoriteBold from '@resources/svg/favoriteBold.svg';
import Filters from '@resources/svg/filters.svg';
import FlashOffOutline from '@resources/svg/flash-off-outline.svg';
import Flash from '@resources/svg/flash.svg';
import ForbiddenIcon from '@resources/svg/forbidden.svg';
import Gift from '@resources/svg/gift.svg';
import GirlIcon from '@resources/svg/girl-v2.svg';
import Google from '@resources/svg/google.svg';
import CrossGreenIcon from '@resources/svg/green-cross.svg';
import Guide from '@resources/svg/guide.svg';
import HandedHeart from '@resources/svg/handed_heart.svg';
import headset from '@resources/svg/headset.svg';
import Heart from '@resources/svg/heart.svg';
import HomeOutlineIcon from '@resources/svg/home-outline.svg';
import HomeIcon from '@resources/svg/home.svg';
import BackPinkIcon from '@resources/svg/ic_back_pink.svg';
import PhoneIcon from '@resources/svg/ic_call.svg';
import ChatPinkIcon from '@resources/svg/ic_chat_pink.svg';
import CheckWhiteIcon from '@resources/svg/ic_check_white.svg';
import EuroIcon from '@resources/svg/ic_euro.svg';
import LibraryLightGreenIcon from '@resources/svg/ic_library_light_green.svg';
import MaintenanceIcon from '@resources/svg/ic_Maintenance.svg';
import NextPinkIcon from '@resources/svg/ic_next_pink.svg';
import NoteIcon from '@resources/svg/ic_note.svg';
import PhoneGreenOutlineIcon from '@resources/svg/ic_phone.svg';
import AttachmentIcon from '@resources/svg/ic_pin.svg';
import PlusGreenIcon from '@resources/svg/ic_plus.svg';
import QuestionIcon from '@resources/svg/ic_question.svg';
import StopIcon from '@resources/svg/ic_stop.svg';
import TeleconsultationOffIcon from '@resources/svg/ic_teleconsultation_off.svg';
import TeleconsultationOnIcon from '@resources/svg/ic_teleconsultation_on.svg';
import TeleconsultationWhiteIcon from '@resources/svg/ic_teleconsultation_white.svg';
import TeleconsultationYellowIcon from '@resources/svg/ic_teleconsultation_yellow.svg';
import VideoIcon from '@resources/svg/ic_video.svg';
import CheckRoundedGreenIcon from '@resources/svg/icon_validation-ok_green.svg';
import ImagesOutline from '@resources/svg/images-outline.svg';
import Info from '@resources/svg/info.svg';
import JournalingOutline from '@resources/svg/journaling-outline.svg';
import Journaling from '@resources/svg/journaling.svg';
import LetterIcon from '@resources/svg/letter.svg';
import LiviText from '@resources/svg/livi-text.svg';
import LockWhiteIcon from '@resources/svg/lock-white.svg';
import Lock from '@resources/svg/lock.svg';
import LogoFrance2 from '@resources/svg/logo-france2.svg';
import LogoLMDM from '@resources/svg/logo-LMDM.svg';
import MagicWand from '@resources/svg/magic_wand.svg';
import Mail from '@resources/svg/mail.svg';
import MayTitle from '@resources/svg/mayTitle.svg';
import MenuAlt4 from '@resources/svg/menu-alt-4.svg';
import MoreInfo from '@resources/svg/more-info.svg';
import More from '@resources/svg/more.svg';
import ThumbIcon from '@resources/svg/ok-icon.svg';
import CheckIcon from '@resources/svg/pass-rules-tick.svg';
import PauseIcon from '@resources/svg/pause.svg';
import PaymentCardGreenIcon from '@resources/svg/payment_card_green.svg';
import PaymentCardShadowIcon from '@resources/svg/payment_card_shadow.svg';
import Pen from '@resources/svg/pen-simplified.svg';
import PhoneWhiteIcon from '@resources/svg/phone-white.svg';
import Picture from '@resources/svg/picture.svg';
import PinkCrossIcon from '@resources/svg/pink-cross.svg';
import PlayInverted from '@resources/svg/play-inverted.svg';
import PlayIcon from '@resources/svg/play.svg';
import Plus from '@resources/svg/plus-thin.svg';
import Pregnant from '@resources/svg/pregnant.svg';
import PregnantIcon from '@resources/svg/pregnantIcon.svg';
import ProfileOutlineIcon from '@resources/svg/profile-outline.svg';
import ProtectionIcon from '@resources/svg/protection-icon.svg';
import RateEmojiBad from '@resources/svg/RateEmoji_bad.svg';
import RateEmojiGood from '@resources/svg/RateEmoji_good.svg';
import RateEmojiNeutral from '@resources/svg/RateEmoji_neutral.svg';
import RollBackIcon from '@resources/svg/rollBack.svg';
import RoundedQuestionMarkIcon from '@resources/svg/rounded-question-mark.svg';
import SadSmileyIcon from '@resources/svg/sad-smiley.svg';
import Scale from '@resources/svg/scale.svg';
import SearchIcon from '@resources/svg/search.svg';
import Send from '@resources/svg/send.svg';
import Settings from '@resources/svg/settings.svg';
import Shape from '@resources/svg/shape.svg';
import ShareAndroid from '@resources/svg/shareAndroid.svg';
import ShareIOS from '@resources/svg/shareIOS.svg';
import Minus from '@resources/svg/sign-minus.svg';
import SliderHandle from '@resources/svg/sliderHandle.svg';
import Smiley from '@resources/svg/smiley.svg';
import StarYellowIcon from '@resources/svg/star_yellow.svg';
import StarYellowFatIcon from '@resources/svg/starYellowFatIcon.svg';
import StoragePermissionIcon from '@resources/svg/storage_permission.svg';
import SubscribeTitleBanner from '@resources/svg/subscribe_title_banner.svg';
import ThinkingSmileyIcon from '@resources/svg/thinking-smiley.svg';
import ThumbsDownEmpty from '@resources/svg/thumbs-down-empty.svg';
import ThumbsDownFull from '@resources/svg/thumbs-down-full.svg';
import ThumbsUpEmpty from '@resources/svg/thumbs-up-empty.svg';
import ThumbsUpFull from '@resources/svg/thumbs-up-full.svg';
import TickOutline from '@resources/svg/tick-outline.svg';
import CheckRoundedPinkIcon from '@resources/svg/tick.svg';
import Trash from '@resources/svg/trash.svg';
import Video from '@resources/svg/video.svg';
import ViewGrid from '@resources/svg/view-grid.svg';
import WarningGreenIcon from '@resources/svg/warning-icon.svg';
import WarningIcon from '@resources/svg/warning.svg';
import Web from '@resources/svg/web.svg';
import WhiteCameraIcon from '@resources/svg/white-camera.svg';
import WhiteCheckCurved from '@resources/svg/whiteCheckCurved.svg';
import World from '@resources/svg/world.svg';

const IconsList = {
  Apple,
  Arrow,
  Article,
  AttachmentIcon,
  Audio,
  BabyBottle,
  BabyHalfInEggIcon,
  BabyIcon,
  BackArrowPinkIcon,
  BackPinkIcon,
  BellIcon,
  BellOutlineIcon,
  BigSearchGlassIcon,
  BlobsGrey,
  Blobs,
  Book,
  BoyIcon,
  BreastFeed,
  CakeIcon,
  CalendarColored,
  CalendarIcon,
  CalendarSimplified,
  Camera,
  CameraOutline,
  CameraReverseOutline,
  CameraShadowIcon,
  Card,
  Chart,
  ChartDots,
  ChatBubble,
  ChatIcon,
  ChatOutlineIcon,
  ChatPinkIcon,
  CheckIcon,
  CheckIconPink,
  CheckRoundedGreenIcon,
  CheckRoundedPinkIcon,
  CheckWhiteIcon,
  Checkmark,
  CheckmarkSlim,
  Clipboard,
  ClipboardTicked,
  Clock,
  CrossGreenIcon,
  CrossIcon,
  Crown,
  CrownFill,
  CrystalBall,
  Download,
  EggIcon: Platform.select({ web: EggWebIcon, default: EggIcon }),
  EmailPinkIcon,
  EuroIcon,
  Explore,
  ExploreOutline,
  FavoriteBold,
  FavoriteBoldFill,
  Filters,
  FlashOffOutline,
  Flash,
  ForbiddenIcon,
  Gift,
  GirlIcon,
  Google,
  Guide,
  HandedHeart,
  headset,
  Heart,
  HomeIcon,
  HomeOutlineIcon,
  ImagesOutline,
  Info,
  Journaling,
  JournalingOutline,
  LetterIcon,
  LibraryLightGreenIcon,
  LiviText,
  Lock,
  LockWhiteIcon,
  LogoFrance2,
  LogoLMDM,
  MagicWand,
  Mail,
  MaintenanceIcon,
  MayTitle,
  MenuAlt4,
  Minus,
  MoreInfo,
  More,
  NextPinkIcon,
  NoteIcon,
  PauseIcon,
  PaymentCardGreenIcon,
  PaymentCardShadowIcon,
  Pen,
  PhoneGreenOutlineIcon,
  PhoneIcon,
  PhoneWhiteIcon,
  Picture,
  PinkCrossIcon,
  PlayInverted,
  PlayIcon,
  Plus,
  PlusGreenIcon,
  Pregnant,
  PregnantIcon,
  ProfileOutlineIcon,
  ProtectionIcon,
  RateEmojiBad,
  RateEmojiGood,
  RateEmojiNeutral,
  RollBackIcon,
  QuestionIcon,
  RoundedQuestionMarkIcon,
  SadSmileyIcon,
  Scale,
  SearchIcon,
  Send,
  Settings,
  Shape,
  ShareAndroid,
  ShareIOS,
  SliderHandle,
  Smiley,
  StarYellowFatIcon,
  StarYellowIcon,
  StopIcon,
  StoragePermissionIcon,
  SubscribeTitleBanner,
  TeleconsultationOffIcon,
  TeleconsultationOnIcon,
  TeleconsultationWhiteIcon,
  TeleconsultationYellowIcon,
  ThinkingSmileyIcon,
  ThumbIcon,
  ThumbsDownEmpty,
  ThumbsDownFull,
  ThumbsUpEmpty,
  ThumbsUpFull,
  TickOutline,
  Trash,
  Video,
  VideoIcon,
  ViewGrid,
  WarningGreenIcon,
  WarningIcon,
  Web,
  WhiteCameraIcon,
  WhiteCheckCurved,
  World,
};

export type IconName = keyof typeof IconsList;

export default IconsList;
