import { AxiosError } from 'axios';

import { logLogin, logSignUp } from '@analytics/events';
import auth from '@api-requests/api/auth';
import {
  finishSignUp,
  initSignUp,
  signupWithVendor,
} from '@api-requests/api/signup';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import { OpenidProviders, Tokens } from '@commonTypes/Auth';
import { useDispatch } from '@commonTypes/redux';
import { useRecaptchaQuery } from '@hooks/useRecaptchaQuery';
import { UserData } from '@redux/user';
import { userCreators } from '@redux/user';
import { useMutation } from '@tanstack/react-query';

export const useInitLoginWith = () => {
  const initSignIn = useRecaptchaQuery(auth.initSignIn, 'signIn');

  return useMutation({ mutationKey: ['initSignIn'], mutationFn: initSignIn });
};

export const useFinishLoginWith = () => {
  const dispatch = useDispatch();
  return useMutation<
    ReducedAxiosResponse<
      | { tokens: Tokens; appUser: UserData }
      | { email: string; phone: string; providerToken: string }
    >,
    AxiosError,
    {
      provider: OpenidProviders;
      codeVerifier: string;
      redirectUrl: string | undefined;
    }
  >({
    mutationKey: ['finishSignIn'],
    mutationFn: auth.finishSignIn,
    onSuccess: (res, { provider }) => {
      logLogin({ method: provider });
      if ('appUser' in res.data) {
        dispatch(
          userCreators.userSuccess({
            ...res.data.appUser,
            detail: true,
          }),
        );
      }
    },
  });
};

export const useVendorSignin = (provider: OpenidProviders.GOOGLE | 'apple') => {
  const dispatch = useDispatch();
  return useMutation({
    mutationKey: ['signInWithVendor'],
    mutationFn: (args: { identityToken: string }) =>
      auth.vendorSignin({ ...args, provider }),
    onSuccess: (res) => {
      logLogin({ method: provider });
      if ('appUser' in res.data) {
        dispatch(
          userCreators.userSuccess({
            ...res.data.appUser,
            detail: true,
          }),
        );
      }
    },
  });
};

export const useInitSignupWith = () => {
  const initSignIn = useRecaptchaQuery(initSignUp, 'signUp');

  return useMutation({ mutationKey: ['initSignUp'], mutationFn: initSignIn });
};

export const useFinishSignupWith = () => {
  return useMutation<
    ReducedAxiosResponse<{ tokens: Tokens; appUser: UserData }>,
    AxiosError,
    {
      provider: OpenidProviders;
      codeVerifier: string;
      redirectUrl: string | undefined;
      newsLetter?: boolean;
    }
  >({
    mutationKey: ['finishSignUp'],
    mutationFn: finishSignUp,
    onSuccess: (_, { provider }) => {
      logSignUp({ method: provider });
    },
  });
};

export const useSignupWithVendor = (
  provider: OpenidProviders.GOOGLE | 'apple',
) => {
  return useMutation<
    ReducedAxiosResponse<{ tokens: Tokens; appUser: UserData }>,
    AxiosError,
    {
      identityToken: string;
      givenName?: string;
      familyName?: string;
      newsLetter?: boolean;
    }
  >({
    mutationKey: ['signUpWithApple'],
    mutationFn: (args) => signupWithVendor({ ...args, provider }),
    onSuccess: () => {
      logSignUp({ method: provider });
    },
  });
};

export const useAttachWithVendor = (
  provider: OpenidProviders.GOOGLE | 'apple',
) => {
  return useMutation<
    ReducedAxiosResponse<{ message: string }>,
    AxiosError,
    {
      identityToken: string;
      givenName?: string;
      familyName?: string;
    }
  >({
    mutationKey: ['signUpWithApple'],
    mutationFn: (args) => auth.attachWithVendor({ ...args, provider }),
  });
};

export const useInitAttachWith = () => {
  const initAttach = useRecaptchaQuery(auth.initAttach, 'signIn');

  return useMutation({ mutationKey: ['initSignIn'], mutationFn: initAttach });
};

export const useFinishAttachWith = () => {
  return useMutation<
    ReducedAxiosResponse<{ tokens: Tokens; appUser: UserData }>,
    AxiosError,
    {
      provider: OpenidProviders;
    } & (
      | {
          codeVerifier: string;
          redirectUrl: string | undefined;
        }
      | { providerToken: string }
    )
  >({
    mutationKey: ['finishSignIn'],
    mutationFn: auth.finishAttach,
  });
};
