import React from 'react';
import { ImageSourcePropType } from 'react-native';
import { Linecap } from 'react-native-svg';

import Image from '@components/Image';
import AppIcon from '@components/ui/Icon';
import { IconName } from '@resources/svg';
import { Colors } from '@resources/themes';

interface IllustrationProps {
  source: IconName | ImageSourcePropType | undefined;
  size?: number;
  iconColor?: string;
  strokeWidth?: string | number;
  strokeLinecap?: Linecap;
}
function Illustration({
  source,
  size = 48,
  iconColor = Colors.duck800,
  strokeWidth,
  strokeLinecap,
}: IllustrationProps) {
  if (!source) {
    return null;
  }
  return typeof source === 'string' ? (
    <AppIcon
      name={source}
      size={size}
      color={iconColor}
      strokeWidth={strokeWidth}
      strokeLinecap={strokeLinecap}
    />
  ) : (
    <Image source={source} style={{ width: size, height: size }} />
  );
}
export default Illustration;
