import mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';

import { setURL } from '@api-requests/api/cms/request';
import { setBaseURL } from '@api-requests/api/main/request';
import * as Sentry from '@sentry/browser';
import { bootstrapRemoteConfig } from '@tools/firebase';
declare global {
  interface Window {
    env: {
      ENV: string;
      REACT_NATIVE_API_URL: string;
      STRAPI_URL: string;
      SENTRY_DSN: string;
      RECAPTCHA_PUBLIC_KEY: string;
      SEGMENT_WRITE_KEY: string;
    };
  }
}

// Near entry of your product, init Mixpanel
mixpanel.init('dc53b29b7c925fcf52364a2685d683be', {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
});

const tagManagerArgs = {
  gtmId: 'GTM-M94R4VT',
};

TagManager.initialize(tagManagerArgs);

export const bootstrap = () => {
  setBaseURL(window.env.REACT_NATIVE_API_URL);
  setURL(window.env.STRAPI_URL);
  bootstrapRemoteConfig();

  if (window.env.ENV === 'production') {
    const dsn = window.env.SENTRY_DSN;
    const isStaging = window.env.REACT_NATIVE_API_URL.includes('staging');
    Sentry.init({
      dsn,
      environment: isStaging ? 'staging' : 'production',
      integrations: [new Sentry.BrowserTracing()],
      tracesSampleRate: 0.01,
    });
  }
};

export const RECAPTCHA_PUBLIC_KEY = window.env.RECAPTCHA_PUBLIC_KEY;

export const SEGMENT_WRITE_KEY = window.env.SEGMENT_WRITE_KEY;
