import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  container: { gap: 4 },
  backButton: { position: 'absolute', top: 0, left: -8 },

  titleContainer: { height: 40, justifyContent: 'center' },
  title: {
    fontFamily: Fonts.type.medium,
    color: Colors.duck800,
    fontSize: Fonts.size.input,
    textAlign: 'center',
    alignSelf: 'center',
  },

  progressContainer: {
    flexDirection: 'row',
    overflow: 'hidden',
    backgroundColor: Colors.yellow300,
    height: 4,
    borderRadius: 2,
    width: '100%',
  },
  progress: {
    height: '100%',
    borderRadius: 2,
    backgroundColor: Colors.yellow500,
  },
});
