import React from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';

import { useDispatch } from '@commonTypes/redux';
import { TextInput } from '@components/ui/hookFormInputs/TextInput';
import { t } from '@config';
import { push } from '@navigation/Actions';
import { ANONYMOUS_STACK } from '@navigation/Routes';
import { signupFormCreator } from '@screens/Auth/SignUp/redux/signupInfos';
import RegisterNavBar from '@screens/Auth/SignUp/RegisterNavBar';

import styles from '@screens/Auth/SignUp/EmailForm/styles';

import { useCheckEmail } from '../hooks/auth';

type EmailForm = { email: string };

const EmailForm = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit, formState, setError } = useForm<EmailForm>({
    defaultValues: { email: '' },
    mode: 'onTouched',
  });

  const { isSubmitting, isValid } = formState;
  const { mutateAsync: checkEmail } = useCheckEmail();
  const handleValidate = handleSubmit(async (data: EmailForm) => {
    const email = data.email.toLowerCase().trim();
    try {
      await checkEmail({ email });
      dispatch(signupFormCreator.addFormEmail(email));
      push(ANONYMOUS_STACK.REGISTER, { step: 'pass', noDismiss: true });
    } catch {
      setError('email', { type: 'EMAIL_ALREADY_USED' });
    }
  });

  return (
    <View style={styles.container}>
      <RegisterNavBar
        onPress={handleValidate}
        title={t('AUTH.SIGN_UP.EMAIL_TITLE')}
        isLoading={isSubmitting}
        enabled={isValid}
      />
      <View style={styles.inputContainer}>
        <TextInput
          autoCapitalize="none"
          inputMode="email"
          name="email"
          onSubmitEditing={isSubmitting ? () => null : handleValidate}
          placeholder={t('FORM.EMAIL_PLACEHOLDER')}
          control={control}
          returnKeyType="next"
          textContentType="emailAddress"
          testID="emailInput"
          label={t('AUTH.SIGN_UP.FORM_EMAIL')}
          rules={{
            required: true,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t('FORM.HOOK_FORMS_ERROR.INCORRECT_EMAIL_FORMAT'),
            },
          }}
          autoFocus
        />
      </View>
    </View>
  );
};

export default EmailForm;
