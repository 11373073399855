import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import AppIcon from '@components/ui/Icon';
import { t } from '@config';
import { IconName } from '@resources/svg';
import { Colors, Fonts } from '@resources/themes';

import { PRODUCT_TIER } from '@api-requests/api/main/payment/products/types';

const styles = StyleSheet.create({
  container: { gap: 16 },
  line: { flexDirection: 'row', gap: 12 },
  icon: { borderRadius: 18, padding: 6, height: 36 },
  primary: { backgroundColor: Colors.duck700 },
  secondary: { backgroundColor: Colors.duck300 },

  info: { flex: 1 },
  title: {
    color: Colors.duck700,
    fontFamily: Fonts.type.semiBold,
    fontSize: Fonts.size.title,
  },
  text: {
    color: Colors.duck700,
    fontFamily: Fonts.type.light,
    fontSize: Fonts.size.extraText,
  },
});

interface RecapUIProps {
  iconName: IconName;
  primary?: boolean;
  title: string;
  text: string;
}
export const RecapUI = ({ iconName, primary, title, text }: RecapUIProps) => (
  <View style={styles.line}>
    <View style={[styles.icon, primary ? styles.primary : styles.secondary]}>
      <AppIcon
        name={iconName}
        color={primary ? Colors.duck300 : Colors.duck400}
      />
    </View>
    <View style={styles.info}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.text}>{text}</Text>
    </View>
  </View>
);

interface EventsRecapProps {
  style?: View['props']['style'];
  hasTrial: boolean;
  tier: PRODUCT_TIER | undefined;
  isDifferentFromParis: boolean;
  canTrial: boolean;
  canUpgrade: boolean;
}
const EventsRecap = ({
  style,
  hasTrial,
  tier,
  canTrial,
  isDifferentFromParis,
  canUpgrade,
}: EventsRecapProps) => (
  <View style={[styles.container, style]}>
    <RecapUI
      iconName={'Lock'}
      primary
      title={t('SUBSCRIBE_MODAL.EVENTS_RECAP.1.TITLE')}
      text={
        tier === PRODUCT_TIER.ESSENTIAL
          ? t('SUBSCRIBE_MODAL.EVENTS_RECAP.1.TEXT_ESSENTIAL')
          : isDifferentFromParis
          ? t('SUBSCRIBE_MODAL.EVENTS_RECAP.1.TEXT_TZ')
          : t('SUBSCRIBE_MODAL.EVENTS_RECAP.1.TEXT')
      }
    />
    <RecapUI
      iconName={'Mail'}
      title={
        hasTrial && canTrial
          ? t('SUBSCRIBE_MODAL.EVENTS_RECAP.2TRIAL.TITLE')
          : t('SUBSCRIBE_MODAL.EVENTS_RECAP.2.TITLE')
      }
      text={
        hasTrial && canTrial
          ? t('SUBSCRIBE_MODAL.EVENTS_RECAP.2TRIAL.TEXT')
          : t('SUBSCRIBE_MODAL.EVENTS_RECAP.2.TEXT')
      }
    />
    <RecapUI
      iconName={(hasTrial && canTrial) || canUpgrade ? 'CrownFill' : 'Heart'}
      title={
        hasTrial && canTrial
          ? t('SUBSCRIBE_MODAL.EVENTS_RECAP.3TRIAL.TITLE')
          : canUpgrade
          ? t('SUBSCRIBE_MODAL.EVENTS_RECAP.3.TITLE')
          : t('SUBSCRIBE_MODAL.EVENTS_RECAP.3_ALT.TITLE')
      }
      text={
        hasTrial && canTrial
          ? t('SUBSCRIBE_MODAL.EVENTS_RECAP.3TRIAL.TEXT')
          : canUpgrade
          ? t('SUBSCRIBE_MODAL.EVENTS_RECAP.3.TEXT')
          : t('SUBSCRIBE_MODAL.EVENTS_RECAP.3_ALT.TEXT')
      }
    />
  </View>
);

export default EventsRecap;
