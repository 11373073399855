import { addScenario } from '@api-requests/api/auth';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import { Scenarios } from '@commonTypes/Auth';
import { useDispatch } from '@commonTypes/redux';
import { AuthCreators } from '@redux/auth';

import useMutationCall from './useMutationCall';

export const usePostScenario = () => {
  const dispatch = useDispatch();
  return useMutationCall<
    ReducedAxiosResponse<void>,
    any,
    { scenario: Scenarios }
  >({
    mutationFn: addScenario,
    mutationKey: ['addScenario'],

    onSuccess: (_, { scenario }) => {
      dispatch(AuthCreators.addScenario(scenario));
    },
  });
};
