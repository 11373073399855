import diacritics from 'diacritics';
import React from 'react';
import { FlatList, Image, Text, TextInput, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import Modal from '@components/Modal';
import { PressableOpacity } from '@components/PressableOpacity';
import { t } from '@config';
import CountryFlags from '@resources/countries/flags';
import CountryPhonePrefixes from '@resources/countries/phonePrefixes';
import TranslationCountry from '@resources/i18n/countries';
import CloseIcon from '@resources/svg/back-arrow-pink.svg';

import styles from './styles';
import type { Country, Props, State } from './types';

const removeDiacritics = diacritics.remove;

const ItemSeparatorComponent = () => <View style={styles.separator} />;

export default class CountryPicker extends React.Component<Props, State> {
  static defaultProps = {
    children: undefined,
    country: undefined,
    error: undefined,
    favorites: [],
    placeholder: t('FORM.COUNTRY'),
  };

  static getCountries = (favorites: Array<string>): Country[] => {
    const list: Country[] = [];
    const favoriteList: Country[] = [];

    favorites.forEach((countryCode) => {
      favoriteList.push({
        id: countryCode,
        prefix: CountryPhonePrefixes.getPrefix(countryCode),
        name: TranslationCountry.translate(countryCode),
        flag: CountryFlags.getFlag(countryCode)!,
      });
    });

    Object.entries(CountryPhonePrefixes.prefixes).forEach(([id, prefix]) => {
      if (!prefix) {
        return;
      }

      list.push({
        id,
        prefix: String(prefix),
        name: TranslationCountry.translate(id),
        flag: CountryFlags.getFlag(id)!,
      });
    });

    return favoriteList.concat(CountryPicker.sortList(list));
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const nextState = {};
    if (
      !!nextProps.favorites &&
      nextProps.favorites.length !== prevState.favorites.length
    ) {
      const countries = CountryPicker.getCountries(nextProps.favorites);
      Object.assign(nextState, { countries, countriesVisible: countries });
    }

    return nextState;
  }

  static sortList = (list: Country[]): Country[] =>
    list.sort((a, b) => {
      const textA = String(a.name).toUpperCase();
      const textB = String(b.name).toUpperCase();

      if (textA < textB) {
        return -1;
      } else if (textA === textB) {
        return 0;
      }

      return 1;
    });

  constructor(props: Props) {
    super(props);

    const countries = CountryPicker.getCountries(props?.favorites ?? []);

    this.state = {
      countries,
      countriesVisible: countries,
      favorites: props.favorites ?? [],
      visible: false,
    };
  }

  textChanged = (value: string) => {
    const { countries } = this.state;

    if (value.length > 0) {
      const countriesVisible = countries.filter(
        (el) =>
          removeDiacritics(String(el.name).toLowerCase())
            .replace(/[\u0300-\u036f]/g, '')
            .indexOf(
              removeDiacritics(value.toLowerCase()).replace(
                /[\u0300-\u036f]/g,
                '',
              ),
            ) >= 0,
      );

      this.setState({ countriesVisible });
    } else {
      this.setState({ countriesVisible: countries });
    }
  };

  onCountrySelected = (country: Country) => () => {
    const { onCountryChanged } = this.props;

    onCountryChanged(country);
    this.hide();
  };

  focus = () => this.show();

  hide = () => this.setState({ visible: false });

  show = () => this.setState({ visible: true });

  renderItem = ({ item }: { item: Country }) => (
    <PressableOpacity
      style={styles.listItem}
      onPress={this.onCountrySelected(item)}
    >
      <Image source={{ uri: item.flag }} style={styles.flag} />
      <Text style={styles.text}>{item.name}</Text>
    </PressableOpacity>
  );

  renderPicker = () => {
    const { countriesVisible, visible } = this.state;

    return (
      <Modal animationType="slide" isVisible={visible}>
        <SafeAreaView style={styles.container}>
          <View style={styles.headerContainer}>
            <PressableOpacity onPress={this.hide} style={styles.iconTouchable}>
              <CloseIcon height={15} style={styles.icon} width={15} />
            </PressableOpacity>
            <View style={styles.titleContainer}>
              <Text style={styles.title}>{t('COUNTRIES.TITLE')}</Text>
            </View>
          </View>

          <View style={styles.inputContainer}>
            <TextInput
              onChangeText={this.textChanged}
              placeholder={t('COUNTRIES.SEARCH')}
              style={styles.searchInput}
            />
          </View>

          <FlatList
            data={countriesVisible}
            ItemSeparatorComponent={ItemSeparatorComponent}
            keyboardDismissMode="on-drag"
            keyboardShouldPersistTaps="handled"
            keyExtractor={(item) => item.id}
            renderItem={this.renderItem}
          />
        </SafeAreaView>
      </Modal>
    );
  };

  render() {
    const { children, country, customStyle, disabled, error, placeholder } =
      this.props;

    return (
      <View style={[styles.mainContainer, customStyle]}>
        <PressableOpacity disabled={disabled} onPress={this.show}>
          {children || (
            <>
              <View style={error ? styles.inputError : null}>
                {country ? (
                  <Text>{TranslationCountry.translate(country)}</Text>
                ) : (
                  <Text>{placeholder}</Text>
                )}
              </View>
              {!!error && <Text style={styles.inputErrorText}>{error}</Text>}
            </>
          )}
        </PressableOpacity>
        {this.renderPicker()}
      </View>
    );
  }
}
