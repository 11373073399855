import dayjs, { extend, OpUnitType, QUnitType } from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { Pregnancy } from '@commonTypes/Pregnancy';
import DURATION from '@resources/constants/duration';

extend(utc);
extend(tz);

export const getActualPregnancyMonth = (pregnancy: Pregnancy) =>
  // add + 2 to get pregnancy beginning instead of end of period
  dayjs().diff(
    dayjs().subtract(pregnancy.amenorrheaWeeks - 2, 'weeks'),
    'months',
  );

export const getLocallyFormattedDate = ({
  date,
  isUtcActive = true,
  localPreset = 'fr',
  resultFormat,
}: {
  date: string | Date;
  isUtcActive?: boolean;
  localPreset?: string;
  resultFormat: string;
}) => {
  return dayjs(date).utc(isUtcActive).locale(localPreset).format(resultFormat);
};

export const getBirthInformation = (
  birthday: string | Date | undefined,
  referenceDate: string | Date = dayjs().utc().toDate(),
): {
  months: number;
  totalMonths: number;
  days: number;
  years: number;
  totalDays: number;
} => {
  if (!birthday) {
    return { months: 0, totalMonths: 0, years: 0, days: 0, totalDays: 0 };
  }

  const reference = dayjs.utc(referenceDate);
  let birthdayValue = dayjs.utc(birthday);

  const years = reference.diff(birthdayValue, 'years');
  birthdayValue = birthdayValue.add(years, 'years');

  const months = reference.diff(birthdayValue, 'months');
  birthdayValue = birthdayValue.add(months, 'months');
  const totalMonths = years * 12 + months;

  const days = reference.diff(birthdayValue, 'days');
  const totalDays = reference.diff(dayjs.utc(birthday), 'days');

  return {
    months,
    totalMonths,
    years,
    days,
    totalDays,
  };
};

export const getDiff = (
  reference: string | number | Date | dayjs.Dayjs,
  target: string | number | Date | dayjs.Dayjs,
  unit: QUnitType | OpUnitType,
  float?: boolean,
) => dayjs.utc(reference).diff(dayjs.utc(target), unit, float);

export const getDaysSinceDate = (date2: string) => {
  return dayjs.utc().diff(dayjs(date2), 'days');
};

export const getWeeksSinceDate = (date: string) => {
  return dayjs.utc().diff(dayjs(date), 'weeks');
};

export const addUnit = (
  reference: string | number | Date,
  value: number,
  unit?: dayjs.ManipulateType | undefined,
) => dayjs(reference).add(value, unit);

export const computeStartPregnancyDate = (expectedEnd: string) => {
  return dayjs
    .utc(expectedEnd)
    .subtract(DURATION.PREGNANCY_WEEKS_DURATION, 'week')
    .toISOString();
};
export const getPregnancyDaysFromStart = ({
  expectedEnd,
}: {
  expectedEnd: string;
}) => {
  // date of the first day of the pregnancy
  const startPregnancyDate = computeStartPregnancyDate(expectedEnd);
  // number of days since the first day of the pregnancy
  return dayjs.utc().diff(dayjs.utc(startPregnancyDate), 'day');
};

export const getPregnancyMonthsFromStart = ({
  expectedEnd,
}: {
  expectedEnd: string;
}) => {
  // date of the first day of the pregnancy
  const startPregnancyDate = computeStartPregnancyDate(expectedEnd);

  // Compute the end date of the nth month from the start date
  const today = dayjs.utc(new Date());

  // number of months since the first day of the pregnancy
  return today.diff(dayjs.utc(startPregnancyDate), 'month');
};
