import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  backIconContainer: {
    transform: [{ rotate: '90deg' }],
  },

  closeIcon: {
    position: 'absolute',
    right: 20,
    top: 4,
  },

  container: {
    flex: 1,
  },

  flag: {
    height: 20,
    margin: 10,
    marginRight: 20,
    width: 30,
  },

  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 15,
    paddingRight: 20,
    paddingTop: 30,
  },

  icon: {
    transform: [{ rotate: '-90deg' }],
  },

  iconTouchable: {
    alignItems: 'center',
    aspectRatio: 1,
    height: 30,
    justifyContent: 'center',
  },

  inputContainer: {
    borderBottomColor: Colors.grey600,
    borderBottomWidth: 1,
    marginBottom: 5,
    marginTop: 20,
    width: '100%',
  },

  inputError: {
    borderBottomColor: Colors.pink700,
  },

  inputErrorText: {
    color: Colors.pink700,
    fontSize: Fonts.size.small,
    marginTop: -5,
    paddingLeft: 10,
    width: '90%',
  },

  listItem: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 4,
  },

  mainContainer: {
    width: 70,
  },

  searchInput: {
    marginLeft: 25,
  },

  separator: {
    backgroundColor: Colors.grey600,
    height: 1,
    width: '100%',
  },

  text: {
    color: Colors.black500,
    fontSize: Fonts.size.title,
  },

  title: {
    color: Colors.duck800,
    fontFamily: Fonts.type.medium,
    fontSize: Fonts.size.title,
    paddingRight: 35,
  },

  titleContainer: {
    alignItems: 'center',
    flex: 1,
  },
});
