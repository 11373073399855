import React from 'react';
import { Image, StyleProp, StyleSheet, ViewStyle } from 'react-native';

import Article from '@commonTypes/apiResponses/Article';
import { Trackers } from '@commonTypes/apiResponses/Trackers';
import { useSelector } from '@commonTypes/redux';
import { FavoritesButton } from '@components/FavoritesButton';
import SubscribedPill from '@components/Pills/SubscribedPill';
import { ReadMarker } from '@components/Pills/ViewMarker';
import { t } from '@config';
import { push } from '@navigation/Actions';
import { COMMON_SCREEN_STACK } from '@navigation/Routes';
import ACCESS from '@resources/constants/access';
import { Colors } from '@resources/themes';
import { getBestImageOf } from '@tools/getBestImageOf';
import { getImageAuthorization } from '@tools/getImageAuthorization';

import ContentCardUI from '../ContentCardUI';
import ReadingTime from '../Fields/ReadingTime';

const styles = StyleSheet.create({ image: StyleSheet.absoluteFillObject });

export interface ArticleUIProps {
  style?: StyleProp<ViewStyle>;
  title: string;
  highlightedTitle: string | undefined;
  uri?: string;
  readingTime: string | undefined | null;
  onPress: () => void;
  isRead: boolean;
  isPremium: boolean;
  favoriteButton?: React.ReactElement | null;
  disabled?: boolean;
  testID?: string;
}
export const ArticleUi = ({
  uri,
  readingTime,
  isRead,
  isPremium,
  testID,
  ...props
}: ArticleUIProps) => {
  const contentColor = isRead ? Colors.grey600 : Colors.duck800;
  const token = useSelector((state) => state.auth.token);
  const Cover = (
    <>
      <Image
        style={styles.image}
        resizeMethod="resize"
        resizeMode="cover"
        source={{ uri, headers: getImageAuthorization(uri, token) }}
      />
      {isRead && <ReadMarker />}
      {isPremium && <SubscribedPill small />}
    </>
  );
  const Field = readingTime && (
    <ReadingTime readingTime={readingTime} contentColor={contentColor} />
  );
  return (
    <ContentCardUI
      {...props}
      label={t('COMMON.ARTICLE')}
      contentColor={contentColor}
      imageSection={Cover}
      fields={Field}
      testID={testID}
    />
  );
};

interface ArticleCardProps {
  style: StyleProp<ViewStyle>;
  testID?: string;
  item: Article;
  trackers?: Trackers;
  screen: string;
  disabled?: boolean;
  toggleAllContentPremium?: boolean;
  toggleAllContentFree?: boolean;
}
const ArticleCard = ({
  item,
  screen,
  trackers,
  toggleAllContentPremium,
  toggleAllContentFree,
  testID,
  ...props
}: ArticleCardProps) => {
  const handlePress = () =>
    push(COMMON_SCREEN_STACK.POST_DETAIL_SCREEN, {
      post: item,
      slug: undefined,
    });
  return (
    <ArticleUi
      {...props}
      title={item.title}
      highlightedTitle={item.highlightedTitle}
      uri={getBestImageOf(item.cover, 'small')}
      readingTime={item.readingTime}
      onPress={handlePress}
      isRead={!!trackers?.posts?.[item.id]}
      isPremium={
        !toggleAllContentFree &&
        (item.access === ACCESS.PREMIUM || !!toggleAllContentPremium)
      }
      testID={testID}
      favoriteButton={
        <FavoritesButton id={`${item.id}`} type={'posts'} item={item} />
      }
    />
  );
};

export default ArticleCard;
