import React from 'react';
import { StyleSheet, View } from 'react-native';

import PlaceholderLine from '@components/Placeholder/PlaceholderLine';
import BackButton from '@components/ui/Pressables/Actions/BackButton';

const styles = StyleSheet.create({
  container: { flexDirection: 'row', alignItems: 'center', gap: 8 },
  back: { backgroundColor: undefined, left: -8 },
  titleLine: { borderRadius: 12 },
});

export function NavBarPlaceholder({ noIcon }: { noIcon?: boolean }) {
  return (
    <View style={styles.container}>
      {!noIcon && <BackButton style={styles.back} />}
      <PlaceholderLine style={styles.titleLine} width={'70%'} height={24} />
    </View>
  );
}
