import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  container: {
    paddingTop: 32,
    paddingHorizontal: 24,
    gap: 24,
    height: '100%',
    backgroundColor: Colors.white100,
  },
  description: {
    textAlign: 'center',
    fontFamily: Fonts.type.regular,
    color: Colors.duck800,
    fontSize: Fonts.size.title,
  },
  bold: { fontFamily: Fonts.type.semiBold },
});
