import { axiosPost } from '@api-requests/api/main/request';

export const setPushNotificationsToken = (payload: {
  token: string;
  platform: string;
}) =>
  axiosPost<{
    token: string;
    platform: string;
  }>({
    url: '/apifo/push_notifications',
    params: { data: payload },
  });
