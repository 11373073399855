import { ReducedAxiosResponse } from '@apiTypes/utils';
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { TOKENS_QUERY_KEYS, useTokens } from './useTokens';

const useMutationCall = <
  TData extends ReducedAxiosResponse = ReducedAxiosResponse<any>,
  TError = any,
  TInputData = void,
  TContext = unknown,
>(
  options: UseMutationOptions<TData, TError, TInputData, TContext>,
) => {
  const queryClient = useQueryClient();
  useTokens();

  return useMutation<TData, TError, TInputData, TContext>({
    ...options,
    onError: (err, variables, context) => {
      options?.onError?.(err, variables, context);

      // @ts-ignore
      if ((err?.response?.status ?? err.status) === 401) {
        queryClient.invalidateQueries({
          queryKey: TOKENS_QUERY_KEYS,
        });
      }
    },
  });
};

export default useMutationCall;
