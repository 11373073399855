import { resetAll } from '@redux/reduxReset';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = { isVisible: false, previousScreen: '', targetTab: 0 };

const subscribeModalSlice = createSlice({
  name: 'subscribeModal',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    show: (
      state,
      action: PayloadAction<{ origin: string; targetTab?: number }>,
    ) => {
      state.isVisible = true;
      state.previousScreen = action.payload.origin;
      state.targetTab = action.payload.targetTab ?? initialState.targetTab;
    },
    hide: (state) => {
      state.isVisible = initialState.isVisible;
      state.previousScreen = initialState.previousScreen;
      state.targetTab = initialState.targetTab;
    },
  },
});

export const subscribeModalCreators = subscribeModalSlice.actions;
export default subscribeModalSlice.reducer;
