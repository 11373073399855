import { resetAll } from '@redux/reduxReset';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ItemSuggestionDetail } from '@screens/LoggedInHome/FaqPurchase/types';

const initialState: { selectedChoiceItem: ItemSuggestionDetail | null } = {
  selectedChoiceItem: null,
};

const faqPurchaseSlice = createSlice({
  name: 'faqPurchase',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setSelectedChoice: (state, action: PayloadAction<ItemSuggestionDetail>) => {
      state.selectedChoiceItem = action.payload;
    },
  },
});

export const faqPurchaseCreators = faqPurchaseSlice.actions;
export default faqPurchaseSlice.reducer;
