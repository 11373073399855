import * as React from 'react';
import { Text, View } from 'react-native';

import RuleCompleted from '@resources/svg/pass-rules-tick.svg';

import PassRuleTickProps from '@components/ui/PassRulesBlock/PasswordRuleTick/types';

import styles from '@components/ui/PassRulesBlock/PasswordRuleTick/styles';

const PasswordRuleTick = ({ text, ruleCompleted }: PassRuleTickProps) => {
  return (
    <View style={styles.oneRuleContainer}>
      <View style={styles.iconContainer}>
        {ruleCompleted && <RuleCompleted />}
      </View>
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

export default PasswordRuleTick;
