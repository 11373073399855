import { Platform, StyleSheet } from 'react-native';

import { Fonts } from '@resources/themes';
import Colors from '@resources/themes/Colors';

export default StyleSheet.create({
  container: {
    height: '100%',
    padding: 24,
    gap: 32,
    ...Platform.select({
      web: { backgroundColor: Colors.white100 },
      default: {},
    }),
  },

  computeMethod: { gap: 16 },
  subtitle: {
    color: Colors.duck800,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.input,
  },

  input: { gap: 16 },
  dateLabel: {
    color: Colors.duck800,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.title,
  },

  result: { gap: 24 },
  termDate: {
    color: Colors.duck800,
    fontFamily: Fonts.type.Recoleta.medium,
    fontSize: Fonts.size.h3,
    textAlign: 'center',
  },

  spacer: { flex: 1 },
  secureText: {
    color: Colors.duck700,
    fontFamily: Fonts.type.light,
    fontSize: Fonts.size.medium,
    textAlign: 'center',
  },
});
