import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  flag: {
    height: 20,
    marginRight: 5,
    width: 30,
  },

  flagInput: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  prefixCountry: {
    color: Colors.duck800,
    fontFamily: Fonts.type.medium,
    fontSize: Fonts.size.medium,
  },
});
