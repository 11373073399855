import React from 'react';

import { useSelector } from '@commonTypes/redux';
import { useUserDetail } from '@hooks/useUserDetail';
import { COMMON_SCREEN_STACK, NAVIGATOR_NAMES } from '@navigation/Routes';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import AddKidScreen from '@screens/AddKidScreen';
import AddPregnancyScreen from '@screens/Profile/Pregnancy/AddPregnancyScreen';
import ComputeEndPregnancy from '@screens/Profile/Pregnancy/ComputeEndPregnancy';
import StaticContentsScreen from '@screens/StaticContentsScreen';
import { WEB_COMMON_SCREEN_STACK } from '@webNavigation/routes';
import HomeScreen from '@webScreens/HomeScreen';
import LogoutScreen from '@webScreens/LogoutScreen';
import SubscribeScreen from '@webScreens/SubscribeScreen';
import SubscribeSuccessScreen from '@webScreens/SubscribeSuccessScreen';
import { UnsubscribedHomeScreen } from '@webScreens/UnsubscribedHomeScreen';

import { WebLoggedNavigatorParamList } from './routeParams';
import { WebOnBoardingNavigator } from './WebOnboardingNavigator';

const useInitialRouteName = () => {
  const hasCompletedOnboarding = useSelector(
    (state) =>
      state.auth.hasCompletedOnboarding ||
      state.auth?.scenarios?.includes('onboarding-v1') ||
      state.auth?.scenarios?.includes('onboarding-v2') ||
      false,
  );
  return hasCompletedOnboarding
    ? WEB_COMMON_SCREEN_STACK.HOME_SCREEN
    : NAVIGATOR_NAMES.ONBOARDING_NAVIGATOR;
};

const Stack = createNativeStackNavigator<WebLoggedNavigatorParamList>();
const WebLoggedNavigator = () => {
  useUserDetail(true);
  const initialRouteName = useInitialRouteName();

  return (
    <>
      <Stack.Navigator
        initialRouteName={initialRouteName}
        screenOptions={{ headerShown: false }}
      >
        <Stack.Screen
          component={WebOnBoardingNavigator}
          name={NAVIGATOR_NAMES.ONBOARDING_NAVIGATOR}
        />
        <Stack.Screen
          component={AddKidScreen}
          name={COMMON_SCREEN_STACK.ADD_KID_SCREEN}
        />
        <Stack.Screen
          component={AddPregnancyScreen}
          name={COMMON_SCREEN_STACK.ADD_PREGNANCY_SCREEN}
        />
        <Stack.Screen
          component={ComputeEndPregnancy}
          name={COMMON_SCREEN_STACK.COMPUTE_END_PREGNANCY}
        />
        <Stack.Screen
          component={StaticContentsScreen}
          name={COMMON_SCREEN_STACK.STATIC_CONTENTS_SCREEN}
        />
        <Stack.Screen
          component={SubscribeScreen}
          name={WEB_COMMON_SCREEN_STACK.SUBSCRIBE_SCREEN}
        />
        <Stack.Screen
          component={SubscribeSuccessScreen}
          name={WEB_COMMON_SCREEN_STACK.SUBSCRIBE_SUCCESS_SCREEN}
        />
        <Stack.Screen
          component={UnsubscribedHomeScreen}
          name={WEB_COMMON_SCREEN_STACK.UNSUBSCRIBED_HOME_SCREEN}
        />
        <Stack.Screen
          component={HomeScreen}
          name={WEB_COMMON_SCREEN_STACK.HOME_SCREEN}
        />
        <Stack.Screen
          component={LogoutScreen}
          name={WEB_COMMON_SCREEN_STACK.LOGOUT_SCREEN}
        />
      </Stack.Navigator>
    </>
  );
};

export default WebLoggedNavigator;
