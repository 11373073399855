import zip from 'lodash/zip';

import {
  ContentType,
  FormatAllContentList,
  FormattedContentItem,
} from '@tools/formatData/formatAllContentList/types';

export const addTypeInList = <
  U extends ContentType,
  T extends { id: number; title: string },
>(
  type: U,
  itemList?: T[],
) => {
  if (Array.isArray(itemList) && itemList?.length) {
    return itemList
      .filter((item) => item?.title && item?.id)
      .map((item) => ({ ...item, type }));
  }
  return [] as (T & { type: U })[];
};

export const formatAllCmsContentList = ({
  postList,
  guideList,
  masterclassList,
  dailyTipsList,
  folderList,
  videoList,
  weeklyTipsList,
}: FormatAllContentList) => {
  const newArticleList = addTypeInList(ContentType.article, postList);
  const newGuideList = addTypeInList(ContentType.guide, guideList);
  const newMasterclassList = addTypeInList(
    ContentType.masterclass,
    masterclassList,
  );
  const newDailyList = addTypeInList(ContentType.dailyTip, dailyTipsList);
  const newFolderList = addTypeInList(ContentType.folder, folderList);
  const newVideoList = addTypeInList(ContentType.video, videoList);

  return [
    ...newArticleList,
    ...newGuideList,
    ...newFolderList,
    ...newMasterclassList,
    ...newDailyList,
    ...newVideoList,
    ...(weeklyTipsList ?? []),
  ];
};

export const formatAllSearchContentList = ({
  postList,
  guideList,
  masterclassList,
  dailyTipsList,
  folderList,
  videoList,
}: FormatAllContentList) => {
  const newArticleList = addTypeInList(ContentType.article, postList);
  const newGuideList = addTypeInList(ContentType.guide, guideList);
  const newMasterclassList = addTypeInList(
    ContentType.masterclass,
    masterclassList,
  );
  const newDailyList = addTypeInList(ContentType.dailyTip, dailyTipsList);
  const newFolderList = addTypeInList(ContentType.folder, folderList);
  const newVideoList = addTypeInList(ContentType.video, videoList);

  return zip<FormattedContentItem>(
    newArticleList,
    newGuideList,
    newFolderList,
    newMasterclassList,
    newDailyList,
    newVideoList,
  ).flat() as FormattedContentItem[];
};
