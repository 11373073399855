import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  photo: { alignSelf: 'center' },

  formContainer: { gap: 24 },
  datePicker: { width: 160 },

  spacer: { flex: 1 },
  actions: { gap: 8 },
  secureText: {
    color: Colors.duck700,
    fontFamily: Fonts.type.light,
    fontSize: Fonts.size.medium,
    textAlign: 'center',
  },
});
