import { StyleSheet } from 'react-native';

import DEVICE from '@resources/constants/device';
import { Colors, Fonts } from '@resources/themes';

const IMAGE_SIZE = 0.5 * DEVICE.WINDOW_WIDTH;

export default StyleSheet.create({
  container: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 24,
    backgroundColor: Colors.white100,
    gap: 40,
  },

  imageContainer: {
    backgroundColor: Colors.yellow400,
    width: IMAGE_SIZE,
    aspectRatio: 1,
    borderRadius: IMAGE_SIZE / 2,
    overflow: 'hidden',
  },
  image: { height: '100%', width: '100%' },

  textContainer: { gap: 16, alignItems: 'center' },
  title: {
    fontFamily: Fonts.type.Recoleta.semiBold,
    color: Colors.duck800,
    fontSize: Fonts.size.h4,
  },
  description: {
    textAlign: 'center',
    fontFamily: Fonts.type.medium,
    color: Colors.duck800,
    fontSize: Fonts.size.title,
  },
});
