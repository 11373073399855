import { jwtDecode, JwtPayload } from 'jwt-decode';

import {
  getDetailIdentification,
  setAnalyticsUserProperties,
} from '@analytics/common';
import { GlobalState } from '@commonTypes/redux';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import { IdentificationData } from '@analytics/types';

import { analyticsCreators } from './analytics';
import { AuthCreators } from './auth';
import { userCreators } from './user';

const listenerMiddleware =
  createListenerMiddleware<Pick<GlobalState, 'analytics' | 'auth' | 'user'>>();
listenerMiddleware.startListening({
  actionCreator: userCreators.userSuccess,
  effect: async ({ payload }, listenerApi) => {
    if (payload.detail) {
      const currentState = listenerApi.getState().analytics;
      const identificationData = await getDetailIdentification(payload);

      if (!identificationData) {
        return;
      }

      const needIdentification = (
        Object.keys(identificationData) as (keyof IdentificationData)[]
      ).some(
        (idKey: keyof IdentificationData) =>
          currentState.identificationData[idKey] !== identificationData[idKey],
      );
      if (needIdentification) {
        listenerApi.dispatch(
          analyticsCreators.dispatchedIdentify(identificationData),
        );
        setAnalyticsUserProperties(identificationData);
      }
    }
  },
});

listenerMiddleware.startListening({
  actionCreator: analyticsCreators.requestIdentification,
  effect: async ({ payload }, listenerApi) => {
    const currentState = listenerApi.getState().analytics;
    const needIdentification = (
      Object.keys(payload) as (keyof IdentificationData)[]
    ).some(
      (idKey: keyof IdentificationData) =>
        currentState.identificationData[idKey] !== String(payload[idKey]),
    );

    if (needIdentification) {
      listenerApi.dispatch(analyticsCreators.dispatchedIdentify(payload));
      setAnalyticsUserProperties(payload);
    }
  },
});

interface AppUserJWTPayload extends JwtPayload {
  id: number;
  email: string;
  firstName?: string;
}
listenerMiddleware.startListening({
  actionCreator: AuthCreators.authLogin,
  effect: async ({ payload }, listenerApi) => {
    if (!payload.token) {
      return;
    }
    //

    // Identify from token
    const currentState = listenerApi.getState().analytics;
    const decoded = jwtDecode<AppUserJWTPayload>(payload.token);
    const { email, id, firstName } = decoded;

    if (
      currentState.identificationData.id !== String(id) ||
      currentState.identificationData.email !== email
    ) {
      // use name from jwt only if  not already set because firstName in jwt may be old
      const name = !currentState.identificationData.name
        ? { name: firstName }
        : {};
      listenerApi.dispatch(
        analyticsCreators.dispatchedIdentify({
          email,
          id: String(id),
          ...name,
        }),
      );
      setAnalyticsUserProperties({ email, id: String(id), name: firstName });
    }
  },
});

export default listenerMiddleware;
