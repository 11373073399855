import React, { ComponentProps, useCallback } from 'react';
import { Controller, Path } from 'react-hook-form';
import { Text, View, Image } from 'react-native';

import CountryPicker from '@components/ui/CountryPicker';
import { t } from '@config';
import Flags from '@resources/countries/flags';
import Prefix from '@resources/countries/phonePrefixes';

import styles from './styles';
import { ExposedControllerProps, RenderInput } from '../types';

type CountryPickerInputProps<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
> = ExposedControllerProps<TFieldValues, TName> &
  Omit<
    ComponentProps<typeof CountryPicker>,
    'onCountryChanged' | 'country' | 'error'
  >;
function CountryPickerInput<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
>({
  name,
  control,
  rules,
  ...others
}: CountryPickerInputProps<TFieldValues, TName>) {
  const render = useCallback(
    ({ field, fieldState }: RenderInput<TFieldValues, TName>) => {
      const error = fieldState.error?.type;

      return (
        <CountryPicker
          country={field.value}
          error={error ? t('FORM.HOOK_FORMS_ERROR.' + error) : undefined}
          onCountryChanged={(res: { id: string }) => {
            field.onChange(res.id);
          }}
          placeholder={t('FORM.PHONE_NUMBER')}
          {...others}
        >
          <View style={styles.flagInput}>
            <Image
              source={{ uri: Flags.getFlag(field.value) }}
              style={styles.flag}
            />
            <Text style={styles.prefixCountry}>
              +{Prefix.getPrefix(field.value)}
            </Text>
          </View>
        </CountryPicker>
      );
    },
    [others],
  );

  return (
    <Controller name={name} control={control} rules={rules} render={render} />
  );
}
export default CountryPickerInput;
