import React, { useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import DEVICE from '@resources/constants/device';
import { Colors } from '@resources/themes';

interface ManipulableDateButtonProps extends DateButtonProps {
  isDatePickerVisible: boolean;
  onValidateDate: () => void;
  onDatePress: () => void;
  onBackdropPress?: () => void;
}
const ManipulableDateButton = ({
  onChange,
  maximumDate,
  minimumDate,
}: ManipulableDateButtonProps) => {
  const dateObj = new Date(maximumDate);

  const year = dateObj.getFullYear().toString().padStart(4, '0');
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObj.getDate().toString().padStart(2, '0');

  const maxDateFormatted = `${year}-${month}-${day}`;

  const min_dateObj = new Date(minimumDate);

  const min_year = min_dateObj.getFullYear().toString().padStart(4, '0');
  const min_month = (min_dateObj.getMonth() + 1).toString().padStart(2, '0');
  const min_day = min_dateObj.getDate().toString().padStart(2, '0');

  const minDateFormatted = `${min_year}-${min_month}-${min_day}`;

  return (
    <View>
      <input
        type="date"
        min={minDateFormatted}
        max={maxDateFormatted}
        defaultValue={maxDateFormatted}
        // eslint-disable-next-line react-native/no-inline-styles
        style={{
          minWidth: 80,
          borderWidth: 0,
          backgroundColor: Colors.grey400,
          color: Colors.duck800,
          borderRadius: '8px',
          padding: '4px 8px',
          outlineWidth: 0,
          display: 'block',
          position: 'relative',
          marginLeft: 8,
        }}
        onChange={(event) => {
          onChange(event.target.value);
        }}
      />
    </View>
  );
};

interface DateButtonProps {
  style?: StyleProp<ViewStyle>;
  label?: string;
  value: Date;
  onChange: (selectedDate: Date | string | undefined) => void;
  maximumDate: Date;
  minimumDate: Date;
}
const DateButton = ({ onChange, ...props }: DateButtonProps) => {
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  return (
    <ManipulableDateButton
      {...props}
      onChange={(d) => {
        onChange(d);
        // onChange is only fired upon validation on Android
        if (DEVICE.IS_ANDROID) {
          setDatePickerVisible(false);
        }
      }}
      isDatePickerVisible={datePickerVisible}
      onDatePress={() => setDatePickerVisible(true)}
      onValidateDate={() => setDatePickerVisible(false)}
      onBackdropPress={() => setDatePickerVisible(false)}
    />
  );
};

export default DateButton;
