export interface PregnancyCreate {
  expectedEnd: string; // YYYY-MM-DD
  multiple?: boolean;
}
export interface PregnancyUpdate extends PregnancyCreate {
  interrupted?: boolean;
  born?: boolean;
}

export enum METHOD {
  DUE_DATE = 'dueDate',
  LAST_PERIOD = 'lastPeriod',
  PREGNANCY_START = 'pregnancyStart',
}
