import React, { useEffect } from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';

import { useDispatch } from '@commonTypes/redux';
import { resetAll } from '@redux/reduxReset';
import { Colors } from '@resources/themes';
import { useQueryClient } from '@tanstack/react-query';
import { useLogout } from '@web/src/api/main/auth/hooks';

const styles = StyleSheet.create({
  container: { height: '100%', backgroundColor: Colors.white100 },
});

/**
 * Resets the store and clears the cache
 *
 * This implicitly mounts AnonymousNavigator which automatically redirects to its initial route
 */
const useLogoutAndReset = () => {
  const dispatch = useDispatch();
  const queryCache = useQueryClient();
  const logoutQuery = useLogout();

  useEffect(() => {
    if (logoutQuery.isSuccess) {
      dispatch(resetAll());
      queryCache.clear();
    }
  }, [dispatch, logoutQuery.isSuccess, queryCache]);
};

const LogoutScreen = () => {
  useLogoutAndReset();
  return (
    <ActivityIndicator
      style={styles.container}
      size="large"
      color={Colors.duck800}
    />
  );
};

export default LogoutScreen;
