import React from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';

import AppIcon from '@components/ui/Icon';
import { Colors, Fonts } from '@resources/themes';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 16,
    height: 32,
    alignItems: 'center',
  },
  unavailable: { opacity: 0.3 },
  label: {
    color: Colors.duck700,
    fontFamily: Fonts.type.bold,
    fontSize: Fonts.size.title,
  },
});

interface AvailablePerkProps {
  style?: StyleProp<ViewStyle>;
  isAvailable?: boolean;
  label: string;
}
const AvailablePerk = ({
  style,
  isAvailable = true,
  label,
}: AvailablePerkProps) => (
  <View style={[styles.container, !isAvailable && styles.unavailable, style]}>
    <AppIcon
      name={isAvailable ? 'CheckmarkSlim' : 'CrossIcon'}
      color={Colors.duck700}
      size={24}
      strokeWidth={2}
    />
    <Text style={styles.label}>{label}</Text>
  </View>
);

export default AvailablePerk;
