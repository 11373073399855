import { Kid } from '@commonTypes/Kid';

import { formatSelection } from './formatSelection';
import { FamilySelection, FamilySelectionRaw, SelectionRaw } from '../types';

export const formatRes = (
  data: FamilySelectionRaw,
  childrenMap: Record<string, Kid>,
): FamilySelection => ({
  pregnancy: formatSelection('pregnancy' in data ? data.pregnancy : undefined),
  children:
    'children' in data
      ? Object.entries<SelectionRaw>(data.children)
          .filter(([childId]) => childId in childrenMap)
          .map(
            ([childId, selectionRaw]) =>
              [childrenMap[childId], formatSelection(selectionRaw)] as const,
          )
          .sort(
            ([{ birthDay: a }], [{ birthDay: b }]) =>
              new Date(b).getTime() - new Date(a).getTime(),
          )
      : [],
  fallback:
    !('pregnancy' in data) && !('children' in data)
      ? formatSelection(data)
      : [],
});
