import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';

import { useDispatch } from '@commonTypes/redux';
import { TextInput } from '@components/ui/hookFormInputs/TextInput';
import { t } from '@config';
import { useFocusField } from '@hooks/useFocusField';
import { push } from '@navigation/Actions';
import { ANONYMOUS_STACK } from '@navigation/Routes';
import { signupFormCreator } from '@screens/Auth/SignUp/redux/signupInfos';
import RegisterNavBar from '@screens/Auth/SignUp/RegisterNavBar';

import styles from '@screens/Auth/SignUp/FirstnameForm/styles';

type FirstNameForm = {
  firstName: string;
};

const FirstnameForm = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit, formState } = useForm<FirstNameForm>({
    defaultValues: { firstName: '' },
  });

  const { isSubmitting, isValid } = formState;
  const inputRef = useRef(null);
  useFocusField(inputRef);

  const handleValidate = handleSubmit((data: FirstNameForm) => {
    const formattedFirstName = data.firstName.trim();
    dispatch(signupFormCreator.addFormName(formattedFirstName));
    push(ANONYMOUS_STACK.REGISTER, { step: 'email', noDismiss: true });
  });

  return (
    <View style={styles.container}>
      <RegisterNavBar
        onPress={handleValidate}
        title={t('AUTH.SIGN_UP.FIRSTNAME_TITLE')}
        isLoading={isSubmitting}
        enabled={isValid}
      />
      <View style={styles.inputContainer}>
        <TextInput
          ref={inputRef}
          autoCapitalize="words"
          control={control}
          name="firstName"
          onSubmitEditing={isSubmitting ? () => null : handleValidate}
          placeholder={t('AUTH.SIGN_UP.FIRSTNAME_PLACEHOLDER')}
          returnKeyType="next"
          testID="firstNameInput"
          label={t('AUTH.SIGN_UP.FORM_FIRSTNAME')}
          rules={{ required: true }}
        />
      </View>
    </View>
  );
};

export default FirstnameForm;
