declare let __DEV__: boolean;

const logInConsole = (
  level: 'log' | 'info' | 'debug' | 'error' | 'warn',
  ...msg: any[]
) => {
  if (__DEV__) {
    if (console[level] && level !== 'error') {
      console[level](...msg);
    } else {
      console.log(...msg);
    }
  }
};

const log = (
  level: 'log' | 'info' | 'debug' | 'error' | 'warn',
  ...msg: any[]
) => {
  logInConsole(level, ...msg);
};

const Logger = {
  log: (...msg: any[]) => log('log', ...msg),
  info: (...msg: any[]) => log('info', ...msg),
  warn: (...msg: any[]) => log('warn', ...msg),
  error: (...msg: any[]) => log('error', ...msg),
  debug: (...msg: any[]) => log('debug', ...msg),
};
export default Logger;
