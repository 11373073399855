import memoize from 'lodash/memoize';
import { useEffect } from 'react';

import user, { UserDetailRaw } from '@api-requests/api/user';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import { AuthProviders } from '@commonTypes/Auth';
import { useDispatch } from '@commonTypes/redux';
import { AuthCreators } from '@redux/auth';
import { userCreators } from '@redux/user';
import { setSentryUser } from '@tools/sentry';

import useAuthenticatedQuery from './useAuthenticatedQuery';

export const USER_QUERY_KEYS = {
  detail: ['appUser'],
};

const select = memoize(
  ({
    data,
  }: ReducedAxiosResponse<
    UserDetailRaw & { authProviders: AuthProviders[] }
  >) => ({
    ...data,
    kids: data?.kids?.sort(
      (item1, item2) =>
        new Date(item2.birthDay).getTime() - new Date(item1.birthDay).getTime(),
    ),
    createdAt: new Date(data?.createdAt),
  }),
);

export const useUserDetail = (enabled = false) => {
  const dispatch = useDispatch();
  const res = useAuthenticatedQuery<
    ReducedAxiosResponse<
      UserDetailRaw & {
        authProviders: AuthProviders[];
      }
    >,
    any,
    Omit<UserDetailRaw, 'createdAt'> & { createdAt: Date } & {
      authProviders: AuthProviders[];
    }
  >({
    queryFn: user.getDetail,
    queryKey: USER_QUERY_KEYS.detail,
    staleTime: 0,
    gcTime: 0,
    select,
    enabled,
  });

  useEffect(() => {
    if (enabled) {
      dispatch(userCreators.setLoading(res.isLoading));
    }
  }, [dispatch, enabled, res.isLoading]);

  useEffect(() => {
    if (res.data && enabled) {
      const { authProviders, createdAt, ...rest } = res.data;
      dispatch(
        userCreators.userSuccess({
          ...rest,
          createdAt: createdAt?.toString(),
          detail: true,
        }),
      );
      dispatch(AuthCreators.setAuthProviders(authProviders));
      if (res.data.id) {
        setSentryUser(res.data.id);
      }
    }
  }, [res.data, dispatch, enabled]);

  return res;
};
