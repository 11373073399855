import { resetAll } from '@redux/reduxReset';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SkinColor } from './constants';

const initialState = { skinColor: SkinColor.LIGHT_SKIN };

const skinColorSlice = createSlice({
  name: 'skinColor',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setColor: (state, action: PayloadAction<SkinColor>) => {
      state.skinColor = action.payload;
    },
  },
});

export const skinColorCreators = skinColorSlice.actions;
export default skinColorSlice.reducer;
