import {
  IImageTemplate,
  IImageTemplateRaw,
  IStepTemplate,
  IStepTemplateRaw,
  Scenario,
  SCENARIO_TARGET,
  ScenarioRaw,
  StepChoice,
  TEMPLATE_TYPE,
} from '../types';

export type FormatScenarioFilter = {
  [SCENARIO_TARGET.ALL]?: boolean;
  [SCENARIO_TARGET.PREGNANCY]?: boolean;
  [SCENARIO_TARGET.CHILDREN]?: boolean;
};
export const formatScenario = (
  raw: { data: ScenarioRaw },
  filters?: FormatScenarioFilter,
): Scenario =>
  raw.data.screens.flatMap((screen) => {
    if (filters && !filters[screen.type]) {
      return [] as never;
    }
    // @ts-ignore
    return formatMap[screen.__component](screen);
  });

export const formatImageTemplate = ({
  id,
  screenName,
  ...rest
}: IImageTemplateRaw): IImageTemplate => rest;

export const formatStepTemplate = ({
  id,
  step,
  ...rest
}: IStepTemplateRaw): IStepTemplate => ({
  ...rest,
  steps: step.map((subStep) => ({
    type: 'choice',
    title: subStep.title,
    description: subStep.description,
    choices: formatChoices(subStep.choices),
  })),
});

export const formatChoices = (choices: string): Array<StepChoice> =>
  choices.split('\n').flatMap((value) => {
    if (!value) {
      return [] as never;
    }
    return { value };
  });

const formatMap = {
  [TEMPLATE_TYPE.IMAGE]: formatImageTemplate,
  [TEMPLATE_TYPE.STEP]: formatStepTemplate,
} as const;
