import React, { useContext, useState } from 'react';
import {
  ActivityIndicator,
  PressableAndroidRippleConfig,
  StyleProp,
  ViewStyle,
} from 'react-native';

import { logEvent } from '@analytics/common';
import { ADD_FAVORITE_CONTENT } from '@analytics/eventNames';
import {
  useAddFavoritesMutation,
  useRemoveFavoritesMutation,
} from '@api-requests/api/cms/queries/useFavoritesMutation';
import { useGetAllFavorites } from '@api-requests/api/cms/queries/useGetAllFavorites';
import { AgeLevel } from '@commonTypes/age-levels';
import { Favorites } from '@commonTypes/apiResponses/favorites';
import { Categories } from '@commonTypes/Categories';
import { PressableOpacity } from '@components/PressableOpacity';
import AppIcon from '@components/ui/Icon';
import { usePopup } from '@components/ui/Popup/hook';
import { PressableNative } from '@components/ui/Pressables/PressableNative';
import { t } from '@config';
import { useRoute } from '@react-navigation/native';
import ACCESS from '@resources/constants/access';
import { hitSlop } from '@resources/constants/hitSlop';
import { Colors } from '@resources/themes';

const FavoritesContext = React.createContext<{
  favorites?: Favorites;
  addToFavorites: ReturnType<typeof useAddFavoritesMutation>['mutate'];
  removeFromFavorites: ReturnType<typeof useRemoveFavoritesMutation>['mutate'];
}>({ addToFavorites: () => {}, removeFromFavorites: () => {} });

export const FavoriteProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data: favorites } = useGetAllFavorites();
  const { mutate: addToFavorites } = useAddFavoritesMutation();
  const { mutate: removeFromFavorites } = useRemoveFavoritesMutation();

  return (
    <FavoritesContext.Provider
      value={{ favorites, addToFavorites, removeFromFavorites }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};

export const FavoritesButton = ({
  style,
  type,
  id,
  size = 22,
  item,
  android_ripple,
}: {
  style?: StyleProp<ViewStyle>;
  type: keyof Favorites;
  id: string;
  size?: number;
  item?: {
    slug?: string;
    age_levels?: AgeLevel[];
    categories?: Categories[];
    access?: ACCESS;
  };
  android_ripple?: null | PressableAndroidRippleConfig | undefined;
}) => {
  const { favorites, addToFavorites, removeFromFavorites } =
    useContext(FavoritesContext);
  const route = useRoute();

  const PressableFeedback = android_ripple ? PressableNative : PressableOpacity;

  const setPopupText = usePopup();

  const isFavorite = Boolean(favorites?.[type]?.[id]);
  const [isLoading, setLoading] = useState(false);

  const handlePress = () => {
    if (isFavorite) {
      setLoading(true);
      removeFromFavorites(
        { id, type },
        {
          onSuccess: () => setPopupText(t('FAVORITE.CONTENT_REMOVED')),
          onError: () => setPopupText(t('FAVORITE.REQUEST_ERROR')),
          onSettled: () => setLoading(false),
        },
      );
    } else {
      logEvent(ADD_FAVORITE_CONTENT, {
        content_type: type,
        item_id: id,
        itemSlug: item?.slug,
        itemAgeLevel: item?.age_levels?.[0]?.name,
        itemCategory: item?.categories?.[0]?.slug,
        screen: route.name,
        access: item?.access || 'all',
      });
      setLoading(true);
      addToFavorites(
        { id, type },
        {
          onSuccess: () => setPopupText(t('FAVORITE.CONTENT_ADDED')),
          onError: () => setPopupText(t('FAVORITE.REQUEST_ERROR')),
          onSettled: () => setLoading(false),
        },
      );
    }
  };

  return (
    <PressableFeedback
      style={style}
      onPress={handlePress}
      hitSlop={hitSlop.small}
      android_ripple={android_ripple}
      disabled={isLoading}
    >
      {!isLoading ? (
        <AppIcon
          name={isFavorite ? 'FavoriteBoldFill' : 'FavoriteBold'}
          size={size}
          color={Colors.pink500}
        />
      ) : (
        <ActivityIndicator color={Colors.pink500} size="small" />
      )}
    </PressableFeedback>
  );
};
