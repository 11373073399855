import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export const BACK_BUTTON_SIZE = 40;
const TEXT_SIZE = 38;
export const INITIAL_TOP_POSITION = -(BACK_BUTTON_SIZE - TEXT_SIZE) / 2;

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  backButton: { position: 'absolute', left: -8, top: INITIAL_TOP_POSITION },
  override: { backgroundColor: undefined },

  title: {
    flex: 1,
    color: Colors.duck800,
    fontFamily: Fonts.type.Recoleta.medium,
    fontSize: Fonts.size.h3,
    lineHeight: TEXT_SIZE,
  },
});
