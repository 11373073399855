import * as SecureStore from 'expo-secure-store';
import { jwtDecode } from 'jwt-decode';

import { OpenidProviders } from '@commonTypes/Auth';
import * as Sentry from '@tools/sentry';

interface expiringToken {
  exp: number;
  iat: number;
}

export const getTokenExpiry = (token: string) => {
  const { exp } = jwtDecode<expiringToken>(token);
  return +new Date(exp * 1000);
};

export const isTokenExpired = (token: string) => {
  const expiryDate = getTokenExpiry(token);
  // Wanna refresh token before end of life ? Set a tolerance here
  // expiryDate.setHours(expiryDate.getHours())
  return expiryDate - +new Date() < 0;
};

export const getTokenDurationSeconds = (token: string) => {
  const { exp, iat } = jwtDecode<expiringToken>(token);
  // Wanna refresh token before end of life ? Set a tolerance here
  // expiryDate.setHours(expiryDate.getHours())
  return exp - iat;
};

export const storeCredentials = async (values: {
  email?: string;
  password?: string;
  refreshToken?: string;
  refreshFetchTime?: number;
  provider?: OpenidProviders | 'apple' | 'may';
}) => {
  try {
    values.email && (await SecureStore.setItemAsync('email', values.email));
    values.password &&
      (await SecureStore.setItemAsync('password', values.password));
    values.refreshToken &&
      (await SecureStore.setItemAsync('refreshToken', values.refreshToken));
    values.refreshFetchTime &&
      (await SecureStore.setItemAsync(
        'refreshFetchTime',
        values.refreshFetchTime.toString(),
      ));
    values.provider &&
      (await SecureStore.setItemAsync('previousProvider', values.provider));
  } catch (err: any) {
    Sentry.captureException(err);
  }
};

export const clearCredentials = async () => {
  try {
    SecureStore.deleteItemAsync('email');
  } catch (err: any) {}
  try {
    SecureStore.deleteItemAsync('password');
  } catch (err: any) {}
  try {
    SecureStore.deleteItemAsync('refreshToken');
  } catch (err: any) {}
  try {
    SecureStore.deleteItemAsync('refreshFetchTime');
  } catch (err: any) {}
};

export const getCredentials = async (): Promise<{
  email?: string | null;
  password?: string | null;
  refreshToken?: string | null;
  refreshFetchTime?: number | null;
}> => {
  try {
    let email: string | null = null;
    let password: string | null = null;
    let refreshToken: string | null = null;
    let refreshFetchTime: string | null = null;

    try {
      email = await SecureStore.getItemAsync('email');
    } catch (err: any) {
      try {
        await SecureStore.deleteItemAsync('email');
      } catch (_) {}
    }
    try {
      password = await SecureStore.getItemAsync('password');
    } catch (err: any) {
      try {
        await SecureStore.deleteItemAsync('password');
      } catch (_) {}
    }
    try {
      refreshToken = await SecureStore.getItemAsync('refreshToken');
    } catch (err: any) {
      try {
        await SecureStore.deleteItemAsync('refreshToken');
      } catch (_) {}
    }
    try {
      refreshFetchTime = await SecureStore.getItemAsync('refreshFetchTime');
    } catch (err: any) {
      try {
        await SecureStore.deleteItemAsync('refreshFetchTime');
      } catch (_) {}
    }

    return {
      email,
      password,
      refreshToken,
      refreshFetchTime: refreshFetchTime ? Number(refreshFetchTime) : undefined,
    };
  } catch (err: any) {
    Sentry.captureException(err);
    return {};
  }
};

export class ApiError extends Error {
  constructor(message = 'Api call error', public ignoreErrorMonitoring = true) {
    super(message);
    this.name = 'ApiError';
  }
}

export class NoTokenError extends Error {
  constructor(
    message = "Token can't be found",
    public ignoreErrorMonitoring = true,
  ) {
    super(message);
    this.name = 'NoTokenError';
  }
}

export class RefreshTokenExpiredError extends Error {
  constructor(
    message = 'Refresh token is expired',
    public ignoreErrorMonitoring = true,
  ) {
    super(message);
    this.name = 'RefreshTokenExpiredError';
  }
}

export class RefreshTokenFailedError extends Error {
  constructor(
    message = 'Refresh token failed',
    public ignoreErrorMonitoring = true,
  ) {
    super(message);
    this.name = 'RefreshTokenFailedError';
  }
}
