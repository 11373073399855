import React from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';

import { useDispatch } from '@commonTypes/redux';
import { TextInput } from '@components/ui/hookFormInputs/TextInput';
import PassRulesBlock from '@components/ui/PassRulesBlock';
import { t } from '@config';
import useCheckPasswordRules, {
  passwordForm,
} from '@hooks/useCheckPasswordRules';
import { push } from '@navigation/Actions';
import { ANONYMOUS_STACK } from '@navigation/Routes';
import { signupFormCreator } from '@screens/Auth/SignUp/redux/signupInfos';
import RegisterNavBar from '@screens/Auth/SignUp/RegisterNavBar';

import styles from '@screens/Auth/SignUp/PasswordForm/styles';

const PasswordForm = () => {
  const dispatch = useDispatch();
  let passRulesCompleted = false;

  const { control, handleSubmit, formState, setError } = useForm<passwordForm>({
    defaultValues: { pass: '' },
  });

  const passwordRulesState = useCheckPasswordRules(control);

  const handleValidate = handleSubmit((data: passwordForm) => {
    passRulesCompleted = !Object.values(passwordRulesState).includes(false);

    if (!data.pass || !passRulesCompleted) {
      setError('pass', {
        message: t('FORM.ERRORS.REGISTER.PASSWORD'),
      });
    } else {
      dispatch(signupFormCreator.addFormPassword(data.pass));

      push(ANONYMOUS_STACK.REGISTER, { step: 'phone', noDismiss: true });
    }
  });
  const { isSubmitting, isValid } = formState;

  return (
    <View style={styles.container}>
      <RegisterNavBar
        onPress={handleValidate}
        title={t('AUTH.SIGN_UP.PASS_TITLE')}
        isLoading={isSubmitting}
        enabled={
          isValid && Object.values(passwordRulesState).every((value) => value)
        }
      />
      <View style={styles.inputContainer}>
        <TextInput
          blurOnSubmit={false}
          control={control}
          name="pass"
          onSubmitEditing={isSubmitting ? () => null : handleValidate}
          placeholder={t('AUTH.SIGN_UP.PASS_PLACEHOLDER')}
          returnKeyType="send"
          secureTextEntry
          textContentType="oneTimeCode"
          testID="passwordInput"
          label={t('AUTH.SIGN_UP.FORM_PASS')}
          rules={{ required: true }}
          errorMessage={(fieldError) =>
            fieldError
              ? fieldError.type === 'server' && fieldError.message
                ? fieldError.message
                : t('FORM.ERRORS.REGISTER.PASSWORD')
              : ''
          }
          autoFocus
        />
        <View style={styles.passRulesContainer}>
          <PassRulesBlock passwordRulesState={passwordRulesState} />
        </View>
      </View>
    </View>
  );
};

export default PasswordForm;
