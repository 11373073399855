import React from 'react';

import { logChildCreation } from '@analytics/events';
import { Kid } from '@commonTypes/Kid';
import { useDispatch } from '@commonTypes/redux';
import KeyboardAvoidingSafeScrollView from '@components/KeyboardAvoidingSafeScrollView';
import KidFormController from '@components/KidFormController';
import { NavBar } from '@components/ui/NavBar';
import { t } from '@config/bootstrap/i18n';
import { goBack, navigate, pop } from '@navigation/Actions';
import {
  COMMON_SCREEN_STACK,
  CONSULTATION_STACK,
  JOURNALING_STACK,
  NAVIGATOR_NAMES,
} from '@navigation/Routes';
import { appointmentCreators } from '@screens/Consultations/redux/consultationAppointment';

import { LoggedNavigatorParamList, ScreenRoute } from '@navigation/types';

import styles from './styles';

const AddKidScreen = ({
  route: {
    params: { originScreen, targetScreen },
  },
}: ScreenRoute<
  LoggedNavigatorParamList,
  COMMON_SCREEN_STACK.ADD_KID_SCREEN
>) => {
  const dispatch = useDispatch();

  const handleCreatedChild = (child: Kid) => {
    logChildCreation({ screen: originScreen });
    switch (targetScreen) {
      case JOURNALING_STACK.KID_GROWTH_SCREEN:
        pop();
        navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
          screen: targetScreen,
          params: { childId: undefined },
        });
        break;
      case JOURNALING_STACK.SLEEP_SCREEN:
        pop();
        navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
          screen: targetScreen,
          params: { childId: undefined },
        });
        break;
      case JOURNALING_STACK.CHILD_FEED_SCREEN:
        pop();
        navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
          screen: targetScreen,
          params: { childId: undefined },
        });
        break;
      case CONSULTATION_STACK.ADD_CONTEXT_SCREEN:
        dispatch(appointmentCreators.setChildId(child.id));
        navigate(NAVIGATOR_NAMES.CONSULTATION_NAVIGATOR, {
          screen: targetScreen,
        });
        break;
      case JOURNALING_STACK.DIAPER_SCREEN:
        pop();
        navigate(NAVIGATOR_NAMES.JOURNALING_NAVIGATOR, {
          screen: targetScreen,
          params: { childId: undefined },
        });
        break;
      default:
        goBack();
        break;
    }
  };

  return (
    <KeyboardAvoidingSafeScrollView style={styles.container}>
      <NavBar title={t('SETTINGS.KIDS.EDIT')} />
      <KidFormController onSuccess={handleCreatedChild} />
    </KeyboardAvoidingSafeScrollView>
  );
};

export default AddKidScreen;
