import React, { useCallback } from 'react';
import { InteractionManager } from 'react-native';

import { useFocusEffect } from '@react-navigation/native';

export const useFocusField = (
  fieldRef: React.MutableRefObject<any>,
  fieldName?: string,
) => {
  /**
   * Autofocus props on <Input> doesn't seem to work properly
   * in combination with react-navigation.
   *
   * What we tried: using useIsFocused hook from RN to toggle input display with autofocus props
   * On ios, we can have a weird rendering effect where the navigation happens twice.
   * This seems an unresolved issue for now.
   * See: https://github.com/react-navigation/react-navigation/issues/8564
   *
   * Here is a hacky way to make it work, I hope we will be able to get rid of this code soon enough.
   * Meanwhile, brace yourself and dont forget to drink water. Peace out.
   *
   **/

  useFocusEffect(
    useCallback(() => {
      const task = InteractionManager.runAfterInteractions(() => {
        setTimeout(() => {
          // When we used our own <Input> component with a "ref",
          // we set the actual Input (from React-Native) reference within our ref
          // in a props called with the value of "inputKey"/
          // Ex: <Input ref={myRef} inputKey='myField' />
          // --> react-native input ref is within myRef.current.myField !!
          // When using "normal" input or library, myRef.current will do the job.
          // That's why we have a fieldName props.
          if (!fieldName) {
            fieldRef?.current?.focus && fieldRef?.current?.focus();
          } else {
            fieldRef?.current?.[fieldName]?.focus &&
              fieldRef?.current?.[fieldName]?.focus();
          }
          // Timeout value of 700ms is purely empirical, it's the minimum value to avoid double navigation
          // Also, I added InteractionManager to make sure we're waiting for the end
          // of animation before trying to use the keyboard but this does not seem to change a thing.
        }, 700);
      });
      return () => task.cancel();
    }, [fieldName, fieldRef]),
  );
};
