import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  activeRadioBtnBackground: {
    backgroundColor: Colors.white100,
    borderRadius: 25,
    height: 6,
    width: 6,
  },

  activeRadioBtnBorder: {
    backgroundColor: Colors.duck800,
  },

  mainContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginHorizontal: 5,
  },

  optionTitle: {
    color: Colors.duck800,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.title,
  },

  selected: {
    fontFamily: Fonts.type.semiBold,
  },

  radioBtn: {
    alignItems: 'center',
    backgroundColor: Colors.white100,
    borderColor: Colors.duck800,
    borderRadius: 25,
    borderWidth: 1,
    height: 18,
    justifyContent: 'center',
    marginRight: 10,
    width: 18,
  },
});
