import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  container: { height: '100%', backgroundColor: Colors.white100 },
  scrollView: { marginHorizontal: 24, paddingBottom: 24 },
  header: {
    marginTop: 48,
    marginBottom: 40,
    alignSelf: 'center',
    alignItems: 'center',
  },

  title: {
    marginTop: 24,
    color: Colors.duck800,
    fontSize: Fonts.size.h6,
    fontFamily: Fonts.type.semiBold,
  },

  text: {
    textAlign: 'center',
    color: Colors.duck800,
    fontSize: Fonts.size.title,
    fontFamily: Fonts.type.regular,
  },

  imageContainer: { width: '100%', aspectRatio: 3 / 4 },
  journalingContainer: { width: '100%', aspectRatio: 5 / 6 },
  image: { width: '100%', height: '100%', borderRadius: 10 },

  section: { marginTop: 8 },

  contentCard: { marginTop: 16 },

  bgGradient: { position: 'absolute' },

  buttonWrapper: { marginVertical: 16 },
});
