import trackers from '@api-requests/api/tracker';
import useAuthenticatedQuery from '@hooks/useAuthenticatedQuery';

export const useGetAllTrackers = (
  options: { enabled?: boolean } = {
    enabled: false,
  },
) =>
  useAuthenticatedQuery({
    queryFn: trackers.getAll,
    queryKey: ['getAllTrackers'],
    ...options,
    select: (data) => data?.data,
  });
