import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  container: {
    paddingVertical: 12,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },

  separator: { borderBottomWidth: 1, borderBottomColor: Colors.grey500 },
  disabled: { opacity: 0.5 },

  badge: {
    height: 40,
    aspectRatio: 1,
    borderRadius: 20,
    backgroundColor: Colors.duck300,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },

  label: {
    flex: 1,
    fontFamily: Fonts.type.semiBold,
    fontSize: Fonts.size.title,
    color: Colors.duck800,
  },

  arrow: { transform: [{ rotate: '180deg' }] },
});
