import dayjs from 'dayjs';

import { Pregnancy } from '@commonTypes/Pregnancy';
import { getActualPregnancyMonth } from '@tools/dateManipulation';

import {
  getFamilyAgesLevelProp,
  queryParams,
} from '@tools/getFamilyAgesParams/types';

export const getFamilyAgesParams = ({
  kidList,
  pregnancy,
  shouldUseBirthday = false,
}: getFamilyAgesLevelProp) => {
  let queryObj: queryParams = [];

  if (kidList?.length >= 0) {
    queryObj = [...kidList].map(({ birthDay, id }) =>
      shouldUseBirthday
        ? {
            p: '',
            d: dayjs(birthDay).format('YYYY-MM-DD'),
            id: id.toString(),
          }
        : {
            p: '',
            a: dayjs().diff(dayjs(birthDay), 'months'),
            id: id.toString(),
          },
    );
  }
  if (pregnancy?.id) {
    const p = pregnancy as Pregnancy;
    shouldUseBirthday
      ? queryObj.push({
          p: '1',
          d: dayjs(p.expectedEnd).format('YYYY-MM-DD'),
          id: p.id.toString(),
        })
      : queryObj.push({
          p: '1',
          a: getActualPregnancyMonth(p),
          id: p.id.toString(),
        });
  }

  // create the payload by looping of every object, encoding it and
  // make a join with '&' if needed
  // Order matters - changing it will most likely cause side effects
  return queryObj
    .map((item) => `familyAges[]=${encodeURIComponent(JSON.stringify(item))}`)
    .join('&');
};
