import React from 'react';
import { InteractionManager } from 'react-native';

import { navigate } from '@navigation/Actions';
import { ANONYMOUS_STACK } from '@navigation/Routes';

import { SignInButton } from './SignInButton';

export const SignUpWithMay = ({ onPress }: { onPress: () => void }) => {
  return (
    <SignInButton
      onPress={() => {
        onPress();
        InteractionManager.runAfterInteractions(() => {
          navigate(ANONYMOUS_STACK.REGISTER, { step: 'firstName' });
        });
      }}
      isSubmitting={false}
      provider={'may'}
    />
  );
};
