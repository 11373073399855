import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

const styles = StyleSheet.create({
  container: { gap: 8 },
  title: {
    fontFamily: Fonts.type.semiBold,
    color: Colors.duck800,
    fontSize: Fonts.size.h6,
  },
  description: {
    fontFamily: Fonts.type.regular,
    color: Colors.duck800,
    fontSize: Fonts.size.title,
  },
});

interface StepHeaderProps {
  title: string;
  description: string | undefined;
}
export function StepHeader({ title, description }: StepHeaderProps) {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      {!!description && <Text style={styles.description}>{description}</Text>}
    </View>
  );
}
