import { Platform, StyleSheet } from 'react-native';

import DEVICE from '@resources/constants/device';
import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  container: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 24,
    ...Platform.select({
      web: { backgroundColor: Colors.white100 },
      default: {},
    }),
  },

  imageContainer: {
    marginBottom: 40,
    backgroundColor: Colors.yellow400,
    width: (65 / 100) * DEVICE.WINDOW_WIDTH,
    aspectRatio: 1,
    borderRadius: 200,
    overflow: 'hidden',
  },
  image: { flex: 1, width: '100%' },

  primaryText: {
    marginBottom: 16,
    textAlign: 'center',
    fontFamily: Fonts.type.semiBold,
    color: Colors.duck800,
    fontSize: Fonts.size.h6,
  },

  secondaryText: {
    marginBottom: 40,
    textAlign: 'center',
    fontFamily: Fonts.type.regular,
    color: Colors.duck800,
    fontSize: Fonts.size.title,
  },
});
