import React, { ComponentProps } from 'react';

import Icons, { IconName } from '@resources/svg';

export interface AppIconProps
  extends ComponentProps<typeof Icons.AttachmentIcon> {
  name: IconName;
  size?: number;
}

const AppIcon = ({ name, size, ...other }: AppIconProps) => {
  const Component = Icons[name];
  const dimensions = size ? { width: size, height: size } : {};
  return <Component {...other} {...dimensions} />;
};
export default AppIcon;
