import React, { useMemo } from 'react';
import { ScrollView, Text, View } from 'react-native';

import { useSelector } from '@commonTypes/redux';
import { YellowButton } from '@components/Buttons';
import AppIcon from '@components/ui/Icon';
import BackButton from '@components/ui/Pressables/Actions/BackButton';
import ScrollableTabBar from '@components/ui/ScrollableTabBar';
import { t } from '@config';

import {
  Product,
  PRODUCT_INTERVAL,
  PRODUCT_TIER,
} from '@api-requests/api/main/payment/products/types';

import styles from './styles';
import AvailablePerksList from '../../components/AvailablePerk/AvailablePerksList';
import { SubscribeModalProps } from '../../types';

const TABS = [
  { text: t('SUBSCRIBE_MODAL.PREMIUM') },
  { text: t('SUBSCRIBE_MODAL.ESSENTIAL') },
];

const SubscribeTab = ({
  type,
  plans,
  onPress,
}: {
  type: PRODUCT_TIER;
  plans: Array<Product> | undefined;
  onPress: (selectedPlan?: Product) => void;
}) => {
  const hasAlreadyPaid = useSelector((state) => !!state.user?.data?.paidPlan);
  const monthlyPlan = plans?.find(
    (plan) => plan.interval === PRODUCT_INTERVAL.MONTH,
  );
  const yearlyPlan = plans?.find(
    (plan) => plan.interval === PRODUCT_INTERVAL.YEAR,
  );
  const monthlyAmount = monthlyPlan?.amount ?? 0;
  const yearlyAmount = yearlyPlan?.amount ?? 0;
  const isPremium = type === PRODUCT_TIER.PREMIUM;
  return (
    <View style={styles.content}>
      <AvailablePerksList style={styles.perks} isPremium={isPremium} />
      <YellowButton
        style={styles.buttonWrapper}
        onPress={() => onPress(monthlyPlan)}
        disabled={!plans}
        label={t('SUBSCRIBE_MODAL.MODAL9.CTA.MONTHLY', {
          amount: (monthlyAmount / 100).toFixed(2),
        })}
        isLoading={false}
        testID="subscribeButton"
      />
      <Text style={styles.additional}>
        {plans?.[0].amount ? t('SUBSCRIBE_MODAL.MODAL9.OFFER') : ''}
      </Text>
      <YellowButton
        style={styles.buttonWrapper}
        onPress={() => onPress(yearlyPlan)}
        disabled={!plans}
        label={t('SUBSCRIBE_MODAL.MODAL9.CTA.YEARLY', {
          amount: (yearlyAmount / 100).toFixed(2),
          context: hasAlreadyPaid ? 'notrial' : '',
        })}
        isLoading={false}
      />
      <Text style={styles.additional}>
        {monthlyAmount
          ? t('SUBSCRIBE_MODAL.MODAL9.DISCOUNT', {
              amount: ((monthlyAmount * 12) / 100).toFixed(2),
              discount: Math.round(
                (1 - yearlyAmount / (monthlyAmount * 12)) * 100,
              ),
            })
          : ''}
      </Text>
    </View>
  );
};

const SubscribeModal9 = ({
  hideModal,
  productList,
  onPress,
}: SubscribeModalProps) => {
  const plans = useMemo(
    () =>
      productList?.reduce(
        (acc, plan) => {
          if (!acc[plan.tier]) {
            acc[plan.tier] = [];
          }
          acc[plan.tier].push(plan);
          return acc;
        },
        {
          [PRODUCT_TIER.PREMIUM]: [] as Product[],
          [PRODUCT_TIER.ESSENTIAL]: [] as Product[],
        },
      ),
    [productList],
  );

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.scrollView}
      showsVerticalScrollIndicator={false}
    >
      <AppIcon style={styles.blobs} name="BlobsGrey" />
      {hideModal && (
        <BackButton
          style={styles.backButton}
          onPress={hideModal}
          testID="closeSubscribeModalButton"
        />
      )}
      <View style={styles.content}>
        <Text style={styles.title}>{t('SUBSCRIBE_MODAL.MODAL9.TITLE')}</Text>
      </View>
      <ScrollableTabBar tabTitleInfos={TABS} tabScrollViewStyle={styles.tabBar}>
        {() => [
          <SubscribeTab
            type={PRODUCT_TIER.PREMIUM}
            plans={plans?.[PRODUCT_TIER.PREMIUM]}
            onPress={onPress}
          />,
          <SubscribeTab
            type={PRODUCT_TIER.ESSENTIAL}
            plans={plans?.[PRODUCT_TIER.ESSENTIAL]}
            onPress={onPress}
          />,
        ]}
      </ScrollableTabBar>
      <Text style={styles.trialInfo}>
        {t('SUBSCRIBE_MODAL.PLAN_SECTION.FREE_TRIAL_PERIOD')}
      </Text>
    </ScrollView>
  );
};

export default SubscribeModal9;
