import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Text, View } from 'react-native';

import {
  useCreateChildren,
  useDeleteChildren,
  useUpdateChildren,
} from '@api-requests/api/main/children';
import { ReducedAxiosResponse } from '@apiTypes/utils';
import { ChildCreateForm, GENDER, Kid } from '@commonTypes/Kid';
import { useDispatch, useSelector } from '@commonTypes/redux';
import { BorderlessButton, PrimaryButton } from '@components/Buttons';
import KidPhotoUploader from '@components/KidPhotoUploader';
import { DateTimeInput } from '@components/ui/hookFormInputs/DateTimeInput';
import { RadioInput } from '@components/ui/hookFormInputs/RadioInput';
import { TextInput } from '@components/ui/hookFormInputs/TextInput';
import AppIcon from '@components/ui/Icon';
import { usePopup } from '@components/ui/Popup/hook';
import { t } from '@config';
import { USER_QUERY_KEYS } from '@hooks/useUserDetail';
import { goBack } from '@navigation/Actions';
import { userCreators } from '@redux/user';
import CakeIcon from '@resources/svg/cake.svg';
import LetterIcon from '@resources/svg/letter.svg';
import { Colors } from '@resources/themes';
import { CONSULTATIONS_QUERY_KEYS } from '@screens/Consultations/hooks/consultations';
import { UseMutateAsyncFunction, useQueryClient } from '@tanstack/react-query';
import { dayjsTZ, formatDate } from '@tools/date';
import { getBirthInformation } from '@tools/dateManipulation';

import ArchivePregnancyModal from './ArchivePregnancyModal';
import DeleteKidModal from './DeleteKidModal';
import styles from './styles';

const BoyGirlPicture = ({ value }: { value: GENDER }) =>
  value === GENDER.F ? (
    <AppIcon name="GirlIcon" size={35} />
  ) : (
    <AppIcon name="BoyIcon" size={35} />
  );

const errorMessages = (error?: { type: string }) =>
  error?.type ? t('JOURNALING.APPOINTMENTS.FORM.ERRORS.' + error.type) : '';

const options = [
  { label: t('FORM.f'), value: GENDER.F },
  { label: t('FORM.m'), value: GENDER.M },
];

const KidFormController = ({
  kid,
  onSuccess,
}: {
  kid?: Kid;
  onSuccess: (kid: Kid) => void;
}) => {
  const initialState = useMemo(
    () => ({
      // birthdays should not account for timezone - Doing this until we use YYYY-MM-DD only
      birthDay: kid?.birthDay
        ? dayjsTZ(kid.birthDay.split('Z')[0], undefined, true).toDate()
        : new Date(),
      firstName: kid?.firstName ?? '',
      sex: kid?.sex ?? GENDER.F,
      image: kid?.image,
    }),
    [kid],
  );

  const pregnancy = useSelector((state) => state?.user.data?.pregnancy);
  const isPregnancyCloseToTerm =
    !!pregnancy?.id && pregnancy.amenorrheaWeeks >= 27;
  const [showArchivePregnancyModal, setShowArchivePregnancyModal] =
    useState(false);
  const [showDeleteKidModal, setShowDeleteKidModal] = useState(false);
  const { control, formState, handleSubmit, setValue, getValues, reset } =
    useForm<ChildCreateForm>({
      defaultValues: {
        ...initialState,
        fromExistingPregnancy: false,
      },
    });
  const { isDirty, isValid, isSubmitting } = formState;

  const queryClient = useQueryClient();

  useEffect(() => {
    reset(initialState);
  }, [initialState, reset]);

  const { mutateAsync: update } = useUpdateChildren(kid?.id);
  const { mutateAsync: create } = useCreateChildren();
  const { mutate: deleteChild, isPending: isDeletingChild } =
    useDeleteChildren();
  const dispatch = useDispatch();

  const popup = usePopup();

  const handleSubmitPressed = () => {
    if (
      isPregnancyCloseToTerm &&
      getBirthInformation(getValues('birthDay')).totalMonths < 1
    ) {
      setShowArchivePregnancyModal(true);
    } else {
      submit();
    }
  };
  const archivePregnancy = () => {
    setValue('fromExistingPregnancy', true);
    setShowArchivePregnancyModal(false);
    submit();
  };
  const submit = handleSubmit(async (data) => {
    const mutate: UseMutateAsyncFunction<
      ReducedAxiosResponse<Kid>,
      any,
      any
    > = kid ? update : create;

    let birthDay;
    if (data.birthDay) {
      birthDay = formatDate(data.birthDay, 'YYYY-MM-DD', undefined, true);
    }
    if (data.firstName) {
      data.firstName = data.firstName.trim();
    }
    try {
      const { data: result } = await mutate({ ...data, birthDay });
      reset();
      queryClient.invalidateQueries({
        queryKey: USER_QUERY_KEYS.detail,
      });
      queryClient.invalidateQueries({
        queryKey: CONSULTATIONS_QUERY_KEYS.upcoming,
      });
      dispatch(userCreators.addOrUpdateKid(result));
      if (data.fromExistingPregnancy) {
        dispatch(userCreators.updateUserPregnancy({}));
      }
      onSuccess(result);
    } catch (err: any) {
      reset(data);
      popup(err?.message ?? 'Impossible de modifier les données');
    }
  });

  const deleteKid = () => {
    kid?.id
      ? deleteChild(kid.id, {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: USER_QUERY_KEYS.detail,
            });
            goBack();
          },
          onError(err) {
            popup(err.message ?? 'Impossible de supprimer les données');
          },
        })
      : null;
  };

  const cancelDeleteKid = () => setShowDeleteKidModal(false);
  const showDeleteAlert = () => setShowDeleteKidModal(true);

  return (
    <>
      <KidPhotoUploader style={styles.photo} id={kid?.id} control={control} />
      <View style={styles.formContainer}>
        <TextInput
          control={control}
          label={t('FORM.FIRST_NAME')}
          name="firstName"
          rules={{ required: true }}
          errorMessage={errorMessages}
          placeholder={t('FORM.EX')}
          leftIcon={<LetterIcon />}
          testID="kidFirstName"
        />
        <RadioInput
          control={control}
          inline={false}
          options={options}
          label={t('FORM.SEXE')}
          name={'sex'}
          inlineChoices={true}
          LeftComponent={BoyGirlPicture}
        />
        <View style={styles.datePicker}>
          <DateTimeInput
            control={control}
            mode="date"
            label={t('FORM.DATE_OF_BIRTH')}
            name="birthDay"
            inline={false}
            errorMessage={errorMessages}
            maximumDate={new Date()}
            rules={{ required: true }}
            leftIcon={<CakeIcon />}
          />
        </View>
      </View>
      <View style={styles.spacer} />
      <View style={styles.actions}>
        <PrimaryButton
          isLoading={isSubmitting}
          disabled={!isValid || !isDirty || !getValues('firstName').trim()}
          onPress={handleSubmitPressed}
          label={kid ? t('FORM.UPDATE_CHILD') : t('FORM.ADD')}
          testID="addKidButton"
        />
        {kid && (
          <BorderlessButton
            textColor={Colors.pink500}
            onPress={showDeleteAlert}
            label={t('SETTINGS.KIDS.DETAIL.DELETE')}
            isLoading={false}
            testID="deleteKid"
          />
        )}
      </View>
      <Text style={styles.secureText}>{t('PROFILE.LEGAL.SECURED_DATA')}</Text>
      <ArchivePregnancyModal
        isVisible={showArchivePregnancyModal}
        onPress={archivePregnancy}
        onClose={submit}
        isSubmitting={isSubmitting}
      />
      <DeleteKidModal
        isVisible={showDeleteKidModal}
        onPress={deleteKid}
        onClose={cancelDeleteKid}
        isSubmitting={isDeletingChild}
      />
    </>
  );
};

export default KidFormController;
