import React, { useEffect } from 'react';
import { Image, Text, View } from 'react-native';

import { logPurchase } from '@analytics/events';
import { PrimaryButton } from '@components/Buttons';
import { t } from '@config/bootstrap/i18n';
import doctorImg from '@resources/images/illu_docteur-seule-chatain.webp';
import { redirectToStore } from '@web/src/utils/redirectToStore';

import styles from './styles';

const SubscribeSuccessScreen = () => {
  useEffect(() => {
    logPurchase({
      itemBrand: 'web',
      value: 11.11,
      createdAt: new Date().toISOString(),
      paymentImage: 'web',
      tier: 'web',
      coupon: undefined,
    });
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image style={styles.image} source={doctorImg} resizeMode="contain" />
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.title}>{t('AUTH.DOWNLOAD.TITLE')}</Text>
        <Text style={styles.description} numberOfLines={3}>
          {t('AUTH.DOWNLOAD.DESCRIPTION')}
        </Text>
      </View>
      <PrimaryButton
        onPress={redirectToStore}
        label={t('AUTH.DOWNLOAD.BUTTON')}
        isLoading={false}
      />
    </View>
  );
};

export default SubscribeSuccessScreen;
