import React, { useState } from 'react';
import { Text } from 'react-native';
import { useDispatch } from 'react-redux';

import { useAddProfileDetails } from '@api-requests/api/main/profiles/hooks';
import { useSelector } from '@commonTypes/redux';
import KeyboardAvoidingSafeView from '@components/KeyboardAvoidingSafeView';
import {
  ImageTemplate,
  ImageTemplateUI,
} from '@components/ui/Templates/ImageTemplate';
import ProgressTopBar from '@components/ui/Templates/StepTemplate/ProgressTopBar';
import { StepChoice } from '@components/ui/Templates/StepTemplate/SubStepVariants/StepChoice';
import { t } from '@config';
import { useDisableBackButton } from '@hooks/useDisableBackButton';
import { goBack, navigate } from '@navigation/Actions';
import {
  COMMON_SCREEN_STACK,
  NAVIGATOR_NAMES,
  ONBOARDING_STACK,
} from '@navigation/Routes';
import { analyticsCreators } from '@redux/analytics';
import FamilyImage from '@resources/images/family_connected_pregnant.webp';
import LockImage from '@resources/images/illu_cadenas.webp';

import {
  ANSWERS_MARITAL_STATUS,
  ANSWERS_SEX,
  ANSWERS_SOURCE,
  UserAnswers,
} from '@api-requests/api/main/profiles/types';
import {
  ChoiceSubStep,
  SubStepAnswer,
} from '@components/ui/Templates/StepTemplate/types';
import { StaticContentsSlug } from '@screens/StaticContentsScreen/types';

import {
  firstStepPairs,
  secondStepPairs,
  thirdStepPairs,
  thirdStepPairsAlt,
} from './choicePairs';
import styles from './styles';

const goToStep2 = () =>
  navigate(NAVIGATOR_NAMES.ONBOARDING_NAVIGATOR, {
    screen: ONBOARDING_STACK.INTRO_FORM_SCREEN2,
  });
const goToPrivacyPolicy = () =>
  navigate(COMMON_SCREEN_STACK.STATIC_CONTENTS_SCREEN, {
    slug: StaticContentsSlug.privacy,
  });
export function IntroFormScreen1() {
  useDisableBackButton();
  return (
    <ImageTemplateUI
      image={LockImage}
      title={t('ON_BOARDING.INTRO_FORM.SCREEN1.TITLE')}
      CTALabel={t('ON_BOARDING.INTRO_FORM.SCREEN1.CTA_LABEL')}
      onNext={goToStep2}
    >
      <Text style={styles.description}>
        {t('ON_BOARDING.INTRO_FORM.SCREEN1.DESCRIPTION')}
        <Text style={styles.bold} onPress={goToPrivacyPolicy}>
          {t('ON_BOARDING.INTRO_FORM.SCREEN1.PRIVACY_POLICY')}
        </Text>
      </Text>
    </ImageTemplateUI>
  );
}

const goToStep3 = () =>
  navigate(NAVIGATOR_NAMES.ONBOARDING_NAVIGATOR, {
    screen: ONBOARDING_STACK.INTRO_FORM_SCREEN3,
  });

const stepIndexToKey = ['sex', 'maritalStatus', 'source', 'paid'] as const;
const steps: Array<ChoiceSubStep> = [
  {
    type: 'choice',
    title: t('ON_BOARDING.INTRO_FORM.SCREEN2.STEP1.TITLE'),
    description: t('ON_BOARDING.INTRO_FORM.SCREEN2.STEP1.DESCRIPTION'),
    choices: firstStepPairs,
  },
  {
    type: 'choice',
    title: t('ON_BOARDING.INTRO_FORM.SCREEN2.STEP2.TITLE'),
    description: t('ON_BOARDING.INTRO_FORM.SCREEN2.STEP2.DESCRIPTION'),
    choices: secondStepPairs,
  },
  {
    type: 'choice',
    title: t('ON_BOARDING.INTRO_FORM.SCREEN2.STEP3.TITLE'),
    description: undefined,
    choices: thirdStepPairs,
  },
  // This one is optional and will be added if the user selects the PAID option in the previous step
  {
    type: 'choice',
    title: t('ON_BOARDING.INTRO_FORM.SCREEN2.STEP_3_ALT.TITLE'),
    description: undefined,
    choices: thirdStepPairsAlt,
  },
];

export function IntroFormScreen2() {
  useDisableBackButton();
  const { mutate: addProfileDetails } = useAddProfileDetails();
  const userId = useSelector((state) => state.user.data.id)!;
  const dispatch = useDispatch();

  const sendUserProperties = (answers: UserAnswers) => {
    addProfileDetails(answers);
    dispatch(
      analyticsCreators.requestIdentification({
        id: String(userId),
        ...answers,
      }),
    );
  };

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const currentStep = steps[currentStepIndex];
  const [userAnswers, setUserAnswers] = useState<UserAnswers>({
    sex: ANSWERS_SEX.UNKNOWN,
    maritalStatus: ANSWERS_MARITAL_STATUS.UNKNOWN,
  });

  const handleEndReached = (answer: SubStepAnswer) => {
    sendUserProperties({
      ...userAnswers,
      // Making sure we are sending the latest data as we cannot rely on the state updating in time
      [stepIndexToKey[currentStepIndex]]: answer.key!,
    });
    goToStep3();
  };

  const handleAnswerPress = (answer: SubStepAnswer) => {
    setUserAnswers((prev) => ({
      ...prev,
      [stepIndexToKey[currentStepIndex]]: answer.key!,
    }));

    if (
      stepIndexToKey[currentStepIndex] === 'source' &&
      answer.key !== ANSWERS_SOURCE.PAID
    ) {
      handleEndReached(answer);
      return;
    }

    if (currentStepIndex + 1 < steps.length) {
      setCurrentStepIndex((prev) => prev + 1);
    } else {
      handleEndReached(answer);
    }
  };

  // Using a destructured StepTemplate component because we're doing some funky stuff in here
  return (
    <KeyboardAvoidingSafeView style={styles.container}>
      <ProgressTopBar
        title={t('ON_BOARDING.INTRO_FORM.SCREEN2.NAME')}
        // We don't want to update progress when adding the alt 3rd step
        progress={Math.min(currentStepIndex + 1, steps.length - 1)}
        total={steps.length - 1}
        onPress={() => {
          if (currentStepIndex === 0) {
            goBack();
          } else if (currentStepIndex > 0) {
            setCurrentStepIndex((prev) => prev - 1);
          }
        }}
      />
      <StepChoice
        step={currentStep}
        onAnswerPress={handleAnswerPress}
        previousAnswers={[]}
      />
    </KeyboardAvoidingSafeView>
  );
}

const goToFamilyOverview = () =>
  navigate(NAVIGATOR_NAMES.ONBOARDING_NAVIGATOR, {
    screen: ONBOARDING_STACK.FAMILY_OVERVIEW,
  });
export function IntroFormScreen3() {
  useDisableBackButton();
  return (
    <ImageTemplate
      image={FamilyImage}
      title={t('ON_BOARDING.INTRO_FORM.SCREEN3.TITLE')}
      description={t('ON_BOARDING.INTRO_FORM.SCREEN3.DESCRIPTION')}
      CTALabel={t('ON_BOARDING.INTRO_FORM.SCREEN3.CTA_LABEL')}
      onNext={goToFamilyOverview}
    />
  );
}
