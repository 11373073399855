import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPut,
} from '@api-requests/api/main/request';
import { PatientProfile } from '@commonTypes/AppUser';
import { AuthProviders } from '@commonTypes/Auth';
import { ChildCreate, Kid } from '@commonTypes/Kid';
import { Pregnancy } from '@commonTypes/Pregnancy';
import { S3SignedUrl } from '@commonTypes/S3';
import { SUBSCRIPTION_STATUS } from '@resources/constants/User';

import { CoParent } from './main/family/types';
import { DetailedFamilyInvitation } from './main/family-pending-invite/types';
import { PRODUCT_TIER } from './main/payment/products/types';

export interface UserDetailRaw {
  id: number;
  email: string;
  phone: string;
  consultationsNumber: number;
  familyId: string;
  firstName: string;
  lastName: string;
  kids: Kid[];
  pregnancy: Pregnancy | Record<string, never>;
  sharedFamily?: boolean;
  subscriptionStatus: SUBSCRIPTION_STATUS;
  subscriptionLevel: PRODUCT_TIER | null;
  createdAt: string;
  pushTokenHashes: number[];
  coparent?: CoParent;
  pendingInvite?: DetailedFamilyInvitation;
  profile?: PatientProfile;
}

export const appUserDetail = () =>
  axiosGet<UserDetailRaw & { authProviders: AuthProviders[] }>({
    url: '/apifo/patients',
  });

export const updateUser = (data: { firstName: string }) =>
  axiosPut<void, { firstName: string }>({
    url: '/apifo/patients',
    params: { data },
  });

export const updateKid = (id: number | string, payload: Partial<ChildCreate>) =>
  axiosPut<Kid>({
    url: `/apifo/children/${id}`,
    params: { data: payload },
  });

export const deleteKid = (id: number | string) =>
  axiosDelete<{}>({ url: `/apifo/children/${id}` });

export const addKid = (payload: ChildCreate) => {
  return axiosPost<Kid>({
    url: '/apifo/children',
    params: { data: payload },
  });
};

export const requestAvatarSignedUrl = (payload: {
  filename: string;
  ContentType: string;
}) =>
  axiosPost<S3SignedUrl>({
    url: '/apifo/children/s3-uploader',
    params: { data: payload },
  });

export default {
  getDetail: appUserDetail,
  update: updateUser,
};
