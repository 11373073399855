import { DYNAMIC_LINKS } from '@resources/constants/DynamicLinks';

export const parseDeepLink = (
  urlString: string,
): {
  pathname: DYNAMIC_LINKS;
  lookup?: string;
  urlString: string;
  searchParams: Record<string, string>;
} | null => {
  try {
    const url = new URL(urlString.replace(/^maysante:\/\//, 'https://'));

    if (
      !url.origin.includes('link.may-care.fr') &&
      !url.origin.includes('maysante.page.link') &&
      !url.origin.includes('may-sante.com') &&
      !url.origin.includes('may.app')
    ) {
      return null;
    }

    // handle long links
    let longLink = url.searchParams.get('link');
    if (longLink) {
      if (longLink.startsWith('/')) {
        longLink = 'https://maysante.page.link' + longLink;
      }
      return parseDeepLink(longLink);
    }

    const rawPathName = url.pathname.replace(/\/+/g, '/');
    const pathname = Object.values(DYNAMIC_LINKS).find(
      (path) => path === rawPathName,
    );

    if (!pathname) {
      return null;
    }

    const id = url.searchParams.get('id');
    const slug = url.searchParams.get('slug');
    const search =
      url.searchParams.get('search') ??
      url.search.replace('?', '').split('&').shift();

    const searchParams = {} as Record<string, string>;
    for (const [key, value] of url.searchParams) {
      searchParams[key] = value;
    }

    return {
      pathname,
      lookup: id || slug || search || undefined,
      urlString,
      searchParams,
    };
  } catch (error) {
    return null;
  }
};
