import React from 'react';
import { Image, StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { logProgramSelect } from '@analytics/events';
import Folder from '@commonTypes/apiResponses/Folder';
import { useSelector } from '@commonTypes/redux';
import { FavoritesButton } from '@components/FavoritesButton';
import { t } from '@config/bootstrap/i18n';
import { navigate } from '@navigation/Actions';
import { COMMON_SCREEN_STACK } from '@navigation/Routes';
import { getBestImageOf } from '@tools/getBestImageOf';
import { getImageAuthorization } from '@tools/getImageAuthorization';

import ContentCardUI from '../ContentCardUI';
import ReadingTime from '../Fields/ReadingTime';

const styles = StyleSheet.create({ image: StyleSheet.absoluteFillObject });

interface FolderUiProps {
  style?: StyleProp<ViewStyle>;
  title: string;
  highlightedTitle: string | undefined;
  uri?: string;
  readingTime: string | undefined | null;
  onPress: () => void;
  favoriteButton?: React.ReactElement | null;
  disabled?: boolean;
}
export const FolderUi = ({ uri, readingTime, ...props }: FolderUiProps) => {
  const token = useSelector((state) => state.auth.token);
  const Cover = (
    <Image
      style={styles.image}
      resizeMethod="resize"
      resizeMode="cover"
      source={{ uri, headers: getImageAuthorization(uri, token) }}
    />
  );
  const Field = readingTime && <ReadingTime readingTime={readingTime} />;
  return (
    <ContentCardUI
      {...props}
      label={t('COMMON.FOLDER')}
      imageSection={Cover}
      fields={Field}
    />
  );
};

interface FolderProps {
  style?: StyleProp<ViewStyle>;
  item: Folder;
  screen: string;
  disabled?: boolean;
}
const FolderCard = ({ item, screen, ...props }: FolderProps) => {
  const handlePress = () => {
    logProgramSelect({
      programId: item.id,
      ageLevel: item.age_levels?.[0]?.name,
      categories: item.categories?.[0]?.slug,
      screen,
      title: item.title,
    });
    navigate(COMMON_SCREEN_STACK.FOLDER_SCREEN, {
      folder: item,
      folderId: undefined,
    });
  };
  return (
    <FolderUi
      {...props}
      title={item.title}
      highlightedTitle={item.highlightedTitle}
      uri={getBestImageOf(item.cover, 'small')}
      readingTime={item.totalTime}
      onPress={handlePress}
      favoriteButton={
        <FavoritesButton id={`${item.id}`} type={'programs'} item={item} />
      }
    />
  );
};

export default FolderCard;
