import React, { useState } from 'react';

import { useWebPreOnboardingScenarios } from '@api-requests/api/cms/scenarii/onboarding-scenarios/hooks';
import { useDispatch, useSelector } from '@commonTypes/redux';
import AuthProviderModal from '@components/Modal/AuthProvider';
import { ImageTemplate } from '@components/ui/Templates/ImageTemplate';
import { StepTemplate } from '@components/ui/Templates/StepTemplate';
import { t } from '@config';
import { WebAnonymousStackParamList } from '@webNavigation/routeParams';
import { WEB_ANONYMOUS_STACK } from '@webNavigation/routes';
import { webPreOnboardingWorkflowCreators } from '@webScreens/PreOnboardingScreens/store/webPreOnboardingWorkflowSlice';

import {
  IImageTemplate,
  IStepTemplate,
  SCENARIO_TARGET,
} from '@api-requests/api/cms/scenarii/types';
import {
  ChoiceSubStep,
  SubStepAnswer,
} from '@components/ui/Templates/StepTemplate/types';
import { ScreenRoute } from '@navigation/types';

import { stepPairs, WEB_PROFILE_CHOICES } from './choicePairs';
import { handlePreOnboardingNavigation } from './handlePreOnboardingNavigation';

const profileStep: Array<ChoiceSubStep> = [
  {
    type: 'choice',
    title: t('ON_BOARDING.WEB.PROFILE.TITLE'),
    description: undefined,
    choices: stepPairs,
  },
];
const answerToFilterMap = {
  [WEB_PROFILE_CHOICES.PREGNANT]: SCENARIO_TARGET.PREGNANCY,
  [WEB_PROFILE_CHOICES.PARENT]: SCENARIO_TARGET.CHILDREN,
  [WEB_PROFILE_CHOICES.BOTH]: SCENARIO_TARGET.PREGNANCY,
};

export function PreOnboardingFormScreen() {
  const dispatch = useDispatch();
  const [displaySignupModal, setDisplaySignupModal] = useState(false);

  const scenario = useWebPreOnboardingScenarios();

  const saveUserAnswer = (answer: SubStepAnswer) => {
    if (scenario.isError || !scenario.data) {
      setDisplaySignupModal(true);
      return;
    }
    const filteredScenario = scenario.data?.filter(
      (screen) =>
        screen.type === SCENARIO_TARGET.ALL ||
        screen.type === answerToFilterMap[answer.key! as WEB_PROFILE_CHOICES],
    );
    dispatch(webPreOnboardingWorkflowCreators.set(filteredScenario));
    handlePreOnboardingNavigation({ workflow: filteredScenario });
  };

  return (
    <>
      <StepTemplate
        name={t('ON_BOARDING.WEB.PROFILE.NAME')}
        steps={profileStep}
        onAnswerPress={saveUserAnswer}
        showBack
      />
      <AuthProviderModal
        isVisible={displaySignupModal}
        onClose={() => setDisplaySignupModal(false)}
      />
    </>
  );
}

export function RemotePreOnboardingImageScreen({
  route: {
    params: { currentIndex },
  },
}: ScreenRoute<
  WebAnonymousStackParamList,
  WEB_ANONYMOUS_STACK.REMOTE_PRE_ONBOARDING_IMAGE_SCREEN
>) {
  const workflow = useSelector(
    (state) => state.webPreOnboardingWorkflow.scenario,
  );

  const [displaySignupModal, setDisplaySignupModal] = useState(false);

  const handleEndReached = () => {
    // On workflow end, display Auth modal
    if (currentIndex + 1 === workflow.length) {
      setDisplaySignupModal(true);
    } else {
      handlePreOnboardingNavigation({
        workflow,
        targetIndex: currentIndex + 1,
      });
    }
  };

  const { __component, type, ...props } = workflow[
    currentIndex
  ] as IImageTemplate;
  return (
    <>
      <ImageTemplate {...props} showBack onNext={handleEndReached} />
      <AuthProviderModal
        isVisible={displaySignupModal}
        onClose={() => setDisplaySignupModal(false)}
      />
    </>
  );
}

export function RemotePreOnboardingStepScreen({
  route: {
    params: { currentIndex },
  },
}: ScreenRoute<
  WebAnonymousStackParamList,
  WEB_ANONYMOUS_STACK.REMOTE_PRE_ONBOARDING_STEP_SCREEN
>) {
  const workflow = useSelector(
    (state) => state.webPreOnboardingWorkflow.scenario,
  );

  const [displaySignupModal, setDisplaySignupModal] = useState(false);

  const handleEndReached = () => {
    // On workflow end, display Auth modal
    if (currentIndex + 1 === workflow.length) {
      setDisplaySignupModal(true);
    } else {
      handlePreOnboardingNavigation({
        workflow,
        targetIndex: currentIndex + 1,
      });
    }
  };

  const { name, steps } = workflow[currentIndex] as IStepTemplate;
  return (
    <>
      <StepTemplate
        name={name}
        steps={steps}
        showBack
        onEndReached={handleEndReached}
      />
      <AuthProviderModal
        isVisible={displaySignupModal}
        onClose={() => setDisplaySignupModal(false)}
      />
    </>
  );
}
