import React, { ReactNode } from 'react';
import { StyleSheet, View, StyleProp, ViewStyle, Platform } from 'react-native';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  runOnJS,
  withTiming,
  SlideInDown,
  SlideOutDown,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Colors } from '@resources/themes';

import styles, { OVERDRAG } from './styles';
import Modal from '../';

interface BottomSheetModalProps {
  style?: StyleProp<ViewStyle>;
  children: ReactNode;
  onClose: () => void;
  isVisible: boolean;
  closeThresholdPercentage?: number;
  handleColor?: string;
  showHandle?: boolean;
  withSafeInsets?: boolean;
}
const BottomSheetModal = ({
  isVisible,
  style,
  children,
  onClose,
  closeThresholdPercentage = 1 / 3,
  handleColor = Colors.duck300,
  showHandle = true,
  withSafeInsets = true,
}: BottomSheetModalProps) => {
  // Doing all this to NOT use SafeAreaView because of https://github.com/th3rdwave/react-native-safe-area-context/issues/114#issuecomment-663928390
  // TL;DR: SafeAreaView flickers when mounted offscreen, which is what happens with our animations
  // We could alternatively declare useSafeAreaInsets() in all occurrences of BottomSheetModal but that would be bad DX
  const { bottom } = useSafeAreaInsets();
  const paddingBottom =
    (withSafeInsets ? bottom : 0) +
    (((StyleSheet.flatten(style)?.paddingBottom ||
      StyleSheet.flatten(style)?.padding) as number) || 0);

  const offset = useSharedValue(0);
  const bottomSheetHeightValue = useSharedValue(0);
  const toggleSheet = () => {
    onClose();
    offset.value = 0;
  };

  const pan = Gesture.Pan()
    .onChange((event) => {
      const offsetDelta = event.changeY + offset.value;
      const clamp = Math.max(-OVERDRAG, offsetDelta);
      offset.value = offsetDelta > 0 ? offsetDelta : withSpring(clamp);
    })
    .onFinalize(() => {
      const closeThreshold =
        closeThresholdPercentage * bottomSheetHeightValue.value;
      if (offset.value < closeThreshold) {
        offset.value = withSpring(0);
      } else {
        offset.value = withTiming(bottomSheetHeightValue.value, {}, () => {
          runOnJS(toggleSheet)();
        });
      }
    });

  const translateY = useAnimatedStyle(
    () => ({ transform: [{ translateY: offset.value }] }),
    [],
  );
  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      withBackdrop
      animationType={'fade'}
    >
      <GestureDetector gesture={pan}>
        <Animated.View
          style={[styles.bottomSheet, translateY, style, { paddingBottom }]}
          {...Platform.select({
            web: {},
            default: {
              entering: SlideInDown.springify().damping(60).stiffness(500),
              exiting: SlideOutDown,
            },
          })}
          onLayout={({ nativeEvent }) => {
            bottomSheetHeightValue.value = nativeEvent.layout.height;
          }}
        >
          {showHandle && (
            <View style={[styles.handle, { backgroundColor: handleColor }]} />
          )}
          {children}
          <View style={styles.overdragArea} />
        </Animated.View>
      </GestureDetector>
    </Modal>
  );
};

export default BottomSheetModal;
