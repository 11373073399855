import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import resources from '@resources/i18n/ui';
import Logger from '@tools/Logger';

const missingTranslationRegex = /^\[missing ".*" translation\]$/;

/**
 * Kévin Bidet 27/01/2022
 * Type copied from library "i18nJS" from which, react-native-i18n is an interface.
 * React-native-i18n is deprecated (it's explain in library and last release is dated
 * of 2018)
 * Theses types / interface must be removed after doing a transition to react-native-localize and
 * extract from the lib.
 */
interface InterpolateOptions {
  [key: string]: any; // interpolation
}

type Scope = string | string[];

export const i18n = i18next;
interface TranslateOptions extends InterpolateOptions {
  scope?: Scope;
  message?: string;
  defaults?: Array<{ message: string } | { scope: Scope }>;
  defaultValue?: string;
}
let init = false;
let defaultLocale = 'fr';
export function setLocale(locale: string) {
  if (!locale) {
    return;
  }
  defaultLocale = locale;
  if (init) {
    i18next.changeLanguage(locale);
  }
}

export default function bootstrapI18n() {
  i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      compatibilityJSON: 'v3',
      lng: defaultLocale,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
  init = true;
}

export function t(text: string, options?: TranslateOptions): string {
  if (!init) {
    bootstrapI18n();
  }
  const translated: string = i18n.t(text, options);

  // The translation does not exist, the default message is not very sexy
  // Instead we return the message we tried to translate
  if (missingTranslationRegex.test(translated)) {
    if (__DEV__) {
      Logger.warn(
        `translation "${translated}" does not exists in translations files`,
      );
    }
    return text;
  }

  return translated;
}
