import { StyleSheet } from 'react-native';

import DEVICE from '@resources/constants/device';
import { Colors } from '@resources/themes';

export const OVERDRAG = 20;

export default StyleSheet.create({
  fill: StyleSheet.absoluteFillObject,
  backdrop: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: `${Colors.black500}${Colors.transparent30}`,
  },
  bottomSheet: {
    position: 'absolute',
    bottom: 0,
    maxHeight: DEVICE.WINDOW_HEIGHT,
    width: DEVICE.WINDOW_WIDTH,
    alignSelf: 'center',
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    backgroundColor: Colors.white100,
  },
  handle: {
    alignSelf: 'center',
    borderRadius: 2,
    height: 4,
    marginBottom: 32,
    marginTop: 24,
    width: 48,
  },
  overdragArea: {
    position: 'absolute',
    bottom: -OVERDRAG,
    left: 0,
    right: 0,
    height: OVERDRAG,
    backgroundColor: Colors.white100,
  },
});
