import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

import App from './App';
import { RECAPTCHA_PUBLIC_KEY } from './bootstrap';
import { persistor, store } from './store';

const queryClient = new QueryClient({ queryCache: new QueryCache() });

const RootComponent = () => (
  <QueryClientProvider client={queryClient}>
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_PUBLIC_KEY}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </GoogleReCaptchaProvider>
  </QueryClientProvider>
);

export default RootComponent;
