import isEqual from 'lodash/isEqual';
import React, { useCallback } from 'react';
import { Controller, Path } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import RadioButton from '@components/ui/RadioButton';

import { InputWrapper } from '../inputWrappers';
import { ExposedControllerProps, RenderInput } from '../types';

const styles = StyleSheet.create({
  choicesInline: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  choicesInColumn: {},
});

type RadioInputProps<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
> = ExposedControllerProps<TFieldValues, TName> & {
  label?: string;
  inline?: boolean;
  inlineChoices?: boolean;
  options: (
    | {
        component: React.ReactElement;
        value: TFieldValues[TName];
      }
    | {
        label: string;
        value: TFieldValues[TName];
      }
  )[];
  LeftComponent?: React.FC<{ value: TFieldValues[TName] }>;
};
export function RadioInput<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
>({
  name,
  control,
  label,
  rules,
  options,
  inline = false,
  inlineChoices = false,
  errorMessage,
  helperText,
  LeftComponent,
}: RadioInputProps<TFieldValues, TName>) {
  const render = useCallback(
    ({ field, fieldState }: RenderInput<TFieldValues, TName>) => {
      return (
        <InputWrapper
          label={label}
          rules={rules}
          inline={inline}
          errorMessage={errorMessage}
          helperText={helperText ? helperText(field.value) : undefined}
          fieldState={fieldState}
        >
          <View
            style={
              inlineChoices ? styles.choicesInline : styles.choicesInColumn
            }
          >
            {LeftComponent ? <LeftComponent value={field.value} /> : null}
            {options.map((option, index) => (
              <RadioButton
                key={index}
                {...option}
                selected={isEqual(field.value, option.value)}
                onPress={() => field.onChange(option.value)}
                testID={'gender' + index}
              />
            ))}
          </View>
        </InputWrapper>
      );
    },
    [
      LeftComponent,
      errorMessage,
      helperText,
      inline,
      inlineChoices,
      label,
      options,
      rules,
    ],
  );

  return (
    <Controller name={name} control={control} rules={rules} render={render} />
  );
}
