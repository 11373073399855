import { axiosGet, axiosPost } from '@api-requests/api/main/request';
import { Discussion } from '@commonTypes/apiResponses/Discussion';
import {
  DiscussionEvent,
  DiscussionEventType,
} from '@commonTypes/apiResponses/DiscussionEvent';
import {
  MultipartUploadFinalizeInput,
  S3MultipartSignedUrl,
  S3SignedUrl,
} from '@commonTypes/S3';
import { NotSeenData } from '@hooks/useDiscussionUnseenCount';

const getDiscussionsList = () =>
  axiosGet<{ pregnancy: Discussion; children: Discussion[] }>({
    url: '/apifo/discussions',
  });

export const getUnseenMessages = () => {
  return axiosGet<NotSeenData[]>({
    url: '/apifo/discussion_events/notSeenCount',
  });
};

export const postKidsMessage = (payload: {
  content: string;
  discussionId: number;
  type: DiscussionEventType;
}) =>
  axiosPost<DiscussionEvent>({
    url: '/apifo/discussion_events',
    params: { data: payload },
  });

export const postPregnancyMessage = (payload: {
  content: string;
  discussionId: number;
  type: DiscussionEventType;
}) =>
  axiosPost<DiscussionEvent>({
    url: '/apifo/discussion_events-midwife',
    params: { data: payload },
  });

export const getSignedUrl = (payload: {
  filename: string;
  ContentType: string;
  type: string;
  fileSizeByte?: number;
}) =>
  axiosPost<S3SignedUrl | S3MultipartSignedUrl>({
    url: '/apifo/discussion_events/s3-uploader',
    params: { data: payload },
  });

export const finalizeUpload = (payload: MultipartUploadFinalizeInput) =>
  axiosPost<S3SignedUrl | S3MultipartSignedUrl>({
    url: '/apifo/discussion_events/s3-finalize',
    params: { data: payload },
  });

export default {
  list: getDiscussionsList,
};
