import upperFirst from 'lodash/upperFirst';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { useSelector } from '@commonTypes/redux';
import { BorderlessButton, PrimaryButton } from '@components/Buttons';
import Image from '@components/Image';
import { t } from '@config';
import { navigate, replace } from '@navigation/Actions';
import { ANONYMOUS_STACK } from '@navigation/Routes';
import DEVICE from '@resources/constants/device';
import LinkAccountImage from '@resources/images/linkAccount.webp';
import { Colors, Fonts } from '@resources/themes';

const styles = StyleSheet.create({
  container: { height: '100%', paddingHorizontal: 24 },
  imageContainer: {
    alignSelf: 'center',
    width: DEVICE.WINDOW_WIDTH * 0.5,
    aspectRatio: 1,
  },
  image: { width: '100%', height: '100%' },
  title: {
    marginTop: 24,
    color: Colors.duck800,
    fontFamily: Fonts.type.semiBold,
    fontSize: Fonts.size.h6,
    textAlign: 'center',
  },
  description: {
    marginTop: 16,
    color: Colors.duck800,
    fontFamily: Fonts.type.regular,
    fontSize: Fonts.size.input,
    textAlign: 'center',
  },
  filler: { flex: 1 },
  actions: { marginBottom: 16, gap: 16 },
});

const AttachSuggestion = () => {
  const { provider } = useSelector((state) => state.signupForm);
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.filler} />
      <View style={styles.imageContainer}>
        <Image style={styles.image} source={LinkAccountImage} />
      </View>
      <Text style={styles.title}>{t('AUTH.ATTACH.TITLE')}</Text>
      <Text style={styles.description}>
        {t('AUTH.ATTACH.DESCRIPTION', { provider: upperFirst(provider) })}
      </Text>
      <View style={styles.filler} />
      <View style={styles.actions}>
        <PrimaryButton
          label={t('AUTH.ATTACH.BUTTON')}
          onPress={() => replace(ANONYMOUS_STACK.VERIFICATION_CODE_SCREEN)}
          isLoading={false}
        />
        <BorderlessButton
          textColor={Colors.pink500}
          onPress={() => navigate(ANONYMOUS_STACK.LOGIN)}
          label={t('AUTH.ATTACH.CANCEL')}
          isLoading={false}
        />
      </View>
    </SafeAreaView>
  );
};

export default AttachSuggestion;
