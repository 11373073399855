import React from 'react';

import { ONBOARDING_STACK } from '@navigation/Routes';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import OnBoardingScreen from '@screens/OnBoarding';
import AnimationScreen from '@screens/OnBoarding/AnimationScreen';
import FamilyOverview from '@screens/OnBoarding/FamilyOverview';
import FeaturesSummaryScreen from '@screens/OnBoarding/FeaturesSummaryScreen';
import {
  IntroFormScreen1,
  IntroFormScreen2,
  IntroFormScreen3,
} from '@screens/OnBoarding/IntroFormScreens';
import {
  RemoteFormImageTemplateScreen,
  RemoteFormStepTemplateScreen,
} from '@screens/OnBoarding/RemoteFormScreens';

import { WebOnboardingStackParamList } from './routeParams';

const Stack = createNativeStackNavigator<WebOnboardingStackParamList>();
export const WebOnBoardingNavigator = () => (
  <Stack.Navigator
    initialRouteName={ONBOARDING_STACK.SUCCESS_REGISTER}
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen
      component={OnBoardingScreen}
      name={ONBOARDING_STACK.SUCCESS_REGISTER}
    />
    <Stack.Screen
      component={IntroFormScreen1}
      name={ONBOARDING_STACK.INTRO_FORM_SCREEN1}
    />
    <Stack.Screen
      component={IntroFormScreen2}
      name={ONBOARDING_STACK.INTRO_FORM_SCREEN2}
    />
    <Stack.Screen
      component={IntroFormScreen3}
      name={ONBOARDING_STACK.INTRO_FORM_SCREEN3}
    />
    <Stack.Screen
      component={FamilyOverview}
      name={ONBOARDING_STACK.FAMILY_OVERVIEW}
    />
    <Stack.Screen
      component={RemoteFormImageTemplateScreen}
      name={ONBOARDING_STACK.REMOTE_FORM_IMAGE_TEMPLATE_SCREEN}
    />
    <Stack.Screen
      component={RemoteFormStepTemplateScreen}
      name={ONBOARDING_STACK.REMOTE_FORM_STEP_TEMPLATE_SCREEN}
    />
    <Stack.Screen
      component={AnimationScreen}
      name={ONBOARDING_STACK.ANIMATION_SCREEN}
    />
    <Stack.Screen
      component={FeaturesSummaryScreen}
      name={ONBOARDING_STACK.FEATURES_SUMMARY}
    />
  </Stack.Navigator>
);
