import React from 'react';
import { Image, StyleSheet } from 'react-native';

import { GENDER } from '@commonTypes/Kid';
import { useSelector } from '@commonTypes/redux';
import AppIcon from '@components/ui/Icon';
import { getImageAuthorization } from '@tools/getImageAuthorization';

const styles = StyleSheet.create({
  profileImage: {
    borderRadius: 50,
    height: 85,
    width: 85,
    padding: 5,
  },
});

export const PhotoUI = ({ image, sex }: { image?: string; sex: GENDER }) => {
  const token = useSelector((state) => state.auth.token);
  if (image) {
    return (
      <Image
        source={{
          uri: image,
          headers: getImageAuthorization(image, token),
        }}
        style={styles.profileImage}
      />
    );
  } else if (sex === GENDER.F) {
    return <AppIcon name="GirlIcon" size={85} />;
  }
  return <AppIcon name="BoyIcon" size={85} />;
};
