import axios, { AxiosRequestConfig, AxiosResponseHeaders, Method } from 'axios';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';

import { ReducedAxiosResponse } from '@apiTypes/utils';
import { DeviceAuth } from '@services/deviceAuthentication';

import { getCredentials } from '../common';

/* ------ config authorization bearer ------ */
let authorizationBearer: string = '';
export const setAuthorizationBearer = (ab: string | null | undefined) => {
  authorizationBearer = ab ?? '';
};

/* ------ config base url ------ */
let baseURL: string = '';
export const setBaseURL = (url: string) => {
  baseURL = url;
};

/* ------ Request helper ------ */
export type responseType = {
  success: boolean;
  errorCode: string;
  data: {};
};

const MAX_TIMEOUT = 20000;

type DefaultInputData = undefined | Record<string, any> | string | {};

function axiosRequest<T = any, D extends DefaultInputData = any>(
  method: Method,
  {
    url,
    params,
    headers,
    timeout = MAX_TIMEOUT,
  }: {
    url: string;
    params: AxiosRequestConfig<D>;
    headers: Record<string, string>;
    timeout?: number;
  },
): Promise<ReducedAxiosResponse<T>> {
  return new Promise((resolve, reject) => {
    axios({
      baseURL,
      method,
      url,
      timeout,
      ...params,
      headers: {
        'Content-type': 'application/json',
        ...headers,
      },
      withCredentials: true,
    })
      .then(
        ({ request, config, headers: _h, ...payload }) => {
          resolve(
            Object.assign(payload, { config: { params: config.params } }),
          );
        },
        ({ request, ...payload }) => {
          if (payload.response) {
            reject(payload.response);
          } else {
            reject(payload);
          }
        },
      )
      .catch((e) => {
        reject(e);
      });
  });
}

/* ------ Prepare request ------ */
type BaseParams = {
  sendToken?: boolean;
};
const prepareRequestSendToken = async ({
  params,
  headers = {} as AxiosResponseHeaders,
  refreshAuthentication = false,
  recaptchaToken,
}: {
  params: BaseParams & { data?: Record<string, any> | string };
  headers?: Record<string, string>;
  refreshAuthentication?: boolean;
  recaptchaToken?: string;
}) => {
  const payload =
    'data' in params && params.data ? params.data : { empty: true };

  let deviceAuth = Platform.select<Record<string, string>>({
    web: recaptchaToken ? { 'X-Recaptcha-Token': recaptchaToken } : {},
    native: { 'X-Device-Auth': await DeviceAuth.generateSignature(payload) },
  });

  if (Platform.OS !== 'web') {
    if (refreshAuthentication) {
      const { refreshToken } = await getCredentials();
      return {
        ...headers,
        authorization: `refresh ${refreshToken}`,
        ...deviceAuth,
        'X-Version': DeviceInfo.getVersion(),
      };
    } else if (
      authorizationBearer &&
      (params.sendToken === undefined || params.sendToken)
    ) {
      return {
        ...headers,
        authorization: `jwt ${authorizationBearer}`,
        ...deviceAuth,
        'X-Version': DeviceInfo.getVersion(),
      };
    }
  }
  return {
    ...headers,
    ...deviceAuth,
    'X-Version': Platform.select({
      native: DeviceInfo.getVersion(),
      web: 'web',
      default: 'default',
    }),
  };
};

/* ------ Request POST ------ */
export const axiosPost = async <T, D extends DefaultInputData = any>({
  url,
  params = {},
  timeout,
  refreshAuthentication = false,
  recaptchaToken,
}: {
  url: string;
  params?: { data?: D } & BaseParams;
  timeout?: number;
  refreshAuthentication?: boolean;
  recaptchaToken?: string;
}) => {
  const reqParams = params.data ? { data: params.data } : {};
  const headers = await prepareRequestSendToken({
    params,
    refreshAuthentication,
    recaptchaToken,
  });
  return axiosRequest<T, D>('POST', {
    url,
    params: reqParams,
    headers,
    timeout,
  });
};

/* ------ Request PUT ------ */
export const axiosPut = async <T, D extends DefaultInputData = any>({
  url,
  params = {},
  timeout,
}: {
  url: string;
  params?: { data?: D } & BaseParams;
  timeout?: number;
}) => {
  const reqParams = params.data ? { data: params.data } : {};
  const headers = await prepareRequestSendToken({ params });
  return axiosRequest<T, D>('PUT', {
    url,
    params: reqParams,
    headers,
    timeout,
  });
};

/* ------ Request GET ------ */
export const axiosGet = async <T = any, D extends DefaultInputData = any>({
  url,
  params = {},
  timeout,
  recaptchaToken,
}: {
  url: string;
  params?: { params?: D } & BaseParams;
  timeout?: number;
  recaptchaToken?: string;
}) => {
  const headers = await prepareRequestSendToken({ params, recaptchaToken });
  return axiosRequest<T>('GET', {
    url,
    params,
    headers,
    timeout,
  });
};

/* ------ Request DELETE ------ */
export const axiosDelete = async <T = any>({
  url,
  params = {},
  timeout,
}: {
  url: string;
  params?: { params?: any } & BaseParams;
  timeout?: number;
}) => {
  const reqParams = params.params ? { params: params.params } : {};
  const headers = await prepareRequestSendToken({ params });
  return axiosRequest<T>('DELETE', {
    url,
    params: reqParams,
    headers,
    timeout,
  });
};
