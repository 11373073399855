import * as React from 'react';
import { View } from 'react-native';

import PasswordRuleTick from '@components/ui/PassRulesBlock/PasswordRuleTick';
import { t } from '@config';

import PasswordRulesBlockProps from '@components/ui/PassRulesBlock/types';

import styles from '@components/ui/PassRulesBlock/styles';

const PassRulesBlock = ({ passwordRulesState }: PasswordRulesBlockProps) => {
  return (
    <View>
      <View style={styles.ruleLine}>
        <PasswordRuleTick
          ruleCompleted={passwordRulesState.passwordMinSizeCompleted}
          text={t('AUTH.SIGN_UP.PASS_RULE_CHAR_MIN')}
        />
        <PasswordRuleTick
          ruleCompleted={passwordRulesState.oneNbCompleted}
          text={t('AUTH.SIGN_UP.PASS_RULE_ONE_NB')}
        />
      </View>
      <View style={styles.ruleLine}>
        <PasswordRuleTick
          ruleCompleted={passwordRulesState.oneUpperCompleted}
          text={t('AUTH.SIGN_UP.PASS_RULE_ONE_UPPER')}
        />
        <PasswordRuleTick
          ruleCompleted={passwordRulesState.oneLowerCompleted}
          text={t('AUTH.SIGN_UP.PASS_RULE_ONE_LOWER')}
        />
      </View>
    </View>
  );
};

export default PassRulesBlock;
