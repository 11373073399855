import shuffle from 'lodash/shuffle';

import { t } from '@config';

import {
  ANSWERS_SEX,
  ANSWERS_MARITAL_STATUS,
  ANSWERS_SOURCE,
  ANSWER_PAID_ORIGIN,
} from '@api-requests/api/main/profiles/types';

const tPath1 = 'ON_BOARDING.INTRO_FORM.SCREEN2.STEP1.CHOICES';
export const firstStepPairs = [
  { key: ANSWERS_SEX.MASCULINE, value: t(`${tPath1}.MASCULINE`) },
  { key: ANSWERS_SEX.FEMININE, value: t(`${tPath1}.FEMININE`) },
  { key: ANSWERS_SEX.UNKNOWN, value: t(`${tPath1}.UNKNOWN`) },
];

const tPath2 = 'ON_BOARDING.INTRO_FORM.SCREEN2.STEP2.CHOICES';
export const secondStepPairs = [
  { key: ANSWERS_MARITAL_STATUS.COUPLE, value: t(`${tPath2}.COUPLE`) },
  { key: ANSWERS_MARITAL_STATUS.ALONE, value: t(`${tPath2}.ALONE`) },
  { key: ANSWERS_MARITAL_STATUS.UNKNOWN, value: t(`${tPath2}.UNKNOWN`) },
];

const tPath3 = 'ON_BOARDING.INTRO_FORM.SCREEN2.STEP3.CHOICES';
export const thirdStepPairs = [
  ...shuffle([
    { key: ANSWERS_SOURCE.INSTAGRAM, value: t(`${tPath3}.INSTAGRAM`) },
    { key: ANSWERS_SOURCE.PAID, value: t(`${tPath3}.PAID`) },
    { key: ANSWERS_SOURCE.STORE_DISCOVERY, value: t(`${tPath3}.STORE`) },
    { key: ANSWERS_SOURCE.FAMILY_AND_FRIENDS, value: t(`${tPath3}.FAMILY`) },
    { key: ANSWERS_SOURCE.DOCTOR, value: t(`${tPath3}.DOCTOR`) },
    { key: ANSWERS_SOURCE.SEO, value: t(`${tPath3}.SEO`) },
  ]),
  { key: ANSWERS_SOURCE.OTHER, value: t(`${tPath3}.OTHER`) },
  { key: ANSWERS_SOURCE.UNKNOWN, value: t(`${tPath3}.UNKNOWN`) },
];

const tPath3Alt = 'ON_BOARDING.INTRO_FORM.SCREEN2.STEP_3_ALT.CHOICES';
export const thirdStepPairsAlt = [
  ...shuffle([
    { key: ANSWER_PAID_ORIGIN.FACEBOOK, value: t(`${tPath3Alt}.FACEBOOK`) },
    { key: ANSWER_PAID_ORIGIN.INSTAGRAM, value: t(`${tPath3Alt}.INSTAGRAM`) },
    { key: ANSWER_PAID_ORIGIN.GOOGLE, value: t(`${tPath3Alt}.GOOGLE`) },
    { key: ANSWER_PAID_ORIGIN.YOUTUBE, value: t(`${tPath3Alt}.YOUTUBE`) },
    { key: ANSWER_PAID_ORIGIN.TIKTOK, value: t(`${tPath3Alt}.TIKTOK`) },
    { key: ANSWER_PAID_ORIGIN.PINTEREST, value: t(`${tPath3Alt}.PINTEREST`) },
  ]),
  { key: ANSWER_PAID_ORIGIN.OTHER, value: t(`${tPath3Alt}.OTHER`) },
  { key: ANSWER_PAID_ORIGIN.UNKNOWN, value: t(`${tPath3Alt}.UNKNOWN`) },
];
