import { Platform, StyleSheet } from 'react-native';

import { Colors, Fonts } from '@resources/themes';

export default StyleSheet.create({
  inputAndIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },

  disabledInput: {
    backgroundColor: Colors.grey400,
  },

  input: {
    alignSelf: 'center',
    borderBottomWidth: 1,
    borderColor: Colors.grey500,
    color: Colors.duck800,
    fontFamily: Fonts.type.medium,
    fontSize: Fonts.size.medium,
    height: 25,
    paddingHorizontal: 8,
    paddingVertical: 0,
    flex: 1,
    width: '100%',
    ...Platform.select({
      web: {
        fontSize: Fonts.size.mediumUp,
        outlineWidth: 0,
      },
      default: {},
    }),
  },

  multilineInput: {
    alignSelf: 'center',
    borderWidth: 1,
    borderColor: Colors.grey500,
    color: Colors.duck800,
    fontFamily: Fonts.type.medium,
    fontSize: Fonts.size.medium,
    height: 100,
    borderRadius: 4,
    padding: 8,
    width: '100%',
    flex: 1,
  },

  showPasswordTouchable: {
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    right: 2,
    top: 0,
  },

  showPasswordText: {
    color: Colors.duck800,
    fontFamily: Fonts.type.medium,
    fontSize: Fonts.size.small,
    lineHeight: 16,
  },
});
